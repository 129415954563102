import React, { useState, useEffect } from 'react'
import { initialUser } from './_models'
import { useCallback } from 'react'
import * as Yup from 'yup'
import { Formik, Field, useFormik, ErrorMessage } from 'formik'
import axios from "axios";
import moment from "moment";
import { useDropzone, } from 'react-dropzone';
import Backdrop from '@mui/material/Backdrop';
import { Typography } from '@mui/material';
import { toast } from 'react-toastify';
import ToastOption from '../Common/ToastOption'
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { PageLoading } from '../../../modules/wizards/common/PageLoading'
const UploadDailyStatus = `/api/Claims/UploadDailyStatus`
const UserDetailsSchema = Yup.object().shape({
  FromDate: Yup.string().required('From Date is required'),
})

function bytesForHuman(bytes: any) {
  let units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']

  let i = 0

  for (i; bytes > 1024; i++) {
    bytes /= 1024;
  }

  return bytes.toFixed(1) + ' ' + units[i]
}
function onFileUploaded(file: any) {
  console.log("file", file)
  throw new Error('Function not implemented.');
}

const DailyStatusUpload: React.FC = () => {
  const [childData, setChildData] = useState("");
  const [open, setOpen] = React.useState(false);
  const [isLoading, setLoading] = useState(false)
  const [ErrorDataMismatchTemplate, setErrorDataMismatchTemplate] = useState([]);
  const [SuccessFileNames, setSuccessFileNames] = useState([]);
  const [DataNoFound, setDataNoFound] = useState([]);
  const [DateMonthFrom, setDateMonthFrom] = useState("")
  const [FromDate, setFromDate] = useState("")
 
  const formik = useFormik({
    initialValues: initialUser,
    validationSchema: UserDetailsSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      try {

        let error = false
        let ErrorArray = []

        if (!DateMonthFrom) {
          ErrorArray.push('Please Select Choose From');
          error = true
        }
        if (!childData) {
          ErrorArray.push('Please Select File');
          error = true
        }
        if (error) {
          ErrorArray.map((res, ind) => {
            toast.error(`${res}`, ToastOption);
          })
          return false;
        }
        var formData = new FormData();
        formData.append("status_date", FromDate);
        Object.entries(childData).forEach(([key, value]) => {
          formData.append("ClaimFiles", value);
        });

        setLoading(true)
        const response = await axios.put(UploadDailyStatus, formData)

        var GetResponseData = response.data
        var MismatchTemplates = GetResponseData.Errors.MismatchTemplates
        var DataNoFound = GetResponseData.Errors.DataNoFound

        var SuccessFileNames = GetResponseData.SuccessFileNames
        if (MismatchTemplates.length > 0) {
          setErrorDataMismatchTemplate(MismatchTemplates)
        } else {
          setErrorDataMismatchTemplate([])
        }
        if (SuccessFileNames.length > 0) {
          setSuccessFileNames(SuccessFileNames)
        } else {
          setSuccessFileNames([])
        }
        if (DataNoFound.length > 0) {
          setDataNoFound(DataNoFound)
        } else {
          setDataNoFound([])
        }
        setChildData("")
        setLoading(false)
      } catch (ex) {
        setLoading(false)
        console.error(ex)
      }
    },
  })

  const handleDateChangeFrom = (date: any) => {
    setDateMonthFrom(date)
    setFromDate(moment(date['$d']).format("YYYY-MM-DD"))
    formik.setFieldValue("FromDate", moment(date['$d']).format("MMM-YYYY"))
  };

  const onDrop = useCallback((acceptedFiles: any[]) => {

    var filestotal: any = acceptedFiles.map((file: any) => Object.assign(file, {
      preview: URL.createObjectURL(file)
    }))
    setChildData(filestotal)

  }, [onFileUploaded]);

  const {
    acceptedFiles,
    getRootProps,
    getInputProps
  } = useDropzone({

    onDrop,
    accept: {
      'application/vnd.ms-excel': ['.xlsx']
    },
  });
  const acceptedFileItems: any = acceptedFiles.map((file: any) => {

    return (<li key={file.path}>
      {file.path} - {bytesForHuman(file.size)}
    </li>)
  });
  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
        <Typography variant="h6" component="div" color="inherit" mt={2} ml={4}>
          Loading...
        </Typography>
      </Backdrop>

      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Daily Status Upload</h3>
        </div>
      </div>
      <hr />
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <Formik initialValues={{ myField: '', }} onSubmit={values => { console.log(values); }}>
            <div className='row'>


              <div className='col-sm-4 fv-row'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>
                  <span className='required'>Status Date</span>
                </label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker

                    value={DateMonthFrom}
                    minDate={moment("2015")}
                    renderInput={(params: any) => <TextField  {...params} />}
                    maxDate={moment()}
                    views={['day', 'month', 'year']} disableFuture
                    onChange={handleDateChangeFrom}
                  />
                </LocalizationProvider>
                {formik.touched.FromDate && formik.errors.FromDate && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.FromDate}</div>
                  </div>
                )}
              </div>



              <div className='w-100 mt-15'>

                <div className='fv-row mb-10'>
                  <label className='form-label required'>Select File</label>


                  <div {...getRootProps({ className: 'dropzone' })}>

                    <Field name="ClaimFiles" id="ClaimFiles" type="file" multiple

                      {...getInputProps()} />

                    <p>Drag 'n' drop some File here, or click to select File</p>

                  </div>
                  <br />
                  <aside>
                    <ul>{acceptedFileItems}</ul>
                  </aside>

                  <div className='text-danger mt-2'>
                    <ErrorMessage name='ClaimFiles' />
                  </div>

                  {
                    SuccessFileNames.length > 0 ?
                      <>
                        <h4>Success Report : </h4>
                        {SuccessFileNames.map((res: any) => {
                          return (<li dangerouslySetInnerHTML={{ __html: res }}></li>)
                        })}
                        <br />
                      </>
                      : null
                  }

                  {
                    DataNoFound.length > 0 ?
                      <>
                        <h4>No Data Found Report : </h4>
                        {DataNoFound.map((res: any) => {
                          return (<li dangerouslySetInnerHTML={{ __html: res }}></li>)
                        })}
                        <br />
                      </>
                      : null
                  }

                  {
                    ErrorDataMismatchTemplate.length > 0 ?
                      <>
                        <h4>Template Mismtach Report : </h4>
                        {ErrorDataMismatchTemplate.map((res: any) => {
                          return (<li dangerouslySetInnerHTML={{ __html: res }}></li>)
                        })}
                      </>
                      : null
                  }
                </div>


              </div>



            </div>
          </Formik>
        </div>

        <div className='text-center pt-15'>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        <div>
        </div>
      </form>
      {isLoading && <PageLoading />}
    </>
  )
}
export { DailyStatusUpload }