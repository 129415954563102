import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import axios from "axios";
import { toast } from 'react-toastify';
import ToastOption from './Common/ToastOption'
import { useAuth } from '../../modules/auth'
import { Chart } from "react-google-charts";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Select from 'react-select';
import swal from "sweetalert"
import moment from "moment";
const getPracticeName = `/api/PracticeSummary/getPracticeName`
const getNcr = `/api/PracticeSummary/getNcr`

const options = {
  title: "Net Collection Ratio",
  is3D: true,
  pieSliceText: "value",
};

const NetCollectionRatio: React.FC = () => {
  const intl = useIntl()
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails: any = location.state;
  const { currentUser }: any = useAuth()
  const [UserNameDatas, setUserNameDatas] = useState([{ value: "", label: "" }])
  const [DateMonthFrom, setDateMonthFrom] = useState("")
const [FromDate, setFromDate] = useState("")
const [ToDate, setToDate] = useState("")
  const [DateMonthTo, setDateMonthTo] = useState("")
  const [SelectedManager, setSelectedManager] = useState({ value: "", label: "" })
  const [SelectedPracticeList, setSelectedPracticeList] = useState([])
  const [PieChartData, setPieChartData] = useState([])

  const [ArData, setArData] = useState([])
  const [NcrData, setNcrData] = useState([])
  const [ChargesByPostDateData,setChargesByPostDateData] = useState([])
  const [NoData,setNoData]=useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const FilterDataList = async () => {
    setNoData(false);
    setOpen(true)
    var tempValues = {
      UserId :currentUser._id,
      Path:window.location.pathname,
      FromDate:FromDate,
      ToDate:ToDate,
      PracticeName : JSON.stringify(SelectedPracticeList)
    }
    axios.get(`${getNcr}?tempValues=${JSON.stringify(tempValues)}&FromDate=${FromDate}&ToDate=${ToDate}&PracticeName=${Object.keys(SelectedPracticeList).length > 0 ? JSON.stringify(SelectedPracticeList) : ""}`).then(_res => {
      if(_res.data.NcrData.length ===0){
        setNoData(true);
        setOpen(false)
      }
      else{
        setOpen(false)
        setNcrData(_res.data.NcrData)
        setChargesByPostDateData(_res.data.QueryChargesByPostDateData)
      }

    })


  }
  const getSelectedPractice = (event: any) => {
    setSelectedPracticeList(event)
  }
  
  const handleDateChangeFrom = (date: any) => {
    setDateMonthFrom(date)
setFromDate(moment(date['$d']).format("MMM-YYYY"))
  };
  const handleDateChangeTo = (date: any) => {
    setDateMonthTo(date)
setToDate(moment(date['$d']).format("MMM-YYYY"))
  };

  const Filter = async (ids: any) => {

    let error = false
    let ErrorArray = []
    if(!FromDate){
      error = true
      ErrorArray.push(`Please Choose From Month / Year`)
    }
    if(!ToDate){
      error = true
      ErrorArray.push(`Please Choose To Month / Year`)
    }
    if(SelectedPracticeList.length == 0){
      error = true
      ErrorArray.push(`Please Select Practice`)
    }
    if(error){
      ErrorArray.map((res,ind)=>{
        toast.error(`${res}`,ToastOption);
      })
      return false;
    }
    if (SelectedPracticeList.length ===0) {
      swal({
        title: 'Practice',
        icon: "info",
        text: `Please choose any Practice`,
      }).then((_res: any) => {

      })
      return false;
    }

    
    FilterDataList()
  }
  const ClearFilter = async (ids: any) => {

    setDateMonthFrom("")
    setDateMonthTo("")
    setSelectedPracticeList([])
    setPieChartData([])

  }
  const ParameterStorage = async () => {
    setOpen(true)
    const getPmListData = await axios.get(`/api/PracticeSummary/GetParameterStorage?Path=${window.location.pathname}`)
    setOpen(false)
    if (getPmListData.data != null) {
      let GetData :any= getPmListData.data
      setDateMonthFrom(GetData.FromDate)
      setDateMonthTo(GetData.ToDate)
      setFromDate(GetData.FromDate)
      setToDate(GetData.ToDate)
      setSelectedPracticeList(JSON.parse(GetData.PracticeName))
    }
  }
  const getPracticeNameata = async () => {
    const Result = await axios.get(getPracticeName)
    setUserNameDatas(Result.data.PracticeData)
  }
  useEffect(() => {
    getPracticeNameata()
    ParameterStorage()
  }, [])
  var UsersListData: any = []
  UserNameDatas.map((practiceList: any, index) => {
    UsersListData.push({
      label: practiceList.practice_name,
      value: practiceList.practice_name
    })
  })

  const getMonthValue = (month:string) => {
    const monthMap:any = {
      'Jan': 1,
      'Feb': 2,
      'Mar': 3,
      'Apr': 4,
      'May': 5,
      'Jun': 6,
      'Jul': 7,
      'Aug': 8,
      'Sep':9,
      "Oct":10,
      "Nov":11,
      "Dec":12
    };
    return monthMap[month];
  };

  var TotalListChart = []
  TotalListChart.push(["Month","NCR"])

  if(NcrData.length > 0){
    NcrData.map((res: any, _inde: any) => {
      var GetTotalBilledAmount :any = ChargesByPostDateData.filter((x:any)=>x.month_year === res.month_year)
      // var TotalCharges = Number(Number(res.total_paid) + Number(res.adjustments))
      // //FinalCharges = (res.total_paid / TotalCharges) * 100
      // FinalCharges = TotalCharges / TotalBilledAmount * 100
      // var TotalCharges = Number(Number(res.total_paid) + Number(res.adjustments))
      // var FinalCharges = TotalCharges / GetTotalBilledAmount[0].billed_amount * 100
      var FinalCharges = (Number(res.total_paid) / (GetTotalBilledAmount[0].billed_amount - res.adjustments)) * 100
      // const roundedFinalCharges = parseFloat(FinalCharges.toFixed(2));
      TotalListChart.push([res.month_year,parseFloat(FinalCharges.toFixed(0))])
      
    })


    TotalListChart.sort((a, b) => {
      const monthA :any= getMonthValue(a[0].split('-')[0]);
      const monthB :any= getMonthValue(b[0].split('-')[0]);
      return monthA - monthB;
    });
  }
  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <div >
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>KPI’S – Net Collection Ratio</h3>
          </div>
        </div>
        <hr />
        <div className='row'>
          
            <>
              <div className='col-sm-3 fv-row'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>
                  <span className='required'>From</span>
                </label><br />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                  value={DateMonthFrom}
                  minDate={moment("2015")}
                  renderInput={(params:any) => <TextField {...params}  />}
                  maxDate={moment()}
                  views={['year', 'month']} disableFuture
                    onChange={handleDateChangeFrom}
                  />
                </LocalizationProvider>
              </div>
              <div className='col-sm-3 fv-row'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>
                  <span className='required'>To</span>
                </label><br />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                  value={DateMonthTo}
                  minDate={moment("2015")}
                  renderInput={(params:any) => <TextField {...params}  />}
                  maxDate={moment()}
                  views={['year', 'month']} disableFuture
                    onChange={handleDateChangeTo}
                  />
                </LocalizationProvider>
              </div>
              <div className='col-sm-3 fv-row'>
                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                  <span className='required'>Select Practice</span>
                </label>

                <Select maxMenuHeight={180} value={SelectedPracticeList}  onChange={getSelectedPractice} id="Practice" name={"Practice"} options={UsersListData} />

              </div></>
          <div className='col-1 pt-13'>
            <button onClick={(e) => { Filter(e) }} className='btn btn-primary'  >Filter</button>
          </div>
          <div className='col-1 pt-13'>
            <button onClick={(e) => { ClearFilter(e) }} className='btn btn-primary'  >Clear</button>
          </div>
       
        </div><hr />

        <div className='row mb-4'>
          {NcrData.length > 0 ? 

          <div className='col-12 d-flex justify-content-center'  style={{
    backgroundColor: 'lightgray', 
    boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.5)', 
    padding: '20px',
  }}>

            <Chart
            chartType="PieChart"
            data={TotalListChart}
            options={options}
            width={"100%"}
            height={"400px"}
          />
            
          </div>
            : NoData ?
                  <h5 className="fw-bold">No Data Found</h5>
                  : null}
            
           
          
        </div>
      </div>
    </>
  )
}
export { NetCollectionRatio }