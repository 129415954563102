import React, { useState, useEffect } from 'react'
import { initialUser } from './_models'
import * as Yup from 'yup'
import { Formik, useFormik } from 'formik'
import axios from "axios";
import Select from 'react-select';
import { toast } from 'react-toastify';
import ToastOption from '../Common/ToastOption'
import Backdrop from '@mui/material/Backdrop';
import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
const getPracticeList = `/api/practice/getPracticeListById`
const getPmList = `/api/pmsystem/getPmList`
const getReportNameList = `api/PracticeSummary/getReportNameList`
const UserDetailsSchema = Yup.object().shape({
  PmName: Yup.string().required('PM Name  is required'),
  ReportName: Yup.string().required('Report Name is required'),
  Practice: Yup.string().required('Practice Name is required'),
})
const SampleTemplateReport: React.FC = () => {

  const [SelectedPmSystem, setSelectedPmSystem] = useState({ label: "", value: "" })
  const [SelectedPractice, setSelectedPractice] = useState({ label: "", value: "" })
  const [SelectedReportName, setSelectedReportName] = useState({ label: "", value: "" })
  const [PracticeListData, setPracticeListData] = useState([])
  const [ReportNameList, setReportNameList] = useState([])
  const [getPmListData, setdatass] = useState([{ PmName: "", _id: "" }])
  const [open,setOpen]=useState<boolean>(false);

  const GetPmList = async () => {
    const getPmListData = await axios.get(getPmList)
    if (getPmListData.data) {
      setdatass(getPmListData.data.data)
    }
    const GetPracticeListData = await axios.get(getReportNameList)
    if (getPmListData.data) {
      setReportNameList(GetPracticeListData.data.TableColumns)
    }
  }
  const DownloadFile = async (e: any) => {
    setOpen(true);

    e.preventDefault();
    let error = false
    let ErrorArray = []
    if (!SelectedPmSystem.value) {
      ErrorArray.push('Please Select PM System');
      error = true
    }
    if (!SelectedPractice.value) {
      ErrorArray.push('Please Select Practice');
      error = true
    }
    if (!SelectedReportName.value) {
      ErrorArray.push('Please Select Report Name');
      error = true
    }
    if (error) {
      ErrorArray.map((res, ind) => {
        toast.error(`${res}`, ToastOption);
      })
      return false;
    }
    await axios.get(e.target.href, { responseType: 'arraybuffer', }).then((response) => {
      if (response.status == 200) {
        toast.success(`Sample Excel Downloaded`, ToastOption);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `SampleTemplate_${SelectedPractice.label}_${SelectedReportName.value}.xlsx`);
        document.body.appendChild(link);
        link.click();
        setOpen(false)

      } else {
        setOpen(false)

        toast.info(`No Data Found`, ToastOption);
      }
    }).catch((error) => console.log(error));
    return false;
  }
  const formik = useFormik({
    initialValues: initialUser,
    validationSchema: UserDetailsSchema,
    onSubmit: async (values, { setSubmitting }) => {

    },
  })
  useEffect(() => {
    GetPmList()
  }, [])
  const getPractice = (event: any) => {
    if (Object.keys(event).length > 0) {

      setSelectedReportName({ label: "", value: "" })
      setSelectedPractice(event)
      formik.setFieldValue("Practice", event.label)
    } else {
      setSelectedReportName({ label: "", value: "" })
      setSelectedPractice({ label: "", value: "" })
    }

  }
  const getReportNameColumns = (event: any) => {
    setSelectedReportName(event)
  }

  const getPMSystem = (event: any) => {
    //setSelectedPractice([])
    setSelectedPmSystem(event)
    if (!event.value) {
      formik.setFieldError("PmName", "PM System is required")
    } else {
      formik.setFieldError("PmName", "")
    }

    if (event.value) {
      formik.setFieldValue("PmName", event.value)
      axios.post(getPracticeList, { PostData: event.value }).then((res) => {
        setPracticeListData(res.data.data)
      })
    }
  };
  var PmListData = [{ label: "Select", value: "" }]
  getPmListData.map((name) => (
    PmListData.push({
      label: name.PmName,
      value: name._id
    })
  ))

  return (
    <>
    <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
        // onClick={handleClose}
        >
            <CircularProgress color="inherit" />
            <Typography variant="h6" component="div" color="inherit" mt={2} ml={4}>
            Loading...
            </Typography>
        </Backdrop>
      <div className='card-header border-0 cursor-pointer' role='button' data-bs-toggle='collapse' data-bs-target='#kt_account_profile_details' aria-expanded='true' aria-controls='kt_account_profile_details' >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Report</h3>
        </div>
      </div>
      <hr />
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div className='d-flex flex-column  me-n7 pe-7' id='kt_modal_add_user_scroll'>
          <Formik initialValues={{ myField: '', }} onSubmit={values => { console.log(values); }}>
            <div className='row'>
              <div className='col-4 fv-row mb-7'>
                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                  <span className='required'>PM System</span>
                </label>
                <Select maxMenuHeight={180} value={SelectedPmSystem} onChange={getPMSystem} id="PmId" name={"PmId"} options={PmListData} />
                {formik.touched.PmName && formik.errors.PmName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.PmName}</div>
                  </div>
                )}
              </div>
              <div className='col-sm-4 fv-row'>
                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                  <span className='required'>Practice Name</span>
                </label>
                <Select maxMenuHeight={180} value={SelectedPractice} onChange={getPractice} id="Practice" name={"Practice"} options={PracticeListData} />
                {formik.touched.Practice && formik.errors.Practice && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.Practice}</div>
                  </div>
                )}
              </div>
              <div className='col-sm-4 fv-row'>
                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                  <span className='required'>Select Report Name</span>
                </label>
                <Select maxMenuHeight={180} value={SelectedReportName} onChange={getReportNameColumns} id="ReportName" name={"ReportName"} options={ReportNameList} />
                {formik.touched.ReportName && formik.errors.ReportName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.ReportName}</div>
                  </div>
                )}
              </div>
              <div className='col-sm-3 fv-row'>
                
              </div>
            </div>
          </Formik>
        </div>
        
          <a rel="noreferrer" onClick={(e) => { DownloadFile(e) }} href={`/api/PracticeSummary/templateDownlaod?practice_id=${SelectedPractice.label}&ReportName=${SelectedReportName.value}`} className='btn btn-lg btn-primary me-3'>
            Sample Template Downlaod
          </a>
        
        <div>
        </div>
      </form>
    </>
  )
}
export { SampleTemplateReport }