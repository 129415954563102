import React, { useState, useEffect, FC, ChangeEvent } from 'react'
//import { useIntl } from 'react-intl'
import axios from "axios";
import { SidebarMenuItem } from './SidebarMenuItem'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { useAuth } from '../../../../../app/modules/auth'
import CPTClassChargesPie from '../../../../../app/pages/dashboard/components/CPTClassChargesPie';

const SidebarMenuMain = () => {
  //const intl = useIntl()
  const { currentUser } = useAuth()
  const [PracticeList, setUserNameDatas] = useState([{ value: "", label: "" }])
  let ListofPermission: any = currentUser?.PermissionsList
  let UserRole: any = currentUser
  const getPowerBiPracticeName = `/api/PracticeSummary/getPowerBiPracticeName`
  const getPracticeNameata = async () => {
    const Result = await axios.get(getPowerBiPracticeName)
    setUserNameDatas(Result.data.PracticeData)
  }
  useEffect(() => {
    //getPracticeNameata()
  }, [])

  const OpenLink =(practice_id:any)=>{
      window.open("https://app.powerbi.com/home?experience=power-bi","_blank")
  }
  let CreatePractice = ListofPermission.findIndex((getLabel: any) => getLabel.label === "CreatePractice");
  let DefineFields = ListofPermission.findIndex((getLabel: any) => getLabel.label === "DefineFields");
  let ClaimUpload = ListofPermission.findIndex((getLabel: any) => getLabel.label === "ClaimUpload");
  let UploadPowerBIReports = ListofPermission.findIndex((getLabel: any) => getLabel.label === "UploadPowerBIReports");
  let ARClaimUpload = ListofPermission.findIndex((getLabel: any) => getLabel.label === "ARClaimUpload");
  let CPTReport = ListofPermission.findIndex((getLabel: any) => getLabel.label === "CPTReport");
  
  
  let MonthEndData = ListofPermission.findIndex((getLabel: any) => getLabel.label === "MonthEndData");
  let OpenClaimsAR = ListofPermission.findIndex((getLabel: any) => getLabel.label === "OpenClaimsAR");
  let UploadFeeSchedule = ListofPermission.findIndex((getLabel: any) => getLabel.label === "UploadFeeSchedule");
  let ViewUploadedData = ListofPermission.findIndex((getLabel: any) => getLabel.label === "ViewUploadedData");
  let ExportPDF = ListofPermission.findIndex((getLabel: any) => getLabel.label === "ExportPDF");
  let dashboard = ListofPermission.findIndex((getLabel: any) => getLabel.label === "dashboard");
  let ChargesCollectionsByLocation = ListofPermission.findIndex((getLabel: any) => getLabel.label === "ChargesCollectionsByLocation");

  let PaymentsBreakDownbyProvider = ListofPermission.findIndex((getLabel: any) => getLabel.label === "PaymentsBreakDownbyProvider");
  let PaymentsBreakDownbyPayer = ListofPermission.findIndex((getLabel: any) => getLabel.label === "PaymentsBreakDownbyPayer");
  let ARDetails = ListofPermission.findIndex((getLabel: any) => getLabel.label === "ARDetails");
  let DaysInAR = ListofPermission.findIndex((getLabel: any) => getLabel.label === "DaysInAR");
  let NetCollectionRatio = ListofPermission.findIndex((getLabel: any) => getLabel.label === "NetCollectionRatio");
  let WaterFallCollectionReport = ListofPermission.findIndex((getLabel: any) => getLabel.label === "WaterFallCollectionReport");
  let RejectionRate = ListofPermission.findIndex((getLabel: any) => getLabel.label === "RejectionRate");
  let DenialRate = ListofPermission.findIndex((getLabel: any) => getLabel.label === "DenialRate");
  let CleanClaimRatio = ListofPermission.findIndex((getLabel: any) => getLabel.label === "CleanClaimRatio");
  let BenchMark = ListofPermission.findIndex((getLabel: any) => getLabel.label === "BenchMark");
  let PracticeSummaryDownload = ListofPermission.findIndex((getLabel: any) => getLabel.label === "PracticeSummaryDownload");
  let ReimbursementAuditReport = ListofPermission.findIndex((getLabel: any) => getLabel.label === "ReimbursementAuditReport");
  let ProfitLoss = ListofPermission.findIndex((getLabel: any) => getLabel.label === "ProfitLoss");

  let ChargesByCPTClass = ListofPermission.findIndex((getLabel: any) => getLabel.label === "ChargesByCPTClass");
  let PaymentsByCPTClass = ListofPermission.findIndex((getLabel: any) => getLabel.label === "PaymentsByCPTClass");
  let ProfitLossDash = ListofPermission.findIndex((getLabel: any) => getLabel.label === "ProfitLossDash");
  let ProviderProductivity = ListofPermission.findIndex((getLabel: any) => getLabel.label === "ProviderProductivity");
  let PayerMixReport = ListofPermission.findIndex((getLabel: any) => getLabel.label === "PayerMixReport");
  let GuarantorAging = ListofPermission.findIndex((getLabel: any) => getLabel.label === "GuarantorAging");
  let DrugNonDrug = ListofPermission.findIndex((getLabel: any) => getLabel.label === "DrugNonDrug");
  let ExportPDFCom = ListofPermission.findIndex((getLabel: any) => getLabel.label === "ExportPDFCom");
  let SampleTemplateReport = ListofPermission.findIndex((getLabel: any) => getLabel.label === "SampleTemplateReport");
  let SampleTemplateFeeSchedule = ListofPermission.findIndex((getLabel: any) => getLabel.label === "SampleTemplateFeeSchedule");
  let PowerBI = ListofPermission.findIndex((getLabel: any) => getLabel.label === "PowerBI");
  let FindyourDrugsPL = ListofPermission.findIndex((getLabel: any) => getLabel.label === "FindyourDrugsPL");
  let CollectedPaymentsReview = ListofPermission.findIndex((getLabel: any) => getLabel.label === "CollectedPaymentsReview");
  return (
    <>
      {/* {ListofPermission.length > 0 ? */}
      <>
      {ListofPermission[CreatePractice].value || ListofPermission[DefineFields].value ?  
        <SidebarMenuItemWithSub to='/error' title='Create / Define Practice' fontIcon='bi-sticky' icon='/media/icons/duotune/general/gen028.svg' >
          {ListofPermission[CreatePractice].value ?
            <SidebarMenuItem to='/CreatePractice' icon='/media/icons/duotune/art/art002.svg' title={"Create/Edit Practice"} fontIcon='bi-app-indicator' /> : null}
          {ListofPermission[DefineFields].value ?
            <SidebarMenuItem to='/DefineFields' icon='/media/icons/duotune/art/art002.svg' title={"Define Fields"} fontIcon='bi-app-indicator' /> : null}
        </SidebarMenuItemWithSub> : null
      }
      { ListofPermission[ClaimUpload].value ||ListofPermission[CPTReport].value||ListofPermission[UploadPowerBIReports].value || ListofPermission[ARClaimUpload].value || ListofPermission[MonthEndData].value || ListofPermission[OpenClaimsAR].value || ListofPermission[UploadFeeSchedule].value || ListofPermission[ViewUploadedData].value ? 
        <SidebarMenuItemWithSub to='/error' title='Report Management' fontIcon='bi-sticky' icon='/media/icons/duotune/general/gen028.svg' >

          {ListofPermission[ClaimUpload].value ?
            <SidebarMenuItem to='/ClaimUpload' title='Upload Report' hasBullet={true} /> : null}
            
            
          {ListofPermission[CPTReport].value ?
            <SidebarMenuItem to='/CPTReport' title='CPT Update' hasBullet={true} /> : null}

          {ListofPermission[UploadPowerBIReports].value ?
            <SidebarMenuItem to='/UploadPowerBIReports' title='Upload Power BI Reports' hasBullet={true} /> : null}

          {ListofPermission[ARClaimUpload].value ?
            <SidebarMenuItem to='/ARClaimUpload' title='Upload Open claims' hasBullet={true} />: null}

          {ListofPermission[MonthEndData].value ?
            <SidebarMenuItem to='/MonthEndData' title='Input Month End Data' hasBullet={true} /> : null}
            
          {ListofPermission[OpenClaimsAR].value ?
            <SidebarMenuItem to='/OpenClaimsAR' title='Input AR Issues' hasBullet={true} /> : null}

          {ListofPermission[UploadFeeSchedule].value ?
            <SidebarMenuItem to='/UploadFeeSchedule' title='Upload Fee Schedule' hasBullet={true} /> : null}

          {ListofPermission[ViewUploadedData].value ?
            <SidebarMenuItem to='/ViewUploadedData' title='View Uploaded Reports' hasBullet={true} /> : null}
        </SidebarMenuItemWithSub> : null
      }
        <SidebarMenuItemWithSub to='/error' title='Month End Dashboards' fontIcon='bi-sticky' icon='/media/icons/duotune/general/gen028.svg' >

          {ListofPermission[dashboard].value ?
            <SidebarMenuItem to='/dashboard' icon='/media/icons/duotune/art/art002.svg' title={`Practice Wise Charges & Collections`} fontIcon='bi-app-indicator' /> : null}
          {ListofPermission[ChargesCollectionsByLocation].value ?
            <SidebarMenuItem to='/ChargesCollectionsByLocation' icon='/media/icons/duotune/art/art002.svg' title={"Location Wise Charges & Collections"} fontIcon='bi-app-indicator' /> : null}
          {ListofPermission[PaymentsBreakDownbyProvider].value ?
            <SidebarMenuItem to='/PaymentsBreakDownbyProvider' icon='/media/icons/duotune/art/art002.svg' title={"Provider Wise Charges & Collections"} fontIcon='bi-app-indicator' /> : null}
          {ListofPermission[PaymentsBreakDownbyPayer].value ?
            <SidebarMenuItem to='/PaymentsBreakDownbyPayer' icon='/media/icons/duotune/art/art002.svg' title={"Payer Mix-Collections"} fontIcon='bi-app-indicator' /> : null}
          {ListofPermission[ARDetails].value ?
            <SidebarMenuItem to='/ARDetails' icon='/media/icons/duotune/art/art002.svg' title={"AR Details"} fontIcon='bi-app-indicator' /> : null}
          {ListofPermission[DaysInAR].value ?
            <SidebarMenuItem to='/DaysInAR' icon='/media/icons/duotune/art/art002.svg' title={"Days In AR"} fontIcon='bi-app-indicator' /> : null}
          {ListofPermission[NetCollectionRatio].value ?
            <SidebarMenuItem to='/NetCollectionRatio' icon='/media/icons/duotune/art/art002.svg' title={"Net Collection Ratio"} fontIcon='bi-app-indicator' /> : null}
          {ListofPermission[WaterFallCollectionReport].value ?
            <SidebarMenuItem to='/WaterFallCollectionReport' icon='/media/icons/duotune/art/art002.svg' title={"Water Fall Collection Report"} fontIcon='bi-app-indicator' /> : null}
          {ListofPermission[RejectionRate].value ?
            <SidebarMenuItem to='/RejectionRate' icon='/media/icons/duotune/art/art002.svg' title={"Rejection Rate"} fontIcon='bi-app-indicator' /> : null}
          {ListofPermission[DenialRate].value ?
            <SidebarMenuItem to='/DenialRate' icon='/media/icons/duotune/art/art002.svg' title={"Denial Rate"} fontIcon='bi-app-indicator' /> : null}
          {ListofPermission[CleanClaimRatio].value ?
            <SidebarMenuItem to='/CleanClaimRatio' icon='/media/icons/duotune/art/art002.svg' title={"Clean ClaimRatio"} fontIcon='bi-app-indicator' /> : null}
          {ListofPermission[BenchMark].value ?
            <SidebarMenuItem to='/BenchMark' icon='/media/icons/duotune/art/art002.svg' title={"Industry Standard-Benchmark"} fontIcon='bi-app-indicator' /> : null}

        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/error' title='Comparative Dashboards' fontIcon='bi-sticky' icon='/media/icons/duotune/general/gen028.svg' >

          {ListofPermission[ChargesByCPTClass].value ? <SidebarMenuItem to='/ChargesByCPTClass' icon='/media/icons/duotune/art/art002.svg' title={"Modality wise Charges"} fontIcon='bi-app-indicator' /> : null}
          {ListofPermission[PaymentsByCPTClass].value ? <SidebarMenuItem to='/PaymentsByCPTClass' icon='/media/icons/duotune/art/art002.svg' title={"Modality wise Payments"} fontIcon='bi-app-indicator' /> : null}
          {ListofPermission[ProfitLossDash].value ? <SidebarMenuItem to='/ProfitLossDash' icon='/media/icons/duotune/art/art002.svg' title={"PL Dashboard"} fontIcon='bi-app-indicator' /> : null}
          {ListofPermission[ProviderProductivity].value ? <SidebarMenuItem to='/ProviderProductivity' icon='/media/icons/duotune/art/art002.svg' title={"Provider Productivity"} fontIcon='bi-app-indicator' /> : null}
          {ListofPermission[PayerMixReport].value ? <SidebarMenuItem to='/PayerMixReport' icon='/media/icons/duotune/art/art002.svg' title={"PayerMix-Report"} fontIcon='bi-app-indicator' /> : null}
          {ListofPermission[GuarantorAging].value ? <SidebarMenuItem to='/GuarantorAging' icon='/media/icons/duotune/art/art002.svg' title={"Guarantor Aging-Report"} fontIcon='bi-app-indicator' /> : null}
          {ListofPermission[DrugNonDrug].value ? <SidebarMenuItem to='/DrugNonDrug' icon='/media/icons/duotune/art/art002.svg' title={"Reimbursement Dashboard"} fontIcon='bi-app-indicator' /> : null}

        </SidebarMenuItemWithSub>


        {ListofPermission[PracticeSummaryDownload].value || ListofPermission[ReimbursementAuditReport].value || ListofPermission[ProfitLoss].value ?
          <SidebarMenuItemWithSub to='/error' title='Download Monthend Reports' fontIcon='bi-sticky' icon='/media/icons/duotune/general/gen028.svg' >
            {ListofPermission[PracticeSummaryDownload].value ?
              <SidebarMenuItem to='/PracticeSummaryDownload' title='Practice Summary' hasBullet={true} /> : null}
            {ListofPermission[ReimbursementAuditReport].value ?
              <SidebarMenuItem to='/ReimbursementAuditReport' icon='/media/icons/duotune/art/art002.svg' title={"Reimbursement Audit Report"} fontIcon='bi-app-indicator' /> : null}
            {ListofPermission[ProfitLoss].value ?
              <SidebarMenuItem to='/ProfitLoss' icon='/media/icons/duotune/art/art002.svg' title={"Profit & Loss"} fontIcon='bi-app-indicator' /> : null}

          </SidebarMenuItemWithSub>
        : null}

        {ListofPermission[ExportPDF].value || ListofPermission[ExportPDFCom].value ?
          <SidebarMenuItemWithSub to='/error' title='Download Monthend Dashboards' fontIcon='bi-sticky' icon='/media/icons/duotune/general/gen028.svg' >
            {ListofPermission[ExportPDF].value ?
              <SidebarMenuItem to='/ExportPDF' icon='/media/icons/duotune/art/art002.svg' title={`Export Month End Reports to PDF`} fontIcon='bi-app-indicator' /> : null}
            {ListofPermission[ExportPDFCom].value ? <SidebarMenuItem to='/ComExportPDF' icon='/media/icons/duotune/art/art002.svg' title={"PDF of Comparative Dashboard"} fontIcon='bi-app-indicator' /> : null}
          </SidebarMenuItemWithSub>
          : null}

        {ListofPermission[SampleTemplateReport].value || ListofPermission[SampleTemplateFeeSchedule].value ?
          <SidebarMenuItemWithSub to='/error' title='Sample Template' fontIcon='bi-sticky' icon='/media/icons/duotune/general/gen028.svg' >
            {ListofPermission[SampleTemplateReport].value ?
              <SidebarMenuItem to='/SampleTemplateReport' icon='/media/icons/duotune/art/art002.svg' title={`Report`} fontIcon='bi-app-indicator' /> : null}
            {ListofPermission[SampleTemplateFeeSchedule].value ? <SidebarMenuItem to='/SampleTemplateFeeSchedule' icon='/media/icons/duotune/art/art002.svg' title={"Fee Schedule"} fontIcon='bi-app-indicator' /> : null}
          </SidebarMenuItemWithSub>
        : null}
        
        {/* {ListofPermission[PowerBI].value ?
          <SidebarMenuItem to='/PowerBI' icon='/media/icons/duotune/art/art002.svg' title={`Launch Power BI`} fontIcon='bi-app-indicator' />
        : null}
        {UserRole.RoleType == "PM-Admin" ?
        <SidebarMenuItem to='/RestrictPowerBI' icon='/media/icons/duotune/art/art002.svg' title={`Restrict Power BI`} fontIcon='bi-app-indicator' />
        : null} */}
        {ListofPermission[FindyourDrugsPL].value ?
         <SidebarMenuItem to='/FindyourDrugsPL' icon='/media/icons/duotune/art/art002.svg' title={`Find your Drugs P/L?`} fontIcon='bi-app-indicator' /> : null }
         {ListofPermission[CollectedPaymentsReview].value ?
         <SidebarMenuItem to='/CollectedPaymentsReview ' icon='/media/icons/duotune/art/art002.svg' title={`Collected Payments Review `} fontIcon='bi-app-indicator' /> : null }
{UserRole.RoleType == "PM-Admin" || UserRole._id === "6423e677feb1d15862e5fbbe" ?
<SidebarMenuItem to='/DailyStatusUpload ' icon='/media/icons/duotune/art/art002.svg' title={`Daily Status Update `} fontIcon='bi-app-indicator' />: null }
        


      </>
      {/* : null} */}
    </>
  )
}
export { SidebarMenuMain }