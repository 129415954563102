import React, { useState, useEffect, HTMLAttributes } from 'react'
import { initialUser, User } from './_models'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import axios from "axios";
import Select from 'react-select';
import swal from "sweetalert"
import { toast } from 'react-toastify';
import ToastOption from '../Common/ToastOption'
import { Backdrop, CircularProgress } from '@mui/material';

const getPracticeList = `/api/practice/getPracticeListById`
const getPmList = `/api/pmsystem/getPmList`
const getReportNameList = `api/PracticeSummary/getReportNameList`

const UserDetailsSchema = Yup.object().shape({
  PmName: Yup.string().required('PM Name  is required'),
  ReportName: Yup.string().required('Report Name is required'),
  Practice: Yup.string().required('Practice Name is required'),
})

const CreatePracticeDefineFields: React.FC = () => {

  const [setClaimDataFieldsList, setClaimDataFields] = useState([])
  const [ClaimColumns, SelectedFieldsNamesList] = useState([{}])
  const [open, setOpen] = React.useState(false);
  const [SelectedPmSystem, setSelectedPmSystem] = useState({ label: "", value: "" })
  const [SelectedPractice, setSelectedPractice] = useState({ label: "", value: "" })

  const [SelectedReportName, setSelectedReportName] = useState({ label: "", value: "" })


  const [PracticeListData, setPracticeListData] = useState([])
  const [ReportNameList, setReportNameList] = useState([])

  const [getPmListData, setdatass] = useState([{ PmName: "", _id: "" }])

  const GetPmList = async () => {
    const getPmListData = await axios.get(getPmList)
    if (getPmListData.data) {
      setdatass(getPmListData.data.data)
    }
    const GetPracticeListData = await axios.get(getReportNameList)
    if (getPmListData.data) {
      setReportNameList(GetPracticeListData.data.TableColumns)
    }

  }
  const formik = useFormik({
    initialValues: initialUser,
    validationSchema: UserDetailsSchema,
    onSubmit: async (values, { setSubmitting }) => {
      console.log("values", values)
      setSubmitting(true)

      if(!SelectedPractice.value){
        toast.error('Please Select Practice',ToastOption);
        return false;
      }
      if(!SelectedPmSystem.value){
        toast.error('Please Select PM System',ToastOption);
        return false;
      }
      if(!SelectedReportName.value){
        toast.error('Please Select Report Name',ToastOption);
        return false;
      }
      if(values.ClaimColumns.length == 0){
        toast.error('Please Select Columns',ToastOption);
        return false;
      }
      try {
        setOpen(true)
        axios.post(`/api/PracticeSummary/DefineFields`, values).then((res) => {
          if (res.data.Response) {
            setOpen(false)
            ClaimColumns.map((res: any) => {
              formik.setFieldValue(res.value, "")
            })
            setClaimDataFields([])
            SelectedFieldsNamesList([])
            formik.setFieldValue("ReportName","")
            setSelectedReportName({ label: "", value: "" })
            formik.setFieldValue("ClaimColumns", [])
            swal({
              title: 'Success',
              text: `${res.data.Message}`,
              icon: "success",
            }).then((res: any) => {
              setSubmitting(false)
            })
          } else {
            swal({
              title: 'Error',
              text: `${res.data.Message}`,
              icon: "info",
            }).then((res: any) => {
              setSubmitting(true)
            })
          }
        })
      } catch (ex) {
        console.error(ex)
      }
    },
  })
  useEffect(() => {
    GetPmList()

  }, [])
  const getPractice = (event: any) => {
    if (Object.keys(event).length > 0) {
      setSelectedReportName({ label: "", value: "" })
      setSelectedPractice(event)
      formik.setFieldValue("Practice", event.label)
    } else {
      formik.setFieldValue("Practice", "")
      setSelectedReportName({ label: "", value: "" })
      setSelectedPractice({ label: "", value: "" })
    }
    ClaimColumns.map((res: any) => {
      formik.setFieldValue(res.value, "")
    })
    setClaimDataFields([])
    SelectedFieldsNamesList([])
    formik.setFieldValue("ReportName","")
    setSelectedReportName({ label: "", value: "" })
    formik.setFieldValue("ClaimColumns", [])
  }
  const getReportNameColumns = (event: any) => {
    setSelectedReportName(event)
    if (event.value) {
      ClaimColumns.map((res: any) => {
        formik.setFieldValue(res.value, "")
      })
      formik.setFieldValue("ClaimColumns", [])
      setClaimDataFields([])
      SelectedFieldsNamesList([])
      formik.setFieldValue("ReportName", event.label)
      var PostData = {
        tableName: event.value,
        ReportName: event.label,
        Practice: formik.values.Practice,
      }
      axios.post(`/api/PracticeSummary/getColumnNames`, PostData).then((res) => {
        if (res.data.Data.length > 0) {
          setClaimDataFields(res.data.Data)
          if (res.data.AlreadyDefineColumns.length > 0) {
            var ClaimColumnsDataFil: any = []
            res.data.AlreadyDefineColumns.map((res: any) => {
              formik.setFieldValue(res.value, res.label)
              ClaimColumnsDataFil.push({
                label: res.value,
                value: res.value,
                report_id : res.report_id
              })
            })

            SelectedFieldsNamesList(res.data.AlreadyDefineColumns)
            formik.setFieldValue("ClaimColumns", ClaimColumnsDataFil)
          } else {

          }
        } else {
          swal({
            title: '',
            text: `${res.data.Message}`,
            icon: "info",
          }).then((res: any) => {
          })

          setClaimDataFields([])
          SelectedFieldsNamesList([])
        }

      })

    } else {
      setClaimDataFields([])
      SelectedFieldsNamesList([])
    }

  }

  const getPMSystem = (event: any) => {
    //setSelectedPractice([])
    setSelectedPmSystem(event)
    if (!event.value) {
      formik.setFieldError("PmName", "PM System is required")
    } else {
      formik.setFieldError("PmName", "")
    }

    if (event.value) {
      formik.setFieldValue("PmName", event.value)
      axios.post(getPracticeList, { PostData: event.value }).then((res) => {
        setPracticeListData(res.data.data)
      })
      ClaimColumns.map((res: any) => {
        formik.setFieldValue(res.value, "")
      })
      setClaimDataFields([])
      setSelectedPractice({ label: "", value: "" })
      SelectedFieldsNamesList([])
      formik.setFieldValue("ReportName","")
      formik.setFieldValue("Practice","")
      setSelectedReportName({ label: "", value: "" })
      formik.setFieldValue("ClaimColumns", [])
    }
  };
  var PmListData = [{ label: "Select", value: "" }]
  getPmListData.map((name) => (
    PmListData.push({
      label: name.PmName,
      value: name._id
    })
  ))
  const handleChangeMultiColumnMulti = (event: { target: { value: any, name: any } }) => {
    var name: any
    name = event.target.name
    var value = event.target.value
    var Data = { [name]: value }

    console.log("name", name, event.target.value)
    formik.setFieldValue(name, event.target.value)
  }

  const handleChangeMultiColumn = (e: any) => {
    SelectedFieldsNamesList(e)
    formik.setFieldValue("ClaimColumns", e)
  }
  console.log("ClaimColumns",ClaimColumns)
  console.log("from", formik)
  return (
    <>
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open} >
        <CircularProgress color="inherit" />
    </Backdrop>
    <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Define Fields</h3>
          </div>
        </div>
        <hr />
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='row'>
            <div className='col-6 fv-row mb-7'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>PM System</span>
              </label>
              <Select maxMenuHeight={180} value={SelectedPmSystem} onChange={getPMSystem} id="PmId" name={"PmId"} options={PmListData} />
              {formik.touched.PmName && formik.errors.PmName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.PmName}</div>
                </div>
              )}

            </div>

            <div className='col-sm-6 fv-row'>
              <label className='col-lg-6 col-form-label fw-bold fs-6'>
                <span className='required'>Practice Name</span>
              </label>

              <Select maxMenuHeight={180} defaultValue={formik.values.ClaimColumns} value={SelectedPractice} onChange={getPractice} id="Practice" name={"Practice"} options={PracticeListData} />

              {formik.touched.Practice && formik.errors.Practice && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.Practice}</div>
                </div>
              )}
            </div>

            <div className='col-sm-6 fv-row'>
              <label className='col-lg-6 col-form-label fw-bold fs-6'>
                <span className='required'>Select Report Name</span>
              </label>

              <Select maxMenuHeight={180} value={SelectedReportName} onChange={getReportNameColumns} id="ReportName" name={"ReportName"} options={ReportNameList} />

              {formik.touched.ReportName && formik.errors.ReportName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.ReportName}</div>
                </div>
              )}
            </div>

            <div className='col-12 fv-row mb-7'>
              <label className='required col-lg-4 col-form-label fw-bold fs-6'>Report Headers</label>


              <Select maxMenuHeight={180} value={formik.values.ClaimColumns} options={setClaimDataFieldsList} isMulti id="ClaimColumns" name="ClaimColumns" onChange={handleChangeMultiColumn} className="basic-multi-select" classNamePrefix="select" />

            </div>


            {/* <div className='col-6 fv-row mb-7'>
              <label className='required col-lg-4 col-form-label fw-bold fs-6'>Upload Sample Template</label>


              <input type="file" name="" />

            </div> */}
          </div>
        </div>
        <div>


          <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
            <thead>
              <tr>
                <th>Column Name</th>
                <th>Display Name</th>

              </tr>
            </thead>
            <tbody>
              {
                ClaimColumns.map((stationIndex: any) => {

                  if (stationIndex.value) {
                    if (stationIndex.report_id) {
                      return <tr className="form__table-row">
                        <td>{stationIndex.value}</td>
                        <td>

                          <input

                            required
                            placeholder='Alias Name'
                            onChange={handleChangeMultiColumnMulti}
                            type='text'
                            name={stationIndex.value}
                            value={stationIndex.label}
                            autoComplete='off'
                            className='form-control'
                          />
                        </td>
                      </tr>
                    } else {
                      return <tr className="form__table-row">
                        <td>{stationIndex.value}</td>
                        <td>

                          <input

                            required
                            placeholder='Alias Name'
                            onChange={handleChangeMultiColumnMulti}
                            type='text'
                            name={stationIndex.value}
                            //value={}
                            autoComplete='off'
                            className='form-control'
                          />
                        </td>
                      </tr>
                    }

                  }

                })
              }
            </tbody>
          </table>

        </div>
        <div className='text-center pt-15'>


          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>

    </>
  )
}
export { CreatePracticeDefineFields }