import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import axios from "axios";
import { toast } from 'react-toastify';
import ToastOption from './Common/ToastOption'
import { useAuth } from '../../modules/auth'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Select from 'react-select';
import swal from "sweetalert"
import moment from "moment";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  LabelList
} from "recharts";



const getLocationName = `/api/ChargesByProvider/getLocationName`
const getPracticeDashboard = `/api/ChargesByProvider/getTopPayer`
const getPracticeName = `/api/PracticeSummary/getPracticeName`
const getPayerWise = `/api/DenialReports/getPayerWise`



const currencyFormatter = new Intl.NumberFormat('enz-US', {
    style: 'currency',
    currency: 'USD',
});

const formatDollarValue = (value: {toLocaleString: () => any}) => `$${value.toLocaleString()}`

const PaymentsBreakDownbyPayer: React.FC = () => {
  const intl = useIntl()
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails: any = location.state;
  const { currentUser }: any = useAuth()
  const [UserNameDatas, setUserNameDatas] = useState([{ value: "", label: "" }])
  const [DateMonthFrom, setDateMonthFrom] = useState("")
const [FromDate, setFromDate] = useState("")
const [ToDate, setToDate] = useState("")
  const [DateMonthTo, setDateMonthTo] = useState("")
  const [SelectedManager, setSelectedManager] = useState({ value: "", label: "" })
  const [SelectedPracticeList, setSelectedPracticeList] = useState([])
  const [GetCountByPosttedTable,setGetCountByPosttedTable] = useState([])
  const [PieChartData, setPieChartData] = useState([])
  const [PaymentsData, setPaymentsData] = useState([])
  const [GetTotalAmount, setGetTotalAmount] = useState([{count : "",payments :"",}])
  const [PracticeDatas, setPracticeDatas] = useState([{ value: "", label: "" }])
  const [PracticeList, setPracticeList] = useState([])
  const [NoData,setNoData]=useState<boolean>(false);
  const [open, setOpen] = React.useState(false);


  const FilterDataList = async () => {
    setPaymentsData([])
    setGetTotalAmount([])
    setNoData(false);
    setOpen(true)
    var tempValues = {
      UserId :currentUser._id,
      Path:window.location.pathname,
      FromDate:FromDate,
      ToDate:ToDate,
      PracticeName : JSON.stringify(PracticeList)
    }
    axios.get(`${getPracticeDashboard}?tempValues=${JSON.stringify(tempValues)}&FromDate=${FromDate}&ToDate=${ToDate}&PracticeName=${PracticeList.length > 0 ? JSON.stringify(PracticeList) : ""}`).then(_res => {
     if(_res.data.message){
       setNoData(true);
       setOpen(false)

     }

      setOpen(false)
      setPaymentsData(_res.data.PaymentsResult)
      setGetTotalAmount(_res.data.GetTotalAmount)
    })

  }


  const ParameterStorage = async () => {
    setOpen(true)
    const getPmListData = await axios.get(`/api/PracticeSummary/GetParameterStorage?Path=${window.location.pathname}`)
    setOpen(false)
    if (getPmListData.data != null) {
      let GetData :any= getPmListData.data
      setDateMonthFrom(GetData.FromDate)
      setDateMonthTo(GetData.ToDate)
      setFromDate(GetData.FromDate)
      setToDate(GetData.ToDate)
      setPracticeList(JSON.parse(GetData.PracticeName))
    }
  }
  const getSelectedPractice = (event: any) => {
    setSelectedPracticeList(event)
  //   axios.get(`${getPayerWise}?PracticeName=${event.length > 0 ? JSON.stringify(event) : ""}`).then(res=>{
  //     setUserNameDatas(res.data.Data)
  // })
}
const handleChangePractice=(event:any)=>{
  setPracticeList(event)
  setPaymentsData([])
  setGetTotalAmount([])
//   axios.get(`${getPayerWise}?PracticeName=${event.length > 0 ? JSON.stringify(event) : ""}`).then(res=>{
//     setUserNameDatas(res.data.Data)
// })
 }



  const handleDateChangeFrom = (date: any) => {
    setDateMonthFrom(date)
setFromDate(moment(date['$d']).format("MMM-YYYY"))
  };
  const handleDateChangeTo = (date: any) => {
    setDateMonthTo(date)
setToDate(moment(date['$d']).format("MMM-YYYY"))
    
  };




  const Filter = async (ids: any) => {

    let error = false
    let ErrorArray = []
    if(!FromDate){
      error = true
      ErrorArray.push(`Please Choose From Month / Year`)
    }
    if(!ToDate){
      error = true
      ErrorArray.push(`Please Choose To Month / Year`)
    }
    
    if(PracticeList.length == 0){
      error = true
      ErrorArray.push(`Please Select Practice`)
    }
    if(error){
      ErrorArray.map((res,ind)=>{
        toast.error(`${res}`,ToastOption);
      })
      return false;
    }
    if (PracticeList.length ===0) {
      swal({
        title: 'Practice',
        icon: "info",
        text: `Please choose any practice`,
      }).then((_res: any) => {

      })
      return false;
    }

    
    FilterDataList()
  }
  const ClearFilter = async (ids: any) => {

    setDateMonthFrom("")
    setDateMonthTo("")
    setSelectedPracticeList([])
    setPieChartData([])

  }
  const getPracticeNameata = async () => {
    const Result = await axios.get(getPracticeName)
    setPracticeDatas(Result.data.PracticeData)
  }
  useEffect(() => {
    getPracticeNameata()
    ParameterStorage()
  }, [])


  var PracticeListData: any = []
  PracticeDatas.map((practiceList: any, index) => {
    PracticeListData.push({
      label: practiceList.practice_name,
      value: practiceList.practice_name
    })
  })


  var TotalListChart:any = []
  var TotalClaims : any = 0;
  var Totalcharges : any = 0;
  var Result :any;
  var TotalSumResult : any = GetTotalAmount;
  if(PaymentsData.length > 0){
    var TotalSum : any = []
    PaymentsData.map((res: any, _inde: any) => {
      var GrandTotalClaimsAmount : any = 0;
      GrandTotalClaimsAmount += Number(res.payments);
      TotalSum.push(Number(GrandTotalClaimsAmount))
    })
    var FianlTotalClaimsAmount = TotalSum.reduce(function(pv:any, cv:any) { return pv + cv; }, 0);
    Result = PaymentsData.map((res: any, _inde: any) => {

      TotalClaims += Number(res.count)
     
      Totalcharges += Number(res.payments)
      
      TotalListChart.push({Payer:res.insurance,"Payments":Number(res.payments).toFixed(2)})
      if(_inde == PaymentsData.length - 1){
          return (
              <>
              <tr>
                <td >{res.insurance}</td>
                <td >{currencyFormatter.format(res.payments)}</td>
                <td >{Number((Number(res.payments) / Number((TotalSumResult[0].payments)) )* 100).toFixed(2)}%</td>
              </tr>
              <tr>
                <td >Others</td>
                <td>{currencyFormatter.format(TotalSumResult[0].payments - Totalcharges)}</td>
                <td >{Number(Number((Number(TotalSumResult[0].payments) - Number(Totalcharges)) / Number(TotalSumResult[0].payments) * 100)).toFixed(2) }%</td>
              </tr>
              
              <tr>
                <td >Grand Total</td>
                <td >{currencyFormatter.format(TotalSumResult[0].payments)}</td>
                <td >{Number(TotalSumResult[0].payments / TotalSumResult[0].payments) * 100 }%</td>
              </tr>
              </>
            )
            
      }
      else {
          return (
              <tr>
                <td >{res.insurance}</td>
                <td >{currencyFormatter.format(res.payments)}</td>
                <td >{Number((Number(res.payments) / Number((TotalSumResult[0].payments)) )* 100).toFixed(2)}%</td>
              </tr>
            )
      }
      
    })
  }
  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <div >
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Payer Mix-Collections</h3>
          </div>
        </div>
        <hr />
        <div className='row'>
          
            <>
            <div className='col-sm-3 fv-row'>
                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                  <span className='required'>Select Practice</span>
                </label>

                <Select maxMenuHeight={180} value={PracticeList} isMulti onChange={handleChangePractice} id="Location" name={"Practice"} options={PracticeListData} />

              </div>


              <div className='col-sm-3 fv-row'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>
                  <span className='required'>From</span>
                </label><br />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                  value={DateMonthFrom}
                 minDate={moment("2015")}
                 renderInput={(params:any) => <TextField {...params}  />}
                 maxDate={moment()}
                 views={['year', 'month']} disableFuture
                    onChange={handleDateChangeFrom}
                  />
                </LocalizationProvider>
              </div>
              <div className='col-sm-3 fv-row'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>
                  <span className='required'>To</span>
                </label><br />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                  value={DateMonthTo}
                 minDate={moment("2015")}
                 renderInput={(params:any) => <TextField {...params}  />}
                 maxDate={moment()}
                 views={['year', 'month']} disableFuture
                    onChange={handleDateChangeTo}
                  />
                </LocalizationProvider>
              </div>
             
              </>
          <div className='col-1 pt-13'>
            <button onClick={(e) => { Filter(e) }} className='btn btn-primary'  >Filter</button>
          </div>
          <div className='col-1 pt-13'>
            <button onClick={(e) => { ClearFilter(e) }} className='btn btn-primary'  >Clear</button>
          </div>
        

        </div><hr />

        <div className='row'>
          <div className='col-12 my-8'>

          {Result ? 
          <BarChart
          width={TotalListChart.length <=3 ? 500 :900 }
          height={400}
          
          data={TotalListChart}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 50
          }}
          >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="Payer" 
           angle={-30}
           textAnchor="end"
           interval={0}
           height={60}
           tick={{ fontSize: 10 }}
          >
          </XAxis>
          <YAxis tickFormatter={formatDollarValue} />
          
          <Tooltip formatter={formatDollarValue} />
          {/* <Legend fontWeight="bold"/> */}
<Legend fontWeight="bold" layout="vertical" verticalAlign="top" align="right" />
          
          <Bar dataKey="Payments" fill="#82ca9d" barSize={TotalListChart.length <=3 ? 40 : 30} >
            </Bar>
          
          </BarChart>

             : null}

{Result ? <table id='kt_table_users' className='table-row-gray-700 my-8 table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                  <thead>
                    <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                      <th>Payer's</th>
                      <th>Payments</th>
                      <th>% of Payment's</th>
                    </tr>
                  </thead>
                  <tbody className='text-gray-600 fw-bold' >
                  {Result}
                  </tbody>
                </table> :   NoData?
    <h5 className="fw-bold">No Data Found</h5>
    : null}


          </div>
          
        </div>
      </div>
    </>
  )
}
export { PaymentsBreakDownbyPayer }

