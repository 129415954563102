import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import axios from "axios";
import { toast } from 'react-toastify';
import ToastOption from './Common/ToastOption'
import { useAuth } from '../../modules/auth'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Select from 'react-select';
import moment from "moment";
import swal from "sweetalert"
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  LabelList
} from "recharts";



const getLocationName = `/api/ChargesByProvider/getLocationName`
const getPracticeDashboard = `/api/ChargesByProvider/getPracticeDashboard`
const getProviderWise = `/api/DenialReports/getProviderWise`
const getPracticeName = `/api/PracticeSummary/getPracticeName`
const options = {style: 'currency', currency: 'USD'}



const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});


const formatDollarValue = (value: {toLocaleString: () => any}) => `$${value.toLocaleString()}`

const PaymentsBreakDownbyProvider: React.FC = () => {
  const intl = useIntl()
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails: any = location.state;
  const { currentUser }: any = useAuth()
  const [UserNameDatas, setUserNameDatas] = useState([{ value: "", label: "" }])
  const [DateMonthFrom, setDateMonthFrom] = useState("")
const [FromDate, setFromDate] = useState("")
const [ToDate, setToDate] = useState("")
  const [DateMonthTo, setDateMonthTo] = useState("")

  const [SelectedPracticeList, setSelectedPracticeList] = useState([])
  
  const [PieChartData, setPieChartData] = useState([])
  // const [PaymentsData, setPaymentsData] = useState([])
  const [open, setOpen] = React.useState(false);
  // const [GetCountByPosttedTable,setGetCountByPosttedTable] = useState([])
  const [PracticeList, setPracticeList] = useState([])
  const [PracticeDatas, setPracticeDatas] = useState([{ value: "", label: "" }])
  const [NoData,setNoData]=useState<boolean>(false);
const [TotalCharges,setTotalCharges]=useState<number>(0)
const [TotalPayments,setTotalPayments]=useState<number>(0)



  const FilterDataList = async () => {
    setPieChartData([])
    // setPaymentsData([])
    // setGetCountByPosttedTable([])
    setNoData(false);
    setOpen(true)
    setTotalCharges(0)
    setTotalPayments(0)
    const tempValues = {UserId :currentUser._id,Path:window.location.pathname,FromDate:FromDate,ToDate:ToDate,Provider : JSON.stringify(SelectedPracticeList),PracticeName:JSON.stringify(PracticeList)}

    axios.get(`${getPracticeDashboard}?FromDate=${FromDate}&ToDate=${ToDate}&PracticeList=${PracticeList.length > 0 ? JSON.stringify(PracticeList) : ""}&PracticeName=${SelectedPracticeList.length > 0 ? JSON.stringify(SelectedPracticeList) : ""}&tempValues=${JSON.stringify(tempValues)}`).then(_res => {

      if(_res.data.message){
        setNoData(true)
        setOpen(false)
      }
      else{
        setOpen(false)
        setPieChartData(_res.data.data)
        let totalCharges:number=0;
        let totalPayments:number=0;
        _res.data.data.forEach((element:any) => {
          totalCharges+=element.charges ? element.charges : 0;
          totalPayments+=element.payments ? element.payments : 0;
        });
        setTotalCharges(totalCharges);
        setTotalPayments(totalPayments);
        // setPaymentsData(_res.data.PaymentsResult)
        // setGetCountByPosttedTable(_res.data.GetCountByPosttedTable)

      }


    })


  }
  const getSelectedPractice = (event: any) => {
    setPieChartData([])
   
    setSelectedPracticeList(event)
  }
  
  const handleDateChangeFrom = (date: any) => {
    setDateMonthFrom(date)
setFromDate(moment(date['$d']).format("MMM-YYYY"))
  };
  const handleDateChangeTo = (date: any) => {
    setDateMonthTo(date)
setToDate(moment(date['$d']).format("MMM-YYYY"))
    
  };

  const ParameterStorage=async()=>{
    // setOpen(true)
    const ParameterValues = await axios.get(`/api/PracticeSummary/GetParameterStorage?Path=${window.location.pathname}`)
    // setOpen(false)
    if(ParameterValues.data != null){
      setFromDate(ParameterValues.data.FromDate)
      setToDate(ParameterValues.data.ToDate)
      setDateMonthFrom(ParameterValues.data.FromDate)
      setDateMonthTo(ParameterValues.data.ToDate)
      setSelectedPracticeList(JSON.parse(ParameterValues.data.Provider))
      setPracticeList(JSON.parse(ParameterValues.data.PracticeName));

      // if(Object.keys(JSON.parse(ParameterValues.data.PracticeName).length >0)){
      axios.get(`${getProviderWise}?PracticeName=${ParameterValues.data.PracticeName}`).then(res=>{
        setUserNameDatas(res.data.Data)
      })
      // }

    }

  }

  const Filter = async (ids: any) => {

    let error = false
    let ErrorArray = []
    if(!FromDate){
      error = true
      ErrorArray.push(`Please Choose From Month / Year`)
    }
    if(!ToDate){
      error = true
      ErrorArray.push(`Please Choose To Month / Year`)
    }
    
    if(PracticeList.length == 0){
      error = true
      ErrorArray.push(`Please Select Practice`)
    }
    if(SelectedPracticeList.length == 0){
      error = true
      ErrorArray.push(`Please Select Provider`)
    }
    if(error){
      ErrorArray.map((res,ind)=>{
        toast.error(`${res}`,ToastOption);
      })
      return false;
    }

    FilterDataList()
  }
  const ClearFilter = async (ids: any) => {

    setDateMonthFrom("")
    setDateMonthTo("")
    setSelectedPracticeList([])
    setPieChartData([])

  }

  const handleChangePractice=(event:any)=>{
    setPracticeList(event)
    setSelectedPracticeList([])
    setPieChartData([])
    if(event.length>0){
      axios.get(`${getProviderWise}?PracticeName=${event.length > 0 ? JSON.stringify(event) : ""}`).then(res=>{
        setUserNameDatas(res.data.Data)
      })
    }
   }

   const getPracticeNameata = async () => {
    const Result = await axios.get(getPracticeName)
    setPracticeDatas(Result.data.PracticeData)
  }
  useEffect(() => {
    getPracticeNameata()
    ParameterStorage()
  }, [])


  var PracticeListData: any = []
  PracticeDatas.map((practiceList: any, index) => {
    PracticeListData.push({
      label: practiceList.practice_name,
      value: practiceList.practice_name
    })
  })


  var UsersListData: any = []
  UserNameDatas.map((practiceList: any, index) => {
    UsersListData.push({
      label: practiceList.provider,
      value: practiceList.provider
    })
  })


  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle> */}
      <div >
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Provider Wise Charges & Collections</h3>
          </div>
        </div>
        <hr />
        <div className='row'>
          
            <>
            <div className='col-sm-3 fv-row'>
                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                  <span className='required'>Select Practice</span>
                </label>

                <Select maxMenuHeight={180} value={PracticeList} isMulti onChange={handleChangePractice} id="Location" name={"Practice"} options={PracticeListData} />

              </div>

              <div className='col-sm-3 fv-row'>
                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                  <span className='required'>Provider List</span>
                </label>

                <Select maxMenuHeight={180} value={SelectedPracticeList} isMulti onChange={getSelectedPractice} id="Practice" name={"Practice"} options={UsersListData} />

              </div>

              <div className='col-sm-2 fv-row'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>
                  <span className='required'>From</span>
                </label><br />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                  value={DateMonthFrom}
                  minDate={moment("2015")}
                  renderInput={(params:any) => <TextField {...params}  />}
                  maxDate={moment()}
                  views={['year', 'month']} disableFuture
                    onChange={handleDateChangeFrom}
                  />
                </LocalizationProvider>
              </div>
              <div className='col-sm-2 fv-row'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>
                  <span className='required'>To</span>
                </label><br />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                  value={DateMonthTo}
                  minDate={moment("2015")}
                  renderInput={(params:any) => <TextField {...params}  />}
                  maxDate={moment()}
                  views={['year', 'month']} disableFuture
                    onChange={handleDateChangeTo}
                  />
                </LocalizationProvider>
              </div>
             
              </>
          <div className='col-1 pt-13'>
            <button onClick={(e) => { Filter(e) }} className='btn btn-primary'  >Filter</button>
          </div>
          <div className='col-1 pt-13'>
            <button onClick={(e) => { ClearFilter(e) }} className='btn btn-primary'  >Clear</button>
          </div>
          {/* <div className='col-1 pt-13'>
            <a onClick={(e) => { DownloadFile(e) }} href={`/users/DownloadDashboard?ManagerID=${Object.keys(SelectedManager).length > 0 ? SelectedManager.value : ""}&UserId=${JSON.stringify(SelectedPracticeList)}&FromDate=${formData.FromDate}&ToDate=${formData.ToDate}`} className='btn btn-primary'>Download</a>
          </div> */}

        </div><hr />

        <div className='row'>
          <div className='col-12 my-8'>

          {PieChartData?.length > 0  ? 

<BarChart
width={PieChartData.length <=3 ? 500 :900 }
height={400}

data={PieChartData}
margin={{
  top: 5,
  right: 30,
  left: 20,
  bottom: 50
}}
barGap={10}  
barCategoryGap="20%" 
>
<CartesianGrid strokeDasharray="3 3" />
<XAxis dataKey="provider" 
       angle={-40}
       textAnchor="end"
       interval={0}
       height={60}
       tick={{ fontSize: 10 }}
>
</XAxis>
<YAxis tickFormatter={formatDollarValue} />
 <Tooltip formatter={formatDollarValue} />
{/* <Legend fontWeight="bold"/> */}
<Legend fontWeight="bold" layout="vertical" verticalAlign="top" align="right" />
<Bar dataKey="charges" fill="#8884d8"  barSize={PieChartData.length <=3 ? 40 : 30} >

</Bar>
<Bar dataKey="payments" fill="#82ca9d" barSize={PieChartData.length <=3 ? 40 : 30}
>
</Bar>
</BarChart>
  : null}

{PieChartData?.length > 0 ?
  <table
              id='kt_table_users'
              className=' w-100 table-bordered align-middle fs-6 font-family-base no-footer w-full my-8 '
            >
<thead style={{background: '#a6b1e1'}}>
<tr className='text-start  fw-bolder fs-7 text-uppercase gs-0 text-gray-800 py-6 px-6'>
                      <th className="text-center p-2">Provider</th>
                      <th className='text-center p-2'>Charges</th>
                      <th className='text-center p-2'>Payments</th>
                      <th className='text-center p-2'>% of Payment's</th>
                    </tr>
                  </thead>
                  <tbody style={{background: '#e7eaf6'}}>
                  {PieChartData?.map((res: any, ind: any) => {
                    return (
                      <tr key={ind}>
                        <td className='px-3 py-2 text-center'>{res.provider ? res.provider : 'None'}</td>
                        <td className='px-3 py-2 text-center'>
                          {res.charges ? res.charges.toLocaleString('en-US', options) : '$0'}
                        </td>
                        <td className='px-3 py-2 text-center'>
                          {res.payments ? res.payments.toLocaleString('en-US', options) : '$0'}
                        </td>
                        <td className='px-3 py-2 text-center'>
                        {!res.payments ||  !res.charges  ? '0' : (Number(res.payments)/Number(res.charges) * 100).toFixed(2)}%
                        </td>
                      </tr>
                    )
                  })}
                  </tbody>
                  <thead style={{background: '#e7eaf6'}}>
                    <tr>
                      <th className="text-center p-2">Grand Total</th>
                      <th className="text-center p-2">{TotalCharges?.toLocaleString('en-US',options)}</th>
                      <th className="text-center p-2">{TotalPayments?.toLocaleString('en-US',options)}</th>
                      <th className="text-center p-2">{!TotalPayments ||  !TotalCharges  ? '0' : (Number(TotalPayments)/Number(TotalCharges) * 100).toFixed(2)}%</th>

                    </tr>
                  </thead>
                </table>
                
                :
     NoData ?
        <h5 className="fw-bold">No Data Found</h5>
        : null
}
          </div>
          
        </div>
      </div>
    </>
  )
}
export { PaymentsBreakDownbyProvider }

