import React, { useState, useEffect, useRef, FunctionComponent } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
// import {toAbsoluteUrl} from '../../../_metronic/helpers'
// import {PageTitle} from '../../../_metronic/layout/core'
import axios from 'axios'
import { toast } from 'react-toastify';
import ToastOption from '../Common/ToastOption'
import { Backdrop, Typography, CircularProgress, ListItemText, formControlClasses } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker'
import TextField from '@mui/material/TextField'
import Select from 'react-select'
import swal from 'sweetalert'
import moment from 'moment'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import LogoImage from '../../../../../src/_metronic/assets/images/favicon.png'
import autoTable from 'jspdf-autotable';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  Legend,
  ReferenceLine,
  LabelList,
  AreaChart, Area, Cell
} from "recharts";
import { useAuth } from '../../../modules/auth'
import CardGuarantor from '../components/CardGuarantor'
import Card from './Card/Card'
import { toPng } from 'html-to-image';


const getPracticeName = `/api/PracticeSummary/getPracticeName`
const getChargesWise = `/api/ChargesWise/getChargesWiseByCPT`
const getPaymentsWiseByCPT = `/api/ChargesWise/getPaymentsWiseByCPT`
const getPayerMixReport = `/api/ChargesWise/getPayerMixReport`
const getGuarantorAging = `/api/ChargesWise/getGuarantorAging`
const getReimbursement = `/api/ChargesWise/getReimbursement`
const getProfitLoss = `/api/ChargesWise/getProfitLoss`
const getProviderWiseByLoc = `/api/ChargesWise/getProviderWiseByLoc`


const options = { style: 'currency', currency: 'USD' }

const colors = [
  "#1f77b4", // blue
  "#ff7f0e", // orange
  "#2ca02c", // green
  "#d62728", // red
  "#9467bd", // purple
  "#8c564b", // brown
  "#e377c2", // pink
  "#7f7f7f", // gray
  "#bcbd22", // lime
  "#17becf",
  "#1f77b4", // blue
  "#ff7f0e", // orange
  "#2ca02c", // green
  "#d62728", // red
  "#9467bd", // purple
  "#8c564b", // brown
  "#e377c2", // pink
  "#7f7f7f", // gray
  "#bcbd22", // lime
  "#17becf" // cyan
];

const formatConeDollarValue = (value: { toLocaleString: () => any; }) => `$${value.toLocaleString()}`;

const formatDollarValue = (value: any) => {
  return `${(value / 1000000).toFixed(2)}M`;
};

const ExportPDFCom: React.FC = () => {

  const location = useLocation()

  const [UserNameDatas, setUserNameDatas] = useState([{ value: '', label: '' }])
  const [DateMonthFrom, setDateMonthFrom] = useState('')
  const [FromDate, setFromDate] = useState('')
  const [ToDate, setToDate] = useState('')
  const [DateMonthTo, setDateMonthTo] = useState('')
  const [SelectedPracticeList, setSelectedPracticeList] = useState([])
  const [PracticeName, setPracticeName] = useState({ label: "" })
  const [open, setOpen] = React.useState(false)


  const [percentage, setPercentage] = useState<any>(0);
  const [isRunning, setIsRunning] = useState<boolean>(true)


  const [ChargesData, setChargesData] = useState<any>([])
  const [ChargesHeader, setChargesHeader] = useState<string[]>([])
  const [PaymentsData, setPaymentsData] = useState<any>([])
  const [PaymentsHeader, setPaymentsHeader] = useState<string[]>([])
  const [PayersData, setPayersData] = useState<any>([])
  const [GuarantorData, setGuarantorData] = useState<any>([])
  //Reimbursement
  const [DrugsProfitData, setDrugsProfitData] = useState<any>([])
  const [DrugsLossData, setDrugsLossData] = useState<string[]>([])
  const [FinalDrugTable, setFinalDrugTable] = useState<any>([])
  const [NonDrugsProfitData, setNonDrugsProfitData] = useState<any>([])
  const [NonDrugsLossData, setNonDrugsLossData] = useState<any>([])
  const [FinalNonDrugTable, setFinalNonDrugTable] = useState<any>([])
  const [SumDrugData, setSumDrugData] = useState<number>(0);
  const [SumNonDrugData, setSumNonDrugData] = useState<number>(0);
  //Profit Loss

  const [ProfitData, setProfitData] = useState<any>([])
  const [LossData, setLossData] = useState<any>([])
  const [FinalTable, setFinalTable] = useState<any>([])
  const [SumPLData, setSumPLData] = useState<number>(0);

  //Provider Data
  const [NcrData, setNcrData] = useState([])
  const [FinalArray, setFinalArray] = useState<any>([])


  const [TotalCharges, setTotalCharges] = useState<number>(0)
  const [ChargesPer, setChargesPer] = useState<number>(0)
  const [TotalPayments, setTotalPayments] = useState<number>(0)
  const [PaymentsPer, setPaymentsPer] = useState<number>(0)
  const [PracticeDisabled, setPracticeDisabled] = useState<boolean>(false)



  const ChargesRef = useRef<any>(null);
  const ChargesTableRef = useRef<any>(null);
  const PaymentsRef = useRef<any>(null);
  const PaymentsTableRef = useRef<any>(null);
  const PayerChargesRef = useRef<any>(null);
  const PayerPaymentsRef = useRef<any>(null);
  const PayerTableRef = useRef<any>(null);
  const GurantorRef = useRef<any>(null);
  const GurantorTableRef = useRef<any>(null);
  const DrugProfitRef = useRef<any>(null);
  const DrugLossRef = useRef<any>(null);
  const DrugTableRef = useRef<any>(null);

  const ProfitRef = useRef<any>(null);
  const LossRef = useRef<any>(null);
  const TableRef = useRef<any>(null);
  const ProviderRef1 = useRef<any>(null)
  const ProviderRef2 = useRef<any>(null)
  const ProviderRef3 = useRef<any>(null)
  const ProviderRef4 = useRef<any>(null)
  const getPath = (x: number, y: number, width: number, height: number) => {
    return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3
      } 
  ${x + width / 2}, ${y}
  C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width
      }, ${y + height}
  Z`;
  };

  const TriangleBar: FunctionComponent<any> = (props: any) => {
    let { fill, x, y, width, height } = props;

    return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
  };

  useEffect(() => {
    getPracticeNameata()
  }, [])


  const providerTickFormatter: any = (value: string) => {
    const names = value.split(" ");
    return names[0]?.length > 0 ? names[0] : names[1];
  }

  const getSelectedPractice = async (event: any) => {
    let error = false
    let ErrorArray = []
    if (!DateMonthFrom) {
      error = true
      ErrorArray.push(`Please Choose From Month / Year`)
    }
    if (!DateMonthTo) {
      error = true
      ErrorArray.push(`Please Choose To Month / Year`)
    }

    // if(Object.keys(SelectedPracticeList).length == 0){
    //   error = true
    //   ErrorArray.push(`Please Select Practice`)
    // }
    if (error) {
      ErrorArray.map((res, ind) => {
        toast.error(`${res}`, ToastOption);
      })
      return false;
    }

    setIsRunning(true)
    setOpen(true)

    setPercentage(0)
    setSelectedPracticeList(event)
    setPracticeName({ label: event.label })

    setChargesData([])
    setChargesHeader([])
    setPaymentsData([])
    setPaymentsHeader([])
    setPayersData([])
    setGuarantorData([])
    setDrugsProfitData([])
    setDrugsLossData([])
    setFinalDrugTable([])
    setNonDrugsProfitData([])
    setNonDrugsLossData([])
    setFinalNonDrugTable([])
    // setSumDrugData(0)
    // setSumNonDrugData(0)

    setProfitData([])
    setLossData([])
    setFinalTable([])
    // setSumPLData(0)
    setNcrData([])
    setFinalArray([])


    const ChargeData = await axios.get(`${getChargesWise}?FromDate=${FromDate}&ToDate=${ToDate}&PracticeName=${Object.keys(event).length > 0 ? JSON.stringify(event) : ''
      }`)
    setChargesData(ChargeData.data.data)
    setChargesHeader(ChargeData.data.CptClass)
    setPercentage(20)
    const PaymentData = await axios.get(`${getPaymentsWiseByCPT}?FromDate=${FromDate}&ToDate=${ToDate}&PracticeName=${Object.keys(event).length > 0 ? JSON.stringify(event) : ''
      }`)
    setPaymentsData(PaymentData.data.ChargesWise)
    setPaymentsHeader(PaymentData.data.CptClass)
    setPercentage(40)

    const PayerData = await axios.get(`${getPayerMixReport}?FromDate=${FromDate}&ToDate=${ToDate}&PracticeName=${Object.keys(event).length > 0 ? JSON.stringify(event) : ''
      }`)
    setPayersData(PayerData.data.data)

    let totalCharges: number = 0;
    let chargesPer: number = 0;
    let totalPayments: number = 0;
    let paymentsPer: number = 0;

    PayerData.data.data.map((res: any, ind: number) => {
      totalCharges += Number(res.Charges);
      chargesPer += Number(res.ChargesPer)
      totalPayments += Number(res.Payments);
      paymentsPer += Number(res.Percentage);
    })
    setTotalCharges(totalCharges)
    setChargesPer(chargesPer)
    setTotalPayments(totalPayments);
    setPaymentsPer(paymentsPer);
    setPercentage(60)

    const GuarData = await axios.get(`${getGuarantorAging}?FromDate=${FromDate}&ToDate=${ToDate}&PracticeName=${Object.keys(event).length > 0 ? JSON.stringify(event) : ''
      }`)
    setGuarantorData(GuarData.data.data)
    setPercentage(56)

    const ReimburseData = await axios.get(`${getReimbursement}?FromDate=${FromDate}&ToDate=${ToDate}&PracticeName=${Object.keys(event).length > 0 ? JSON.stringify(event) : ''
      }`)
    setDrugsProfitData(ReimburseData.data.DrugsProfitData)
    setDrugsLossData(ReimburseData.data.DrugsLossData)
    setFinalDrugTable(ReimburseData.data.FinalDrugTable)
    // setNonDrugsProfitData(ReimburseData.data.NonDrugsProfitData)
    // setNonDrugsLossData(ReimburseData.data.NonDrugsLossData)
    // setFinalNonDrugTable(ReimburseData.data.FinalNonDrugTable)

    setSumDrugData(ReimburseData.data.SumDrugData)
    setSumNonDrugData(ReimburseData.data.SumNonDrugData)
    setPercentage(80)



    const Profit_LossData = await axios.get(`${getProfitLoss}?FromDate=${FromDate}&ToDate=${ToDate}&PracticeName=${Object.keys(event).length > 0 ? JSON.stringify(event) : ''
      }`)
    setProfitData(Profit_LossData.data.ProfitResult)
    setLossData(Profit_LossData.data.LossResult)
    setFinalTable(Profit_LossData.data.FinalTable)
    setSumPLData(Profit_LossData.data.SumData)
    setPercentage(90)


    const ProData = await axios.get(`${getProviderWiseByLoc}?FromDate=${FromDate}&ToDate=${ToDate}&PracticeName=${Object.keys(event).length > 0 ? JSON.stringify(event) : ''
      }`)

    setNcrData(ProData.data.data)
    let array: any = [];
    let batches: any = [];
    ProData.data.data.map((res: any, ind: any) => {
      Object.values(res).map((ress: any) => {
        const tempData: any = Object.values(ress)[0]
        if (tempData.length > 0) {
          array.push(ress)
        }


      })
    })
    for (let i = 0; i < array?.length; i += 9) {
      batches.push(array.slice(i, i + 9));
    }

    setFinalArray(batches)
    setPercentage(100)

    setIsRunning(false);
    setOpen(false)
  }
  const handleDateChangeFrom = (date: any) => {
    setSelectedPracticeList([])
    setDateMonthFrom(date)
    setFromDate(moment(date['$d']).format('MMM-YYYY'))
  }

  const handleDateChangeTo = (date: any) => {
    setSelectedPracticeList([])
    setDateMonthTo(date)
    setToDate(moment(date['$d']).format('MMM-YYYY'))

    const fromDate = new Date(DateMonthFrom);
    const toDate = new Date(date);

    const monthsDiff = (toDate.getFullYear() - fromDate.getFullYear()) * 12 + (toDate.getMonth() - fromDate.getMonth());


    if (monthsDiff > 2) {
      toast.warn("Please choose maximum of 3 months", ToastOption);
      setPracticeDisabled(true)
      return false;
    } else {
      setPracticeDisabled(false)
    }
  }
  async function createPdfModality(doc: any, el: any, imgData: any) {
    let top = 80;
    const padding = 10;

    let elHeight = el.offsetHeight;
    let elWidth = el.offsetWidth;
    const pageWidth = doc.internal.pageSize.getWidth();

    if (elWidth > pageWidth) {
      const ratio = pageWidth / elWidth;
      elHeight = elHeight * ratio - padding;
      elWidth = elWidth * ratio - padding;
    }
    const pageHeight = doc.internal.pageSize.getHeight();

    if (top + elHeight > pageHeight) {
      doc.addPage();
      top = 20;
    }

    doc.addImage(imgData, "PNG", padding, top, elWidth, elHeight);
    top += elHeight;
  

  }

  async function createPdf(doc: any, el: any, imgData: any) {
    let top = 70;
    const padding = 10;

    let elHeight = el.offsetHeight;
    let elWidth = el.offsetWidth;
    const pageWidth = doc.internal.pageSize.getWidth();

    if (elWidth > pageWidth) {
      const ratio = pageWidth / elWidth;
      elHeight = elHeight * ratio - padding;
      elWidth = elWidth * ratio - padding;
    }
    const pageHeight = doc.internal.pageSize.getHeight();

    if (top + elHeight > pageHeight) {
      doc.addPage();
      top = 20;
    }

    doc.addImage(imgData, "PNG", padding, top, elWidth, elHeight);
    top += elHeight;
  }

  async function pageAlignment(doc: jsPDF, titleOfThePage?: string, pageNo?: number) {
    const logoX = doc.internal.pageSize.getWidth() - 20 - 10;

    doc.setTextColor('#023e8a')
    const title = `${PracticeName.label} - ${FromDate} - ${ToDate}`;

    const titleX = doc.internal.pageSize.getWidth() / 2;
    const titleY = 25;
    const contentWidth = doc.internal.pageSize.getWidth();
    const contentHeight = doc.internal.pageSize.getHeight();
    const footerY = contentHeight - 14;
    const lineY = footerY - 10;
    const footerContent = 'Copyright @2023.All rights reserved';
    const footerTextWidth = doc.getStringUnitWidth(footerContent) * 8 / doc.internal.scaleFactor;

    const footerX = (contentWidth - footerTextWidth) / 2;
    doc.addImage(LogoImage, 'PNG', logoX, 10, 20, 20);
    doc.setFontSize(10);
    doc.text(title, titleX, titleY, { align: 'center' });

    doc.setLineWidth(0.5);

    doc.text(title, titleX, titleY, { align: 'center' });

    const lineYY = titleY + 8;
    doc.line(10, lineYY, contentWidth - 20, lineYY);


    doc.setTextColor('#023e8a')
    doc.setFontSize(8);


    doc.text(`Page ${pageNo}`, doc.internal.pageSize.getWidth() - 18, doc.internal.pageSize.getHeight() - 28, { align: 'right' });

    doc.setLineWidth(0.5);
    doc.line(10, lineY, contentWidth - 20, lineY);

    doc.setFontSize(8);
    doc.text(footerContent, footerX, footerY);
    doc.setFontSize(10)

    doc.text(`${titleOfThePage}`, titleX, 50, { align: 'center' })

  }

  const columnStyles = (length: number) => {
    let columnStyles: any = {}

    for (let i = 0; i < length; i++) {
      if (i === 0) {
        columnStyles[i] = { halign: 'left' };
      } else {
        columnStyles[i] = { halign: 'right' };
      }
    }

    return columnStyles;
  }

  const payerColumnStyles = (length: number) => {
    let columnStyles: any = {}

    for (let i = 0; i < length; i++) {
      if (i === 2 || i === 4) {
        columnStyles[i] = { halign: 'center' };
      }
      if (i === 1 || i === 3) {
        columnStyles[i] = { halign: 'right' };
      }

    }

    return columnStyles;
  }


  async function horizontalBar(title: string, doc: jsPDF) {
    const titleHeight = 58;
    const barHeight = 18;
    doc.setFillColor(18, 38, 66);
    doc.rect(10, titleHeight - barHeight / 2, doc.internal.pageSize.width - 20, barHeight, 'F'); // Draw the bar
    doc.setTextColor(255, 255, 255); // Set text color to white
    doc.text(title, 25, 62);
  }



  const GetPDF = async () => {
    var NODATATITLE = 315.70875;
    setPercentage(0)
    setOpen(true)
    const doc: any = new jsPDF("l", "px", undefined, true);

    await pageAlignment(doc, "", 0);

    //Index Page

    const toc = [
      "Modality Wise Charges",
      "Modality Wise Payments",
      "Payer Mix Charges & Payments",
      "Payer Mix Charges-Table",
      "Guarantor Aging Repor",
      "Top 10 Profitable Drugs (Based on ASP Pricing)",
      "Top 10 Loss making Drugs (Based on ASP Pricing)",
      "Top 10 Profitable Drugs (Based on Drug Pricing)",
      "Top 10 Loss making Drugs (Based on Drug Pricing)",
      "Provider Productivity"
    ];


    doc.setFontSize(16); // Set the title font size
    doc.setFont("Source Sans Pro Black (Headings)", "bold");


    await horizontalBar("Contents", doc);

    doc.setFillColor(255, 255, 255);
    doc.setTextColor(0, 0, 0);

    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    let titleHeight = 58;
    toc.forEach((item, index) => {
      const text = `\u2022 ${item}`;
      const lineHeight = 14;
      const verticalSpacing = 5;

      // Add content to the PDF
      doc.text(text, 30, titleHeight + 30 + index * (lineHeight + verticalSpacing));
    });

    //First Page
    doc.addPage();

    await pageAlignment(doc, "", 1);

    doc.setFontSize(16);
    doc.setFont("Source Sans Pro Black (Headings)", "bold");

    if (ChargesRef.current === null) {

      doc.text(' No Data Available', NODATATITLE, 160, { align: "center" })
    }

    await horizontalBar("Modality Wise Charges", doc);


    if (ChargesRef.current) {
      const imgData: any = await toPng(ChargesRef.current)
      await createPdfModality(doc, ChargesRef.current, imgData)

    }


    setPercentage(8)

    // Second page

    doc.addPage();

    await pageAlignment(doc, "", 2);

    if (ChargesRef.current === null) {

      doc.text(' No Data Available', NODATATITLE, 160, { align: "center" })
    }


    if (ChargesRef.current) {

      let chargesHeader = [...ChargesHeader];
      if (!ChargesHeader.includes("Month")) {
        chargesHeader.unshift("Month")
      }

      //First Ten
      const firstTenChargesHeader:any=ChargesHeader.slice(0,11);
        const tableData:any = ChargesData?.map((item: any) => {
        const row = [item.month_year];
        firstTenChargesHeader?.map((key:string,i:number)=>{
          const ans= item[key] ? item[key].toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }) : '$0';
          row.push(ans);
        })
        return row;
      })
      autoTable(doc, {
        head: [chargesHeader.slice(0,11)],
        body: tableData,
        startY: 60,
        theme: "grid",
        styles: {
          fontSize: 10,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],

        },
        columnStyles: columnStyles(chargesHeader.length),
        headStyles: {
          halign: "center",
          fillColor: [211, 211, 211],
          textColor: [0, 0, 0],
          fontSize: 10,
        },
        bodyStyles: {
          textColor: [1, 2, 3],
        },
      });
    

//Remaining Ten

if( ChargesHeader.length > 11){

const remChargesHeader:string[]=ChargesHeader.slice(10,ChargesHeader.length);
if (!remChargesHeader.includes("Month")) {
  remChargesHeader.unshift("Month")
}
    const reaminingChargesHeader:any=ChargesHeader.slice(10,ChargesHeader.length);


    const reaminingtableData = ChargesData?.map((item: any) => {
    const row = [item.month_year];
    reaminingChargesHeader?.map((key:string,i:number)=>{
      const ans= item[key] ? item[key].toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }) : '$0';
      row.push(ans);
    })
    return row;
  })
  autoTable(doc, {
    head: [remChargesHeader],
    body: reaminingtableData,
    startY: 180,
    theme: "grid",
    styles: {
      fontSize: 10,
      lineWidth: 0.5,
      lineColor: [0, 0, 0],
  
    },
    columnStyles: columnStyles(chargesHeader.length),
    headStyles: {
      halign: "center",
      fillColor: [211, 211, 211],
      textColor: [0, 0, 0],
      fontSize: 10,
    },
    bodyStyles: {
      textColor: [1, 2, 3],
    },
  });
}



    }

  
    setPercentage(16)
    //3rd Page 
    doc.addPage();

    await pageAlignment(doc, "", 3);

    doc.setFontSize(16);
    doc.setFont("Source Sans Pro Black (Headings)", "bold");

    if (PaymentsRef.current === null) {

      doc.text(' No Data Available', NODATATITLE, 160, { align: "center" })
    }

    await horizontalBar("Modality Wise Payments", doc);


    if (PaymentsRef.current) {

      const imgData: any = await toPng(PaymentsRef.current)
      await createPdfModality(doc, PaymentsRef.current, imgData)


    }

    setPercentage(24)

    // 4th Page 

    doc.addPage();
    await pageAlignment(doc, "", 4);

    if (PaymentsRef.current === null) {

      doc.text(' No Data Available', NODATATITLE, 160, { align: "center" })
    }

    if (PaymentsRef.current) {

      let paymentsHeader = [...PaymentsHeader];
      if (!PaymentsHeader.includes("Month")) {

        paymentsHeader.unshift("Month")
      }
      //First Ten
   
      const firstTenPaymentsHeader:any=PaymentsHeader.slice(0,11);
        const tableData = PaymentsData?.map((item: any) => {
        const row = [item.month_year];
        firstTenPaymentsHeader?.map((key:string,i:number)=>{
          const ans= item[key] ? item[key].toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }) : '$0';
          row.push(ans);
        })
        return row;
      })
      autoTable(doc, {
        head: [paymentsHeader.slice(0,11)],
        body: tableData,
        startY: 60,
        theme: "grid",
        styles: {
          fontSize: 10,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],

        },
        columnStyles: columnStyles(paymentsHeader.length),
        headStyles: {
          halign: "center",
          fillColor: [211, 211, 211],
          textColor: [0, 0, 0],
          fontSize: 10,
        },
        bodyStyles: {
          textColor: [1, 2, 3],
        },
      });

//Remaining Ten

if( PaymentsHeader.length > 11){

const remPaymentsHeader:string[]=PaymentsHeader.slice(10,PaymentsHeader.length);
if (!remPaymentsHeader.includes("Month")) {
  remPaymentsHeader.unshift("Month")
}

  const reaminingPaymentsHeader:any=PaymentsHeader.slice(10,PaymentsHeader.length);
    const reaminingtableData = PaymentsData?.map((item: any) => {
    const row = [item.month_year];
    reaminingPaymentsHeader?.map((key:string,i:number)=>{
      const ans= item[key] ? item[key].toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }) : '$0';
      row.push(ans);
    })
    return row;
  })
  autoTable(doc, {
    head: [remPaymentsHeader],
    body: reaminingtableData,
    startY: 180,
    theme: "grid",
    styles: {
      fontSize: 10,
      lineWidth: 0.5,
      lineColor: [0, 0, 0],
  
    },
    columnStyles: columnStyles(paymentsHeader.length),
    headStyles: {
      halign: "center",
      fillColor: [211, 211, 211],
      textColor: [0, 0, 0],
      fontSize: 10,
    },
    bodyStyles: {
      textColor: [1, 2, 3],
    },
  });
}

    }

    setPercentage(32)
    // 5th page

    doc.addPage();

    await pageAlignment(doc, "", 5);

    doc.setFontSize(16);
    doc.setFont("Source Sans Pro Black (Headings)", "bold");

    if (PayerChargesRef.current === null) {

      doc.text(' No Data Available', NODATATITLE, 160, { align: "center" })
    }

    await horizontalBar("Payer Mix Charges", doc);

    if (PayerChargesRef.current) {

      const imgData: any = await toPng(PayerChargesRef.current)
      await createPdf(doc, PayerChargesRef.current, imgData)

    }
    setPercentage(40)
    //6th Page -Payer Mix Table
    doc.addPage();
    await pageAlignment(doc, "", 6);

    doc.setFontSize(16);
    doc.setFont("Source Sans Pro Black (Headings)", "bold");

    if (PayerPaymentsRef.current === null) {

      doc.text(' No Data Available', NODATATITLE, 160, { align: "center" })
    }

    await horizontalBar("Payer Mix Payments", doc);
    if (PayerPaymentsRef.current) {

      const imgData: any = await toPng(PayerPaymentsRef.current)
      await createPdf(doc, PayerPaymentsRef.current, imgData)
    }


    setPercentage(48)
    //7th Page -
    doc.addPage();
    await pageAlignment(doc, "", 7);

    doc.setFontSize(16);
    doc.setFont("Source Sans Pro Black (Headings)", "bold");


    if (PayersData.length > 0) {


      const rows: any = []
      const tableHeaders: any = [
        // First header row
        [
          { content: 'Payer', styles: { halign: 'center' } },
          { content: 'Charges', colSpan: 2, styles: { halign: 'center' } },
          { content: 'Payments', colSpan: 2, styles: { halign: 'center' } },
        ],
        // Second header row
        [
          '',
          { content: '$', styles: { halign: 'center' } },
          { content: '%', styles: { halign: 'center' } },
          { content: '$', styles: { halign: 'center' } },
          { content: '%', styles: { halign: 'center' } },
        ],
      ];

      PayersData.map((item: any) => {
        const rowData = [item.Payer ? item.Payer : "None", item.Charges ? Number(item.Charges).toLocaleString('en-US', options) : "$0", item.ChargesPer ? item.ChargesPer + "%" : "0%", item.Payments ? Number(item.Payments).toLocaleString('en-US', options) : '$0', item.Percentage ? item.Percentage + "%" : '0'];
        rows.push(rowData);
      });

      rows.push(["Total",
        TotalCharges ? TotalCharges.toLocaleString("en-US", options) : "$0", ChargesPer ? Math.round(ChargesPer) + "%" : "0%"
        , TotalPayments ? TotalPayments.toLocaleString("en-US", options) : "$0",
        PaymentsPer ? Math.round(PaymentsPer) + "%" : "0%"]
      )

      autoTable(doc, {
        head: tableHeaders,
        body: rows.map((row: any, rowIndex: any) => {
          const values = Object.values(row);

          const styles = rowIndex === rows.length - 1 ? {
            fontStyle: 'bold',
            fillColor: [211, 211, 211]
          } : {};

          return values.map((value: any) => ({ content: value, styles }));
        }),
        startY: 60,
        styles: {
          fontSize: 10,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],

        },
        theme: "grid",
        columnStyles: payerColumnStyles(5),
        headStyles: {
          halign: "center",
          fillColor: [211, 211, 211],
          textColor: [0, 0, 0],
          fontSize: 10,
        },
        bodyStyles: {
          textColor: [1, 2, 3],
        },
      });

    }
    else {
      doc.text(' No Data Available', NODATATITLE, 160, { align: "center" })
    }
    setPercentage(56)

    //8th -Guarantor Report

    doc.addPage();

    await pageAlignment(doc, "", 8);

    doc.setFontSize(16);
    doc.setFont("Source Sans Pro Black (Headings)", "bold");

    if (GuarantorData.length === 0) {

      doc.text(' No Data Available', NODATATITLE, 160, { align: "center" })
    }

    await horizontalBar("Guarantor Aging Report", doc);

    if (GuarantorData.length > 0) {

      const imgData: any = await toPng(GurantorRef.current)
      await createPdf(doc, GurantorRef.current, imgData)

    }


    setPercentage(64)

    //9th Page - Reimbursement Drug 

    doc.addPage();

    await pageAlignment(doc, "", 9);

    doc.setFontSize(16);
    doc.setFont("Source Sans Pro Black (Headings)", "bold");


    if (GuarantorData?.length > 0) {
      const columns = ['Month/Year', '0-30', "30-60", "60-90", "90-120", "Above120", "Total"];

      const rows: any = [];

      GuarantorData.forEach((res: any) => {
        const rowData = [res.month_year ? res.month_year : "None",
        res["0_30"] ? res["0_30"].toLocaleString('en-US', options) : '$0',
        res["30_60"] ? res["30_60"].toLocaleString('en-US', options) : '$0',
        res["60_90"] ? res["60_90"].toLocaleString('en-US', options) : '$0',
        res["90_120"] ? res["90_120"].toLocaleString('en-US', options) : '$0',
        res["120Above"] ? res["120Above"].toLocaleString('en-US', options) : '$0',
        (
          (res["0_30"] ? res["0_30"] : 0)
          + (res["30_60"] ? res["30_60"] : 0)
          + (res["60_90"] ? res["60_90"] : 0)
          + (res["90_120"] ? res["90_120"] : 0)
          + (res["120Above"] ? res["120Above"] : 0))
          .toLocaleString('en-US', options)

        ];
        rows.push(rowData);
      });
      autoTable(doc, {
        head: [columns],
        body: rows,
        startY: 60,
        styles: {
          fontSize: 10,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],

        },
        columnStyles: columnStyles(columns.length),
        theme: "grid",

        headStyles: {
          halign: "center",
          fillColor: [211, 211, 211],
          textColor: [0, 0, 0],
          fontSize: 10,
        },
        bodyStyles: {
          textColor: [1, 2, 3],
        },
      });

    }
    else {
      doc.text(' No Data Available', NODATATITLE, 160, { align: "center" })
    }

    setPercentage(72)
    //10th Page
    doc.addPage()

    await pageAlignment(doc, "", 10);

    doc.setFontSize(16);
    doc.setFont("Source Sans Pro Black (Headings)", "bold");


    if (DrugProfitRef.current === null) {

      doc.text(' No Data Available', NODATATITLE, 160, { align: "center" })
    }

    await horizontalBar("Top 10 Profitable drugs (Based on ASP Pricing)", doc);

    if (DrugProfitRef.current) {

      const imgData: any = await toPng(DrugProfitRef.current)
      await createPdf(doc, DrugProfitRef.current, imgData)
    }
    setPercentage(75)
    //11th page
    doc.addPage()

    await pageAlignment(doc, "", 11);

    doc.setFontSize(16);
    doc.setFont("Source Sans Pro Black (Headings)", "bold");


    if (DrugLossRef.current === null) {

      doc.text(' No Data Available', NODATATITLE, 160, { align: "center" })
    }

    await horizontalBar("Top 10 loss making drugs (Based on ASP Pricing)", doc);

    if (DrugLossRef.current) {

      const imgData: any = await toPng(DrugLossRef.current)
      await createPdf(doc, DrugLossRef.current, imgData)
    }

    doc.setFontSize(12);
    doc.setTextColor("#333333")
    doc.text(`The Total amount yet to receive for Drugs: ${SumDrugData > 0 ? SumDrugData.toLocaleString("en-US", options) : "$0"}`, 30, 370)

    setPercentage(80)

    //12th Page -

    doc.addPage();

    await pageAlignment(doc, "", 12);

    if (FinalDrugTable.length > 0) {

      const columns = ['CPT Codes', 'CPT Description', "Amount", "Over/UnderPaid"];

      const rows: any = [];

      FinalDrugTable.forEach((res: any) => {
        const rowData = [res.cp_cpt_code ? res.cp_cpt_code : 'None',
        res.cpt_description ? res.cpt_description : 'None',
        res.Payment ? res.Payment.toLocaleString('en-US', options) : '0',
        res.pl_type ? res.pl_type : '0'

        ];
        rows.push(rowData);


      });
      autoTable(doc, {
        head: [columns],
        body: rows,
        startY: 50,
        styles: {
          fontSize: 10,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],

        },
        theme: "grid",
        columnStyles: {
          2: { halign: "right" },
        },
        headStyles: {
          halign: "center",
          fillColor: [211, 211, 211],
          textColor: [0, 0, 0],
          fontSize: 10,
        },
        bodyStyles: {
          textColor: [1, 2, 3],
        },
      });

    }
    else {
      doc.text(' No Data Available', NODATATITLE, 160, { align: "center" })

    }
    setPercentage(85)

    //13th Page 

    doc.addPage();
    await pageAlignment(doc, "", 13);


    doc.setFontSize(16);
    doc.setFont("Source Sans Pro Black (Headings)", "bold");


    if (ProfitRef.current === null) {

      doc.text(' No Data Available', NODATATITLE, 160, { align: "center" })
    }

    await horizontalBar("Top 10 Profitable Drugs (Based on Drug Pricing)", doc);

    if (ProfitRef.current) {
      const imgData: any = await toPng(ProfitRef.current)
      await createPdf(doc, ProfitRef.current, imgData)
    }
    setPercentage(90)
    //14th Page

    doc.addPage();
    await pageAlignment(doc, "", 14);


    doc.setFontSize(16);
    doc.setFont("Source Sans Pro Black (Headings)", "bold");


    if (LossRef.current === null) {

      doc.text(' No Data Available', NODATATITLE, 160, { align: "center" })
    }

    await horizontalBar("Top 10 Loss making Drugs (Based on Drug Pricing)", doc);

    if (LossRef.current) {
      const imgData: any = await toPng(LossRef.current)
      await createPdf(doc, LossRef.current, imgData)
    }

    doc.setFontSize(12);
    doc.setTextColor("#333333")
    doc.text(`The Total amount yet to receive for Drugs: ${SumPLData > 0 ? SumPLData.toLocaleString("en-US", options) : "$0"}`, 30, 370)

    setPercentage(90)

    // 15th page
    doc.addPage();
    await pageAlignment(doc, "", 15);

    if (FinalTable?.length > 0) {

      const columns = ['CPT Codes', 'CPT Description', "Payment", "Profit/Loss"];

      const rows: any = [];

      FinalTable.forEach((res: any) => {
        const rowData = [res.cpt_codes ? res.cpt_codes : 'None',
        res.cpt_description ? res.cpt_description : 'None',
        res.Payment ? res.Payment.toLocaleString('en-US', options) : '0',
        res.pl_type ? res.pl_type : '0'

        ];

        rows.push(rowData);
      });
      autoTable(doc, {
        head: [columns],
        body: rows,
        startY: 60,
        styles: {
          fontSize: 10,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],

        },
        theme: "grid",
        columnStyles: {
          2: { halign: "right" },
        },
        headStyles: {
          halign: "center",
          fillColor: [211, 211, 211],
          textColor: [0, 0, 0],
          fontSize: 10,
        },
        bodyStyles: {
          textColor: [1, 2, 3],
        },
      });

    }
    else {
      doc.text(' No Data Available', NODATATITLE, 160, { align: "center" })

    }
    setPercentage(95)
    //16th page
    doc.addPage();
    await pageAlignment(doc, "", 16);

    doc.setFontSize(16);
    doc.setFont("Source Sans Pro Black (Headings)", "bold");


    if (ProviderRef1.current === null) {

      doc.text(' No Data Available', NODATATITLE, 160, { align: "center" })
    }
    await horizontalBar("Provider Productivity", doc);

    if (ProviderRef1.current) {

      const imgData: any = await toPng(ProviderRef1.current)
      await createPdf(doc, ProviderRef1.current, imgData)
    }

    // 17th page

    if (FinalArray[1]?.length > 0) {

      doc.addPage();
      await pageAlignment(doc, "", 17);

      const imgData: any = await toPng(ProviderRef2.current)
      await createPdf(doc, ProviderRef2.current, imgData)
    }
    setPercentage(100)
    //18th page

    if (FinalArray[2]?.length > 0) {

      doc.addPage();
      await pageAlignment(doc, "", 18);

      const imgData: any = await toPng(ProviderRef3.current)
      await createPdf(doc, ProviderRef3.current, imgData)
    }


    //19th page
    if (FinalArray[3]?.length > 0) {

      doc.addPage();
      await pageAlignment(doc, "", 19);

      const imgData: any = await toPng(ProviderRef4.current)
      await createPdf(doc, ProviderRef4.current, imgData)
    }


    doc.save(`${PracticeName.label}-${FromDate} -${ToDate}Report.pdf`)
    setPercentage(100)

    setOpen(false)
  }



  const getPracticeNameata = async () => {
    const Result = await axios.get(getPracticeName)
    setUserNameDatas(Result.data.PracticeData)
  }


  var UsersListData: any = []
  UserNameDatas.map((practiceList: any, index) => {
    UsersListData.push({
      label: practiceList.practice_name,
      value: practiceList.practice_name,
    })
  })






  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}>
        <CircularProgress color='inherit' />
        <Typography variant="h6" component="div" color="inherit" mt={1} ml={2}>
          {`Processing...${Number(percentage)}%`}
        </Typography>
      </Backdrop>

      <div>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Comparative Dashboard Report in PDF</h3>
          </div>
        </div>
        <hr />
        <div className='row'>
          <>
            <div className='col-sm-3 fv-row'>
              <label className='col-lg-6 col-form-label fw-bold fs-6'>
                <span className='required'>From</span>
              </label>
              <br />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={DateMonthFrom}
                  minDate={moment('2015')}
                  renderInput={(params: any) => <TextField {...params} />}
                  maxDate={moment()}
                  views={['year', 'month']}
                  disableFuture
                  onChange={handleDateChangeFrom}
                />
              </LocalizationProvider>
            </div>
            <div className='col-sm-3 fv-row'>
              <label className='col-lg-6 col-form-label fw-bold fs-6'>
                <span className='required'>To</span>
              </label>
              <br />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={DateMonthTo}
                  minDate={moment('2015')}
                  renderInput={(params: any) => <TextField {...params} />}
                  maxDate={moment()}
                  views={['year', 'month']}
                  disableFuture
                  onChange={handleDateChangeTo}
                />
              </LocalizationProvider>
            </div>

            <div className='col-sm-3 fv-row'>
              <label className='col-lg-6 col-form-label fw-bold fs-6'>
                <span className=''>Select Practice</span>
              </label>

              <Select
                maxMenuHeight={180}
                value={SelectedPracticeList}
                onChange={getSelectedPractice}
                id='Practice'
                name={'Practice'}
                options={UsersListData}
                isDisabled={PracticeDisabled}
              />
            </div>
          </>
          <div className='col-2 pt-13'>
            {FromDate && ToDate && PracticeName.label ?
              <button onClick={GetPDF} className='btn btn-primary' disabled={isRunning}>
                Get PDF
              </button> :
              <button onClick={GetPDF} className='btn btn-primary' disabled={true}>
                Get PDF
              </button>
            }
          </div>
          <div className='col-1 pt-13'>
            <button
              // onClick={ClearFilter}
              className='btn btn-primary'>
              Clear
            </button>
          </div>
        </div>
        <hr />

        {/* //Charges Data */}

        {ChargesData?.length > 0 ? (
          <>
            <h4 className='text-center fs-7'>Modality Wise Charges</h4>
             <div  className='d-flex justify-content-center'>
              <div ref={ChargesRef}>
              <BarChart
              width={1100}
              height={500}

                data={ChargesData}
                margin={{
                  top: 30,
                  right: 10,
                  left: 20,
                  bottom: 30
                }}
              >
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <XAxis dataKey='month_year' tick={{
                  fontSize: 12,
                  fontWeight: '700',
                  fill: 'rgb(0, 0, 0)'
                }} />
                <YAxis tickFormatter={formatDollarValue} tick={{
                  fontSize: 12,
                  fontWeight: '700',
                  fill: 'rgb(0, 0, 0)'
                }} />
                <Tooltip formatter={formatConeDollarValue} />
                <Legend formatter={(value) => <span style={{ color: 'rgb(0, 0, 0)', fontWeight: 'bold', fontSize: 10 }}>{value}</span>} />
                {Object.keys(ChargesData[0])
                  .filter(key => key !== 'month_year')
                  .map((key, index) => (
                    <Bar
                      key={index}
                      dataKey={key}
                      fill={colors[index]}
                      stroke={colors[index]}
                      barSize={Object.keys(ChargesData[0]).length < 9 ? 25 : 15}
                      label={{
                        position: 'top',
                        formatter: formatDollarValue,
                        angle: Object.keys(ChargesData[0]).length > 8 ? -90 : 0,
                        dy: Object.keys(ChargesData[0]).length > 8 ? -15 : 0,
                        dx: Object.keys(ChargesData[0]).length > 8 ? 2 : 0,
                        fontWeight: 600,
                        fill: 'rgb(0, 0, 0)',
                        fontSize: 10
                      }}
                    >
                    </Bar>
                  ))}
              </BarChart>
              </div>
      
            
            </div>
          </>
        ) : null}


        {ChargesData?.length > 0 ? (
          <div className='col-11 shadow mt-8' ref={ChargesTableRef}>
            <table
              id='kt_table_users'
              className=' w-100 table-bordered align-middle fs-7 font-family-base no-footer w-full '
            >
              <thead style={{ background: '#a6b1e1' }}>
                <tr className=' fs-7 text-uppercase gs-0 text-gray-800 py-6 px-6'>
                  <th className='text-center p-2'>Month</th>
                  {ChargesHeader?.map((headerText: string) => (
                    <th className='text-center p-2' key={headerText}>
                      {headerText}
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody style={{ background: '#e7eaf6' }}>
                {ChargesData?.map((item: any, index: number) => (
                  <tr key={index} className=" fs-7 gs-0 text-gray-800">
                    <td className='px-3 py-2 text-center'>{item.month_year}</td>
                    {ChargesHeader?.map((key: string, i: number) => (
                      <td className='px-3 py-2 text-center' key={i}>
                        {item[key] ? item[key].toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }) : '$0'}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}



        {/* //Payments Data */}

        {PaymentsData?.length > 0 ? (
          <>
            <h4 className='text-center fs-7'>Modality Wise Payments</h4>
            <div  className='d-flex justify-content-center'>
              <div ref={PaymentsRef}>
            <BarChart
                data={PaymentsData}
                width={1100}
                height={500}
                margin={{
                  top: 30,
                  right: 10,
                  left: 20,
                  bottom: 30
                }}
              >
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <XAxis dataKey='month_year' tick={{
                  fontSize: 12,
                  fontWeight: '700',
                  fill: 'rgb(0, 0, 0)'
                }} />
                <YAxis tickFormatter={formatDollarValue}
                  tick={{
                    fontSize: 12,
                    fontWeight: '700',
                    fill: 'rgb(0, 0, 0)'
                  }}
                />
                <Tooltip formatter={formatConeDollarValue} />
                <Legend formatter={(value) => <span style={{ color: 'rgb(0, 0, 0)', fontWeight: 'bold', fontSize: 10 }}>{value}</span>} />
                {Object.keys(PaymentsData[0])
                  .filter(key => key !== 'month_year')
                  .map((key, index) => (
                    <Bar
                      key={index}
                      dataKey={key}
                      fill={colors[index]}
                      stroke={colors[index]}
                      barSize={Object.keys(PaymentsData[0]).length < 9 ? 25 : 15}
                      label={{
                        position: 'top',
                        formatter: formatDollarValue,
                        angle: Object.keys(PaymentsData[0]).length > 8 ? -90 : 0,
                        dy: Object.keys(PaymentsData[0]).length > 8 ? -15 : 0,
                        dx: Object.keys(PaymentsData[0]).length > 8 ? 2 : 0,
                        fontWeight: 600,
                        fill: 'rgb(0, 0, 0)',
                        fontSize: 10

                      }}
                    >

                    </Bar>
                  ))}
              </BarChart>
              </div>
            
            </div>
          </>
        ) : null}


        {PaymentsData?.length > 0 ? (
          <div className='col-11 shadow mt-8' ref={PaymentsTableRef}>
            <table
              id='kt_table_users'
              className=' w-100 table-bordered align-middle fs-7 font-family-base no-footer w-full '
            >
              <thead style={{ background: '#a6b1e1' }}>
                <tr className='text-start fs-7 text-uppercase gs-0 text-gray-800 py-6 px-6'>
                  <th className='text-center p-2'>Month</th>
                  {PaymentsHeader?.map((headerText: string) => (
                    <th className='text-center p-2' key={headerText}>
                      {headerText}
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody style={{ background: '#e7eaf6' }}>
                {PaymentsData?.map((item: any, index: number) => (
                  <tr key={index} className="fs-7 gs-0 text-gray-800">
                    <td className='px-3 py-2 text-center'>{item.month_year}</td>
                    {PaymentsHeader?.map((key: string, i: number) => (
                      <td className='px-3 py-2 text-center' key={i}>
                        {item[key] ? item[key].toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }) : '$0'}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}

        {/* Payer Mix Report */}

        {PayersData?.length > 0 ? (
          <>
            <h4 className='text-center my-8 fs-7'>Payer Mix Report</h4>
       
            <div  className='d-flex justify-content-center'>
              <div ref={PayerChargesRef}>
            <BarChart
             width={1100}
             height={500}
             
                data={PayersData}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5
                }}
              >

                <XAxis
                  dataKey="Payer"
                  tick={{ fontSize: 10, fontWeight: '700', fill: 'rgb(0, 0, 0)' }}
                  interval="preserveStartEnd"
                  tickLine={false}
                  tickFormatter={providerTickFormatter}
                >
                </XAxis>
                <YAxis
                  // domain={[0, 100]}
                  tickFormatter={formatDollarValue}
                  tick={{ fontSize: 10, fontWeight: '700', fill: 'rgb(0, 0, 0)' }}
                />
                <Tooltip formatter={formatDollarValue} />
                <Legend formatter={(value) => <span style={{ color: 'rgb(0, 0, 0)', fontWeight: 'bold', fontSize: 10 }}>{value}</span>} />

                <Bar dataKey="Charges" fill="rgb(30,144,255)" barSize={PayersData.length <= 3 ? 40 : 30}
                >
                  <LabelList
                    dataKey="Charges"
                    position="top"
                    formatter={formatDollarValue}
                    style={{ fontSize: 10, fontWeight: '700', fill: 'rgb(0, 0, 0)' }}
                  />
                </Bar>
              </BarChart>
              </div>
            
            </div>
          </>
        ) : null}

        {PayersData?.length > 0 ? (
          <>
            <div  className='d-flex justify-content-center'>
              <div ref={PayerPaymentsRef}>
            <BarChart
               width={1100}
               height={500}
                data={PayersData}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5
                }}
              >
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <XAxis dataKey="Payer"
                  tick={{ fontSize: 10, fontWeight: '700', fill: 'rgb(0, 0, 0)' }}
                  interval="preserveStartEnd"
                  tickLine={false}
                  tickFormatter={providerTickFormatter}
                >
                </XAxis>
                <YAxis
                  // domain={[0, 100]}
                  tickFormatter={formatDollarValue}
                  tick={{ fontSize: 10, fontWeight: '700', fill: 'rgb(0, 0, 0)' }}
                />
                <Tooltip formatter={formatDollarValue} />
                <Legend formatter={(value) => <span style={{ color: 'rgb(0, 0, 0)', fontWeight: 'bold', fontSize: 10 }}>{value}</span>} />
                <Bar dataKey="Payments" fill="rgb(30,144,255)" barSize={PayersData.length <= 3 ? 40 : 30} >
                  <LabelList
                    dataKey="Payments"
                    position="top"
                    formatter={formatDollarValue}
                    style={{ fontSize: 10, fontWeight: '700', fill: 'rgb(0, 0, 0)' }}


                  />
                </Bar>

              </BarChart>
              </div>
           
            </div>
          </>
        ) : null}


        {PayersData?.length > 0 ? (
          <div ref={PayerTableRef} className='my-8'>
            <table
              id='kt_table_users'
              className=' table-bordered align-middle fs-7 p-4  font-family-base no-footer col-12 h-full '
            >
              <thead style={{ background: "#a6b1e1" }}>
                <tr className="fs-7 gs-0 text-gray-800">
                  <th className='text-center p-2'>Payer</th>
                  <th className='text-center p-2' colSpan={2}>Charges</th>
                  <th className='text-center p-2' colSpan={2}>Payments</th>
                </tr>

                <tr className="fs-7 gs-0 text-gray-800">
                  <th className='text-center p-2'></th>
                  <th className='text-center p-2'>$</th>
                  <th className='text-center p-2'>%</th>
                  <th className='text-center p-2'>$</th>
                  <th className='text-center p-2'>%</th>
                </tr>

              </thead>
              <tbody style={{ background: "#e7eaf6" }}>
                {PayersData.map((res: any, ind: any) => {
                  return (
                    <tr key={ind} className="fs-7 gs-0 text-gray-800">
                      <td className='px-3 py-2 text-center'>{res.Payer ? res.Payer : 'None'}</td>
                      <td className='px-3 py-2 text-center'>
                      {res.Charges ? Number(res.Charges).toLocaleString('en-US', options) : '$0'}
                      </td>
                      <td className='px-3 py-2 text-center'>
                        {res.ChargesPer ? res.ChargesPer : '0'}%
                      </td>
                      <td className='px-3 py-2 text-center'>
                      {res.Payments ? Number(res.Payments).toLocaleString('en-US', options) : '$0'}
                      </td>
                      <td className='px-3 py-2 text-center'>
                        {res.Percentage ? res.Percentage : '0'}%
                      </td>
                    </tr>
                  )
                })}
              </tbody>
              <thead style={{ background: "#a6b1e1" }}>
                <tr className="fs-7 gs-0 text-gray-800 text-center px-3 py-2">
                  <td>Total</td>
                  <td>{TotalCharges ? TotalCharges.toLocaleString("en-US", options) : "$0"}</td>
                  <td>{ChargesPer ? Math.round(ChargesPer) : "0"}%</td>
                  <td>{TotalPayments ? TotalPayments.toLocaleString("en-US", options) : "$0"}</td>
                  <td>{PaymentsPer ? Math.round(PaymentsPer) : "0"}%</td>
                </tr>
              </thead>
            </table>
          </div>

        ) : null}

        {/* Guarantor Report */}
        {GuarantorData?.length > 0 ?
          <>
            <h4 className='text-center my-8 fs-7'>Guarantor Aging Report</h4>
            <div className='parentContainerCharges' ref={GurantorRef}>
              
            {GuarantorData?.map((card: any, ind: number) => (
                <CardGuarantor
                  data={card}
                  key={ind}
                  color={colors[ind]}
                  background="no"
                 customWidth={window.innerWidth >= 1900 ? 600 : 500}
                 customHeight={window.innerWidth >= 1900 ? 350 : 250}
                />
              ))}
             
             </div>

          </>
          : null}


        {GuarantorData?.length > 0 ? (
          <div ref={GurantorTableRef}>
            <table
              id='kt_table_users'
              className=' table-bordered align-middle fs-7 p-4  font-family-base no-footer col-12 h-full '
            >
              <thead style={{ background: "#a6b1e1" }}>
                <tr>
                  <th className='text-center p-2'>Month/Year</th>
                  <th className='text-center p-2' >0-30</th>
                  <th className='text-center p-2' >30-60</th>
                  <th className='text-center p-2' >60-90</th>
                  <th className='text-center p-2' >90-120</th>
                  <th className='text-center p-2' >Above 120</th>
                  <th className='text-center p-2' >Total</th>

                </tr>

              </thead>
              <tbody style={{ background: "#e7eaf6" }}>
                {GuarantorData?.map((res: any, ind: any) => {
                  return (
                    <tr key={ind} className="fs-7 gs-0 text-gray-800">
                      <td className='px-3 py-2 text-center'>{res.month_year ? res.month_year : 'None'}</td>
                      <td className='px-3 py-2 text-center'>
                        {res["0_30"] ? res["0_30"].toLocaleString('en-US', options) : '$0'}
                      </td>
                      <td className='px-3 py-2 text-center'>
                        {res["30_60"] ? res["30_60"].toLocaleString('en-US', options) : '$0'}
                      </td>
                      <td className='px-3 py-2 text-center'>
                        {res["60_90"] ? res["60_90"].toLocaleString('en-US', options) : '$0'}
                      </td>
                      <td className='px-3 py-2 text-center'>
                        {res["90_120"] ? res["90_120"].toLocaleString('en-US', options) : '$0'}
                      </td>
                      <td className='px-3 py-2 text-center'>
                        {res["120Above"] ? res["120Above"].toLocaleString('en-US', options) : '$0'}
                      </td>

                      <td className='px-3 py-2 text-center'>
                        {(
                          (res["0_30"] ? res["0_30"] : 0)
                          + (res["30_60"] ? res["30_60"] : 0)
                          + (res["60_90"] ? res["60_90"] : 0)
                          + (res["90_120"] ? res["90_120"] : 0)
                          + (res["120Above"] ? res["120Above"] : 0))
                          .toLocaleString('en-US', options)}
                      </td>

                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        ) : null}

        {/* Drug  */}

        {DrugsProfitData?.length > 0 ? (
          <>
            <h4 className='my-8 text-center'>Top 10 Profitable Drugs (Based on ASP Pricing)</h4>
            <div  className='d-flex justify-content-center'>
              <div ref={DrugProfitRef}>
            <BarChart
             width={1100}
             height={500}
             
                data={DrugsProfitData}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5
                }}
              >
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <XAxis dataKey="cp_cpt_code" tick={{ fontSize: 10, fontWeight: '700', fill: 'rgb(0, 0, 0)' }} />
                <YAxis tickFormatter={formatConeDollarValue} tick={{ fontSize: 10, fontWeight: '700', fill: 'rgb(0, 0, 0)' }} />
                <Legend formatter={(value) => <span style={{ color: 'rgb(0, 0, 0)', fontWeight: 'bold', fontSize: 12 }}>{value}</span>} />
                <Bar
                  dataKey="Payment"
                  fill="#8884d8"
                  shape={<TriangleBar />}
                  label={{ position: "top", formatter: formatConeDollarValue, fontSize: 10, fontWeight: '700', fill: 'rgb(0, 0, 0)' }}
                >
                  {DrugsProfitData.map((entry: any, index: number) => (
                    <Cell key={`cell-${index}`} fill={colors[index]} />
                  ))}
                </Bar>
              </BarChart>
              </div>

             
            </div>
          </>
        ) : null}


        {DrugsLossData?.length > 0 ? (
          <>
            <h4 className='fs-7 uppercase text-center '>Top 10 Loss making Drugs (Based on ASP Pricing)</h4>
            <div  className='d-flex justify-content-center'>
              <div ref={DrugLossRef}>
            <BarChart
                width={1100}
                height={500}
                data={DrugsLossData}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5
                }}
              >
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <XAxis dataKey="cp_cpt_code" tick={{ fontSize: 10, fontWeight: '700', fill: 'rgb(0, 0, 0)' }} />
                <YAxis tickFormatter={formatConeDollarValue} tick={{ fontSize: 10, fontWeight: '700', fill: 'rgb(0, 0, 0)' }} />
                <Legend formatter={(value) => <span style={{ color: 'rgb(0, 0, 0)', fontWeight: 'bold', fontSize: 12 }}>{value}</span>} />
                <Bar
                  dataKey="Payment"
                  fill="#8884d8"
                  shape={<TriangleBar />}
                  label={{ position: "top", formatter: formatConeDollarValue, fontSize: 10, fontWeight: '700', fill: 'rgb(0, 0, 0)' }}
                >
                  {DrugsLossData.map((entry: any, index: number) => (
                    <Cell key={`cell-${index}`} fill={colors[index]} />
                  ))}
                </Bar>
              </BarChart>
            </div>
             
            </div>

          </>) : null}


        {FinalDrugTable?.length > 0 ? (
          <div ref={DrugTableRef}>
            <table
              id='kt_table_users'
              className=' table-bordered align-middle fs-7 p-4  font-family-base no-footer col-12 h-full '
            >
              <thead style={{ background: "#a6b1e1" }}>
                <tr className="fs-7 gs-0 text-gray-800">
                  <th className='text-center p-2'>CPT Codes</th>
                  <th className='text-center p-2' >CPT Description</th>
                  <th className='text-center p-2' >Amount</th>
                  <th className='text-center p-2' >Over/UnderPaid</th>

                </tr>

              </thead>
              <tbody style={{ background: "#e7eaf6", width: "auto", height: "auto" }}>
                {FinalDrugTable.map((res: any, ind: any) => {
                  return (
                    <tr key={ind} className="fs-7 gs-0 text-gray-800">
                      <td className='px-3 py-2 text-center'>{res.cp_cpt_code ? res.cp_cpt_code : 'None'}</td>
                      <td className='px-3 py-2 text-center'>
                        {res.cpt_description ? res.cpt_description : 'None'}
                      </td>
                      <td className='px-3 py-2 text-center'>
                        {res.Payment ? res.Payment.toLocaleString('en-US', options) : '0'}
                      </td>
                      <td className={`px-3 py-2 text-center text-uppercase ${res.pl_type === "Under Paid" ? "bg-danger" : "bg-success"}`}>
                        {res.pl_type ? res.pl_type : '0'}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        ) : null}

        {FinalDrugTable?.length > 0 ? (

          <div className='col-6 shadow rounded my-6 p-4'>
            <h5>Note:</h5>
            <p className='py-2 '>The total amount yet to receive for Drugs :<span className='text-dark fw-bold'>{SumDrugData > 0 ? SumDrugData.toLocaleString('en-US', options) : "$0"}</span> </p>
          </div>
        ) : null}

        {FinalNonDrugTable?.length > 0 && (

          <div className='col-6 shadow rounded my-6 p-4'>
            <h5>Note:</h5>
            <p className='py-2 '>The total amount yet to receive for Non Drugs:<span className='text-dark fw-bold'>{SumNonDrugData > 0 ? SumNonDrugData.toLocaleString('en-US', options) : "$0"}</span> </p>
          </div>
        )}

        {/* Profit Loss Report Based on Drug Pricing) */}

        {ProfitData?.length > 0 ? (
          <>
            <h4 className='my-8 text-center fs-7'>Top 10 Profitable Drugs (Based on Drug Pricing)</h4>
            <div  className='d-flex justify-content-center'>
              <div ref={ProfitRef}>
              <BarChart
                width={1100}
                height={500}
                data={ProfitData}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5
                }}
              >
                <XAxis dataKey="cpt_codes" tick={{ fontSize: 10, fontWeight: '700', fill: 'rgb(0, 0, 0)' }} />
                <YAxis tick={{ fontSize: 10, fontWeight: '700', fill: 'rgb(0, 0, 0)' }} tickFormatter={formatConeDollarValue} />
                <Legend formatter={(value) => <span style={{ color: 'rgb(0, 0, 0)', fontWeight: 'bold', fontSize: 12 }}>{value}</span>} />
                <Bar
                  dataKey="Payment"
                  fill="#8884d8"
                  shape={<TriangleBar />}
                  label={{ position: "top", formatter: formatConeDollarValue, fontSize: 10, fontWeight: '700', fill: 'rgb(0, 0, 0)' }}
                >
                  {ProfitData?.map((entry: any, index: number) => (
                    <Cell key={`cell-${index}`} fill={colors[index]} />
                  ))}
                </Bar>
              </BarChart>
            </div>

            </div>
          </>
        ) : null}


        {LossData?.length > 0 ? (
          <>
            <h4 className='text-center fs-7'>Top 10 Loss making Drugs (Based on Drug Pricing)</h4>
            <div  className='d-flex justify-content-center'>
              <div ref={LossRef}>
              <BarChart
                width={1100}
                height={500}
                data={LossData}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5
                }}
              >
                <XAxis dataKey="cpt_codes" tick={{ fontSize: 10, fontWeight: '700', fill: 'rgb(0, 0, 0)' }} />
                <YAxis tick={{ fontSize: 10, fontWeight: '700', fill: 'rgb(0, 0, 0)' }} tickFormatter={formatConeDollarValue} />

                <Legend formatter={(value) => <span style={{ color: 'rgb(0, 0, 0)', fontWeight: 'bold', fontSize: 12 }}>{value}</span>} />
                <Bar
                  dataKey="Payment"
                  fill="#8884d8"
                  shape={<TriangleBar />}
                  label={{ position: "top", formatter: formatConeDollarValue, fontSize: 10, fontWeight: '700', fill: 'rgb(0, 0, 0) ' }}
                >
                  {LossData?.map((entry: any, index: number) => (
                    <Cell key={`cell-${index}`} fill={colors[index]} />
                  ))}
                </Bar>
              </BarChart>
            </div>
            </div>


          </>) : null}

        {FinalTable?.length > 0 ? (
          <div ref={TableRef}>
            <table
              id='kt_table_users'
              className=' table-bordered align-middle fs-7 p-4  font-family-base no-footer col-12 h-full '
            >
              <thead style={{ background: "#a6b1e1" }}>
                <tr>
                  <th className='text-center p-2'>CPT Codes</th>
                  <th className='text-center p-2' >CPT Description</th>
                  <th className='text-center p-2' >Amount</th>
                  <th className='text-center p-2' >Profit/Loss</th>

                </tr>

              </thead>
              <tbody style={{ background: "#e7eaf6" }}>
                {FinalTable.map((res: any, ind: any) => {
                  return (
                    <tr key={ind} className="fs-7 gs-0 text-gray-800">
                      <td className='px-3 py-2 text-center'>{res.cpt_codes ? res.cpt_codes : 'None'}</td>
                      <td className='px-3 py-2 text-center'>
                        {res.cpt_description ? res.cpt_description : 'None'}
                      </td>
                      <td className='px-3 py-2 text-center'>
                        {res.Payment ? res.Payment.toLocaleString('en-US', options) : '0'}
                      </td>
                      <td className={`px-3 py-2 text-center text-uppercase ${res.pl_type === "Profit" ? "bg-success" : "bg-danger"}`}>
                        {res.pl_type ? res.pl_type : 'UnKnown'}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        ) : null}


        {FinalTable?.length > 0 && (

          <div className='col-6 shadow rounded my-6 p-4'>
            <h5>Note:</h5>
            <p className='py-2 '>The total amount yet to receive for:<span className='text-dark fw-bold'>{SumPLData > 0 ? SumPLData.toLocaleString('en-US', options) : "$0"}</span> </p>
          </div>
        )}
        {/* Provider Productivity */}

        {FinalArray?.length > 0 ?
          <>
            <h4 className='text-center '>Provider Productivity</h4>

            <div className='parentContainerProd mx-4 pt-4' ref={ProviderRef1}>
              {FinalArray[0]?.length > 0 ?

                FinalArray[0]?.map((card: any, id: any) => {
                  const cardValues: any = Object.values(card)[0]
                  if (cardValues.length > 0) {
                    return (
                      <div key={id} >
                        
                        <Card
                          color={id % 2 === 0 ? {
                            backGround: "linear-gradient(180deg, #bb67ff 0%, #c484f3 100%)",
                            boxShadow: "0px 10px 20px 0px #e0c6f5",
                          } : {
                            backGround: "linear-gradient(180deg, #FF919D 0%, #FC929D 100%)",
                            boxShadow: "0px 10px 20px 0px #FDC0C7",
                          }}
                          data={Object.values(card)}
                          meta="yes"
                          total_paid={card.total}
                          count={card.count}
                          billed_amount={card.billed_amount}
                        //  style={{text:"black"}}
                        />
                      </div>
                    )

                  }

                }
                ) : null}
           
            </div>



            <div className='parentContainerProd mx-4' ref={ProviderRef2}>
              {
                FinalArray[1]?.length > 0 ?
                  FinalArray[1]?.map((card: any, id: any) => {
                    const cardValues: any = Object.values(card)[0]
                    if (cardValues.length > 0) {
                      return (
                        <div key={id} >
                          <Card
                            color={id % 2 === 0 ? {
                              backGround: "linear-gradient(180deg, #bb67ff 0%, #c484f3 100%)",
                              boxShadow: "0px 10px 20px 0px #e0c6f5",
                            } : {
                              backGround: "linear-gradient(180deg, #FF919D 0%, #FC929D 100%)",
                              boxShadow: "0px 10px 20px 0px #FDC0C7",
                            }}
                            data={Object.values(card)}
                            meta="yes"
                            total_paid={card.total}
                            count={card.count}
                            billed_amount={card.billed_amount}
                          //  style={{text:"black"}}
                          />
                        </div>
                      )
                    }

                  }
                  ) : null
              }
            </div>

            <div className='parentContainerProd mx-4' ref={ProviderRef3}>
              {
                FinalArray[2]?.length > 0 ?
                  FinalArray[2]?.map((card: any, id: any) => {
                    const cardValues: any = Object.values(card)[0]
                    if (cardValues.length > 0) {
                      return (
                        <div key={id} >
                          <Card
                            color={id % 2 === 0 ? {
                              backGround: "linear-gradient(180deg, #bb67ff 0%, #c484f3 100%)",
                              boxShadow: "0px 10px 20px 0px #e0c6f5",
                            } : {
                              backGround: "linear-gradient(180deg, #FF919D 0%, #FC929D 100%)",
                              boxShadow: "0px 10px 20px 0px #FDC0C7",
                            }}
                            data={Object.values(card)}
                            meta="yes"
                            total_paid={card.total}
                            count={card.count}
                            billed_amount={card.billed_amount}
                          //  style={{text:"black"}}
                          />
                        </div>
                      )
                    }

                  }
                  ) : null
              }
            </div>


            <div className='parentContainerProd mx-4' ref={ProviderRef4}>
              {
                FinalArray[3]?.length > 0 ?
                  FinalArray[3]?.map((card: any, id: any) => {
                    const cardValues: any = Object.values(card)[0]
                    if (cardValues.length > 0) {
                      return (
                        <div key={id} >
                          <Card
                            color={id % 2 === 0 ? {
                              backGround: "linear-gradient(180deg, #bb67ff 0%, #c484f3 100%)",
                              boxShadow: "0px 10px 20px 0px #e0c6f5",
                            } : {
                              backGround: "linear-gradient(180deg, #FF919D 0%, #FC929D 100%)",
                              boxShadow: "0px 10px 20px 0px #FDC0C7",
                            }}
                            data={Object.values(card)}
                            meta="yes"
                            total_paid={card.total}
                            count={card.count}
                            billed_amount={card.billed_amount}
                          //  style={{text:"black"}}
                          />
                        </div>
                      )
                    }

                  }
                  ) : null
              }
            </div>


          </>
          : null}



      </div>
    </>


  )
}
export { ExportPDFCom }