import React, {useState, useEffect, FunctionComponent} from 'react'
import {useNavigate} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {useIntl} from 'react-intl'
import axios from 'axios'
import { toast } from 'react-toastify';
import ToastOption from '../Common/ToastOption'
import {useAuth} from '../../../modules/auth'
import {Typography, CircularProgress, Backdrop} from '@mui/material'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import TextField from '@mui/material/TextField'
import Select from 'react-select'
import swal from 'sweetalert'
import moment from 'moment'
import {
  AreaChart,
  Area,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  Cell,
} from 'recharts'

const getPracticeName = `/api/PracticeSummary/getPracticeName`
const getChargesWise = `/api/ChargesWise/getChargesWiseByCPT`



interface PracticeListType {
  label: string
  value: string
}
const formatDollarValue = (value: {toLocaleString: () => any}) => `$${value.toLocaleString()}`
const options = {style: 'currency', currency: 'USD', maximumFractionDigits: 0}
const colors = [
  "#1f77b4", // blue
  "#ff7f0e", // orange
  "#2ca02c", // green
  "#d62728", // red
  "#9467bd", // purple
  "#8c564b", // brown
  "#e377c2", // pink
  "#7f7f7f", // gray
  "#bcbd22", // lime
  "#17becf"  // cyan
];

const ChargesByCPTClass: React.FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const location = useLocation()
  const userDetails: any = location.state
  const {currentUser}: any = useAuth()
  const [UserNameDatas, setUserNameDatas] = useState([{value: '', label: ''}])
  const [DateMonthFrom, setDateMonthFrom] = useState('')
  const [FromDate, setFromDate] = useState('')
  const [ToDate, setToDate] = useState('')
  const [DateMonthTo, setDateMonthTo] = useState('')
  const [SelectedPracticeList, setSelectedPracticeList] = useState<PracticeListType[]>([])
  const [ArData, setArData] = useState([])
  const [NcrData, setNcrData] = useState([])
  const [open, setOpen] = React.useState(false)
  const [Headers, setHeaders] = useState<string[]>([])
  const [NoData,setNoData]=useState<boolean>(false);
const [ChartNcrData,SetChartNcrData]=useState<any>([])


  const FilterDataList = async () => {
    setNcrData([])
    setHeaders([])
    setOpen(true)
    var tempValues = {
      UserId :currentUser._id,
      Path:window.location.pathname,
      FromDate:FromDate,
      ToDate:ToDate,
      PracticeName : JSON.stringify(SelectedPracticeList)
    }
    axios
      .get(
        `${getChargesWise}?tempValues=${JSON.stringify(tempValues)}&FromDate=${FromDate}&ToDate=${ToDate}&PracticeName=${JSON.stringify(
          SelectedPracticeList
        )}`
      )
      .then((_res) => {

        if(_res.data.message){
          setNoData(true)
          setOpen(false)

        }
        else{
          setNcrData(_res.data.data)
          setHeaders(_res.data.CptClass)
          setOpen(false)

          const allSetKeys:any=new Set(_res.data.data.flatMap(Object.keys));

          const newArr=_res.data.data?.map((obj:any)=>{
            const newObj={...obj};
            
            allSetKeys?.forEach((key:string)=>{
                if(!newObj.hasOwnProperty(key)){
                    newObj[key]=0;
                }
            })
            
            return newObj;
        })
        SetChartNcrData(newArr)

        }

      })
  }
  const ParameterStorage = async () => {
    setOpen(true)
    const getPmListData = await axios.get(`/api/PracticeSummary/GetParameterStorage?Path=${window.location.pathname}`)
    setOpen(false)
    if (getPmListData.data != null) {
      let GetData :any= getPmListData.data
      setDateMonthFrom(GetData.FromDate)
      setDateMonthTo(GetData.ToDate)
      setFromDate(GetData.FromDate)
      setToDate(GetData.ToDate)
      setSelectedPracticeList(JSON.parse(GetData.PracticeName))
    }
  }
  const getSelectedPractice = (event: any) => {
    setNcrData([])
    setHeaders([])
    setSelectedPracticeList(event)
  }

  const handleDateChangeFrom = (date: any) => {
    setNcrData([])
    setHeaders([])
    setDateMonthFrom(date)
    setFromDate(moment(date['$d']).format('MMM-YYYY'))
  }
  const handleDateChangeTo = (date: any) => {
    setNcrData([])
    setHeaders([])
    setDateMonthTo(date)
    setToDate(moment(date['$d']).format('MMM-YYYY'))
  }

  const Filter = async (ids: any) => {
    let error = false
    let ErrorArray = []
    if (!DateMonthFrom) {
      error = true
      ErrorArray.push(`Please Choose From Month / Year`)
    }
    if (!DateMonthTo) {
      error = true
      ErrorArray.push(`Please Choose To Month / Year`)
    }
    if(Object.keys(SelectedPracticeList).length == 0){
      error = true
      ErrorArray.push(`Please Select Practice`)
    }
    if(error){
      ErrorArray.map((res,ind)=>{
        toast.error(`${res}`,ToastOption);
      })
      return false;
    }
    FilterDataList()
  }
  const ClearFilter = async (ids: any) => {
    setDateMonthFrom('')
    setDateMonthTo('')
    setSelectedPracticeList([])
  }
  const getPracticeNameata = async () => {
    const Result = await axios.get(getPracticeName)
    setUserNameDatas(Result.data.PracticeData)
  }
  useEffect(() => {
    getPracticeNameata()
    ParameterStorage()
  }, [])
  var UsersListData: any = []
  UserNameDatas.map((practiceList: any, index) => {
    UsersListData.push({
      label: practiceList.practice_name,
      value: practiceList.practice_name,
    })
  })

  return (
    <>
      <Backdrop
        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
        open={open}
        // onClick={handleClose}
      >
        <CircularProgress color='inherit' />
        <Typography variant='h5' className='text-white mx-4'>
          Fetching data...
        </Typography>
      </Backdrop>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle> */}
      <div>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Modality wise Charges</h3>
          </div>
        </div>
        <hr />
        <div className='row'>
          <>
            <div className='col-sm-3 fv-row'>
              <label className='col-lg-6 col-form-label fw-bold fs-6'>
                <span className='required'>From</span>
              </label>
              <br />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={DateMonthFrom}
                  minDate={moment('2015')}
                  renderInput={(params: any) => <TextField {...params} />}
                  maxDate={moment()}
                  views={['year', 'month']}
                  disableFuture
                  onChange={handleDateChangeFrom}
                />
              </LocalizationProvider>
            </div>
            <div className='col-sm-3 fv-row'>
              <label className='col-lg-6 col-form-label fw-bold fs-6'>
                <span className='required'>To</span>
              </label>
              <br />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={DateMonthTo}
                  minDate={moment('2015')}
                  renderInput={(params: any) => <TextField {...params} />}
                  maxDate={moment()}
                  views={['year', 'month']}
                  disableFuture
                  onChange={handleDateChangeTo}
                />
              </LocalizationProvider>
            </div>
            <div className='col-sm-3 fv-row'>
              <label className='col-lg-12 col-form-label fw-bold fs-6'>
                <span className='required'>Select Practice</span>
              </label>

              <Select
                maxMenuHeight={180}
                value={SelectedPracticeList}
                onChange={getSelectedPractice}
                id='Practice'
                name={'Practice'}
                options={UsersListData}
              />
            </div>
          </>
          <div className='col-1 pt-13'>
            <button
              onClick={(e) => {
                Filter(e)
              }}
              className='btn btn-primary'
            >
              Filter
            </button>
          </div>
          <div className='col-1 pt-13'>
            <button
              onClick={(e) => {
                ClearFilter(e)
              }}
              className='btn btn-primary'
            >
              Clear
            </button>
          </div>
        </div>
        <hr />

        <div className='mb-8'>
          {NcrData.length > 0 ? (
            <>
              <h4 className='fs-5 uppercase text-center'>CPT Class ~ Charges</h4>

              <BarChart
width={900 }
height={400}

data={ChartNcrData}
margin={{
  top: 80,
  right: 10,
  left: 20,
  bottom: 0
}}
>
<CartesianGrid strokeDasharray="3 3" />
<XAxis dataKey='month_year'  tick={{ fontSize: 12 }}/>
<YAxis tickFormatter={formatDollarValue}  tick={{ fontSize: 12 }}/>
<Tooltip formatter={(value) => formatDollarValue(value)} />
<Legend fontWeight="bold"/>
    {Object.keys(ChartNcrData[0])
    .filter(key => key !== 'month_year')
    .map((key, index) => (
      <Bar
        key={index}
        dataKey={key}
        fill={colors[index]}
        stroke={colors[index]}
        barSize={30}
        label={{
          position: 'top',
          formatter: formatDollarValue,
          angle: -90,
          dy: -30,
          dx:2,
          fontSize:10
        
        }} 
      >
       

      </Bar>
    ))}


   
</BarChart>
            </>
          ) : null}
        </div>

          {NcrData.length > 0 ? (
            <table
              id='kt_table_users'
              className=' w-100 table-bordered align-middle fs-6 font-family-base no-footer w-full '
            >
              <thead style={{background: '#a6b1e1'}}>
                <tr className='text-start  fw-bolder fs-7 text-uppercase gs-0 text-gray-800 py-6 px-6'>
                  <th className='text-center p-2'>Month</th>
                  {Headers?.map((headerText: string) => (
                    <th className='text-center p-2' key={headerText}>
                      {headerText}
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody style={{background: '#e7eaf6'}}>
                {NcrData?.map((item: any, index: number) => (
                  <tr key={index}>
                    <td className='px-3 py-2 text-center'>{item.month_year}</td>
                    {Headers?.map((key: string, i: number) => (
                      <td className='px-3 py-2 text-center' key={i}>
                        {item[key] ? item[key].toLocaleString('en-US', options) : '$0'}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          ) :
              (NcrData.length===0  && NoData)?
                  <div>
                    <h5 className="text-center fw-bold">NO Data Found</h5>
                  </div>
                  : null
          }
      </div>
    </>
  )
}
export {ChargesByCPTClass}
