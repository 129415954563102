import React, { useState, ChangeEvent, FormEvent } from 'react';
import { toast } from 'react-toastify';

interface FormData {
    CPT_class1: string;
    CPT_class2: string;
    CPT_description: string;
    CPT_code: string;
}

interface AddnewvalueProps {
    showModal: string;
    handleClose: () => void;
    onFormDataChange: (data: FormData) => void;
}

const Addnewvalue: React.FC<AddnewvalueProps> = ({ showModal, handleClose, onFormDataChange }) => {
    const [formData, setFormData] = useState<FormData>({
        CPT_class1: '',
        CPT_class2: '',
        CPT_description: '',
        CPT_code: ''
    });

    const handleSave = (e: FormEvent) => {
        e.preventDefault();


        if (!formData.CPT_code) {
            toast.error('Please Enter the CPT Code');
            return;
        }
        if (!formData.CPT_class1) {
            toast.error('Please Enter the CPT Class1');
            return;
        }
        if (!formData.CPT_class2) {
            toast.error('Please Enter the CPT Class2');
            return;
        }
        if (!formData.CPT_description) {
            toast.error('Please Enter the CPT Description');
            return;
        }


        onFormDataChange(formData);
        toast.success('Data saved successfully!');


        setFormData({
            CPT_class1: '',
            CPT_class2: '',
            CPT_description: '',
            CPT_code: ''
        });
        handleClose()

    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    return (
        <div className={`modal fade ${showModal}`} id='kt_modal_add_user' role='dialog' tabIndex={-1} aria-modal='false'>
            <div className='modal-dialog modal-dialog-centered modal-xl'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h2 className='fw-bolder'>Add New Value</h2>
                        <div
                            onClick={handleClose}
                            className='btn btn-icon btn-sm btn-active-icon-primary'
                            data-kt-users-modal-action='close'
                            style={{ cursor: 'pointer' }}
                        >
                            <img src='/media/icons/duotune/arrows/arr061.svg' alt='Close' className='svg-icon-1' />
                        </div>
                    </div>
                    <div className='container row'>

                        <div className='col-4 fv-row mb-4 d-flex flex-column'>
                            <label className='col-form-label col-lg-12 fs-6 fw-bold'>
                                <span className='required'>CPT Code:</span>
                            </label>
                            <input
                                className='p-2 w-70'
                                type='text'
                                name='CPT_code'
                                value={formData.CPT_code}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='col-4 fv-row mb-4 d-flex flex-column'>
                            <label className='col-form-label fw-bold fs-6  '>
                                <span className='required '>CPT Class1:</span>
                            </label>
                            <input
                                name='CPT_class1'
                                type='text'
                                value={formData.CPT_class1}
                                onChange={handleChange}
                                className='p-2 w-70'
                            />
                        </div>
                   
                   
                        <div className='col-4 fv-row mb-4 d-flex flex-column'>
                            <label className='col-form-label fw-bold fs-6 '>
                                <span className='required'>CPT Class2:</span>
                            </label>
                            <input
                                name='CPT_class2'
                                type='text'
                                value={formData.CPT_class2}
                                onChange={handleChange}
                                className='p-2 w-70'
                            />
                        </div>
                        <div className='col-9 fv-row mb-4 d-flex flex-column'>
                            <label className='col-form-label fw-bold fs-6 '>
                                <span className='required'>CPT Description:</span>
                            </label>
                            <input
                                name='CPT_description'
                                type='text'
                                value={formData.CPT_description}
                                onChange={handleChange}
                                className='p-2 w-70'
                            />
                        </div>
                        <div className='fv-row pt-13 mb-2 text-center me-4 d-flex'>
                            <button
                                type='button'
                                className='btn btn-danger rounded'
                                onClick={handleSave}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default Addnewvalue;
