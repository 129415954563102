import React, { useState, useEffect ,FunctionComponent} from 'react'
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import axios from "axios";
import { toast } from 'react-toastify';
import ToastOption from '../Common/ToastOption'
import { useAuth } from '../../../modules/auth'
import {Typography,CircularProgress,Backdrop} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Select from 'react-select';
import swal from "sweetalert"
import moment from "moment";
import {
    AreaChart,
  Area,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ReferenceLine,
    Cell
  } from "recharts";


  const colors = [
    "#1f77b4", // blue
    "#ff7f0e", // orange
    "#2ca02c", // green
    "#d62728", // red
    "#9467bd", // purple
    "#8c564b", // brown
    "#e377c2", // pink
    "#7f7f7f", // gray
    "#bcbd22", // lime
    "#17becf"  // cyan
  ];

const getPracticeName = `/api/PracticeSummary/getPracticeName`
const getReimbursement=`/api/ChargesWise/getReimbursement`

interface PracticeListType{
    label:string,
    value:string
}
interface DrugType{
    
        label:string,
        value:string
    
}

const DrugOptions:DrugType[]=[{label:"Drugs",value:"Drugs"},{label:"Non Drugs",value:"Non Drugs"}]
const formatDollarValue = (value: { toLocaleString: () => any; }) => `$${value.toLocaleString()}`;
const options : any = { style: 'currency', currency: 'USD',maximumFractionDigits: 0 };

const DrugNonDrug: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails: any = location.state;
  const { currentUser }: any = useAuth()
  const [UserNameDatas, setUserNameDatas] = useState([{ value: "", label: "" }])
  const [DateMonthFrom, setDateMonthFrom] = useState("")
const [FromDate, setFromDate] = useState("")
const [ToDate, setToDate] = useState("")
  const [DateMonthTo, setDateMonthTo] = useState("")
  const [SelectedPracticeList, setSelectedPracticeList] = useState<PracticeListType[]>([])
  const [NcrData, setNcrData] = useState([])
  const [open, setOpen] = React.useState(false);
const [DrugType,setDrugType]=useState<DrugType>({label:"",value:""})
const [ProfitChart,setProfitChart]=useState<any>([])
const [LossChart,setLossChart]=useState<any>([])
const [SumData,setSumData]=useState<number>(0);
  const [NoData,setNoData]=useState<boolean>(false);
  const FilterDataList = async () => {
    setOpen(true)
    setProfitChart([])
    setLossChart([])
    setNcrData([])
    var tempValues = {
      UserId :currentUser._id,
      Path:window.location.pathname,
      FromDate:FromDate,
      ToDate:ToDate,
      PracticeName : JSON.stringify(SelectedPracticeList),
      DrugType : JSON.stringify(DrugType),
    }
    axios.get(`${getReimbursement}?tempValues=${JSON.stringify(tempValues)}&FromDate=${FromDate}&ToDate=${ToDate}&PracticeName=${JSON.stringify(SelectedPracticeList)}&DrugType=${DrugType.label}`).then(_res => {

      if(_res.data.message){
        setNoData(true)
        setOpen(false)

      }else{
        setProfitChart(_res.data.ProfitData)
        setLossChart(_res.data.LossData)
        setNcrData(_res.data.FinalTable)
        setSumData(_res.data.SumData)
        setOpen(false)
        setNoData(true)

      }

      
    })
  }
  const ParameterStorage = async () => {
    setOpen(true)
    const getPmListData = await axios.get(`/api/PracticeSummary/GetParameterStorage?Path=${window.location.pathname}`)
    setOpen(false)
    if (getPmListData.data != null) {
      let GetData :any= getPmListData.data
      setDateMonthFrom(GetData.FromDate)
      setDateMonthTo(GetData.ToDate)
      setFromDate(GetData.FromDate)
      setToDate(GetData.ToDate)
      setSelectedPracticeList(JSON.parse(GetData.PracticeName))
      setDrugType(JSON.parse(GetData.DrugType))
    }
  }

  
  const getPath = (x: number, y: number, width: number, height: number) => {
    return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${
      y + height / 3
    } 
    ${x + width / 2}, ${y}
    C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${
      x + width
    }, ${y + height}
    Z`;
  };
  
  const TriangleBar: FunctionComponent<any> = (props: any) => {
    let { fill, x, y, width, height } = props;
  
    return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
  };

  const getSelectedDrugType=(event:any)=>{
    setProfitChart([])
    setLossChart([])
    setNcrData([])
    setDrugType({label:event.label,value:event.value});
  }



  const getSelectedPractice = (event: any) => {

       setSelectedPracticeList(event)
  }
  
  const handleDateChangeFrom = (date: any) => {
    setDateMonthFrom(date)
setFromDate(moment(date['$d']).format("MMM-YYYY"))
  };
  const handleDateChangeTo = (date: any) => {
    setDateMonthTo(date)
setToDate(moment(date['$d']).format("MMM-YYYY"))
  };

  const Filter = async (ids: any) => {

    let error = false
    let ErrorArray = []
    if (!DateMonthFrom) {
      error = true
      ErrorArray.push(`Please Choose From Month / Year`)
    }
    if (!DateMonthTo) {
      error = true
      ErrorArray.push(`Please Choose To Month / Year`)
    }
    if(Object.keys(SelectedPracticeList).length == 0){
      error = true
      ErrorArray.push(`Please Select Practice`)
    }
    if(!DrugType.label){
      error = true
      ErrorArray.push(`Please Select Type`)
    }
    if(error){
      ErrorArray.map((res,ind)=>{
        toast.error(`${res}`,ToastOption);
      })
      return false;
    }

    if (SelectedPracticeList.length === 0) {
      swal({
        title: 'Practice',
        icon: "info",
        text: `Please choose any Practice`,
      }).then((_res: any) => {

      })
      return false;
    }
    if (DrugType.label ==="") {
      swal({
        title: 'DrugType',
        icon: "info",
        text: `Please choose any Drug Type`,
      }).then((_res: any) => {

      })
      return false;
    }
    
    FilterDataList()
  }
  const ClearFilter = async (ids: any) => {

    setDateMonthFrom("")
    setDateMonthTo("")
    setSelectedPracticeList([])

  }
  const getPracticeNameata = async () => {
    const Result = await axios.get(getPracticeName)
    setUserNameDatas(Result.data.PracticeData)
  }
  useEffect(() => {
    getPracticeNameata()
    ParameterStorage()
  }, [])


  var UsersListData: any = []
  UserNameDatas.map((practiceList: any, index) => {
    UsersListData.push({
      label: practiceList.practice_name,
      value: practiceList.practice_name
    })
  })


  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
        <Typography variant="h5" className='text-white mx-4'>Fetching data...</Typography>
      </Backdrop>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle> */}
      <div >
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Reimbursement Dashboard</h3>
          </div>
        </div>
        <hr />
        <div className='row'>
          
            <>
              <div className='col-sm-2 fv-row'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>
                  <span className='required'>From</span>
                </label><br />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                  value={DateMonthFrom}
                  minDate={moment("2015")}
                  renderInput={(params:any) => <TextField {...params}  />}
                  maxDate={moment()}
                  views={['year', 'month']} disableFuture
                    onChange={handleDateChangeFrom}
                  />
                </LocalizationProvider>
              </div>
              <div className='col-sm-2 fv-row'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>
                  <span className='required'>To</span>
                </label><br />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                  value={DateMonthTo}
                  minDate={moment("2015")}
                  renderInput={(params:any) => <TextField {...params}  />}
                  maxDate={moment()}
                  views={['year', 'month']} disableFuture
                    onChange={handleDateChangeTo}
                  />
                </LocalizationProvider>
              </div>
              <div className='col-sm-3 fv-row'>
                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                  <span className='required'>Select Practice</span>
                </label>

                <Select maxMenuHeight={180} value={SelectedPracticeList}  onChange={getSelectedPractice} id="Practice" name={"Practice"} options={UsersListData} />

              </div>
              <div className='col-sm-3 fv-row'>
                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                  <span className='required'>Select Type</span>
                </label>

                <Select maxMenuHeight={180} value={DrugType}  onChange={getSelectedDrugType} id="DrugType" name={"DrugType"} options={DrugOptions} />

              </div>
              
              
              </>
          <div className='col-1 pt-13'>
            <button onClick={(e) => { Filter(e) }} className='btn btn-primary'  >Filter</button>
          </div>
          <div className='col-1 pt-13'>
            <button onClick={(e) => { ClearFilter(e) }} className='btn btn-primary'  >Clear</button>
          </div>
       
        </div><hr />

        <div>

{ProfitChart?.length > 0 ? (
  <>
<h4 className='fs-3 uppercase text-center'>Top 10 Profitable {DrugType.label} (Based on ASP Pricing)</h4>
<div className='col-12 my-10' style={{background:"#dbd8e3"}}>
<BarChart
width={900}
height={400}
data={ProfitChart}
margin={{
top: 20,
right: 30,
left: 20,
bottom: 5
}}
>
<CartesianGrid strokeDasharray="3 3" />
<XAxis dataKey="cp_cpt_code" />
<YAxis />
<Legend />
<Bar
dataKey="Payment"
fill="#8884d8"
shape={<TriangleBar />}
label={{ position: "top" ,formatter: formatDollarValue }}
>
{ProfitChart.map((entry:any, index:number) => (
<Cell key={`cell-${index}`} fill={colors[index]} />
))}
</Bar>
</BarChart>
</div>
</>
) : null}


{LossChart?.length > 0 ? (
<>
<h4 className='fs-3 uppercase text-center'>Top 10 Loss making {DrugType.label} (Based on ASP Pricing)</h4>
<div className='col-12 my-10' style={{background:"#dbd8e3"}}>
<BarChart
width={900}
height={400}
data={LossChart}
margin={{
top: 20,
right: 30,
left: 20,
bottom: 5
}}
>
<CartesianGrid strokeDasharray="3 3" />
<XAxis dataKey="cp_cpt_code" />
<YAxis />
<Legend />
<Bar
dataKey="Payment"
fill="#8884d8"
shape={<TriangleBar />}
label={{ position: "top",formatter: formatDollarValue  }}
>
{ProfitChart.map((entry:any, index:number) => (
<Cell key={`cell-${index}`} fill={colors[index]} />
))}
</Bar>
</BarChart>
</div>

</>    ) : null}


{NcrData.length > 0 ? (
  <table
      id='kt_table_users'
      className=' table-bordered align-middle fs-6 p-4  font-family-base no-footer col-12 h-full '
    >
      <thead style={{background:"#a6b1e1"}}>
        <tr>
          <th className='text-center p-2'>CPT Codes</th>
          <th className='text-center p-2' >CPT Description</th>
          <th className='text-center p-2' >Amount</th>
          <th className='text-center p-2' >Over/UnderPaid</th>

        </tr>
        
      </thead>
      <tbody style={{background:"#e7eaf6",width:"auto",height:"auto"}}>
        {NcrData.map((res: any, ind: any) => {
          return (
            <tr key={ind}>
              <td className='px-3 py-2 text-center'>{res.cp_cpt_code ? res.cp_cpt_code : 'None'}</td>
              <td className='px-3 py-2 text-center'>
                {res.cpt_description ? res.cpt_description : 'None'}
              </td>
              <td className='px-3 py-2 text-center'>
                {res.Payment ? res.Payment.toLocaleString('en-US', options) : '0'}
              </td>
              <td className={`px-3 py-2 text-center text-uppercase ${res.pl_type === "Under Paid" ? "bg-danger" : "bg-success" }`}>
                {res.pl_type ? res.pl_type : '0'}
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
): (NcrData.length===0 && LossChart.length===0 && ProfitChart.length ===0 && NoData)?
    <div>
      <h5 className="text-center fw-bold">No Data Found</h5>
    </div>
    : null
}

{NcrData.length > 0 ? (

<div className='col-6 shadow rounded my-6 p-4'>
  <h5>Note:</h5>
  <p className='py-2 '>The total amount yet to receive for {DrugType.label} :<span className='text-dark fw-bold'>{SumData ? SumData.toLocaleString('en-US', options): "$0"}</span> </p>
</div>
) : null}


</div>


        
       
      </div>
    </>
  )
}
export { DrugNonDrug }