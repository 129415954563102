import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import axios from "axios";
import { toast } from 'react-toastify';
import ToastOption from './Common/ToastOption'
import { useAuth } from '../../../app/modules/auth'
import { Chart } from "react-google-charts";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Select from 'react-select';
import swal from "sweetalert"
import moment from "moment";
const getPracticeName = `/api/PracticeSummary/getPracticeName`
const getArDetails = `/api/PracticeSummary/getArDetails`


const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});
const ARDetails: React.FC = () => {
  const intl = useIntl()
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails: any = location.state;
  const { currentUser }: any = useAuth()
  const [UserNameDatas, setUserNameDatas] = useState([{ value: "", label: "" }])
  const [DateMonthFrom, setDateMonthFrom] = useState("")
const [FromDate, setFromDate] = useState("")
const [ToDate, setToDate] = useState("")
  const [DateMonthTo, setDateMonthTo] = useState("")
  const [SelectedManager, setSelectedManager] = useState({ value: "", label: "" })
  const [SelectedPracticeList, setSelectedPracticeList] = useState([])
  const [TableData, setTableData] = useState([])
  const [open, setOpen] = React.useState(false);
  const [NoData,setNoData]=useState<boolean>(false);


  const FilterDataList = async () => {
    setNoData(false);
    setOpen(true)
    var tempValues = {
      UserId :currentUser._id,
      Path:window.location.pathname,
      FromDate:FromDate,
      ToDate:ToDate,
      PracticeName : JSON.stringify(SelectedPracticeList)
    }
    axios.get(`${getArDetails}?tempValues=${JSON.stringify(tempValues)}&FromDate=${FromDate}&ToDate=${ToDate}&PracticeName=${Object.keys(SelectedPracticeList).length > 0 ? JSON.stringify(SelectedPracticeList) : ""}`).then(_res => {
      if(_res.data.message){
        setNoData(true);
        setOpen(false)

      }
      setOpen(false)
      setTableData(_res.data.Data)
    })


  }
  const getSelectedPractice = (event: any) => {
    setSelectedPracticeList(event)
  }
  const ParameterStorage = async () => {
    setOpen(true)
    const getPmListData = await axios.get(`/api/PracticeSummary/GetParameterStorage?Path=${window.location.pathname}`)
    setOpen(false)
    if (getPmListData.data != null) {
      let GetData :any= getPmListData.data
      setDateMonthFrom(GetData.FromDate)
      setDateMonthTo(GetData.ToDate)
      setFromDate(GetData.FromDate)
      setToDate(GetData.ToDate)
      setSelectedPracticeList(JSON.parse(GetData.PracticeName))
    }
  }
  const handleDateChangeFrom = (date: any) => {
    setDateMonthFrom(date)
setFromDate(moment(date['$d']).format("MMM-YYYY"))
  };
  const handleDateChangeTo = (date: any) => {
    setDateMonthTo(date)
setToDate(moment(date['$d']).format("MMM-YYYY"))
  };

  const Filter = async (ids: any) => {

    let error = false
    let ErrorArray = []
    if(!FromDate){
      error = true
      ErrorArray.push(`Please Choose From Month / Year`)
    }
    // if(!ToDate){
    //   error = true
    //   ErrorArray.push(`Please Choose To Month / Year`)
    // }
    if(SelectedPracticeList.length == 0){
      error = true
      ErrorArray.push(`Please Select Practice`)
    }
    if(error){
      ErrorArray.map((res,ind)=>{
        toast.error(`${res}`,ToastOption);
      })
      return false;
    }

    FilterDataList()
  }
  const ClearFilter = async (ids: any) => {

    setDateMonthFrom("")
    setDateMonthTo("")
    setSelectedPracticeList([])
    setTableData([])

  }
  const getPracticeNameata = async () => {
    const Result = await axios.get(getPracticeName)
    setUserNameDatas(Result.data.PracticeData)
  }
  useEffect(() => {
    getPracticeNameata()
    ParameterStorage()
  }, [])
  var UsersListData: any = []
  UserNameDatas.map((practiceList: any, index) => {
    UsersListData.push({
      label: practiceList.practice_name,
      value: practiceList.practice_name
    })
  })
  var Result :any;

  var TotalAddressedCount : any = 0;
  var TotalAddressedAmount : any = 0;
  var TotalUnAddressedCount : any = 0;
  var TotalUnAddressedAmount : any = 0;

  var GrnadTotalClaimsLines : any = 0;
  
  var FianlTotalClaimsAmount : any = 0;
  var TotalSum : any = []
  if(TableData?.length > 0){
    TableData?.map((res: any, _inde: any) => {
      var GrandTotalClaimsAmount : any = 0;
      GrandTotalClaimsAmount += Number(res.TouchedAmount) + Number(res.UnTouchedAmount);
    
      TotalSum.push(Number(GrandTotalClaimsAmount))
    })
    var FianlTotalClaimsAmount = TotalSum.reduce(function(pv:any, cv:any) { return pv + cv; }, 0);
    var FinalPercentage = Number(FianlTotalClaimsAmount / FianlTotalClaimsAmount * 100).toFixed(0)

    Result = TableData.map((res: any, _inde: any) => {
      var TotalClaimsLines : any = 0;
      var TotalClaimsAmount : any = 0;
      TotalAddressedCount += Number(res.TouchedCount)
      TotalAddressedAmount += Number(res.TouchedAmount)
      TotalUnAddressedCount += Number(res.UnTouchedCount)
      TotalUnAddressedAmount += Number(res.UnTouchedAmount)


      TotalClaimsLines += Number(res.TouchedCount) + Number(res.UnTouchedCount)

      TotalClaimsAmount += Number(res.TouchedAmount) + Number(res.UnTouchedAmount)

      GrnadTotalClaimsLines += TotalClaimsLines;
      if(_inde == TableData.length - 1){
        return (
          <>
          
          <tr className='text-start  fw-bolder fs-7 text-uppercase gs-0 text-gray-800 py-6 px-6'>
            <th className='text-center p-2'>{res.Aging}</th>
            <th className='text-center p-2'>{res.TouchedCount}</th>
            <th className='text-center p-2'>{ currencyFormatter.format(res.TouchedAmount)}</th>
            <th className='text-center p-2'>{res.UnTouchedCount}</th>
            <th className='text-center p-2'>{ currencyFormatter.format(res.UnTouchedAmount)}</th>
            <th className='text-center p-2'>{TotalClaimsLines}</th>
            <th className='text-center p-2'>{currencyFormatter.format(TotalClaimsAmount)}</th>
            <th className='text-center p-2'>{ Number(TotalClaimsAmount / FianlTotalClaimsAmount * 100).toFixed(0) }%</th>
          </tr>
          <tr className='text-start  fw-bolder fs-7 text-uppercase gs-0 text-gray-800 py-6 px-6'>
            <th className='text-center p-2' >Total</th>
            <th className='text-center p-2'>{TotalAddressedCount}</th>
            <th className='text-center p-2'>{ currencyFormatter.format(TotalAddressedAmount)}</th>
            <th className='text-center p-2'>{TotalUnAddressedCount}</th>
            <th className='text-center p-2'>{ currencyFormatter.format(TotalUnAddressedAmount)}</th>
            <th className='text-center p-2'>{GrnadTotalClaimsLines}</th>
            <th className='text-center p-2'>{currencyFormatter.format(FianlTotalClaimsAmount)}</th>
            <th className='text-center p-2'>{FinalPercentage}%</th>
          </tr>
          </>
          

        )
      }
      return (

          <tr className='text-start  fw-bolder fs-7 text-uppercase gs-0 text-gray-800 py-6 px-6'>
            <th className='text-center p-2'>{res.Aging}</th>
            <th className='text-center p-2'>{res.TouchedCount}</th>
            <th className='text-center p-2'>{ currencyFormatter.format(res.TouchedAmount)}</th>
            <th className='text-center p-2'>{res.UnTouchedCount}</th>
            <th className='text-center p-2'>{ currencyFormatter.format(res.UnTouchedAmount)}</th>
            <th className='text-center p-2'>{TotalClaimsLines}</th>
            <th className='text-center p-2'>{currencyFormatter.format(TotalClaimsAmount)}</th>
            <th className='text-center p-2'>{ Number(TotalClaimsAmount / FianlTotalClaimsAmount * 100).toFixed(0) }%</th>
          </tr>
      )
    })
  }

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <div >
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>AR Details</h3>
          </div>
        </div>
        <hr />
        <div className='row'>
          
            <>
              <div className='col-sm-3 fv-row'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>
                  <span className='required'>Month</span>
                </label><br />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={DateMonthFrom}
                    minDate={moment("2015")}
                    renderInput={(params:any) => <TextField {...params}  />}
                    maxDate={moment()}
                    views={['year', 'month']} disableFuture
                    onChange={handleDateChangeFrom}
                  />
                </LocalizationProvider>
              </div>
              {/* <div className='col-sm-3 fv-row'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>
                  <span className='required'>To</span>
                </label><br />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={DateMonthTo}
                    minDate={moment("2015")}
                    renderInput={(params:any) => <TextField {...params}  />}
                    maxDate={moment()}
                    views={['year', 'month']} disableFuture
                    onChange={handleDateChangeTo}
                  />
                </LocalizationProvider>
              </div> */}
              <div className='col-sm-3 fv-row'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>
                  <span className=''>Select Practice</span>
                </label>

                <Select maxMenuHeight={180} value={SelectedPracticeList}  onChange={getSelectedPractice} id="Practice" name={"Practice"} options={UsersListData} />

              </div></>
          <div className='col-1 pt-13'>
            <button onClick={(e) => { Filter(e) }} className='btn btn-primary'  >Filter</button>
          </div>
          <div className='col-1 pt-13'>
            <button onClick={(e) => { ClearFilter(e) }} className='btn btn-primary'  >Clear</button>
          </div>


        </div><hr />

        <div className='row'>
          <div className='col-12'>

            {Result ?
            <table
              id='kt_table_users'
              className=' w-100 table-bordered align-middle fs-6 p-4  font-family-base no-footer w-full '
            >
              <thead >
                <tr className='text-start  fw-bolder fs-7 text-uppercase gs-0 text-gray-800 py-6 px-6'>
                  <th className='text-center p-2' colSpan={8} >Addressed and Unaddressed Details By Age and Dollar</th>
                </tr>
                <tr className='text-start  fw-bolder fs-7 text-uppercase gs-0 text-gray-800 py-6 px-6'>
                  <th className='text-center p-2'  >Aging</th>
                  <th className='text-center p-2' colSpan={2} >Addressed AR</th>
                  <th className='text-center p-2' colSpan={2} >UnAddressed AR</th>
                  <th className='text-center p-2'  >Total</th>
                  <th className='text-center p-2' ></th>
                  <th className='text-center p-2'  >%</th>
                </tr>
                

                <tr className='text-start  fw-bolder fs-7 text-uppercase gs-0 text-gray-800 py-6 px-6'>
                  <th className='text-center p-2'  ></th>
                  <th className='text-center p-2'  >#</th>
                  <th className='text-center p-2'  >$</th>
                  <th className='text-center p-2'  >#</th>
                  <th className='text-center p-2'  >$</th>
                  <th className='text-center p-2'  >#</th>
                  <th className='text-center p-2'  >$</th>
                  <th className='text-center p-2' colSpan={2} ></th>
                </tr>

              </thead>
              <tbody className='text-gray-600 fw-medium p-8'>
                {Result}
              </tbody>
              
            </table>
                : NoData ?
                    <h5 className="fw-bold">No Data Found</h5>
                    : null }


          </div>

        </div>
      </div>
    </>
  )
}
export { ARDetails }