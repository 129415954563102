import React, { useState, useEffect } from 'react'
import { initialUser } from './_models'
import { useCallback } from 'react'
import * as Yup from 'yup'
import { Formik, Field, useFormik, ErrorMessage } from 'formik'
import axios from "axios";
import Select from 'react-select';
import swal from "sweetalert"
import moment from "moment";
import { useDropzone, } from 'react-dropzone';
import Backdrop from '@mui/material/Backdrop';
import { Typography } from '@mui/material';
import { toast } from 'react-toastify';
import ToastOption from '../Common/ToastOption'
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { PageLoading } from '../../../modules/wizards/common/PageLoading'
import { async } from 'q'
const getPracticeList = `/api/practice/getPracticeListById`
const getPmList = `/api/pmsystem/getPmList`
const UplodClaimsAr = `/api/Claims/UplodClaimsAr`
const ClaimOutstandingManualUpload = `/api/DenialReports/ClaimOutstandingManualUpload`
const GetARReport = `/api/DenialReports/GetARReport`
const UserDetailsSchema = Yup.object().shape({
  PmName: Yup.string().required('PM Name  is required'),
  Practice: Yup.string().required('Practice Name is required'),
  FromDate : Yup.string().required('From Date is required'),
})

function bytesForHuman(bytes: any) {
  let units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']

  let i = 0

  for (i; bytes > 1024; i++) {
    bytes /= 1024;
  }

  return bytes.toFixed(1) + ' ' + units[i]
}
function onFileUploaded(file: any) {
  console.log("file", file)
  throw new Error('Function not implemented.');
}

const ClaimUploadARData: React.FC = () => {
  const [ClaimColumns, SelectedFieldsNamesList] = useState([])
  const [childData, setChildData] = useState("");
  const [open, setOpen] = React.useState(false);
  const [SelectedPmSystem, setSelectedPmSystem] = useState({ label: "", value: "" })
  const [SelectedPractice, setSelectedPractice] = useState({ label: "", value: "" })
  const [isLoading, setLoading] = useState(false)
  const [ErrorDataMismatchTemplate, setErrorDataMismatchTemplate] = useState([]);
  const [SuccessFileNames, setSuccessFileNames] = useState([]);
  const [DataNoFound, setDataNoFound] = useState([]);
  const [PracticeListData, setPracticeListData] = useState([])
  const [getPmListData, setdatass] = useState([{ PmName: "", _id: "" }])
  const [DateMonthFrom, setDateMonthFrom] = useState("")
  const [FromDate, setFromDate] = useState("")
  const GetPmList = async () => {
    const getPmListData = await axios.get(getPmList)
    if (getPmListData.data) {
      setdatass(getPmListData.data.data)
    }

  }
  const formik = useFormik({
    initialValues: initialUser,
    validationSchema: UserDetailsSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      try {

        let error = false
        let ErrorArray = []
        if(!SelectedPmSystem.value){
          ErrorArray.push('Please Select PM System');
          error = true
        }
        if(!SelectedPractice.value){
          ErrorArray.push('Please Select Practice');
          error = true
        }
        if(!DateMonthFrom){
          ErrorArray.push('Please Select Choose From');
          error = true
        }
        if(!childData){
          ErrorArray.push('Please Select File');
          error = true
        }
        if(error){
          ErrorArray.map((res,ind)=>{
            toast.error(`${res}`,ToastOption);
          })
          return false;
        }
        var formData = new FormData();
        formData.append("PraciceId", SelectedPractice.label);
        formData.append("Month", FromDate);
        Object.entries(childData).forEach(([key, value]) => {
          formData.append("ClaimFiles", value);
        });

        setLoading(true)
        const response = await axios.put(UplodClaimsAr, formData)

        var GetResponseData = response.data
        var MismatchTemplates = GetResponseData.Errors.MismatchTemplates
        var DataNoFound = GetResponseData.Errors.DataNoFound

        var SuccessFileNames = GetResponseData.SuccessFileNames
        var ClaimColumns = GetResponseData.ClaimColumns
        formik.setFieldValue("ClaimColumns", ClaimColumns)
        SelectedFieldsNamesList(ClaimColumns)
        if (MismatchTemplates.length > 0) {
          setErrorDataMismatchTemplate(MismatchTemplates)
        } else {
          setErrorDataMismatchTemplate([])
        }
        if (SuccessFileNames.length > 0) {
          setSuccessFileNames(SuccessFileNames)
        } else {
          setSuccessFileNames([])
        }
        if (DataNoFound.length > 0) {
          setDataNoFound(DataNoFound)
        } else {
          setDataNoFound([])
        }
        setChildData("")
        setLoading(false)
      } catch (ex) {
        setLoading(false)
        console.error(ex)
      }
    },
  })
  const UpdateAR = async() => {

    let GetData :any = formik.values.ClaimColumns;
    var DataPush :any = []
    var FromData :any = formik.values
    GetData.map((ress:any,ind:number)=>{
      DataPush.push({
        ...ress,
        "TouchedCount": `${FromData[`${ress.Aging}_TouchedCount`]}`,
        "TouchedAmount": `${FromData[`${ress.Aging}_TouchedAmount`]}`,
        "UnTouchedAmount": `${FromData[`${ress.Aging}_UnTouchedAmount`]}`,
        "UnTouchedCount": `${FromData[`${ress.Aging}_UnTouchedCount`]}`
      })
    })
    var Data = {
      ClaimData : JSON.stringify(DataPush)
    }
    setOpen(true)
    const response = await axios.post(ClaimOutstandingManualUpload, Data)
    var GetResponseData = response.data.PracticeData
    if(response.data && Object.keys(GetResponseData).length > 0){
      await axios.get(`${GetARReport}?ChooseDate=${FromDate}&PracticeName=${Object.keys(SelectedPractice).length > 0 ? JSON.stringify(SelectedPractice) : ""}`);

      setOpen(false)
      SelectedFieldsNamesList([])
      swal({
        title: 'Parctice',
        icon: "info",
        text: `Updated Successfully...!`,
      }).then((_res: any) => {
  
      })
    }
  };
  useEffect(() => {
    GetPmList()

  }, [])
  const getPractice = (event: any) => {
    if (Object.keys(event).length > 0) {

      setSelectedPractice(event)
      formik.setFieldValue("Practice", event.label)
    } else {
      setSelectedPractice({ label: "", value: "" })
    }

  }

  const handleChangeMultiColumnMulti = (event: { target: { value: any, name: any } }) => {
    var name: any
    name = event.target.name

    formik.setFieldValue(name, event.target.value)
  }
  const getPMSystem = (event: any) => {
    //setSelectedPractice([])
    setSelectedPmSystem(event)
    if (!event.value) {
      formik.setFieldError("PmName", "PM System is required")
    } else {
      formik.setFieldError("PmName", "")
    }

    if (event.value) {
      formik.setFieldValue("PmName", event.value)
      axios.post(getPracticeList, { PostData: event.value }).then((res) => {
        setPracticeListData(res.data.data)
      })
    }
  };
  var PmListData = [{ label: "Select", value: "" }]
  getPmListData.map((name) => (
    PmListData.push({
      label: name.PmName,
      value: name._id
    })
  ))

  const handleDateChangeFrom = (date: any) => {
    setDateMonthFrom(date)
    setFromDate(moment(date['$d']).format("MMM-YYYY"))
    formik.setFieldValue("FromDate",moment(date['$d']).format("MMM-YYYY"))
  };
  const handleChangeMultiColumn = (e: any) => {
    SelectedFieldsNamesList(e)
    formik.setFieldValue("ClaimColumns", e)
  }
  const onDrop = useCallback((acceptedFiles: any[]) => {

    var filestotal: any = acceptedFiles.map((file: any) => Object.assign(file, {
      preview: URL.createObjectURL(file)
    }))
    setChildData(filestotal)

  }, [onFileUploaded]);

  const {
    acceptedFiles,
    getRootProps,
    getInputProps
  } = useDropzone({

    onDrop,
    accept: {
      'application/vnd.ms-excel': ['.xlsx']
    },
  });
  const acceptedFileItems: any = acceptedFiles.map((file: any) => {

    return (<li key={file.path}>
      {file.path} - {bytesForHuman(file.size)}
    </li>)
  });
  return (
    <>
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
        <Typography variant="h6" component="div" color="inherit" mt={2} ml={4}>
          Loading...
        </Typography>
      </Backdrop>

      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Upload Open claims</h3>
        </div>
      </div>
      <hr />
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <Formik initialValues={{ myField: '', }} onSubmit={values => { console.log(values); }}>
            <div className='row'>
              <div className='col-4 fv-row mb-7'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>PM System</span>
                </label>
                <Select maxMenuHeight={180} value={SelectedPmSystem} onChange={getPMSystem} id="PmId" name={"PmId"} options={PmListData} />

                {formik.touched.PmName && formik.errors.PmName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.PmName}</div>
                </div>
              )}
              </div>

              <div className='col-sm-4 fv-row'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>
                  <span className='required'>Practice Name</span>
                </label>

                <Select maxMenuHeight={180} value={SelectedPractice} onChange={getPractice} id="Practice" name={"Practice"} options={PracticeListData} />

                {formik.touched.Practice && formik.errors.Practice && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.Practice}</div>
                </div>
              )}
              </div>


              <div className='col-sm-4 fv-row'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>
                  <span className='required'>From</span>
                </label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker

                    value={DateMonthFrom}
                    minDate={moment("2015")}
                    renderInput={(params: any) => <TextField  {...params} />}
                    maxDate={moment().subtract(1, 'months')}
                    views={['year', 'month']} disableFuture
                    onChange={handleDateChangeFrom}
                  />
                </LocalizationProvider>
                {formik.touched.FromDate && formik.errors.FromDate && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.FromDate}</div>
                </div>
              )}
              </div>



              <div className='w-100 mt-15'>

                <div className='fv-row mb-10'>
                  <label className='form-label required'>Select Files</label>


                  <div {...getRootProps({ className: 'dropzone' })}>

                    <Field name="ClaimFiles" id="ClaimFiles" type="file" multiple

                      {...getInputProps()} />

                    <p>Drag 'n' drop some files here, or click to select files</p>

                  </div>
                  <br />
                  <aside>
                    <ul>{acceptedFileItems}</ul>
                  </aside>

                  <div className='text-danger mt-2'>
                    <ErrorMessage name='ClaimFiles' />
                  </div>

                  {
                    SuccessFileNames.length > 0 ?
                      <>
                        <h4>Success Report : </h4>
                        {SuccessFileNames.map((res: any) => {
                          return (<li dangerouslySetInnerHTML={{ __html: res }}></li>)
                        })}
                        <br />
                      </>
                      : null
                  }

                  {
                    DataNoFound.length > 0 ?
                      <>
                        <h4>No Data Found Report : </h4>
                        {DataNoFound.map((res: any) => {
                          return (<li dangerouslySetInnerHTML={{ __html: res }}></li>)
                        })}
                        <br />
                      </>
                      : null
                  }

                  {
                    ErrorDataMismatchTemplate.length > 0 ?
                      <>
                        <h4>Template Mismtach Report : </h4>
                        {ErrorDataMismatchTemplate.map((res: any) => {
                          return (<li dangerouslySetInnerHTML={{ __html: res }}></li>)
                        })}
                      </>
                      : null
                  }
                </div>
                {Object.keys(ClaimColumns).length > 0 ?
                  <>
                    <table className="align-middle gs-0 gy-4 table table-row-dashed table-row-gray-300">
                      <thead>
                        <tr key="0" className='bg-success'>
                          <th colSpan={3} className='text-center'>Uploaded Claims</th>
                          <th colSpan={2} className='text-center'>Addressed</th>
                          <th colSpan={2} className='text-center'>Un Addressed</th>
                        </tr>
                        <tr key="1" className='fw-bold text-muted bg-light'>
                          <th className='text-center'>Aging</th>
                          <th className='text-center'>Counts</th>
                          <th className='text-center'>$</th>
                          <th className='text-center'>Counts</th>
                          <th className='text-center'>$</th>
                          <th className='text-center'>Counts</th>
                          <th className='text-center'>$</th>

                        </tr>
                      </thead>
                      <tbody>
                        {
                          ClaimColumns.map((stationIndex: any, index: any) => {

                            return <tr key={index} className="form__table-row">

                              <td className='text-center'>{stationIndex.Aging}</td>
                              <td className='text-center'>{stationIndex.NoClaims}</td>
                              <td className='text-center'>{stationIndex.ClaimBalance}</td>
                              <td className='text-center'><input
                                style={{ width: "124px" }}
                                required
                                placeholder={stationIndex.TouchedCount}
                                onChange={handleChangeMultiColumnMulti}
                                type='text'
                                name={stationIndex.Aging + "_TouchedCount"}
                                autoComplete='off'
                                className='form-control'
                              /></td>
                              <td className='text-center'><input
                                style={{ width: "124px" }}
                                required
                                placeholder={stationIndex.TouchedAmount}
                                onChange={handleChangeMultiColumnMulti}
                                type='text'
                                name={stationIndex.Aging + "_TouchedAmount"}
                                autoComplete='off'
                                className='form-control'
                              /></td>
                              <td className='text-center'><input
                                style={{ width: "124px" }}
                                required
                                placeholder={stationIndex.UnTouchedCount}
                                onChange={handleChangeMultiColumnMulti}
                                type='text'
                                name={stationIndex.Aging + "_UnTouchedCount"}
                                autoComplete='off'
                                className='form-control'
                              /></td>
                              <td className='text-center'><input
                                style={{ width: "124px" }}
                                required
                                placeholder={stationIndex.UnTouchedAmount}
                                onChange={handleChangeMultiColumnMulti}
                                type='text'
                                name={stationIndex.Aging + "_UnTouchedAmount"}
                                autoComplete='off'
                                className='form-control'
                              /></td>

                            </tr>
                          })
                        }
                      </tbody>
                    </table>
                    
                  </>
                  : null
                }

              </div>



            </div>
          </Formik>
        </div>

        <div className='text-center pt-15'>

        {Object.keys(ClaimColumns).length > 0 ?
          <button onClick={() => UpdateAR()} type='button' className='btn btn-primary'>
            <span className='indicator-label'>Submit</span>
          </button>
        :
          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
}
        </div>
        <div>
        </div>
      </form>
      {isLoading && <PageLoading />}
    </>
  )
}
export { ClaimUploadARData }