import React, { useState, useEffect ,FunctionComponent} from 'react'
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { PageTitle } from '../../../../_metronic/layout/core'
import axios from "axios";
import { toast } from 'react-toastify';
import ToastOption from '../Common/ToastOption'
import { useAuth } from '../../../modules/auth'
import { Chart } from "react-google-charts";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Select from 'react-select';
import swal from "sweetalert"
import moment from "moment";
import CardGuarantor from '../components/CardGuarantor';
import "./Card/Card.css"




const getPracticeName = `/api/PracticeSummary/getPracticeName`
const getGuarantorAging=`/api/ChargesWise/getGuarantorAging`

interface PracticeListType{
    label:string,
    value:string
}
const formatDollarValue = (value: { toLocaleString: () => any; }) => `$${value.toLocaleString()}`;
const options = { style: 'currency', currency: 'USD',maximumFractionDigits: 0 };


const colors = [
    "#1f77b4", // blue
    "#ff7f0e", // orange
    "#2ca02c", // green
    "#d62728", // red
    "#9467bd", // purple
    "#8c564b", // brown
    "#e377c2", // pink
    "#7f7f7f", // gray
    "#bcbd22", // lime
    "#17becf"  // cyan
  ];

const GuarantorAging: React.FC = () => {
  const intl = useIntl()
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails: any = location.state;
  const { currentUser }: any = useAuth()
  const [UserNameDatas, setUserNameDatas] = useState([{ value: "", label: "" }])
  const [DateMonthFrom, setDateMonthFrom] = useState("")
const [FromDate, setFromDate] = useState("")
const [ToDate, setToDate] = useState("")
  const [DateMonthTo, setDateMonthTo] = useState("")
  const [SelectedPracticeList, setSelectedPracticeList] = useState<PracticeListType[]>([])
  const [NcrData, setNcrData] = useState([])
  const [open, setOpen] = React.useState(false);
  const [NoData,setNoData]=useState<boolean>(false);

  const FilterDataList = async () => {
    setNcrData([])
    setOpen(true)
    var tempValues = {
      UserId :currentUser._id,
      Path:window.location.pathname,
      FromDate:FromDate,
      ToDate:ToDate,
      PracticeName : JSON.stringify(SelectedPracticeList)
    }
    axios.get(`${getGuarantorAging}?tempValues=${JSON.stringify(tempValues)}&FromDate=${FromDate}&ToDate=${ToDate}&PracticeName=${JSON.stringify(SelectedPracticeList)}`).then(_res => {
      if(_res.data.message){
        setNoData(true)
        setOpen(false)

      }
     else{
        setNcrData(_res.data.data)
        setOpen(false)
      }

      
    })
  }


  const ParameterStorage = async () => {
    setOpen(true)
    const getPmListData = await axios.get(`/api/PracticeSummary/GetParameterStorage?Path=${window.location.pathname}`)
    setOpen(false)
    if (getPmListData.data != null) {
      let GetData :any= getPmListData.data
      setDateMonthFrom(GetData.FromDate)
      setDateMonthTo(GetData.ToDate)
      setFromDate(GetData.FromDate)
      setToDate(GetData.ToDate)
      setSelectedPracticeList(JSON.parse(GetData.PracticeName))
    }
  }

  const getSelectedPractice = (event: any) => {
       setSelectedPracticeList(event)
  }
  
  const handleDateChangeFrom = (date: any) => {
    setDateMonthFrom(date)
setFromDate(moment(date['$d']).format("MMM-YYYY"))
  };
  const handleDateChangeTo = (date: any) => {
    setDateMonthTo(date)
setToDate(moment(date['$d']).format("MMM-YYYY"))
  };

  const Filter = async (ids: any) => {

    let error = false
    let ErrorArray = []
    if (!DateMonthFrom) {
      error = true
      ErrorArray.push(`Please Choose From Month / Year`)
    }
    if (!DateMonthTo) {
      error = true
      ErrorArray.push(`Please Choose To Month / Year`)
    }
    if(Object.keys(SelectedPracticeList).length == 0){
      error = true
      ErrorArray.push(`Please Select Practice`)
    }
    if(error){
      ErrorArray.map((res,ind)=>{
        toast.error(`${res}`,ToastOption);
      })
      return false;
    }
    FilterDataList()
  }
  const ClearFilter = async (ids: any) => {

    setDateMonthFrom("")
    setDateMonthTo("")
    setSelectedPracticeList([])

  }
  const getPracticeNameata = async () => {
    const Result = await axios.get(getPracticeName)
    setUserNameDatas(Result.data.PracticeData)
  }
  useEffect(() => {
    getPracticeNameata()
    ParameterStorage()
  }, [])
  var UsersListData: any = []
  UserNameDatas.map((practiceList: any, index) => {
    UsersListData.push({
      label: practiceList.practice_name,
      value: practiceList.practice_name
    })
  })


  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle> */}
      <div >
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Guarantor Aging</h3>
          </div>
        </div>
        <hr />
        <div className='row'>
          
            <>
              <div className='col-sm-3 fv-row'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>
                  <span className='required'>From</span>
                </label><br />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                  value={DateMonthFrom}
                  minDate={moment("2015")}
                  renderInput={(params:any) => <TextField {...params}  />}
                  maxDate={moment()}
                  views={['year', 'month']} disableFuture
                    onChange={handleDateChangeFrom}
                  />
                </LocalizationProvider>
              </div>
              <div className='col-sm-3 fv-row'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>
                  <span className='required'>To</span>
                </label><br />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                  value={DateMonthTo}
                  minDate={moment("2015")}
                  renderInput={(params:any) => <TextField {...params}  />}
                  maxDate={moment()}
                  views={['year', 'month']} disableFuture
                    onChange={handleDateChangeTo}
                  />
                </LocalizationProvider>
              </div>
              <div className='col-sm-3 fv-row'>
                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                  <span className='required'>Select Practice</span>
                </label>

                <Select maxMenuHeight={180} value={SelectedPracticeList}  onChange={getSelectedPractice} id="Practice" name={"Practice"} options={UsersListData} />

              </div></>
          <div className='col-1 pt-13'>
            <button onClick={(e) => { Filter(e) }} className='btn btn-primary'  >Filter</button>
          </div>
          <div className='col-1 pt-13'>
            <button onClick={(e) => { ClearFilter(e) }} className='btn btn-primary'  >Clear</button>
          </div>
       
        </div><hr />

        <div className='parentContainerCharges'>
            {NcrData?.map((card:any,ind:number)=>(
                 <CardGuarantor 
                 data={card}
                 key={ind}
                 color={colors[ind]}
                 />
            ))}
           
        </div>

         <div className='mt-12'>
         {NcrData.length > 0 ? (
            <table
                id='kt_table_users'
                className=' table-bordered align-middle fs-6 p-4  font-family-base no-footer col-12 h-full '
              >
                <thead style={{background:"#a6b1e1"}}>
                  <tr>
                    <th className='text-center p-2'>Month/Year</th>
                    <th className='text-center p-2' >0-30</th>
                    <th className='text-center p-2' >30-60</th>
                    <th className='text-center p-2' >60-90</th>
                    <th className='text-center p-2' >90-120</th>
                    <th className='text-center p-2' >Above 120</th>
                    <th className='text-center p-2' >Total</th>

                  </tr>
                  
                </thead>
                <tbody style={{background:"#e7eaf6"}}>
                  {NcrData?.map((res: any, ind: any) => {
                    return (
                      <tr key={ind}>
                        <td className='px-3 py-2 text-center'>{res.month_year ? res.month_year : 'None'}</td>
                        <td className='px-3 py-2 text-center'>
                          {res["0_30"] ? res["0_30"].toLocaleString('en-US', options) : '$0'}
                        </td>
                        <td className='px-3 py-2 text-center'>
                          {res["30_60"] ? res["30_60"].toLocaleString('en-US', options) : '$0'}
                        </td>
                        <td className='px-3 py-2 text-center'>
                          {res["60_90"] ? res["60_90"].toLocaleString('en-US', options) : '$0'}
                        </td>
                        <td className='px-3 py-2 text-center'>
                          {res["90_120"] ? res["90_120"].toLocaleString('en-US', options) : '$0'}
                        </td>
                        <td className='px-3 py-2 text-center'>
                          {res["120Above"] ? res["120Above"].toLocaleString('en-US', options) : '$0'}
                        </td>

                        <td className='px-3 py-2 text-center'>
                          {(
                            (res["0_30"] ? res["0_30"] : 0)
                            +( res["30_60"] ? res["30_60"] : 0)
                            +(res["60_90"] ? res["60_90"] : 0)
                            +(res["90_120"] ? res["90_120"] : 0)
                            +(res["120Above"] ? res["120Above"] : 0))
                            .toLocaleString('en-US', options)}
                        </td>

                      </tr>
                    )
                  })}
                </tbody>
              </table>
          ):
             (NcrData.length===0  && NoData)?
                 <div>
                   <h5 className="text-center fw-bold">No Data Found</h5>
                 </div>
                 : null}
         </div>


        
       
      </div>
    </>
  )
}
export { GuarantorAging }