import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { PageTitle } from '../../../../_metronic/layout/core'
import axios from "axios";
import { useAuth } from '../../../modules/auth'
import { Chart } from "react-google-charts";
import { toast } from 'react-toastify';
import ToastOption from '../Common/ToastOption'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Select from 'react-select';
import swal from "sweetalert"
import moment from "moment";
import { PieChart, Pie, Sector, Cell, Label } from "recharts";


const getPracticeName = `/api/PracticeSummary/getPracticeName`
const getPayerMixReport=`/api/ChargesWise/getPayerMixReport`

interface PracticeListType{
    label:string,
    value:string
}
const formatDollarValue = (value: { toLocaleString: () => any; }) => `$${value.toLocaleString()}`;
const options : any = { style: 'currency', currency: 'USD' };

const PayerMixReport: React.FC = () => {
  const intl = useIntl()
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails: any = location.state;
  const { currentUser }: any = useAuth()
  const [UserNameDatas, setUserNameDatas] = useState([{ value: "", label: "" }])
  const [DateMonthFrom, setDateMonthFrom] = useState("")
const [FromDate, setFromDate] = useState("")
const [ToDate, setToDate] = useState("")
  const [DateMonthTo, setDateMonthTo] = useState("")
  const [SelectedPracticeList, setSelectedPracticeList] = useState<PracticeListType[]>([])

  const [NcrData, setNcrData] = useState([])
    const [open, setOpen] = React.useState(false);

  const [NoData,setNoData]=useState<boolean>(false);
    const [activeIndex, setActiveIndex] = useState<number>(0);
const [TotalCharges,setTotalCharges]=useState<number>(0)
  const [ChargesPer,setChargesPer]=useState<number>(0)
  const [TotalPayments,setTotalPayments]=useState<number>(0)
  const [PaymentsPer,setPaymentsPer]=useState<number>(0)
  const renderActiveShapeCharges = (props: any) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      tooltipPayload,
      value,
    } = props;
  
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";
  
    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.Payer}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >{`${tooltipPayload[0].dataKey} ${value.toLocaleString("en-US",options)}`}</text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
        >
          {`(Rate ${payload.ChargesPer}%)`}
        </text>
      </g>
    );
  };

  const renderActiveShapePayments = (props: any) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      tooltipPayload,
      value,
    } = props;
  
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";
  
    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.Payer}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
  x={ex + (cos >= 0 ? 1 : -1) * 12}
  y={ey}
  textAnchor={textAnchor}
  fill="#333"
>
  {`${tooltipPayload[0].dataKey} ${value ? value.toLocaleString("en-US", options) : "0"}`}
</text>

        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
        >
          {`(Rate ${payload.Percentage}%)`}
        </text>
      </g>
    );
  };


  
  const onPieEnter = useCallback(
    (_:any, index:any) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );
  const ParameterStorage = async () => {
    setOpen(true)
    const getPmListData = await axios.get(`/api/PracticeSummary/GetParameterStorage?Path=${window.location.pathname}`)
    setOpen(false)
    if (getPmListData.data != null) {
      let GetData :any= getPmListData.data
      setDateMonthFrom(GetData.FromDate)
      setDateMonthTo(GetData.ToDate)
      setFromDate(GetData.FromDate)
      setToDate(GetData.ToDate)
      setSelectedPracticeList(JSON.parse(GetData.PracticeName))
    }
  }

  const FilterDataList = async () => {
    setTotalCharges(0)
    setChargesPer(0)
    setTotalPayments(0);
    setPaymentsPer(0);
    setOpen(true)
    setNcrData([])
    var tempValues = {
      UserId :currentUser._id,
      Path:window.location.pathname,
      FromDate:FromDate,
      ToDate:ToDate,
      PracticeName : JSON.stringify(SelectedPracticeList)
    }
    axios.get(`${getPayerMixReport}?tempValues=${JSON.stringify(tempValues)}&FromDate=${FromDate}&ToDate=${ToDate}&PracticeName=${JSON.stringify(SelectedPracticeList)}`).then(_res => {

      if(_res.data.message){
        setNoData(true)
        setOpen(false)

      }
      else {
        let totalCharges:number=0;
        let chargesPer:number=0;
        let totalPayments:number=0;
        let paymentsPer:number=0;
        setNcrData(_res.data.data)
        setOpen(false)
        _res.data.data.map((res:any,ind:number)=>{
          totalCharges+=Number(res.Charges);
          chargesPer+=Number(res.ChargesPer)
          totalPayments+=Number(res.Payments);
          paymentsPer+=Number(res.Percentage);
        })
        setTotalCharges(totalCharges)
        setChargesPer(chargesPer)
        setTotalPayments(totalPayments);
        setPaymentsPer(paymentsPer);

      }
    })

  }
  const getSelectedPractice = (event: any) => {
    setNcrData([])
    setSelectedPracticeList(event)
  }
  
  const handleDateChangeFrom = (date: any) => {
    setDateMonthFrom(date)
setFromDate(moment(date['$d']).format("MMM-YYYY"))
  };
  const handleDateChangeTo = (date: any) => {
    setDateMonthTo(date)
setToDate(moment(date['$d']).format("MMM-YYYY"))
  };

  const Filter = async (ids: any) => {

    let error = false
    let ErrorArray = []
    if (!DateMonthFrom) {
      error = true
      ErrorArray.push(`Please Choose From Month / Year`)
    }
    if (!DateMonthTo) {
      error = true
      ErrorArray.push(`Please Choose To Month / Year`)
    }
    if(Object.keys(SelectedPracticeList).length == 0){
      error = true
      ErrorArray.push(`Please Select Practice`)
    }
    if(error){
      ErrorArray.map((res,ind)=>{
        toast.error(`${res}`,ToastOption);
      })
      return false;
    }
    
    FilterDataList()
  }
  const ClearFilter = async (ids: any) => {

    setDateMonthFrom("")
    setFromDate("")
    setToDate("")
    setDateMonthTo("")
    setSelectedPracticeList([])

  }
  const getPracticeNameata = async () => {
    const Result = await axios.get(getPracticeName)
    setUserNameDatas(Result.data.PracticeData)
  }
  useEffect(() => {
    getPracticeNameata()
    ParameterStorage()
  }, [])
  var UsersListData: any = []
  UserNameDatas.map((practiceList: any, index) => {
    UsersListData.push({
      label: practiceList.practice_name,
      value: practiceList.practice_name
    })
  })
 



  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <div >
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>PayerMix-Report</h3>
          </div>
        </div>
        <hr />
        <div className='row'>
          
            <>
              <div className='col-sm-3 fv-row'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>
                  <span className='required'>From</span>
                </label><br />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                  value={DateMonthFrom}
                  minDate={moment("2015")}
                  renderInput={(params:any) => <TextField {...params}  />}
                  maxDate={moment()}
                  views={['year', 'month']} disableFuture
                    onChange={handleDateChangeFrom}
                  />
                </LocalizationProvider>
              </div>
              <div className='col-sm-3 fv-row'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>
                  <span className='required'>To</span>
                </label><br />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                  value={DateMonthTo}
                  minDate={moment("2015")}
                  renderInput={(params:any) => <TextField {...params}  />}
                  maxDate={moment()}
                  views={['year', 'month']} disableFuture
                    onChange={handleDateChangeTo}
                  />
                </LocalizationProvider>
              </div>
              <div className='col-sm-3 fv-row'>
                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                  <span className='required'>Select Practice</span>
                </label>

                <Select maxMenuHeight={180} value={SelectedPracticeList}  onChange={getSelectedPractice} id="Practice" name={"Practice"} options={UsersListData} />

              </div></>
          <div className='col-1 pt-13'>
            <button onClick={(e) => { Filter(e) }} className='btn btn-primary'  >Filter</button>
          </div>
          <div className='col-1 pt-13'>
            <button onClick={(e) => { ClearFilter(e) }} className='btn btn-primary'  >Clear</button>
          </div>
       
        </div><hr />

<div className='d-flex flex-column align-items-center'>
{NcrData.length > 0 ? 
          <div className='col-10  d-flex justify-content-center shadow rounded my-8 position-relative' style={{background:"#dbd8e3"}}>
         <h4 className='position-absolute'>Charges of Payer</h4>
          <PieChart width={700} height={400}>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShapeCharges}
            data={NcrData}
            cx={350}
            cy={200}
            innerRadius={80}
            outerRadius={120}
            fill="#FF6969"
            dataKey="Charges"
            onMouseEnter={onPieEnter}
          />
        </PieChart>
            
          </div>
              : null}

          {NcrData.length > 0 ? 
          <div className='col-10 d-flex justify-content-center my-8 shadow rounded position-relative' style={{background:"#dbd8e3"}}>
           <h4 className='position-absolute'>Payments of Payer</h4>
          <PieChart width={700} height={400}>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShapePayments}
            data={NcrData}
            cx={350}
            cy={200}
            innerRadius={80}
            outerRadius={120}
            fill="#088395"
            dataKey="Payments"
            onMouseEnter={onPieEnter}
          />
        </PieChart>
    
           
          </div>
           : null}
</div>
{NcrData.length > 0 ? (

<table
                id='kt_table_users'
                className=' table-bordered align-middle fs-6 p-4  font-family-base no-footer col-12 h-full '
              >
                <thead style={{background:"#a6b1e1"}}>
                  <tr>
                    <th className='text-center p-2'>Payer</th>
                    <th className='text-center p-2' colSpan={2}>Charges</th>
                    <th className='text-center p-2' colSpan={2}>Payments</th>
                  </tr>

                  <tr>
                    <th className='text-center p-2'></th>
                    <th className='text-center p-2'>$</th>
                    <th className='text-center p-2'>%</th>
                    <th className='text-center p-2'>$</th>
                    <th className='text-center p-2'>%</th>
                  </tr>
                  
                </thead>
                <tbody style={{background:"#e7eaf6"}}>
                  {NcrData.map((res: any, ind: any) => {
                    return (
                      <tr key={ind}>
                        <td className='px-3 py-2 text-center'>{res.Payer ? res.Payer : 'None'}</td>
                        <td className='px-3 py-2 text-center'>
                          {res.Charges ? Number(res.Charges).toLocaleString('en-US', options) : '$0'}
                        </td>
                        <td className='px-3 py-2 text-center'>
                          {res.ChargesPer ? res.ChargesPer : '0'}%
                        </td>
                        <td className='px-3 py-2 text-center'>
                          {res.Payments ? Number(res.Payments).toLocaleString('en-US', options) : '$0'}
                        </td>
                        <td className='px-3 py-2 text-center'>
                          {res.Percentage ? res.Percentage : '0'}%
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
  <thead style={{background:"#a6b1e1"}}>
  <tr className='px-3 py-4 text-center'>
    <td>Total</td>
    <td>{TotalCharges ? TotalCharges.toLocaleString("en-US",options) : "$0"}</td>
    <td>{ChargesPer ? Math.round(ChargesPer):"0"}%</td>
    <td>{TotalPayments ? TotalPayments.toLocaleString("en-US",options) : "$0"}</td>
    <td>{PaymentsPer ? Math.round(PaymentsPer) : "0"}%</td>
  </tr>
  </thead>
              </table>


) :
    (NcrData.length===0  && NoData)?
        <div>
          <h5 className="text-center fw-bold">No Data Found</h5>
        </div>
        : null}
<div>

</div>


        
       
      </div>
    </>
  )
}
export { PayerMixReport }