import React, {useState} from 'react'
import './Card.css'
import {KTSVG} from '../../../../../_metronic/helpers'
import {motion, AnimateSharedLayout} from 'framer-motion'
// import { UilTimes } from "@iconscout/react-unicons";

import {
  AreaChart,
  Area,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
} from 'recharts'


const options = { style: 'currency', currency: 'USD' };
const formatDollarValue = (value: {toLocaleString: () => any}) => `$${value.toLocaleString()}`

const Card = (props: any) => {
  const [expanded, setExpanded] = useState<boolean>(false)


  return (
    <AnimateSharedLayout>
      {expanded ? (
        <ExpandedCard param={props} setExpanded={() => setExpanded(false)} />
      ) : (
        <CompactCard param={props} setExpanded={() => setExpanded(true)} />
      )}
    </AnimateSharedLayout>
  )
}

// Compact Card
function CompactCard({param, setExpanded}: any) {

  const array: any = param?.data[0]
  let Total_Paid = 0
  let Billed_Amount = 0

  array?.map((item: any) => {
    Total_Paid += item.total_paid ? Number(item.total_paid) : 0
    Billed_Amount += item.billed_amount ? Number(item.billed_amount) : 0
  })
  return (
    <>
      {param.data[0]?.length > 0 ? (
        <motion.div
          className='CompactCard'
          style={{
            boxShadow: param.color.boxShadow,
            background: param.color.backGround,
            // color:param.meta ==="yes" ? "black" : "white"
          }}
          layoutId='expandableCard'
          onClick={setExpanded}
        >

          <div className='detail'>
            <div className='title'>
              <span className='fw-bolder'>{array[0].facility}</span>
            </div>
            <div className='small-data'>

            <div className='paid_value'>
                <span>Provider </span>
                <span className='mx-2 fw-bolder'>
                {array[0].provider}
                </span>
              </div>

              <div className='paid_value'>
                <span>Total Paid </span>
                <span className='mx-2 fw-bolder'>
                  {Total_Paid ? Number(Total_Paid).toLocaleString('en-US',options) : 0}
                </span>
              </div>
              <div className='paid_value'>
                <span>Billed Amount </span>
                <span className='mx-2 fw-bolder'>
                  {Billed_Amount ? Number(Billed_Amount).toLocaleString('en-US',options) : 0}
                </span>
              </div>
              {/* <div className='paid_value'>
                <span>Count</span>
                <span className='mx-2 fw-bolder'>{Count ? Count : 0}</span>
              </div> */}
            </div>
          </div>
        </motion.div>
      ) : null}
    </>
  )
}

// Expanded Card
function ExpandedCard({param, setExpanded}: any) {
  return (
    <motion.div
      className='ExpandedCard'
      style={{
        boxShadow: param.color.boxShadow,
        background: param.color.backGround,
      }}
      layoutId='expandableCard'
    >
      <div style={{alignSelf: 'flex-end', cursor: 'pointer', color: 'black', marginTop: '30px'}}>
        <div
          onClick={setExpanded}
          className='btn btn-icon btn-sm btn-active-icon-primary'
          data-kt-users-modal-action='close'
          style={{cursor: 'pointer'}}
        >
          <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1 text-white' />
        </div>
      </div>
      <div className='d-flex'>
      <p className='fw-500 text-dark'>{param.data[0][0].facility}&emsp;</p>
      <span className='fw-500 text-dark'>{param.data[0][0].provider}</span>
      </div>
      <div className='chartContainer'>
        <BarChart
          width={800}
          height={400}
          data={param.data[0]}
          margin={{
            top: 5,
            right: 20,
            left: 10,
            bottom: 15,
          }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='cpt_class1' 
           angle={-15}
           textAnchor="end"
           interval={0}
           height={60}
           tick={{ fontSize: 10 }}
          />
          <YAxis tickFormatter={formatDollarValue} />
          <Tooltip formatter={formatDollarValue} />
          <Legend />

          {/* <Bar dataKey='count' fill='#9467bd' name='Count' barSize={param.data[0].length <=2 ? 35 : 25} /> */}
          <Bar dataKey='billed_amount' fill='#1f77b4' name='Billed Amount' barSize={param.data[0].length <=2 ? 35 : 25}/>
          <Bar dataKey='total_paid' fill='#2ca02c' name='Total Paid' barSize={param.data[0].length <=2 ? 35 : 25} />
        </BarChart>
      </div>
    </motion.div>
  )
}

export default Card
