import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { PageTitle } from '../../../../_metronic/layout/core'
import axios from "axios";
import { toast } from 'react-toastify';
import ToastOption from '../Common/ToastOption'
import { useAuth } from '../../../modules/auth'
import { Chart } from "react-google-charts";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Select from 'react-select';
import swal from "sweetalert"
import moment from "moment";
import {
    AreaChart,
  Area,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ReferenceLine
  } from "recharts";
import "./Card/Card.css";

import Card from './Card/Card';



const getPracticeName = `/api/PracticeSummary/getPracticeName`
const getProviderWiseByLoc=`/api/ChargesWise/getProviderWiseByLoc`
const getProviderWise=`/api/ChargesWise/getProviderWise`

interface PracticeListType{
    label:string,
    value:string
}

interface ProviderListType{
  label:string,
  value:string
}


const options = { style: 'currency', currency: 'USD' };

const ProviderProductivity: React.FC = () => {
  const intl = useIntl()
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails: any = location.state;
  const { currentUser }: any = useAuth()
  const [UserNameDatas, setUserNameDatas] = useState([{ value: "", label: "" }])
  const [DateMonthFrom, setDateMonthFrom] = useState("")
const [FromDate, setFromDate] = useState("")
const [ToDate, setToDate] = useState("")
  const [DateMonthTo, setDateMonthTo] = useState("")
  const [SelectedPracticeList, setSelectedPracticeList] = useState<PracticeListType[]>([])
  const [PieChartData, setPieChartData] = useState([])
  const [ProviderList,setProviderList]=useState<ProviderListType[]>([])
  const [SelectedProviderList, setSelectedProviderList] = useState<ProviderListType[]>([])
  const [NcrData, setNcrData] = useState([])
    const [open, setOpen] = React.useState(false)
    const [FinalArray,setFinalArray]=useState<any>([])


  const FilterDataList = async () => {
    setNcrData([])
    setFinalArray([])
    setOpen(true)
    var tempValues = {
      UserId :currentUser._id,
      Path:window.location.pathname,
      FromDate:FromDate,
      ToDate:ToDate,
      PracticeName : JSON.stringify(SelectedPracticeList),
      Provider : JSON.stringify(SelectedProviderList)
    }
    axios.get(`${getProviderWiseByLoc}?tempValues=${JSON.stringify(tempValues)}&FromDate=${FromDate}&ToDate=${ToDate}&PracticeName=${JSON.stringify(SelectedPracticeList)}&ProviderName=${JSON.stringify(SelectedProviderList)}`).then(_res => {
      setNcrData(_res.data.data)
     let array:any=[];
      const Data=_res.data.data
      Data.map((res:any,ind:any)=>{
        Object.values(res).map((ress,inds)=>{
          array.push(ress)
         
        })
        
        })

        setFinalArray(array)
      setOpen(false)
      
    })
  }


  const getSelectedPractice = (event: any) => {
       setSelectedPracticeList(event)
    setNcrData([])
    setFinalArray([])
       axios.get(`${getProviderWise}?PracticeName=${JSON.stringify(event)}`).then(_res => {

         setProviderList(_res.data.data)
         setNcrData([])
       })
  }
  
const getSelectedProvider=(e:any)=>{
  setSelectedProviderList(e)
  if(Object.keys(e).length ===0)
  {
    setNcrData([])
    setFinalArray([])
  }

}

  const handleDateChangeFrom = (date: any) => {
    setDateMonthFrom(date)
setFromDate(moment(date['$d']).format("MMM-YYYY"))
  };
  const handleDateChangeTo = (date: any) => {
    setDateMonthTo(date)
setToDate(moment(date['$d']).format("MMM-YYYY"))
  };

  const ParameterStorage = async () => {
    setOpen(true)
    const getPmListData = await axios.get(`/api/PracticeSummary/GetParameterStorage?Path=${window.location.pathname}`)
    setOpen(false)
    if (getPmListData.data != null) {
      let GetData :any= getPmListData.data
      setDateMonthFrom(GetData.FromDate)
      setDateMonthTo(GetData.ToDate)
      setFromDate(GetData.FromDate)
      setToDate(GetData.ToDate)
      setSelectedPracticeList(JSON.parse(GetData.PracticeName))
      setSelectedProviderList(JSON.parse(GetData.Provider))
    }
  }
  const Filter = async (ids: any) => {


    let error = false
    let ErrorArray = []
    if (!DateMonthFrom) {
      error = true
      ErrorArray.push(`Please Choose From Month / Year`)
    }
    if(Object.keys(SelectedPracticeList).length == 0){
      error = true
      ErrorArray.push(`Please Select Practice`)
    }
    if (SelectedProviderList.length ===0 ) {
      error = true
      ErrorArray.push(`Please choose any provider`)
    }
    if(error){
      ErrorArray.map((res,ind)=>{
        toast.error(`${res}`,ToastOption);
      })
      return false;
    }
    
    FilterDataList()
  }
  const ClearFilter = async (ids: any) => {

    setDateMonthFrom("")
    setDateMonthTo("")
    setSelectedPracticeList([])
    setPieChartData([])

  }
  const getPracticeNameata = async () => {
    const Result = await axios.get(getPracticeName)
    setUserNameDatas(Result.data.PracticeData)
  }
  useEffect(() => {
    getPracticeNameata()
    ParameterStorage()
  }, [])


  var UsersListData: any = []
  UserNameDatas.map((practiceList: any, index) => {
    UsersListData.push({
      label: practiceList.practice_name,
      value: practiceList.practice_name
    })
  })
  let ProviderListData:{label:string,value:string}[]=[]
  ProviderList.map((item:any)=>{
    ProviderListData.push({
      label:item.provider,
      value:item.provider
    })
  })

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle> */}
      <div >
        {/* <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        > */}
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Provider Productivity</h3>
          </div>
        {/* </div> */}
        <hr />
        <div className='row'>
          
            <>
              <div className='col-sm-2 fv-row'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>
                  <span className='required'>From</span>
                </label><br />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                  value={DateMonthFrom}
                  minDate={moment("2015")}
                  renderInput={(params:any) => <TextField {...params}  />}
                  maxDate={moment()}
                  views={['year', 'month']} disableFuture
                    onChange={handleDateChangeFrom}
                  />
                </LocalizationProvider>
              </div>

               <div className='col-sm-2 fv-row'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>
                  <span className='required'>To</span>
                </label><br />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                  value={DateMonthTo}
                  minDate={moment("2015")}
                  renderInput={(params:any) => <TextField {...params}  />}
                  maxDate={moment()}
                  views={['year', 'month']} disableFuture
                    onChange={handleDateChangeTo}
                  />
                </LocalizationProvider>
              </div> 

              <div className='col-sm-3 fv-row'>
                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                  <span className='required'>Select Practice</span>
                </label>

                <Select maxMenuHeight={180} value={SelectedPracticeList}  onChange={getSelectedPractice} id="Practice" name={"Practice"} options={UsersListData}  />

              </div>

              <div className='col-sm-3 fv-row'>
                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                  <span className='required'>Select Provider</span>
                </label>

                <Select maxMenuHeight={180} value={SelectedProviderList} isMulti onChange={getSelectedProvider} id="Provider" name={"Provider"} options={ProviderListData} />

              </div>
              
              
              </>
          <div className='col-1 pt-13'>
            <button onClick={(e) => { Filter(e) }} className='btn btn-primary'  >Filter</button>
          </div>
          <div className='col-1 pt-13'>
            <button onClick={(e) => { ClearFilter(e) }} className='btn btn-primary'  >Clear</button>
          </div>
       
        </div><hr />

          <div className='col-12 d-flex justify-content-center mb-8'>
          {/* {NcrData.length > 0 ?  */}
<div className='parentContainer'>
{FinalArray?.map((card:any, id:any) => {
  const cardValues:any=Object.values(card)[0]
    let color:{backGround:string,boxShadow:string}={
    backGround: '',
    boxShadow: ''
  }
  if(id ===0 || id===1){
Object.assign(color,{
  backGround:
  "linear-gradient(rgb(248, 212, 154) -146.42%, rgb(255 202 113) -46.42%)",
  boxShadow: "0px 10px 20px 0px #e0c6f5",              
})
}
  else if(id%2 == 0){
Object.assign(color,{
  backGround: "linear-gradient(180deg, #FF919D 0%, #FC929D 100%)",
  boxShadow: "0px 10px 20px 0px #FDC0C7",
})
  }
  else{
    Object.assign(color,{
      
        backGround:"linear-gradient(180deg, #bb67ff 0%, #c484f3 100%)",
      boxShadow: "0px 10px 20px 0px #F9D59B",
    })
  }
  

  if (cardValues && cardValues.length > 0) {
    return (
      <div key={id}>
        <Card
          color={color}
          data={Object.values(card)}
          total_paid={card.total}
          count={card.count}
          billed_amount={card.billed_amount}
        />
      </div>
    );
  }
})}
</div>
 
            
    {/* :null} */}
          </div>


        
       
      </div>
    </>
  )
}
export { ProviderProductivity }