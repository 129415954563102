import React, { useState, useEffect } from 'react'
//import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import axios from "axios";
import { toast } from 'react-toastify';
import ToastOption from './Common/ToastOption'
import { useAuth } from '../../modules/auth'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Select from 'react-select';
import moment from "moment";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";



const getPracticeDashboard = `/api/ChargesByLocation/getPracticeDashboard`
const getLocationWise = `/api/DenialReports/getLocationWise`
const getPracticeName = `/api/PracticeSummary/getPracticeName`


const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const formatDollarValue = (value: {toLocaleString: () => any}) => `$${value.toLocaleString()}`

const ChargesCollectionsByLocation: React.FC = () => {
  const intl = useIntl()
  // const navigate = useNavigate();
  //const location = useLocation();
  // const userDetails: any = location.state;
  const { currentUser }: any = useAuth()
  const [UserNameDatas, setUserNameDatas] = useState([{ value: "", label: "" }])
  const [DateMonthFrom, setDateMonthFrom] = useState("")
const [FromDate, setFromDate] = useState("")
const [ToDate, setToDate] = useState("")
  const [DateMonthTo, setDateMonthTo] = useState("")
 
  const [SelectedPracticeList, setSelectedPracticeList] = useState([])
  const [PieChartData, setPieChartData] = useState([])
  const [PaymentsData, setPaymentsData] = useState([])
  const [open, setOpen] = React.useState(false);
  const [GetCountByPosttedTable,setGetCountByPosttedTable] = useState([])
  const [PracticeDatas, setPracticeDatas] = useState([{ value: "", label: "" }])
  const [PracticeList, setPracticeList] = useState([])
  const [NoData,setNoData]=useState<boolean>(false);
  let UsersListData: any = []

  const FilterDataList = async () => {
    setNoData(false)
    setOpen(true)

    const tempValues = {UserId :currentUser._id,Path:window.location.pathname,FromDate:FromDate,ToDate:ToDate,Location : JSON.stringify(SelectedPracticeList),PracticeName:JSON.stringify(PracticeList)}


    // axios.get(`${getPracticeDashboard}?FromDate=${FromDate}&ToDate=${ToDate}&PracticeList=${PracticeList.length > 0 ? JSON.stringify(PracticeList) : ""}&PracticeName=${SelectedPracticeList.length > 0 ? JSON.stringify(SelectedPracticeList) : ""}&tempValues=${JSON.stringify(tempValues)}`)
    axios.get(getPracticeDashboard,{
      params:{
        FromDate:FromDate,
        ToDate:ToDate,
        PracticeList:PracticeList.length > 0 ? JSON.stringify(PracticeList) : "",
        PracticeName:SelectedPracticeList.length > 0 ? JSON.stringify(SelectedPracticeList) : ""
      }
    })
    .then(_res => {
      if(_res.data.message){
        setNoData(true)
        setOpen(false)
      }
      else{
        setOpen(false)
        setPieChartData(_res.data.data)
        setPaymentsData(_res.data.PaymentsResult)
        setGetCountByPosttedTable(_res.data.GetCountByPosttedTable)
      }

    })


  }
  const getSelectedPractice = (event: any) => {
    setSelectedPracticeList(event)

  }
  const ParameterStorage=async()=>{
    // setOpen(true)
    const ParameterValues = await axios.get(`/api/PracticeSummary/GetParameterStorage?Path=${window.location.pathname}`)
    // setOpen(false)
    if(ParameterValues.data != null){
      setFromDate(ParameterValues.data.FromDate)
      setToDate(ParameterValues.data.ToDate)
      setDateMonthFrom(ParameterValues.data.FromDate)
      setDateMonthTo(ParameterValues.data.ToDate)
      setSelectedPracticeList(JSON.parse(ParameterValues.data.Location))
      setPracticeList(JSON.parse(ParameterValues.data.PracticeName));

      // if(Object.keys(JSON.parse(ParameterValues.data.PracticeName).length >0)){
        axios.get(`${getLocationWise}?PracticeName=${ParameterValues.data.PracticeName}`).then(res=>{
          setUserNameDatas(res.data.Data)
        })
      // }

    }

  }
  const handleChangePractice=(event:any)=>{
    setUserNameDatas([])
    setSelectedPracticeList([])
    setPracticeList(event)
    if(event.length >0){
      axios.get(`${getLocationWise}?PracticeName=${event.length > 0 ? JSON.stringify(event) : ""}`).then(res=>{
        setUserNameDatas(res.data.Data)
      })
    }

  }

  
  const handleDateChangeFrom = (date: any) => {
    setDateMonthFrom(date)
setFromDate(moment(date['$d']).format("MMM-YYYY"))
  };
  const handleDateChangeTo = (date: any) => {
    setDateMonthTo(date)
setToDate(moment(date['$d']).format("MMM-YYYY"))
    
  };

  const Filter = async (ids: any) => {

    let error = false
    let ErrorArray = []
    if(!FromDate){
      error = true
      ErrorArray.push(`Please Choose From Month / Year`)
    }
    if(!ToDate){
      error = true
      ErrorArray.push(`Please Choose To Month / Year`)
    }
    
    if(PracticeList.length === 0){
      error = true
      ErrorArray.push(`Please Select Practice`)
    }
    if(SelectedPracticeList.length === 0){
      error = true
      ErrorArray.push(`Please Select Location`)
    }
    if(error){
      ErrorArray.map((res,ind)=>{
        toast.error(`${res}`,ToastOption);
      })
      return false;
    }
    
    
    FilterDataList()
  }
  const ClearFilter = async (ids: any) => {

    setDateMonthFrom("")
    setDateMonthTo("")
    setSelectedPracticeList([])
    setPieChartData([])

  }


  // const getLocationNameData = async () => {
  //   const Result = await axios.get(getLocationName)
  //   setUserNameDatas(Result.data.PracticeData)
  // }
  // useEffect(() => {
  //   getLocationNameData()
  // }, [])

  const getPracticeNameata = async () => {
    const Result = await axios.get(getPracticeName)
    setPracticeDatas(Result.data.PracticeData)
  }
  useEffect(() => {
    getPracticeNameata()
    ParameterStorage()
  }, [])

  var PracticeListData: any = []
  PracticeDatas.map((practiceList: any, index) => {
    PracticeListData.push({
      label: practiceList.practice_name,
      value: practiceList.practice_name
    })
  })



  UserNameDatas.map((practiceList: any, index) => {
    UsersListData.push({
      label: practiceList.facility,
      value: practiceList.facility
    })
  })



  var TotalListChart:string[] | any = []
  var TotalChargesValues :any= [""]
  var TotalpaymentsValues :any= [""]
  var TotalClaims : any = 0;
  var Totalcharges : any = 0;
  var Totalpayments : any = 0;
  var Result :any;
  if(PieChartData.length > 0){
    Result = PieChartData.map((res: any, _inde: any) => {
      var FilterDataCountClaims :any = GetCountByPosttedTable.filter((x:any)=> x.facility.toLowerCase() == res.facility.toLowerCase())
      var FilterData :any = PaymentsData.filter((x:any)=> x.facility.toLowerCase() == res.facility.toLowerCase())
      
      var Payments = FilterData.length > 0 ? FilterData[0].payments : 0;
      TotalClaims += Number(FilterDataCountClaims[0].count)
      Totalcharges += Number(res.charges)
      Totalpayments += Number(Payments)
      TotalChargesValues.push(Number(Totalcharges))
      TotalpaymentsValues.push(Number(Totalpayments))
      TotalListChart.push({Location:res.facility,Charges:res.charges,Payments:Payments})

      
      if(_inde == PieChartData.length - 1){
        
          return (
              <>
              <tr>
                <td >{res.facility}</td>
                <td >{currencyFormatter.format(res.charges)}</td>
                <td >{currencyFormatter.format(Payments)}</td>
                <td >{Number(Number(Payments) / Number(res.charges) * 100).toFixed(2)}%</td>
              </tr>
              <tr>
                <td >Grand Total</td>
                <td >{currencyFormatter.format(Totalcharges)}</td>
                <td >{currencyFormatter.format(Totalpayments)}</td>
                <td >{Number(Totalpayments / Totalcharges  * 100).toFixed(2)}%</td>
              </tr>
              </>
            )
            
      }
      else {
          return (
              <tr>
                <td >{res.facility}</td>
                <td >{currencyFormatter.format(res.charges)}</td>
                <td >{currencyFormatter.format(Payments)}</td>
                <td >{Number(Number(Payments) / Number(res.charges)  * 100).toFixed(2)}%</td>
              </tr>
            )
      }
      
    })
  }

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <div >
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Location Wise Charges & Collections</h3>
          </div>
        </div>
        <hr />
        <div className='row'>
 
            <>
            <div className='col-sm-3 fv-row'>
                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                  <span className='required'>Select Practice</span>
                </label>

                <Select maxMenuHeight={180} value={PracticeList} isMulti onChange={handleChangePractice} id="Location" name={"Practice"} options={PracticeListData} />

              </div>

              <div className='col-sm-3 fv-row'>
                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                  <span className='required'>Location List</span>
                </label>

                <Select maxMenuHeight={180} value={SelectedPracticeList} isMulti onChange={getSelectedPractice} id="Practice" name={"Practice"} options={UsersListData} />

              </div>

              <div className='col-sm-2 fv-row'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>
                  <span className='required'>From</span>
                </label><br />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                  value={DateMonthFrom}
                  minDate={moment("2015")}
                  renderInput={(params:any) => <TextField {...params}  />}
                  maxDate={moment()}
                  views={['year', 'month']} disableFuture
                  onChange={handleDateChangeFrom}
                  
                  />
                </LocalizationProvider>
              </div>
              <div className='col-sm-2 fv-row'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>
                  <span className='required'>To</span>
                </label><br />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                  value={DateMonthTo}
                  minDate={moment("2015")}
                  renderInput={(params:any) => <TextField {...params}  />}
                  maxDate={moment()}
                  views={['year', 'month']} disableFuture
                  onChange={handleDateChangeTo}

                  />
                </LocalizationProvider>
              </div>
             
              </>
          <div className='col-1 pt-13'>
            <button onClick={(e) => { Filter(e) }} className='btn btn-primary'  >Filter</button>
          </div>
          <div className='col-1 pt-13'>
            <button onClick={(e) => { ClearFilter(e) }} className='btn btn-primary'  >Clear</button>
          </div>
          {/* <div className='col-1 pt-13'>
            <a onClick={(e) => { DownloadFile(e) }} href={`/users/DownloadDashboard?ManagerID=${Object.keys(SelectedManager).length > 0 ? SelectedManager.value : ""}&UserId=${JSON.stringify(SelectedPracticeList)}&FromDate=${formData.FromDate}&ToDate=${formData.ToDate}`} className='btn btn-primary'>Download</a>
          </div> */}

        </div><hr />

        <div className='row'>
          <div className='col-12'>

            {Result ? 

<BarChart
width={TotalListChart.length <=3 ? 500 :900 }
height={400}

data={TotalListChart}
margin={{
  top: 5,
  right: 30,
  left: 20,
  bottom: 5
}}
>
<CartesianGrid strokeDasharray="3 3" />
<XAxis dataKey="Location" 
>
</XAxis>
<YAxis tickFormatter={formatDollarValue} />
                <Tooltip formatter={formatDollarValue} />
<Legend fontWeight="bold"/>
<Bar dataKey="Charges" fill="#8884d8"  barSize={TotalListChart.length <=3 ? 40 : 30} 
>

</Bar>
<Bar dataKey="Payments" fill="#82ca9d" barSize={TotalListChart.length <=3 ? 40 : 30} >
  </Bar>

</BarChart>
            
            : ""}
            

            {Result ? <table id='kt_table_users' className='table-row-gray-700 table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                  <thead>
                    <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                      <th className='text-dark'>Location</th>
                     
                      <th className='text-dark'>Charges</th>
                      <th className='text-dark'>Payments</th>
                      <th className='text-dark'>% of Payment's</th>
                    </tr>
                  </thead>
                  <tbody className='text-dark fw-bold' >
                  {Result}
                  </tbody>
                </table> :
                 NoData ?
                    <h5 className="fw-bold">No Data Found</h5>
                    : null}

          
         
            
          </div>
          
        </div>
      </div>
    </>
  )
}
export { ChargesCollectionsByLocation }