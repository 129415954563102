import { PieChart, Pie, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Label } from "recharts";


     interface MonthType
     {
      name:string,value:string
    }

    // const formatDollarValue = (value: { toLocaleString: () => any; }) => `$${value.toLocaleString()}`;
    const formatDollarValue = (value:any) => {
      if (value >= 1000) {
        return `${(value / 1000).toFixed(2)}K`;
      }
      return value.toFixed(2);
    };
    
const CardGuarantor = (props: any) => {

  
const data=Object.entries(props.data)?.map(([name,value]:any)=>({name,value}))

const month:MonthType | undefined=data.find(el=>el.name==="month_year")

const FinalData=data.filter(el=>el.name !== "month_year")

  return (
    <div 
    style={{background: props.background =="no" ? "none" : "#dbd8e3"}} 
    className="p-6"    
    >
<h4 className="text-center fs-7">{month?.value}</h4>
<AreaChart
          width={props.customWidth ? props.customWidth : 400}
          height={props.customHeight ? props.customHeight : 200}
          data={FinalData}
          margin={{
            top: 10,
            right: 30, 
            left: 0,
            bottom: 0,
          }}
        >
          
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis dataKey="name"  tick={{ fontSize: props.customHeight ? 14 : 10,  fontWeight: '700',fill: 'rgb(0, 0, 0)' }}  tickLine={false} interval="preserveStartEnd"/>
          <YAxis tickFormatter={formatDollarValue}  tick={{ fontSize: props.customHeight ? 14 : 10,fontWeight: '700',fill: 'rgb(0, 0, 0)' }}/>
      <Tooltip formatter={formatDollarValue} />
          <Area type="monotone" dataKey="value" stroke="#8884d8" fill={props.color} />

        </AreaChart>


 </div>
  )
}



export default CardGuarantor
