import React, { FC, useState, useEffect, useRef} from 'react'
import * as Yup from 'yup'
import { useFormik, useField } from 'formik'
import clsx from 'clsx'
import Select from 'react-select';
import axios from 'axios'
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import ToastOption from '../Common/ToastOption'
import { AnyObject } from 'yup/lib/types';
import {KTSVG} from '../../../../_metronic/helpers'
import {States} from '../../../../utils/data'


const BackGround: React.CSSProperties=
{
    background: `linear-gradient(
        rgba(255, 255, 255, 0.7),
        rgba(255, 255, 255, 0.3)
      ),
      url("https://images.pexels.com/photos/114979/pexels-photo-114979.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500")`,
    backgroundSize: "cover",
    backgroundPosition: "center"
}


const Location=({setAddLocation,onData,showModal,LocationEditData,setEditLocation}:any)=>{
  const [Speciality,setSpeciality]=useState<any>([])
const [StateValue,setStateValue]=useState<any>()
const [SpecialityValue,setSpecialityValue]=useState<any>()
  const [formData, setFormData] = useState<any>({
  Location: '',
  TaxIdLoc: '',
  NPILoc: '',
  StateLoc:"",
  SpecialityLoc:"",
  LocationStatus:'',
  PhyLineLoc1:'',
  PhyLineLoc2:"",
  PhyLineLoc3:'',
  globalID:0,
  location_id:'',
  address_id:"",
  Edited:false,
 
});

const [RadioChecked,setRadioChecked]=useState<any>(
  {
      StatusActive:false,
      StatusInactive:false,
     
  })
  const GetSpeciality = async()=>{
    let Response = await axios.get(`/api/Claims/getSpeciality`) 
    setSpeciality(Response.data.data)
  }
  const handleLocationStatus=(e:any)=>{
    
    if(e.target.value==="Active"){
      setRadioChecked({StatusActive:true})
    }
    else if(e.target.value==="Inactive"){
      setRadioChecked({StatusInactive:true})
    }
       setFormData((prevState:any) => {
      return {...prevState,LocationStatus:e.target.value}
      });
  }

const handleStateChange=(e:any)=>{
  setStateValue(e)
  setFormData((prevState:any) => {
    return {...prevState,StateLoc:e.label}
    });

}

const handleSpecialityChange=(e:any)=>{
  setSpecialityValue(e)
  setFormData((prevState:any) => {
    return {...prevState,SpecialityLoc:e.label}
    });
}

  const handleInputChange = (event:any) => {
    const { name, value } = event.target;
    setFormData((prevState:any) => {
    return {  ...prevState,
      [name]: value }
    });
  };

 
  const handleSave=(e:any)=>{
    e.preventDefault()
    if (!formData.Location) {
      toast.error('Please Enter the Location',ToastOption);
      return false;
    }
    if (!formData.TaxIdLoc) {
      toast.error('Please Enter the Tax ID',ToastOption);
      return false;
    }
    if (!formData.NPILoc) {
      toast.error('Please Enter the NPI',ToastOption);
      return false;
    }
     
    if (!formData.SpecialityLoc) {
      toast.error('Please Select any Speciality',ToastOption);
      return false;
    }
    if (!formData.PhyLineLoc1) {
      toast.error('Please Enter your Physical Address',ToastOption);
      return false;
    }
    if (!formData.StateLoc) {
      toast.error('Please Select any State',ToastOption);
      return false;
    }
    if (!formData.LocationStatus) {
      toast.error('Please Select any Location Status',ToastOption);
      return false;
    }
    toast.success(`Location ${formData.Edited ? "Updated" : "Addded"} Successfully...!`,ToastOption);
    onData(formData)
    setAddLocation(false)
    setEditLocation(false)
  }

  useEffect(()=>{
    GetSpeciality()
       if(LocationEditData && setEditLocation){
      LocationEditData.map((res:any,ind:any)=>{
        setFormData({
          Location: res.Location,
          TaxIdLoc: res.TaxIdLoc,
          NPILoc: res.NPILoc,
          StateLoc:res.StateLoc,
          SpecialityLoc:res.SpecialityLoc,
          LocationStatus:res.LocationStatus,
          PhyLineLoc1:res.PhyLineLoc1,
          PhyLineLoc2:res.PhyLineLoc2,
          PhyLineLoc3:res.PhyLineLoc3,
          globalID:res.globalID,
          location_id:res.location_id,
          address_id:res.address_id,
          Edited:true
        })
        setSpecialityValue({label:res.SpecialityLoc,value:res.SpecialityLoc})
        setStateValue({label:res.StateLoc,value:res.StateLoc})


        if(res.LocationStatus==="Active" || res.LocationStatus==="A"){
          setRadioChecked({StatusActive:true})
        }
        else if(res.LocationStatus==="Inactive" || res.LocationStatus==="I"){
          setRadioChecked({StatusInactive:true})
        }
       
      })  
    }
    else{
      setFormData({
        Location: '',
        TaxIdLoc: '',
        NPILoc: '',
        StateLoc:"",
        SpecialityLoc:"",
        LocationStatus:'',
        PhyLineLoc1:"",
        PhyLineLoc2:"",
        PhyLineLoc3:"",
        globalID:0,
        Edited:false
      })
      setSpecialityValue([])
      setStateValue([])
      setRadioChecked({StatusActive:false,StatusInactive:false})
    }
    
  },[])

  const handleClose=()=>{
    setAddLocation(false)
    setEditLocation(false)
  }

    return (
      <div className={`modal fade ${showModal}`}  id='kt_modal_add_user' role='dialog' tabIndex={-1} aria-modal='false' >
      <div className='modal-dialog modal-dialog-centered modal-xl'>
          <div className='modal-content'>
              <div className='modal-header'>
              <h2 className='fw-bolder'>Add Location</h2>

              <div
                                onClick={handleClose}
                                className='btn btn-icon btn-sm btn-active-icon-primary'
                                data-kt-users-modal-action='close'
                                style={{ cursor: 'pointer' }}
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                            </div>
    </div>



              <div className='d-flex w-full p-6 align-content-center' >
                    {/* 1st column */}
                <div className='d-flex flex-column w-50 pt-2 px-2 mx-4'>
    
                <div className='col-12 fv-row mb-4 d-flex flex-column'>
                  <label className=' col-form-label fw-bold fs-6 w-30'>
                    <span className='required'>Location</span>
                  </label>
                  <input className='p-2 w-70' name="Location" value={formData.Location} onChange={handleInputChange} />
               </div>
    
               <div className='col-12 fv-row mb-4 d-flex flex-column'>
                  <label className='col-form-label fw-bold fs-6  '>
                    <span className='required '>Tax ID</span>
                  </label>
                  <input className='p-2 w-70' name="TaxIdLoc" value={formData.TaxIdLoc} onChange={handleInputChange} />
               </div>
    
               
               <div className='col-12 fv-row mb-4 d-flex flex-column'>
                  <label className='col-form-label fw-bold fs-6 '>
                    <span className='required'>NPI</span>
                  </label>
                  <input className='p-2 w-70' name="NPILoc" value={formData.NPILoc} onChange={handleInputChange}  />
    
               </div>
    
              
               <div className='fv-row mb-4'>
                  <label className='col-form-label fw-bold fs-6'>
                    <span className='required'>Speciality</span>
                  </label>
                  <Select maxMenuHeight={150} className='w-70' name="SpecialityLoc" value={SpecialityValue} options={Speciality} onChange={handleSpecialityChange}/>
               </div>
        
               </div>
    {/* 2nd column */}
           <div className='d-flex flex-column w-50 pt-2 mx-4'>
    
           <div className='fv-row mb-4 d-flex flex-column'>
                  <label className='col-form-label fw-bold fs-6'>
                    <span className='required'>Physical Address</span>
                  </label>
                  {/* <textarea className='p-2 w-70' name="AddressLoc" value={formData.AddressLoc} onChange={handleInputChange}  /> */}

                  <input type="text" className='mb-2 p-2' placeholder='Address Line 1...' name="PhyLineLoc1" value={formData.PhyLineLoc1} onChange={handleInputChange}/>
              <input type="text" className='mb-2 p-2' placeholder='Address Line 2...' name="PhyLineLoc2" value={formData.PhyLineLoc2} onChange={handleInputChange}/>
              <input type="text"  className='mb-2 p-2' placeholder='Address Line 3...' name="PhyLineLoc3" value={formData.PhyLineLoc3} onChange={handleInputChange}/>
               </div>
 
               <div className='fv-row mb-4 '>
                  <label className='col-form-label fw-bold fs-6'>
                    <span className='required'>State</span>
                  </label>
                  <Select maxMenuHeight={150} className='w-70 '  value={StateValue} options={States} onChange={handleStateChange} />
               </div>

              
               <div className='fv-row mb-4 d-flex'>
               <div className='fv-row mb-4 d-flex flex-column'>

                  <label className='col-form-label fw-bold fs-6'>
                    <span className='required'>Location Status</span>
                  </label>
                  <div className='d-flex'>
                  <div className='form-check form-check-inline mt-4 '>
                            <input
                                className='form-check-input'
                               checked={RadioChecked.StatusActive}
                                type='radio'
                                name='Practice'
                                id='Practice'
                                value='Active' 
                                onChange={handleLocationStatus}
                            />
                            <label className='form-check-label' htmlFor='flexRadioDefault2'>
                                Active
                            </label>
                        </div>
                        <div className='form-check form-check-inline mt-4'>
                            <input
                                className='form-check-input'
                                checked={RadioChecked.StatusInactive}
                                type='radio'
                                name='Practice'
                                id='Practice'
                                value='Inactive' 
                                onChange={handleLocationStatus}
                            />
                            <label className='form-check-label' htmlFor='flexRadioDefault2'>
                                Inactive
                            </label>
                        </div>
              </div>

                        </div>
                        {/* <div className='fv-row mb-2 d-flex'> */}
               <div className='fv-row pt-13 mb-2 text-center  me-4 d-flex'>
               <button type='button' className='btn btn-danger rounded ' onClick={handleSave}>Save</button>
              </div>
              {/* </div> */}

               </div>

           
    </div>
    
              </div>

              
            </div>
            <div>
    
    
             
    
            </div>
            </div>          
         
        </div>
      )


}


export default Location

