import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import axios from 'axios'
import { useAuth } from '../../modules/auth'
import { Chart } from 'react-google-charts'
import { Backdrop, Typography, CircularProgress } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker'
import TextField from '@mui/material/TextField'
import Select from 'react-select'
import swal from 'sweetalert'
import moment from 'moment'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import { esES } from '@mui/x-date-pickers'
import LogoImage from '../../../../src/_metronic/assets/images/favicon.png'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  Legend,
  ReferenceLine,
  LabelList,
  Label
} from "recharts";
import autoTable from 'jspdf-autotable';
import { toPng } from 'html-to-image';
import { AnyCnameRecord } from 'dns'
import { isFloat32Array } from 'util/types'


const getPracticeName = `/api/PracticeSummary/getPracticeName`
const getNcr = `/api/PracticeSummary/getNcr`
const getWaterfallReport = `/api/DenialReports/WaterfallReport`
const getRejectionRate = `/api/DenialReports/getRejectionRate`
const getDenialRate = `/api/DenialReports/getDenialRate`
const getCleanClaimRatio = `/api/DenialReports/getCleanClaimRatio`
const getPracticeDashboard = `/api/PracticeSummary/getPracticeDashboard`
const getArDetails = `/api/PracticeSummary/getArDetails`
const getTopPayer = `/api/ChargesByProvider/getTopPayer`
const getDaysinAr = `/api/PracticeSummary/getDaysinAr`
const getBenchMark = `/api/DenialReports/getBenchMark`
const getProvider = `/api/ChargesByProvider/getPracticeDashboard`
const getLocation = `/api/ChargesByLocation/getPracticeDashboard`



const ExportPDF: React.FC = () => {
  const intl = useIntl()
  const location = useLocation()

  const [UserNameDatas, setUserNameDatas] = useState([{ value: '', label: '' }])
  const [DateMonthFrom, setDateMonthFrom] = useState('')
  const [FromDate, setFromDate] = useState('')

  const [SelectedPracticeList, setSelectedPracticeList] = useState([])
  const [PracticeName, setPracticeName] = useState({ label: "" })

  const [NcrData, setNcrData] = useState([])
  const [WaterfallTable, setWaterfallTable] = useState<any>([])
  const [open, setOpen] = React.useState<boolean>(false)
  const [DenialChart, setDenialChart] = useState([])
  const [DenialTable, setDenialTable] = useState<any>([])
  const [RejectionChart, setRejectionChart] = useState([])
  const [RejectionTable, setRejectionTable] = useState<any>([])
  const [CleanChart, setCleanChart] = useState<any>([])
  const [CleanTable, setCleanTable] = useState<any>([])
  const [PracticeSummaryChart, setPracticeSummaryChart] = useState<any>([])
  const [ArDetailsTable, setArDetailsTable] = useState<any>([])
  const [PaymentsData, setPaymentsData] = useState([])
  const [ChargesByPostDateData,setChargesByPostDateData] = useState([])
  const [GetTotalAmount, setGetTotalAmount] = useState([{ count: "", payments: "", }])
  const [ArData, setArData] = useState([])
  const [GetCountByPosttedTable, setGetCountByPosttedTable] = useState([])
  const [NcrDays, setNcrDays] = useState([])
  const [BenchMarkTable, setBenchMarkTable] = useState<any>([])
  const [BenchMarkChart, setBenchMarkChart] = useState<any>([])
  const [ProviderData, setProviderData] = useState<any>([])
  const [LocationData, setLocationData] = useState<any>([])
  const [LocationPayments, setLocationPayments] = useState<any>([])
  const [LocationTable, setLocationTable] = useState<any>([])
  const [TableData, setTableData] = useState<any>([])
  const [ProviderTotalCharges, setProviderTotalCharges] = useState<number>(0)
  const [ProviderTotalPayments, setProviderTotalPayments] = useState<number>(0)

  const WaterfallRef = useRef<any>(null)
  const WaterfallTableRef = useRef<any>(null)
  const DenialRef = useRef<any>(null)
  const DenialTableRef = useRef<any>(null)
  const RejectionRef = useRef<any>(null)
  const RejectionTableRef = useRef<any>(null)
  const CleanRef = useRef<any>(null)
  const CleanTableRef = useRef<any>(null)
  const PracticeSummaryRef = useRef<any>(null)
  const ArDetailsTableRef = useRef<any>(null)
  const PayerChartRef = useRef<any>(null)
  const PayerTableRef = useRef<any>(null)
  const ArDaysRef = useRef<any>(null)
  const NcrDaysRef = useRef<any>(null)
  const BenchMarkChartRef = useRef<any>(null)
  const BenchMarkTableRef = useRef<any>(null)
  const ProviderChartRef = useRef<any>(null)
  const ProviderTableRef = useRef<any>(null)
  const LocationChartRef = useRef<any>(null)
  const LocationTableRef = useRef<any>(null)
  const PracticeSummaryTableRef = useRef<any>(null)
  const [percentage, setPercentage] = useState<any>(0);
  const [isRunning, setIsRunning] = useState<boolean>(true)

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  
  const options = { style: 'currency', currency: 'USD' }
  
  
  // const formatDollarValue = (value: any) => {
  //   if (value >= 1000) {
  //     return `${(value / 1000).toFixed(2)}K`;
  //   }
  //   return value.toFixed(2);
  // };
  const formatDollarValue = (value:any) => {
    return `${(value / 1000000).toFixed(2)}M`;
  };

  const formatDollarValueYAxis = (value:any) => {
    return `${(value / 1000000).toFixed(0)}M`;
  };

  const customTickFormatter = (value:string) => {
    return value.length > 8 ? `${value.substring(0, 8)}...` : value;
  };

  const providerTickFormatter:any=(value:string)=>{
      const names=value.split(", ");
      return names[1]?.length > 0 ? names[1] : names[0];
  } 

  const getSelectedPractice = async (event: any) => {
    if (!DateMonthFrom) {
      swal({
        title: 'Date',
        icon: "info",
        text: `Please choose any Month`,
      }).then((_res: any) => {

      })
      return false;
    }
    setIsRunning(true)
    setPercentage(0)

    setOpen(true)
    setNcrData([])
    setWaterfallTable([])
    setRejectionChart([])
    setRejectionTable([])
    setCleanChart([])
    setCleanTable([])
    setDenialChart([])
    setDenialTable([])
    setPracticeSummaryChart([])
    setArDetailsTable([])
    setPaymentsData([])
    setGetTotalAmount([])
    setArData([])
    setNcrDays([])
    setChargesByPostDateData([])
    setBenchMarkTable([])
    setBenchMarkChart([])
    setTableData([])
    setGetCountByPosttedTable([])
    setPracticeName({ label: "" })

    setSelectedPracticeList(event)
    setPracticeName({ label: event.label })

    const LocationData = await axios.get(`${getLocation}?OneDate=${FromDate}&PracticeName=${Object.keys(event).length > 0 ? JSON.stringify(event) : ''
      }`)
    setLocationData(LocationData.data.data)
    setLocationPayments(LocationData.data.PaymentsResult)
    setLocationTable(LocationData.data.GetCountByPosttedTable)
    setPercentage(9)

    const ProviderData = await axios.get(`${getProvider}?OneDate=${FromDate}&PracticeName=${Object.keys(event).length > 0 ? JSON.stringify(event) : ''
      }`)
    setProviderData(ProviderData.data.data)
    let totalCharges: number = 0;
    let totalPayments: number = 0;
    ProviderData.data.data.forEach((element: any) => {
      totalCharges += element.charges ? element.charges : 0;
      totalPayments += element.payments ? element.payments : 0;
    });
    setProviderTotalCharges(totalCharges);
    setProviderTotalPayments(totalPayments);
    // setProviderPayments(ProviderData.data.PaymentsResult)
    // setProviderTable(ProviderData.data.GetCountByPosttedTable)
    setPercentage(18)

    const WaterfallData = await axios.get(
      `${getWaterfallReport}?ChooseDate=${FromDate}&PracticeName=${Object.keys(event).length > 0 ? JSON.stringify(event) : ''
      }`
    )
    setNcrData(WaterfallData.data.WaterfallData)
    setWaterfallTable(WaterfallData.data.WaterfallTable)
    setPercentage(27)


    const RejectionData = await axios.get(`${getRejectionRate}?OneDate=${FromDate}&PracticeName=${Object.keys(event).length > 0 ? JSON.stringify(event) : ''
      }`)
    setRejectionChart(RejectionData.data.dataPdf)
    setRejectionTable(RejectionData.data.table)
    setPercentage(36)

    const CleanData = await axios.get(`${getCleanClaimRatio}?OneDate=${FromDate}&PracticeName=${Object.keys(event).length > 0 ? JSON.stringify(event) : ""}`)
    setCleanChart(CleanData.data.dataPdf)
    setCleanTable(CleanData.data.table)
    setPercentage(45)

    const DenialData = await axios.get(`${getDenialRate}?OneDate=${FromDate}&PracticeName=${Object.keys(event).length > 0 ? JSON.stringify(event) : ""}`)
    setDenialChart(DenialData.data.dataPdf)
    setDenialTable(DenialData.data.table)
    setPercentage(54)

    const PracticeSummaryData = await axios.get(`${getPracticeDashboard}?OneDate=${FromDate}&PracticeName=${Object.keys(event).length > 0 ? JSON.stringify(event) : ""}`)
    setPracticeSummaryChart(PracticeSummaryData.data.data)
    setPercentage(63)


    const ArDetailsData = await axios.get(`${getArDetails}?OneDate=${FromDate}&PracticeName=${Object.keys(event).length > 0 ? JSON.stringify(event) : ""}`)
    setArDetailsTable(ArDetailsData.data.Data)
    setPercentage(72)

    const PayerData = await axios.get(`${getTopPayer}?OneDate=${FromDate}&PracticeName=${Object.keys(event).length > 0 ? JSON.stringify(event) : ""}`)
    setPaymentsData(PayerData.data.PaymentsResult)
    setGetTotalAmount(PayerData.data.GetTotalAmount)
    setPercentage(81)

    const GetArDaysData = await axios.get(`${getDaysinAr}?OneDate=${FromDate}&PracticeName=${Object.keys(event).length > 0 ? JSON.stringify(event) : ""}`)
    setArData(GetArDaysData.data.ArData)
    setGetCountByPosttedTable(GetArDaysData.data.GetCountByPosttedTable)
    setPercentage(90)

    const NcrData = await axios.get(`${getNcr}?OneDate=${FromDate}&PracticeName=${Object.keys(event).length > 0 ? JSON.stringify(event) : ""}`)
    setNcrDays(NcrData.data.NcrData)
    setChargesByPostDateData(NcrData.data.QueryChargesByPostDateData)
    setPercentage(95)

    const BenchMarkData = await axios.get(`${getBenchMark}?ChooseDate=${FromDate}&PracticeName=${Object.keys(event).length > 0 ? JSON.stringify(event) : ""}`)
    setBenchMarkTable(BenchMarkData.data.BenchMarkTable)
    setBenchMarkChart(BenchMarkData.data.BenchMarkChartPDF)
 

    
    setPercentage(100)
    // setDuration(35)
    setIsRunning(false)
    setOpen(false)
    

  }

  const generateDynamicColor = (data:any) => {
    console.log({data})
    return "#ff0000"; 
  };

  const handleDateChangeFrom = (date: any) => {
    setDateMonthFrom(date)
    setSelectedPracticeList([])
    setFromDate(moment(date['$d']).format('MMM-YYYY'))
  }

  useEffect(() => {
    getPracticeNameata()
    // ParameterStorage()
  }, [])

  async function createPdf(doc: any, el: any, imgData: any,paddingLeft?:number | null) {
    let top = 70;
    const padding =  paddingLeft || 10;


    let elHeight = el.offsetHeight;
    let elWidth = el.offsetWidth;
    const pageWidth = doc.internal.pageSize.getWidth();
    if (elWidth > pageWidth) {
      const ratio = pageWidth / elWidth;
      elHeight = elHeight * ratio - 10;
      elWidth = elWidth * ratio - 10;
    }
    const pageHeight = doc.internal.pageSize.getHeight();

    if (top + elHeight > pageHeight) {
      doc.addPage();
      top = 20;
    }

    doc.addImage(imgData, "PNG", padding, top, elWidth, elHeight);
    top += elHeight;
  }

  async function pageAlignment(doc: jsPDF, titleOfThePage?: string, pageNo?: number) {
    const logoX = doc.internal.pageSize.getWidth() - 20 - 10;

    doc.setTextColor('#023e8a')
    const title = `${PracticeName.label} - ${FromDate}`;

    const titleX = doc.internal.pageSize.getWidth() / 2;
   
    const titleY = 25;
    const contentWidth = doc.internal.pageSize.getWidth();
    const contentHeight = doc.internal.pageSize.getHeight();
    const footerY = contentHeight - 14;
    const lineY = footerY - 10;
    const footerContent = 'Copyright @2023.All rights reserved';
    const footerTextWidth = doc.getStringUnitWidth(footerContent) * 8 / doc.internal.scaleFactor;

    const footerX = (contentWidth - footerTextWidth) / 2;
    doc.addImage(LogoImage, 'PNG', logoX, 10, 20, 20);
    doc.setFontSize(10);
    doc.text(title, titleX, titleY, { align: 'center' });

    doc.setLineWidth(0.5);

    // doc.setFontSize(10);
    doc.text(title, titleX, titleY, { align: 'center' });

    const lineYY = titleY + 8;
    doc.line(10, lineYY, contentWidth - 20, lineYY);


    doc.setTextColor('#023e8a')
    doc.setFontSize(8);
    // doc.text(`${FromDate} Report for ${PracticeName.label}`, 10, doc.internal.pageSize.getHeight() - 28, { align: 'left' });

    doc.text(`Page ${pageNo} of 10`, doc.internal.pageSize.getWidth() - 18, doc.internal.pageSize.getHeight() - 28, { align: 'right' });

    doc.setLineWidth(0.5);
    doc.line(10, lineY, contentWidth - 20, lineY);

    doc.setFontSize(8);
    doc.text(footerContent, footerX, footerY);
    doc.setFontSize(10)

    doc.text(`${titleOfThePage}`, titleX, 50, { align: 'center' })

  }


    const waterfallcolumnStyles = (length: number) => {
    let columnStyles: any = {}

    for (let i = 0; i < length; i++) {
      if (i === 0) {
        columnStyles[i] = { halign: 'left',cellWidth:60 };
      } 
      else {
        columnStyles[i] = { halign: 'right',cellWidth:100 };
      }
    }

    return columnStyles;
  }

  
  const benchmarkcolumnStyles = (length: number) => {
    let columnStyles: any = {}

    for (let i = 0; i < length; i++) {
      if (i === 0) {
        columnStyles[i] = { halign: 'left' };
      } 
      else {
        columnStyles[i] = { halign: 'center',cellWidth:70 };
      }
    }

    return columnStyles;
  }


  const practiceColumnStyles = (length: number) => {
    let columnStyles: any = {}

    for (let i = 0; i < length; i++) {
      if (i === 0) {
        columnStyles[i] = { halign: 'center',columnStyles };
      } 
      else {
        columnStyles[i] = { halign: 'right' };
      }
    }

    return columnStyles;
  }

  const locationColumnStyles = (length: number) => {
    let columnStyles: any = {}

    for (let i = 0; i < length; i++) {
      if (i === 0) {
        columnStyles[i] = { halign: 'left',cellWidth: 'auto' };
      } 

      if (i === length - 1) {
        columnStyles[i] = { cellWidth: 80 ,halign: 'center' }; 
      } 
      if(i > 0 && i < length - 1) {
        columnStyles[i] = { halign: 'right' ,cellWidth: 110};
      }
    }

    return columnStyles;
  }

  const rejectionColumnStyles = (length: number) => {
    let columnStyles: any = {}

    for (let i = 0; i < length; i++) {

         if (i === length - 1 || i === 0) {
        columnStyles[i] = {halign: 'center' }; 
      } 
      if(i > 0 && i < length - 1) {
        columnStyles[i] = { halign: 'right' };
      }
     
    }

    return columnStyles;
  }

  
   const arDetailsColumnStyles = (length: number) => {
    let columnStyles: any = {}

    for (let i = 0; i < length; i++) {
      if (i === 0) {
        columnStyles[i] = { halign: 'left' };
      } 

      if (i === 1 || i === 3 || i === 5 || i === 7) {
        columnStyles[i] = {halign: 'center' }; 
      } 
      if(i === 2 || i === 4 || i === 6 ) {
        columnStyles[i] = { halign: 'right' };
      }
    }

    return columnStyles;
  }


  async function horizontalBar(title:string,doc:jsPDF){
    const titleHeight = 58;
  const barHeight = 18;
  doc.setFillColor(18, 38, 66); 
  doc.rect(10, titleHeight - barHeight / 2, doc.internal.pageSize.width - 20, barHeight, 'F'); // Draw the bar
  doc.setTextColor(255, 255, 255); // Set text color to white
  doc.text(title, 25, 62);
  }


  const GetPDF = async () => {

    var NODATATITLE=315.70875;
    if (SelectedPracticeList.length === 0) {
      swal({
        title: 'Practice',
        icon: "info",
        text: `Please choose any Practice`,
      }).then((_res: any) => {

      })
      return false;
    }
    setPercentage(0)
    setOpen(true)
    const doc:any = new jsPDF("l", "px", undefined, true);
  
   await pageAlignment(doc, "", 0);

    const toc = [
      "Practice Wise Charges and Payments",
  "Charges & Collections By Location - YTD",
  "Charges & Collections By Provider - YTD",
  "Payments Break Down By Payer - YTD",
  "AR Details",
  "Denial Rate",
  "Rejection Rate",
  "Days in AR",
  "Net Collection Ratio",
  "Clean Claim Ratio",
  "Water Fall Collection Report",
  "Benchmarks"
  
  ];
  
 
  doc.setFontSize(16); // Set the title font size
  doc.setFont("Source Sans Pro Black (Headings)", "bold"); 
  
  
  await horizontalBar("Contents",doc);
  
  doc.setFillColor(255, 255, 255); 
  doc.setTextColor(0, 0, 0); 
  
  doc.setFontSize(12);
  doc.setFont("helvetica", "normal");
  let titleHeight = 58;
  toc.forEach((item, index) => {
      const text = `\u2022 ${item}`; 
      const lineHeight = 14; 
      const verticalSpacing = 5; 
  
      // Add content to the PDF
      doc.text(text, 30, titleHeight + 30 + index * (lineHeight + verticalSpacing));
  });



    //1st Page

    doc.addPage();
    await pageAlignment(doc, "", 1);

    doc.setFontSize(16); 
    doc.setFont("Source Sans Pro Black (Headings)", "bold");

    if (PracticeSummaryRef.current === null) {

      doc.text(' No Data Available', NODATATITLE, 160, { align: "center" })
    }

    await horizontalBar("Practice Wise Charges and Payments",doc);

    if (PracticeSummaryRef.current) {

      const imgData: any = await toPng(PracticeSummaryRef.current)
      await createPdf(doc, PracticeSummaryRef.current, imgData)

      const columns: string[] = ["Month", "Charges", 'Payments'];
      let rows: any = [];
      PracticeSummaryChart.forEach((item: any) => {
        const rowData = [item.month_year, item.Charges.toLocaleString('en-US', options), item.Payments.toLocaleString('en-US', options)];
        rows.push(rowData);
      });


      autoTable(doc, {
        head: [columns],
        body: rows,
       
        startY: 170,
        tableWidth:250,
            margin:{
              left: 350
         },
        theme: "grid",
        styles: {
          fontSize: 10,
          lineWidth: 0.5,
          lineColor: [0, 0, 0], 
          
        },
        columnStyles: practiceColumnStyles(columns.length),
        headStyles: {
          halign: "center",
          fillColor: [211, 211, 211], 
          // textColor: [255, 255, 255], 
          textColor: [0, 0, 0],
          fontSize: 10, 
        },
        bodyStyles: {
          textColor: [1, 2, 3], 
        },


      });

    }
  
    setPercentage(6)


    // //Second Page
    doc.addPage();

    await pageAlignment(doc, "", 2);

    doc.setFontSize(16); // Set the title font size
    doc.setFont("Source Sans Pro Black (Headings)", "bold");

    if (LocationChartRef.current === null) {

      doc.text(' No Data Available', NODATATITLE, 160, { align: "center" })
    }

    await horizontalBar("Charges and Collections By Location",doc);

    if (LocationChartRef.current) {
      const imgData: any = await toPng(LocationChartRef.current)

      await createPdf(doc, LocationChartRef.current, imgData,LocationTable?.length <= 2 ? 100 : null)
    }
    
    setPercentage(12)


    // //Third Page
    doc.addPage();

    await pageAlignment(doc, "", 3);

    if (LocationChartRef.current) {

      let TotalchargesPDF: any = 0;
      let TotalpaymentsPDF: any = 0;

      let rows: any = []
      const ResultPDF = LocationData?.map((res: any, _inde: any) => {
        var FilterDataCountClaims: any = LocationTable?.filter((x: any) => x.facility == res.facility)
        var FilterData: any = LocationPayments?.filter((x: any) => x.facility == res.facility)

        var Payments = FilterData.length > 0 ? FilterData[0].payments : 0;
        TotalchargesPDF += Number(res.charges)
        TotalpaymentsPDF += Number(Payments)


        rows.push({
          Location: res.facility,
          Charges: currencyFormatter.format(res.charges),
          Payments: currencyFormatter.format(Payments),
          Percentage: Number((Payments / res.charges) * 100).toFixed(2) + '%',
        });


      })

      rows.push({
        Location: "Grand Total",
        Charges: currencyFormatter.format(TotalchargesPDF),
        Payments: currencyFormatter.format(TotalpaymentsPDF),
        Percentage: Number((TotalpaymentsPDF / TotalchargesPDF) * 100).toFixed(2) + '%',
      });

      const columns = ['Location', 'Charges', 'Payments', "% of Payment's"];

        autoTable(doc, {
        head: [columns],
        // body: rows.map((row: any) => Object.values(row)),
           body: rows.map((row:any, rowIndex:any) => {
    const values = Object.values(row);

    const styles = rowIndex === rows.length - 1 ? { fontStyle: 'bold',
    fillColor: [211, 211, 211] } : {};

    return values.map((value:any) => ({ content: value, styles }));
  }),
        startY: 50, 
        theme: "grid",
        tableWidth:500,
        margin:{
          left: 60
     },
        styles: {
          fontSize: 10,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          
        },
        columnStyles: locationColumnStyles(columns.length),
        headStyles: {
          halign: "center",
          fillColor: [211, 211, 211], 
          textColor: [0, 0, 0], 
          fontSize: 10, 
        },
        bodyStyles: {
          textColor: [1, 2, 3], 
        },
      
      });

      }
    else {
      doc.setFontSize(16); 
      doc.text(' No Data Available', NODATATITLE, 160, { align: "center" })
    }
  
    setPercentage(18)


    // // 4th Page
    doc.addPage();
    await pageAlignment(doc, "", 4);

    doc.setFontSize(16); // Set the title font size
    doc.setFont("Source Sans Pro Black (Headings)", "bold");

    if (ProviderChartRef.current === null) {

      doc.text(' No Data Available', NODATATITLE, 160, { align: "center" })
    }

    await horizontalBar("Charges and Collections by Provider",doc);

    if (ProviderChartRef.current) {

      const imgData: any = await toPng(ProviderChartRef.current)

      await createPdf(doc, ProviderChartRef.current, imgData);
    }
  
       
    setPercentage(24)


    // // 5th Page
    doc.addPage();

    await pageAlignment(doc, "", 5);

    if(ProviderChartRef.current){

      const columns: string[] = ["Provider", "Charges", 'Payments', "% of Payments"];
      let rows: any = [];

      ProviderData.forEach((res: any) => {
        rows.push({
          Provider: res.provider ? res.provider : "None",
          Charges: res.charges ? currencyFormatter.format(res.charges) : '$0',
          Payments: res.payments ? currencyFormatter.format(res.payments) : "$0",
          Percentage: (!res.payments || !res.charges) ? "0%" : Number((res.payments / res.charges) * 100).toFixed(2) + '%',
        });
      });

      rows.push({
        Provider: "Grand Total",
        Charges: currencyFormatter.format(ProviderTotalCharges),
        Payments: currencyFormatter.format(ProviderTotalPayments),
        Percentage: Number((ProviderTotalPayments / ProviderTotalCharges) * 100).toFixed(2) + '%'
      });

      autoTable(doc, {
        head: [columns],
        // body: rows.map((row: any) => Object.values(row)),
          body: rows.map((row:any, rowIndex:any) => {
    const values = Object.values(row);

    const styles = rowIndex === rows.length - 1 ? { fontStyle: 'bold',
    fillColor: [211, 211, 211] } : {};

    return values.map((value:any) => ({ content: value, styles }));
  }),
        
        startY: 50,
        theme:"grid",  
        tableWidth:500,
        margin:{
          left: 60
     },
        styles: {
          fontSize: 10,
          lineWidth: 0.5,
          lineColor: [0, 0, 0], 
          
        },
        columnStyles: locationColumnStyles(columns.length),
        headStyles: {
          halign: "center",
          fillColor: [211, 211, 211], 
          textColor: [0, 0, 0], 
          fontSize: 10, 
        },
        bodyStyles: {
          textColor: [1, 2, 3], 
        },
       
      });

    }
    else {
      doc.setFontSize(16); 
      doc.text(' No Data Available', NODATATITLE, 160, { align: "center" })
    }
    
   
   
  setPercentage(30)

     // 6th Page
    doc.addPage();

    await pageAlignment(doc, "", 6);

    doc.setFontSize(16); // Set the title font size
    doc.setFont("Source Sans Pro Black (Headings)", "bold");

    
    if (PayerChartRef.current === null) {

      doc.text(' No Data Available', NODATATITLE, 160, { align: "center" })
    }

    await horizontalBar("Payments Break Down By Payer",doc);

     if (PayerChartRef.current) {

      const imgData: any = await toPng(PayerChartRef.current)

      await createPdf(doc, PayerChartRef.current, imgData);

      }
     

      setPercentage(36)
       // // 7th Page

    doc.addPage();
    await pageAlignment(doc, "", 7);

    if(PayerChartRef.current){
      var TotalSumResult: any = GetTotalAmount;
      let rows: any = []
      let TotalSum: any = []
      PaymentsData.map((res: any, _inde: any) => {
        var GrandTotalClaimsAmount: any = 0;
        GrandTotalClaimsAmount += Number(res.payments);
        TotalSum.push(Number(GrandTotalClaimsAmount))
      })


      var FianlTotalClaimsAmount = TotalSum.reduce(function (pv: any, cv: any) { return pv + cv; }, 0);
      Result = PaymentsData.map((res: any, _inde: any) => {

        TotalClaims += Number(res.count)

        Totalcharges += Number(res.payments)

        rows.push({
          Payers: res.insurance,
          Payments: currencyFormatter.format(res.payments),
          Percentage: Number((Number(res.payments) / Number((TotalSumResult[0].payments))) * 100).toFixed(2) + "%"
        });


      })

      rows.push({
        Location: "Grand Total",
        Charges: currencyFormatter.format(TotalSumResult[0].payments),
        Percentage: Number(TotalSumResult[0].payments / TotalSumResult[0].payments) * 100 + "%",
      });

      const columns = ['Payers', 'Payments', "% of Payment's"];

      autoTable(doc, {
        head: [columns],
        body: rows.map((row:any, rowIndex:any) => {
          const values = Object.values(row);
      
          const styles = rowIndex === rows.length - 1 ? { fontStyle: 'bold',
          fillColor: [211, 211, 211] } : {};
      
          return values.map((value:any) => ({ content: value, styles }));
        }),
            
        startY: 50,
        tableWidth:400,
        margin:{
          left: 110
     },
        styles: {
          fontSize: 10,
          lineWidth: 0.5,
          lineColor: [0, 0, 0], 
          
        },
        theme: "grid",
        columnStyles: locationColumnStyles(columns.length),
        headStyles: {
          halign: "center",
          fillColor: [211, 211, 211], 
          textColor: [0, 0, 0], 
          fontSize: 10, 
        },
        bodyStyles: {
          textColor: [1, 2, 3], 
        },
      });
    }

    else {
      doc.setFontSize(16); 
        doc.text(' No Data Available', NODATATITLE, 160, { align: "center" })
      }
      setPercentage(42)

   // 8th Page
    doc.addPage();

    await pageAlignment(doc, "", 8);

    doc.setFontSize(16); 
    doc.setFont("Source Sans Pro Black (Headings)", "bold");

    if (ArDetailsTableRef.current === null) {

      doc.text(' No Data Available', NODATATITLE, 130, { align: "center" })
    }

    await horizontalBar("AR-Details",doc);

    if(ArDetailsTableRef.current){
        let TotalAddressedCount: any = 0;
    let TotalAddressedAmount: any = 0;
    let TotalUnAddressedCount: any = 0;
    let TotalUnAddressedAmount: any = 0;
    let GrnadTotalClaimsLines: any = 0;
    let TotalSum: any = []
    let rows: any = [];
    ArDetailsTable?.map((res: any, _inde: any) => {
      let GrandTotalClaimsAmount: any = 0;
      GrandTotalClaimsAmount += Number(res.TouchedAmount) + Number(res.UnTouchedAmount);
      TotalSum.push(Number(GrandTotalClaimsAmount))
    })

    const FinalTotalClaimsAmount = TotalSum.reduce(function (pv: any, cv: any) { return pv + cv; }, 0);
    const FinalPercentage = Number(FinalTotalClaimsAmount / FinalTotalClaimsAmount * 100).toFixed(0)

    ArDetailsTable?.map((res: any, _inde: any) => {
      let TotalClaimsLines: any = 0;
      let TotalClaimsAmount: any = 0;
      TotalAddressedCount += Number(res.TouchedCount)
      TotalAddressedAmount += Number(res.TouchedAmount)
      TotalUnAddressedCount += Number(res.UnTouchedCount)
      TotalUnAddressedAmount += Number(res.UnTouchedAmount)


      TotalClaimsLines += Number(res.TouchedCount) + Number(res.UnTouchedCount)

      TotalClaimsAmount += Number(res.TouchedAmount) + Number(res.UnTouchedAmount)

      GrnadTotalClaimsLines += TotalClaimsLines;

      const rowData = [
        res.Aging,
        res.TouchedCount,
        currencyFormatter.format(res.TouchedAmount),
        res.UnTouchedCount,
        currencyFormatter.format(res.UnTouchedAmount),
        res.TouchedCount + res.UnTouchedCount,
        currencyFormatter.format(res.TouchedAmount + res.UnTouchedAmount),
        Number(TotalClaimsAmount / FinalTotalClaimsAmount * 100).toFixed(0) + '%'
      ];
      rows.push(rowData);

      if (_inde === ArDetailsTable.length - 1) {

        const rowDataTotal = [
          'Total',
          TotalAddressedCount,
          currencyFormatter.format(TotalAddressedAmount),
          TotalUnAddressedCount,
          currencyFormatter.format(TotalUnAddressedAmount),
          GrnadTotalClaimsLines,
          currencyFormatter.format(FinalTotalClaimsAmount),
          FinalPercentage + '%',
        ];
        rows.push(rowDataTotal);
      }

    })

    const columns = [
      'Aging',
      'Worked Count',
      'Worked Amount',
      'Untouched Count',
      'Untouched Amount',
      'Total Claims Lines',
      'Total Claims Amount',
      'Percentage',
    ];

    autoTable(doc, {
      head: [columns],
      // body: rows,
      body: rows.map((row:any, rowIndex:any) => {
        const values = Object.values(row);
    
        const styles = rowIndex === rows.length - 1 ? { fontStyle: 'bold',
        fillColor: [211, 211, 211] } : {};
    
        return values.map((value:any) => ({ content: value, styles }));
      }),
      startY: 80,
      styles: {
        fontSize: 10,
        lineWidth: 0.5,
        lineColor: [0, 0, 0], 
        
      },
      theme: "grid",
      columnStyles: arDetailsColumnStyles(columns.length),
      headStyles: {
        halign: "center",
          fillColor: [211, 211, 211], 
          textColor: [0, 0, 0], 
          fontSize: 10, 
      },
      bodyStyles: {
        textColor: [1, 2, 3], 
      },
    });
    }
    
    doc.setTextColor('#023e8a')
     doc.setFontSize(16);
    doc.text('AR Days', 160, 230)
    doc.text('NCR %', 370, 230)


    if (ArDaysRef.current) {

      const imgData: any = await toPng(ArDaysRef.current)
      const el: any = ArDaysRef.current;
      let top = 50;
      const padding = 10;

      let elHeight = el.offsetHeight;
      let elWidth = el.offsetWidth;
      const pageWidth = doc.internal.pageSize.getWidth();

      if (elWidth > pageWidth) {
        const ratio = pageWidth / elWidth;
        elHeight = elHeight * ratio - padding;
        elWidth = elWidth * ratio - padding;
      }

      const pageHeight = doc.internal.pageSize.getHeight();

      if (top + elHeight > pageHeight) {
        doc.addPage();
        top = 20;
      }

      doc.addImage(imgData, "PNG", 100, 235, elWidth, elHeight);
      top += elHeight;
    }
    if (ArDaysRef.current === null) {

      doc.setFontSize(16)
      doc.text(' No Data Available', 140, 330)
    }

   if (NcrDaysRef.current) {


        const imgData: any = await toPng(NcrDaysRef.current)
        const el: any = NcrDaysRef.current;
  
        let top = 50;
        const padding = 10;
  
        let elHeight = el.offsetHeight;
        let elWidth = el.offsetWidth;
        const pageWidth = doc.internal.pageSize.getWidth();
  
        if (elWidth > pageWidth) {
          const ratio = pageWidth / elWidth;
          elHeight = elHeight * ratio - padding;
          elWidth = elWidth * ratio - padding;
        }
  
        const pageHeight = doc.internal.pageSize.getHeight();
  
        if (top + elHeight > pageHeight) {
          doc.addPage();
          top = 20;
        }
        doc.addImage(imgData, "PNG", 310, 236, elWidth, elHeight);
        top += elHeight;
  
      }
      else if (NcrDaysRef.current === null) {
  
        doc.setFontSize(16)
        doc.text(' No Data Available', 350, 330)
      }
      setPercentage(50)
    // // 9th Page
    doc.addPage();

    await pageAlignment(doc, "", 9);

     doc.setFontSize(16); 
  doc.setFont("Source Sans Pro Black (Headings)", "bold"); 


  if (WaterfallRef.current == null) {
   
    doc.text(' No Data Available', NODATATITLE, 160, { align: "center" })
  }
  
  await horizontalBar("Waterfall Report",doc);

     if (WaterfallRef.current && WaterfallTableRef.current) {
      const imgData: any = await toPng(WaterfallRef.current)

      await createPdf(doc, WaterfallRef.current, imgData);

    }
    else {

      doc.setFontSize(12)
      doc.text(' No Data Available', 100, 150)
    }
    setPercentage(57)

   // // 10th Page
    doc.addPage();

    await pageAlignment(doc, "", 10);
    doc.setFont("Source Sans Pro Black (Headings)", "bold"); 
    if (WaterfallRef.current && WaterfallTableRef.current) {

      const columns = ['Year', 'Total Paid',];

      const rows: any = [];

      WaterfallTable.forEach((item: any) => {
        const rowData = [item.Month, item.Total_Paid.toLocaleString('en-US', options)];
        rows.push(rowData);
      });
      autoTable(doc, {
        head: [columns],
        body: rows,
        startY: 50,
        margin: {
          left: 220
        },
        theme: "grid",
        tableWidth: 210,
        styles: {
          fontSize: 10,
          lineWidth: 0.5,
          lineColor: [0, 0, 0], 
          
        },
        columnStyles: waterfallcolumnStyles(columns.length),
        headStyles: {
          halign: "center",
          fillColor: [211, 211, 211], 
          textColor: [0, 0, 0],
          fontSize: 10, 
        },
        bodyStyles: {
          textColor: [1, 2, 3], 
        },
      });

    }
    else {

      doc.setFontSize(16)
      doc.text(' No Data Available', NODATATITLE, 160, { align: "center" })
    }

    setPercentage(64)


      //11 Page

      doc.addPage()

      await pageAlignment(doc, "", 11);

      doc.setFontSize(16); 
   doc.setFont("Source Sans Pro Black (Headings)", "bold"); 

   if (RejectionRef.current == null) {
   
    doc.text(' No Data Available', NODATATITLE, 160, { align: "center" })
  }
   
   await horizontalBar("Rejection Ratio",doc);

      if (RejectionRef.current && RejectionTableRef.current) {

      const imgData: any = await toPng(RejectionRef.current)

      await createPdf(doc, RejectionRef.current, imgData);


      const columns = ['Month', 'Total Rejects', "Total Charges", "Reject %"];

      const rows: any = [];

      RejectionTable.forEach((item: any) => {
        const rowData = [item.Month_Wise ? item.Month_Wise : "None", item.Total_Rejects ? item.Total_Rejects.toLocaleString('en-US', options) : "$0", item.Total_Charges ? item.Total_Charges.toLocaleString('en-US', options) : "$0", item.Reject_Percentage ? item.Reject_Percentage +"%" : "0%"];
        rows.push(rowData);
      });
      autoTable(doc, {
        head: [columns],
        body: rows,
        startY: 170,
            margin:{
              left: 350
         },
         styles: {
          fontSize: 10,
          lineWidth: 0.5,
          lineColor: [0, 0, 0], 
          
        },
        theme: "grid",
        columnStyles: rejectionColumnStyles(columns.length),
        headStyles: {
          halign: "center",
          fillColor: [211, 211, 211], 
          textColor: [0, 0, 0],
          fontSize: 10, 
        },
        bodyStyles: {
          textColor: [1, 2, 3], 
        },
      });


    }
    
    setPercentage(72)

//12 Pgae
    doc.addPage()

      await pageAlignment(doc, "", 12);

      doc.setFontSize(16); 
   doc.setFont("Source Sans Pro Black (Headings)", "bold"); 

   if (DenialRef.current == null) {
   
  doc.text(' No Data Available', NODATATITLE, 160, { align: "center" })
}
 
   await horizontalBar("Denial Rate",doc);


   if (DenialRef.current && DenialTableRef.current) {
      // doc.text('Denial Rate Report', titleX, 50, { align: "center" })
      const imgData: any = await toPng(DenialRef.current)
      await createPdf(doc, DenialRef.current, imgData);


      const columns = ['Month', "Denial", "Total Charges", "Denial Rate"];

      const rows: any = [];

      DenialTable.forEach((item: any) => {
        const rowData = [item.Month_Wise ? item.Month_Wise : "None", item.Denial_Charges ? item.Denial_Charges.toLocaleString('en-US', options) : "$0", item.Total_Charges ? item.Total_Charges.toLocaleString('en-US', options) : "$0", item.Denial_Rate ? item.Denial_Rate +"%" : "0%"];
        rows.push(rowData);
      });
      autoTable(doc, {
        head: [columns],
        body: rows,
        startY: 170,
        margin:{
          left: 350
     },
     styles: {
      fontSize: 10,
      lineWidth: 0.5,
      lineColor: [0, 0, 0], 
      
    },
        theme: "grid",
        columnStyles: rejectionColumnStyles(columns.length),
        headStyles: {
          halign: "center",
          fillColor: [211, 211, 211], 
          textColor: [0, 0, 0],
          fontSize: 10, 
        },
        bodyStyles: {
          textColor: [1, 2, 3], 
        },
      });

    }
    
    setPercentage(78)


    //13 Page

    doc.addPage()

    await pageAlignment(doc, "", 13);

    doc.setFontSize(16); 
 doc.setFont("Source Sans Pro Black (Headings)", "bold"); 
 
 await horizontalBar("Clean Claim Rate",doc);


  if (CleanRef.current && CleanTableRef.current) {
      // doc.text('Clean Claim Report', titleX, 50, { align: "center" })
      const imgData: any = await toPng(CleanRef.current)
      await createPdf(doc, CleanRef.current, imgData);


      const columns = ['Month', "Total Charges", "Clean Claim", "Clean Claim Rate"];

      const rows: any = [];

      CleanTable.forEach((item: any) => {
        const rowData = [item.Month_Wise ? item.Month_Wise : "None", item.Total_Charges ? item.Total_Charges.toLocaleString('en-US', options) : "$0", item.Clean_Claim ? item.Clean_Claim.toLocaleString('en-US', options) : "$0", item.Clean_Claim_Rate ? item.Clean_Claim_Rate+"%" : "0%"];
        rows.push(rowData);
      });
      autoTable(doc, {
        head: [columns],
        body: rows,
        startY: 170,
        styles: {
          fontSize: 10,
          lineWidth: 0.5,
          lineColor: [0, 0, 0], 
          
        },
        margin:{
          left: 350
     },
        theme: "grid",
        columnStyles: rejectionColumnStyles(columns.length),
        headStyles: {
          halign: "center",
          fillColor: [211, 211, 211], 
          // textColor: [255, 255, 255], 
          textColor: [0, 0, 0],
          fontSize: 10, 
        },
        bodyStyles: {
          textColor: [1, 2, 3], 
        },
      });

    }
    else {

      doc.setFontSize(12)
      doc.text(' No Data Available', 220, 160, { align: "center" })
    }
    setPercentage(84)

//14 Page
    doc.addPage()

    await pageAlignment(doc, "", 14);

    doc.setFontSize(16); 
 doc.setFont("Source Sans Pro Black (Headings)", "bold"); 

  if (BenchMarkChartRef.current == null) {
    doc.setFontSize(12); 
  doc.text(' No Data Available', 220, 160, { align: "center" })
}
doc.setFontSize(16); 
 await horizontalBar("Industry Standard Benchmark",doc);

 if (BenchMarkChartRef.current && BenchMarkTableRef.current) {
      const imgData: any = await toPng(BenchMarkChartRef.current)

      await createPdf(doc, BenchMarkChartRef.current, imgData);


   }
    // else {

    //   doc.setFontSize(12)
    //   doc.text(' No Data Available', 220, 160, { align: "center" })
    // }
    setPercentage(92)
// 15 Page
    doc.addPage()

    await pageAlignment(doc, "", 15);

 if (BenchMarkChartRef.current && BenchMarkTableRef.current) {

  const columns = ['Category', "Benchmark", "DM Achieved",];

  const rows: any = [];


  BenchMarkTable.forEach((res: any) => {
    rows.push(
      [
        { content: 'AR Days', },
        { content: '35 days', },
        { content: res.ArDataDemo ? res.ArDataDemo + ' days' : 'None', },
      ],
      [
        { content: 'NCR', },
        { content: '98%', },
        { content: res.NcrData ? res.NcrData + '%' : 'None', },
      ],
      [
        { content: 'Clean Claim Rate', },
        { content: '95%', },
        { content: res.Clean_Claim_Rate ? res.Clean_Claim_Rate + '%' : 'None', },
      ],
      [
        { content: 'Denial Rate', },
        { content: '5%', },
        { content: res.Denial_Rate ? res.Denial_Rate + '%' : 'None', },
      ],
      [
        { content: 'AR 120+', },
        { content: '16%', },
        { content: res.Final120Rate ? res.Final120Rate + '%' : 'None', },
      ],
      [
        { content: 'AR 90+', },
        { content: '21%', },
        { content: res.Final90Rate ? res.Final90Rate + '%' : 'None', },
      ],
      // [
      //   { content: 'GCR', },
      //   { content: '47%', },
      //   { content: res.TotalGCR ? res.TotalGCR + '%' : 'None', },
      // ]
    );
  });

  autoTable(doc, {
    head: [columns],
    body: rows,
    startY: 50,
    tableWidth:270,
    margin:{
      left: 170
 },
    styles: {
      fontSize: 10,
      lineWidth: 0.5,
      lineColor: [0, 0, 0], 
      
    },
    theme: "grid",
    columnStyles: benchmarkcolumnStyles(columns.length),
    headStyles: {
      halign: "center",
      fillColor: [211, 211, 211], 
      textColor: [0, 0, 0],
      fontSize: 10, 
    },
    bodyStyles: {
      textColor: [1, 2, 3], 
    },
  });


   }
    else {

      doc.setFontSize(16)
      doc.text(' No Data Available', NODATATITLE, 160, { align: "center" })
    }

    setPercentage(100)

    doc.save(`${PracticeName.label}-${FromDate} Report.pdf`)
    setIsRunning(false)
    setOpen(false)
  }
  const getPracticeNameata = async () => {
    const Result = await axios.get(getPracticeName)
    setUserNameDatas(Result.data.PracticeData)
  }

  var UsersListData: any = []
  UserNameDatas.map((practiceList: any, index) => {
    UsersListData.push({
      label: practiceList.practice_name,
      value: practiceList.practice_name,
    })
  })

  var ArResult: any;
  var TotalAddressedCount: any = 0;
  var TotalAddressedAmount: any = 0;
  var TotalUnAddressedCount: any = 0;
  var TotalUnAddressedAmount: any = 0;
  var GrnadTotalClaimsLines: any = 0;
  var FianlTotalClaimsAmount: any = 0;
  var TotalSum: any = []
  if (ArDetailsTable?.length > 0) {

    ArDetailsTable.map((res: any, _inde: any) => {
      var GrandTotalClaimsAmount: any = 0;
      GrandTotalClaimsAmount += Number(res.TouchedAmount) + Number(res.UnTouchedAmount);
      TotalSum.push(Number(GrandTotalClaimsAmount))
    })

    var FianlTotalClaimsAmount = TotalSum.reduce(function (pv: any, cv: any) { return pv + cv; }, 0);
    var FinalPercentage = Number(FianlTotalClaimsAmount / FianlTotalClaimsAmount * 100).toFixed(0)
    ArResult = ArDetailsTable.map((res: any, _inde: any) => {
      var TotalClaimsLines: any = 0;
      var TotalClaimsAmount: any = 0;
      TotalAddressedCount += Number(res.TouchedCount)
      TotalAddressedAmount += Number(res.TouchedAmount)
      TotalUnAddressedCount += Number(res.UnTouchedCount)
      TotalUnAddressedAmount += Number(res.UnTouchedAmount)


      TotalClaimsLines += Number(res.TouchedCount) + Number(res.UnTouchedCount)

      TotalClaimsAmount += Number(res.TouchedAmount) + Number(res.UnTouchedAmount)

      GrnadTotalClaimsLines += TotalClaimsLines;
      if (_inde == ArDetailsTable.length - 1) {

        return (
          <>

            <tr className='text-start py-6 px-6'>
              <th className='text-center p-2 fw-normal fst-normal'>{res.Aging}</th>
              <th className='text-center p-2 fw-normal fst-normal'>{res.TouchedCount}</th>
              <th className='text-center p-2 fw-normal fst-normal'>{currencyFormatter.format(res.TouchedAmount)}</th>
              <th className='text-center p-2 fw-normal fst-normal'>{res.UnTouchedCount}</th>
              <th className='text-center p-2 fw-normal fst-normal'>{currencyFormatter.format(res.UnTouchedAmount)}</th>
              <th className='text-center p-2 fw-normal fst-normal'>{TotalClaimsLines}</th>
              <th className='text-center p-2 fw-normal fst-normal'>{currencyFormatter.format(TotalClaimsAmount)}</th>
              <th className='text-center p-2 fw-normal fst-normal'>{Number(TotalClaimsAmount / FianlTotalClaimsAmount * 100).toFixed(0)}%</th>
            </tr>
            <tr className='text-start py-6 px-6'>
              <th className='text-center p-2 fw-normal fst-normal'>Total</th>
              <th className='text-center p-2 fw-normal fst-normal'>{TotalAddressedCount}</th>
              <th className='text-center p-2 fw-normal fst-normal'>{currencyFormatter.format(TotalAddressedAmount)}</th>
              <th className='text-center p-2 fw-normal fst-normal'>{TotalUnAddressedCount}</th>
              <th className='text-center p-2 fw-normal fst-normal'>{currencyFormatter.format(TotalUnAddressedAmount)}</th>
              <th className='text-center p-2 fw-normal fst-normal'>{GrnadTotalClaimsLines}</th>
              <th className='text-center p-2 fw-normal fst-normal'>{currencyFormatter.format(FianlTotalClaimsAmount)}</th>
              <th className='text-center p-2 fw-normal fst-normal'>{FinalPercentage}%</th>
            </tr>
          </>


        )
      }
      return (

        <tr className='text-start py-6 px-6'>
          <th className='text-center p-2 fw-normal fst-normal'>{res.Aging}</th>
          <th className='text-center p-2 fw-normal fst-normal'>{res.TouchedCount}</th>
          <th className='text-center p-2 fw-normal fst-normal'>{currencyFormatter.format(res.TouchedAmount)}</th>
          <th className='text-center p-2 fw-normal fst-normal'>{res.UnTouchedCount}</th>
          <th className='text-center p-2 fw-normal fst-normal'>{currencyFormatter.format(res.UnTouchedAmount)}</th>
          <th className='text-center p-2 fw-normal fst-normal'>{TotalClaimsLines}</th>
          <th className='text-center p-2 fw-normal fst-normal'>{currencyFormatter.format(TotalClaimsAmount)}</th>
          <th className='text-center p-2 fw-normal fst-normal'>{Number(TotalClaimsAmount / FianlTotalClaimsAmount * 100).toFixed(0)}%</th>
        </tr>
      )
    })
  }

  var TotalListChart: any = []
  var TotalClaims: any = 0;
  var Totalcharges: any = 0;
  var Result: any;
  var TotalSumResult: any = GetTotalAmount;

  if (PaymentsData?.length > 0) {
    var TotalSum: any = []
    PaymentsData.map((res: any, _inde: any) => {
      var GrandTotalClaimsAmount: any = 0;
      GrandTotalClaimsAmount += Number(res.payments);
      TotalSum.push(Number(GrandTotalClaimsAmount))
    })
    var FianlTotalClaimsAmount = TotalSum.reduce(function (pv: any, cv: any) { return pv + cv; }, 0);
    Result = PaymentsData.map((res: any, _inde: any) => {

      TotalClaims += Number(res.count)

      Totalcharges += Number(res.payments)
      TotalListChart.push({ "Payer": res.insurance, "Payments": Number(res.payments).toFixed(2) })
      if (_inde == PaymentsData.length - 1) {

        return (
          <>
            <tr>
              <td >{res.insurance}</td>
              {/* <td >{res.count}</td> */}
              <td >{currencyFormatter.format(res.payments)}</td>
              <td >{Number((Number(res.payments) / Number((TotalSumResult[0].payments))) * 100).toFixed(2)}%</td>
            </tr>
            <tr>
              <td >Others</td>
              {/* <td >{TotalSumResult[0].count - TotalClaims}</td> */}
              <td >${Number(TotalSumResult[0].payments - Totalcharges).toFixed(2)}</td>
              <td >{Number(Number((Number(TotalSumResult[0].payments) - Number(Totalcharges)) / Number(TotalSumResult[0].payments) * 100)).toFixed(2)}%</td>
            </tr>

            <tr>
              <td >Grand Total</td>
              {/* <td >{TotalSumResult[0].count}</td> */}
              <td >{currencyFormatter.format(TotalSumResult[0].payments)}</td>
              <td >{Number(TotalSumResult[0].payments / TotalSumResult[0].payments) * 100}%</td>
            </tr>
          </>
        )

      }
      else {
        return (
          <tr>
            <td >{res.insurance}</td>
            {/* <td >{res.count}</td> */}
            <td >{currencyFormatter.format(res.payments)}</td>
            <td >{Number((Number(res.payments) / Number((TotalSumResult[0].payments))) * 100).toFixed(2)}%</td>
          </tr>
        )
      }

    })
  }

  var TotalArDaysListChart = []
  var LocationArDaysList: any = ["", "AR Days",{role:"annotation"}]
  TotalArDaysListChart.push(LocationArDaysList)
  var TotalBilledAmount = 0.00;
  if (GetCountByPosttedTable?.length > 0) {
    GetCountByPosttedTable.map((res: any, _inde: any) => {
      var FilterDataCountClaims: any = ArData.filter((x: any) => x.Month == res.Month)
      TotalBilledAmount += res.billed_amount;
      if (FilterDataCountClaims.length > 0) {
        var FinalCharges = FilterDataCountClaims[0].TouchedAmount / res.billed_amount

        TotalArDaysListChart.push([res.Month, parseFloat(FinalCharges.toFixed(0)),parseFloat(FinalCharges.toFixed(0)) + " days"])

      }
    })
  }



  var TotalNcrDaysListChart = []
  var LocationNcrDaysList: any = ["", "NCR",{role:"annotation"}]
  TotalNcrDaysListChart.push(LocationNcrDaysList)

  if (NcrDays?.length > 0) {
    NcrDays.map((res: any, _inde: any) => {
      var TotalCharges = Number(Number(res.total_paid) + Number(res.adjustments))
      //var FinalCharges = res.total_paid / (TotalCharges) * 100
      var GetChargesByPostDateData : any = ChargesByPostDateData.filter((x:any)=> x.month_year === res.month_year)
      //var FinalCharges = TotalCharges / GetChargesByPostDateData[0].billed_amount * 100
      var FinalCharges = (Number(res.total_paid) / (GetChargesByPostDateData[0].billed_amount - Number(res.adjustments))) * 100
      TotalNcrDaysListChart.push([res.month_year, parseFloat(FinalCharges.toFixed(0)),parseFloat(FinalCharges.toFixed(0))+"%"])

    })
  }

  // Location List
  var TotalListChartLocation: any = []

  var TotalChargesValuesLocation: any = [""]
  var TotalpaymentsValuesLocation: any = [""]
  var TotalClaimsLocation: any = 0;
  var TotalchargesLocation: any = 0;
  var TotalpaymentsLocation: any = 0;
  var ResultLocation: any;
  if (LocationData?.length > 0) {
    ResultLocation = LocationData.map((res: any, _inde: any) => {
      // LocationList.push(res.facility)
      var FilterDataCountClaims: any = LocationTable.filter((x: any) => x.facility == res.facility)
      var FilterData: any = LocationPayments.filter((x: any) => x.facility == res.facility)
      var Payments = FilterData.length > 0 ? FilterData[0].payments : 0;
      TotalClaimsLocation += Number(FilterDataCountClaims[0].count)
      TotalchargesLocation += Number(res.charges)
      TotalpaymentsLocation += Number(Payments)
      TotalChargesValuesLocation.push(Number(TotalchargesLocation))
      TotalpaymentsValuesLocation.push(Number(TotalpaymentsLocation))
      TotalListChartLocation.push({ Location: res.facility, Charges: Number(res.charges).toFixed(2), Payments: Number(Payments).toFixed(2) })
      if (_inde == LocationData.length - 1) {

        return (
          <>
            <tr>
              <td >{res.facility}</td>
              <td >{currencyFormatter.format(res.charges)}</td>
              <td >{currencyFormatter.format(Payments)}</td>
              <td >{Number(Number(Payments) / Number(res.charges) * 100).toFixed(2)}%</td>
            </tr>
            <tr>
              <td >Grand Total</td>
              <td >{currencyFormatter.format(TotalchargesLocation)}</td>
              <td >{currencyFormatter.format(TotalpaymentsLocation)}</td>
              <td >{Number(TotalpaymentsLocation / TotalchargesLocation * 100).toFixed(2)}%</td>
            </tr>
          </>
        )

      } else {
        return (
          <tr>
            <td >{res.facility}</td>
            <td >{currencyFormatter.format(res.charges)}</td>
            <td >{currencyFormatter.format(Payments)}</td>
            <td >{Number(Number(Payments) / Number(res.charges) * 100).toFixed(2)}%</td>
          </tr>
        )
      }

    })
  }

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}>
        <CircularProgress color='inherit' />
        <Typography variant="h6" component="div" color="inherit" mt={1} ml={2}>
          {`Processing...${Number(percentage)}%`}
        </Typography>
      </Backdrop>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>

      <div>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Exporting Month End Reports into PDF</h3>
          </div>
        </div>
        <hr />
        <div className='row'>
          <>
            <div className='col-sm-4 fv-row'>
              <label className='col-lg-6 col-form-label fw-bold fs-6'>
                <span className='required'>Select Month/Year</span>
              </label>
              <br />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={DateMonthFrom}
                  minDate={moment('2015')}
                  renderInput={(params: any) => <TextField {...params} />}
                  maxDate={moment()}
                  views={['year', 'month']}
                  disableFuture
                  onChange={handleDateChangeFrom}
                />
              </LocalizationProvider>
            </div>

            <div className='col-sm-4 fv-row'>
              <label className='col-lg-6 col-form-label fw-bold fs-6'>
                <span className=''>Select Practice</span>
              </label>

              <Select
                maxMenuHeight={180}
                value={SelectedPracticeList}
                onChange={getSelectedPractice}
                id='Practice'
                name={'Practice'}
                options={UsersListData}
              />
            </div>
          </>
          <div className='col-2 pt-13'>
            <button onClick={GetPDF} className='btn btn-primary'
              disabled={isRunning}
            >
              Get PDF
            </button>
          </div>
          <div className='col-1 pt-13'>
            <button
              // onClick={ClearFilter}
              className='btn btn-primary'>
              Clear
            </button>
          </div>
        </div>
        <hr />

        <div className='row'>
          <div className='col-12'>
            {PracticeSummaryChart?.length > 0 ?
              <>
                <h4 className='text-centesetHeadersr my-4'>Practice Wise Charges and Payments</h4>
                <div className="w-30" ref={PracticeSummaryRef} >
                  
 <BarChart

width={window.innerWidth > 1650 ? 800 : 500}
height={window.innerWidth > 1650 ? 500 : 400}

data={PracticeSummaryChart}
margin={{
  top: 35,
  right: 30,
  left: 30,
  bottom: 5
}}
>

<XAxis dataKey="month_year"
  tick={{
    fontSize: window.innerWidth > 1650 ? 14 : 12,
    fontWeight: '700', 
    fill: 'rgb(0, 0, 0)'
  }}
>
</XAxis>
<YAxis tickFormatter={formatDollarValueYAxis}
   tick={{
    fontSize: window.innerWidth > 1650 ? 14 : 12,
    fontWeight: '700', 
    fill: 'rgb(0, 0, 0)'
  }}
/>
<Tooltip formatter={formatDollarValue} />
<Legend  formatter={(value) => <span style={{ color: 'rgb(0, 0, 0)', fontWeight: 'bold',fontSize: window.innerWidth > 1650 ? 12 :10 }}>{value}</span>}
/>
<Bar dataKey="Charges" fill="rgb(30,144,255)" barSize={PracticeSummaryChart.length <= 3 ? 40 : 30}
>
  <LabelList
    dataKey="Charges"
    position="top"
    formatter={formatDollarValue}
    style={{ fontSize:window.innerWidth > 1650 ? 12 : 10, fontWeight: '700', fill: 'rgb(0, 0, 0)' }}


  />
</Bar>
<Bar dataKey="Payments" fill="rgb(70,130,180)" barSize={PracticeSummaryChart.length <= 3 ? 40 : 30} >
  <LabelList
    dataKey="Payments"
    position="top"
    formatter={formatDollarValue}
    style={{ fontSize: 10, fontWeight: '700', fill: 'rgb(0, 0, 0)' }}
  />
</Bar>

</BarChart>
                  
                </div>
              </>
              : null}
          </div>

          <div className='col-5 mx-2 d-flex align-items-center justify-content-center' >

            {PracticeSummaryChart?.length > 0 ?
              <div className="w-100 my-4 p-4" ref={PracticeSummaryTableRef} >
                <table id='kt_table_users' className='table-row-gray-700 table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer' >
                  <thead>
                    <tr className='text-start fw-bolder fs-7 text-uppercase gs-0'>
                      <th className='text-dark text-center p-2'>Month</th>
                      <th className='text-dark text-center p-2'>Charges</th>
                      <th className='text-dark text-center p-2'>Payments</th>
                    </tr>
                  </thead>
                  <tbody >
                    {PracticeSummaryChart.map((res: any) => {
                      return (
                        <tr className='text-dark text-end fw-bolder fs-7'>
                          <td className='px-3 py-2 text-center'>{res.month_year ? res.month_year : "None"}</td>
                          <td className='px-3 py-2 text-center'>{res.Charges ? res.Charges.toLocaleString('en-US', options) : "-"}</td>
                          <td className='px-3 py-2 text-center'>{res.Payments ? res.Payments.toLocaleString('en-US', options) : "-"}</td>

                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div> : ""}
          </div>

        </div>


        {ResultLocation ?
          <>
            <h4 className='text-center my-8'>Charges and Collections By Locations</h4>
              
            <div  className='d-flex justify-content-center'>
              <div ref={LocationChartRef}>
                <BarChart
                width={1100}
                height={500}

                data={TotalListChartLocation}
                margin={{
                  top: 20,
                  right: 30,
                  left: 40,
                  bottom: 40
                }}

              >

<XAxis
  dataKey="Location"
  angle={TotalListChartLocation?.length > 12 ? -10 : 0}
  textAnchor={TotalListChartLocation?.length > 12 ? "end" : "center"}
  tick={{ fontSize: 10, fontWeight: '700', 
  fill: 'rgb(0, 0, 0)'}}
  interval="preserveStartEnd"
  tickLine={false}
  tickFormatter={customTickFormatter}
/>
                
                <YAxis tickFormatter={formatDollarValueYAxis}
                  tick={{ fontSize: 10 , fontWeight: '700',fill: 'rgb(0, 0, 0)'}}
                  domain={[0, Math.max(...TotalListChartLocation.map((item: any) => item.Charges)) * 1.2]}

                />
                <Tooltip formatter={formatDollarValue} />
                <Legend  formatter={(value) => <span style={{ color: 'rgb(0, 0, 0)', fontWeight: 'bold',fontSize: 10 }}>{value}</span>} />
                <Bar dataKey="Charges" fill="rgb(30,144,255)" barSize={TotalListChartLocation.length <= 3 ? 40 : 30} >
                  <LabelList
                 dataKey="Charges"
                 position="top"
                 formatter={formatDollarValue}
                 style={{ fontSize: 10 ,fontWeight: '700',fill: 'rgb(0, 0, 0)' }}
                 angle={TotalListChartLocation?.length > 12 ? -90 : 0}
                 dy= {TotalListChartLocation?.length > 12 ? -15 : 0}
                 dx={TotalListChartLocation?.length > 12 ? 2 : 0}

               />
                  
                </Bar>
                <Bar dataKey="Payments" fill="rgb(70,130,180)" barSize={TotalListChartLocation.length <= 3 ? 40 : 30}  
                
                
                >
                  <LabelList
                    dataKey="Payments"
                    position="top"
                    formatter={formatDollarValue}
                    style={{ fontSize: 10 , fontWeight: '700',fill: 'rgb(0, 0, 0)'}}
                    angle={TotalListChartLocation?.length > 12 ? -90 : 0}
                    dy= {TotalListChartLocation?.length > 12 ? -15 : 0}
                    dx={TotalListChartLocation?.length > 12 ? 2 : 0}
                  />
                </Bar>

              </BarChart>
             
              </div>

            </div>

            <div ref={LocationTableRef} className={ResultLocation.length === 1 ? "w-50 my-8" : "w-100"} style={{ background: "#dbd8e3" }}>
              <table id='kt_table_users'
                className='table table-striped align-middle fs-6 p-4  font-family-base no-footer col-12 h-full '>
                <thead className='thead-dark bg-dark'>
                  <tr className='text-start text-white fw-bolder fs-7 text-uppercase gs-0'>
                    <th>Location</th>
                    <th>Charges</th>
                    <th>Payments</th>
                    <th>% of Payment's</th>
                  </tr>
                </thead>
                <tbody className='text-dark fw-bold' >
                  {ResultLocation}
                </tbody>
              </table>
            </div>
          </>
          : null
        }

        {ProviderData?.length > 0 ?
          <>
            <h4 className='text-center my-8'>Charges and Collections By Provider</h4>

            <div  className='d-flex justify-content-center'>
              <div ref={ProviderChartRef}>
            <BarChart
                width={1100}
                height={500}

                data={ProviderData}
                margin={{
                  top: 30,
                  right: 30,
                  left: 20,
                  bottom: 40
                }}
              >

                <XAxis dataKey="provider"
                tick={{ fontSize: 10,  fontWeight: '700',fill: 'rgb(0, 0, 0)' }}
                angle={ProviderData?.length > 12 ? -10 : 0}
                textAnchor={ProviderData?.length > 12 ? "end" : "center"}
                interval="preserveStartEnd"
                tickLine={false}
                tickFormatter={providerTickFormatter}
                 
                >
                </XAxis>
                <YAxis tickFormatter={formatDollarValue}
                 tick={{ fontSize: 10 , fontWeight: '700',fill: 'rgb(0, 0, 0)'}}
                  domain={[0, Math.max(...ProviderData?.map((item: any) => item.charges)) * 1.2]}
                />
                <Tooltip formatter={formatDollarValue} />
                <Legend  formatter={(value) => <span style={{ color: 'rgb(0, 0, 0)', fontWeight: 'bold',fontSize: 10 }}>{value}</span>} />
               

                <Bar dataKey="charges" fill="rgb(30,144,255)" barSize={ProviderData?.length <= 3 ? 40 : 30}
                name="Charges"
                >
                  <LabelList
                    dataKey="charges"
                    position="top"
                    formatter={formatDollarValue}
                    style={{ fontSize: 10 , fontWeight: '700',fill: 'rgb(0, 0, 0)'}}
                    angle={ProviderData?.length > 12 ? -90 : 0}
                    dy= {ProviderData?.length > 12 ? -15 : 0}
                    dx={ProviderData?.length > 12 ? 2 : 0}
                  />

                </Bar>
                <Bar dataKey="payments" fill="rgb(70,130,180)" barSize={ProviderData?.length <= 3 ? 40 : 30} name="Payments" 
                 
                >
                  <LabelList
                    dataKey="payments"
                    position="top"
                    formatter={formatDollarValue}
                    style={{ fontSize: 10 , fontWeight: '700',fill: 'rgb(0, 0, 0)'}}
                    angle={ProviderData?.length > 12 ? -90 : 0}
                    dy= {ProviderData?.length > 12 ? -15 : 0}
                    dx={ProviderData?.length > 12 ? 2 : 0}

                  />

                </Bar>

              </BarChart>
             

              </div>

            </div>

            <div className="w-100 my-4" ref={ProviderTableRef} style={{ background: "#dbd8e3" }}>
              <table
                id='kt_table_users'
                className=' w-100 table-bordered align-middle fs-6 font-family-base no-footer w-full '
              >
                <thead style={{ background: '#a6b1e1' }}>
                  <tr className='text-start  fw-bolder fs-7 text-uppercase gs-0 text-gray-800 py-6 px-6'>
                    <th className="text-center p-2">Provider</th>
                    <th className='text-center p-2'>Charges</th>
                    <th className='text-center p-2'>Payments</th>
                    <th className='text-center p-2'>% of Payment's</th>
                  </tr>
                </thead>
                <tbody style={{ background: '#e7eaf6' }}>
                  {ProviderData?.map((res: any, ind: any) => {
                    return (
                      <tr key={ind}>
                        <td className='px-3 py-2 text-center'>{res.provider ? res.provider : 'None'}</td>
                        <td className='px-3 py-2 text-center'>
                          {res.charges ? res.charges.toLocaleString('en-US', options) : '$0'}
                        </td>
                        <td className='px-3 py-2 text-center'>
                          {res.payments ? res.payments.toLocaleString('en-US', options) : '$0'}
                        </td>
                        <td className='px-3 py-2 text-center'>
                          {!res.payments || !res.charges ? '0' : (Number(res.payments) / Number(res.charges) * 100).toFixed(2)}%
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
                <thead style={{ background: '#e7eaf6' }}>
                  <tr>
                    <th className="text-center p-2">Grand Total</th>
                    <th className="text-center p-2">{ProviderTotalCharges?.toLocaleString('en-US', options)}</th>
                    <th className="text-center p-2">{ProviderTotalPayments?.toLocaleString('en-US', options)}</th>
                    <th className="text-center p-2">{!ProviderTotalPayments || !ProviderTotalCharges ? '0' : (Number(ProviderTotalPayments) / Number(ProviderTotalCharges) * 100).toFixed(2)}%</th>

                  </tr>
                </thead>
              </table>
            </div>
          </>
          : null
        }


        {Result ?
          <>
            <h4 className='text-center my-8'>Payments Breakdown By Payers Report</h4>
           
 <div  className='d-flex justify-content-center'>
              <div ref={PayerChartRef}>
<BarChart
                width={1100}
                height={500}

                data={TotalListChart}
                margin={{
                  top: 30,
                  right: 20,
                  left: 40,
                  bottom: 20
                }}
              >
                {/* <CartesianGrid strokeDasharray={null} /> */}
                <XAxis dataKey="Payer"
                  // angle={-20}
                  // textAnchor="end"
                  // height={20}
                  tick={{ fontSize: 10,  fontWeight: '700',fill: 'rgb(0, 0, 0)' }}
                  interval="preserveStartEnd"
                  tickLine={false}
                  tickFormatter={customTickFormatter}
                >
                </XAxis>
                <YAxis tickFormatter={formatDollarValue}
                  tick={{ fontSize: 10,fontWeight: '700',fill: 'rgb(0, 0, 0)' }}
                  domain={[Math.min(...TotalListChart.map((item: any) => item.Payments)), Math.max(...TotalListChart.map((item: any) => item.Payments))*1.2]}

                />
                <Tooltip formatter={formatDollarValue} />
                <Legend  formatter={(value) => <span style={{ color: 'rgb(0, 0, 0)', fontWeight: 'bold',fontSize: 10 }}>{value}</span>} />
                <Bar dataKey="Payments" fill="rgb(30,144,255)"

                  barSize={TotalListChart.length <= 3 ? 40 : 30}
                  >
                  <LabelList
                    dataKey="Payments"
                    position="top"
                    formatter={formatDollarValue}
                    style={{ fontSize: 10 ,fontWeight: '700',fill: 'rgb(0, 0, 0)'}}
                  />
                </Bar>

              </BarChart>

            </div>
             
            </div>

            <div className='w-30 my-4' ref={PayerTableRef} style={{ background: "#dbd8e3" }}>
              <table id='kt_table_users'
                className='table table-striped align-middle fs-6 p-4  font-family-base no-footer col-12 h-full '>
                <thead className='thead-dark bg-dark'>
                  <tr className='text-start fw-bolder fs-7 text-uppercase gs-0 text-white'>
                    <th className='text-white'>Payers</th>

                    <th>Payments</th>
                    <th>% of Payment's</th>
                  </tr>
                </thead>
                <tbody className='text-dark fw-bold' >
                  {Result}
                </tbody>
              </table>
            </div>
          </> : ""}

        {ArDetailsTable?.length > 0 ? (
          <>
            <h4 className='text-center'>AR-Details Report</h4>
            <div className='w-30 shadow rounded-lg mx-4 my-4' ref={ArDetailsTableRef}>

              <table
                id='kt_table_users'
                className=' w-100 table-bordered align-middle fs-7 p-4 no-footer w-full fw-normal fst-normal'
              >
                <thead >
                  <tr className='text-start text-uppercase  py-6 px-6'>
                    <th className='text-center p-2 fw-normal fst-normal' colSpan={8} >Addressed and Unaddressed Details By Age and Dollar</th>
                  </tr>
                  <tr className='text-start text-uppercase  py-6 px-6 fw-normal fst-normal' >
                    <th className='text-center p-2 fw-normal fst-normal'  >Aging</th>
                    <th className='text-center p-2 fw-normal fst-normal' colSpan={2}  >Addressed AR</th>
                    <th className='text-center p-2 fw-normal fst-normal' colSpan={2} >UnAddressed AR</th>
                    <th className='text-center p-2 fw-normal fst-normal' colSpan={2} >Total</th>
                    {/* <th className='text-center p-2' ></th> */}
                    <th className='text-center p-2 fw-normal fst-normal'  >%</th>
                  </tr>


                  <tr className='text-start py-6 px-6'>
                    <th className='text-center p-2 fw-normal fst-normal'  ></th>
                    <th className='text-center p-2 fw-normal fst-normal'  >#</th>
                    <th className='text-center p-2 fw-normal fst-normal'  >$</th>
                    <th className='text-center p-2 fw-normal fst-normal'  >#</th>
                    <th className='text-center p-2 fw-normal fst-normal'  >$</th>
                    <th className='text-center p-2 fw-normal fst-normal'  >#</th>
                    <th className='text-center p-2 fw-normal fst-normal'  >$</th>
                    <th className='text-center p-2 fw-normal fst-normal' colSpan={2} ></th>
                  </tr>

                </thead>
                <tbody className="fw-normal fst-normal">
                  {ArResult}
                </tbody>

              </table>

            </div>
          </>

        ) : null}

        {ArData?.length > 0 ?
          <>
            <h4 className='text-center my-4'>AR-Days Report</h4>

            <div className='w-auto h-auto bg-transparent' ref={ArDaysRef} >
  <Chart
    chartType="ColumnChart"
    data={TotalArDaysListChart}
    options={{
      colors: ['rgb(30,144,255)'],
      hAxis: {
       
        textStyle: {
          fontSize: window.innerWidth > 1650 ? 16 : 14,
          fontStyle:"bold",
          fontWeight:800,
          color:"black"
        
        },
      },
      legend: 'none',
      vAxis: {
        gridlines: {
          color: 'transparent',
        },
        textStyle: {
          fontSize: window.innerWidth > 1650 ? 16 : 14,
          fontStyle:"bold",
          fontWeight:700,
          color:"black"
        
        },
        minValue: 0, 
        maxValue:100,
        format: 'short',
        viewWindow: {
          min: 0,
        },
      },
      annotations: {
        textStyle: {
          fontSize: window.innerWidth > 1650 ? 16 : 14,
          fontStyle:"bold",
          fontWeight:700,
          color:"black"
        },
        stem: {
          color: 'transparent',
          length: 2,
        },
        alwaysOutside: true,}
    }}
    width={window.innerWidth > 1650 ? "70%" : "50%"} height={ window.innerWidth > 1650 ? "350px" : "250px"}
  />
</div>



          </> : null}

        {NcrDays?.length > 0 ?
          <>
            <h4 className='text-center my-4'>NCR Report</h4>
            <div className='w-auto h-auto' ref={NcrDaysRef} >

              <Chart chartType="ColumnChart" data={TotalNcrDaysListChart} options={{

                hAxis: {
       
                  textStyle: {
                    fontSize: window.innerWidth > 1650 ? 16 : 14,
                    fontStyle:"bold",
                    fontWeight:800,
                    color:"black"
                  
                  },
                },
                legend: 'none',
                colors: ['rgb(30,144,255)'],
                vAxis: {
                  gridlines: {
                    color: 'transparent',
                  },
                  minValue: 0, 
                  maxValue:100,
                  format: 'short', 
                  viewWindow: {
                    min: 0,
                  },
                  textStyle: {
                    fontSize: window.innerWidth > 1650 ? 16 : 14, 
                    fontStyle:"bold",
                    fontWeight:800,
                    color:"black"
                  
                  },
                },
               
                annotations: {
                  textStyle: {
                    fontSize: window.innerWidth > 1650 ? 16 : 14, 
                    fontStyle:"bolder",
                    color:"black"
                  },
                  stem: {
                    color: 'transparent',
                    length: 2,
                  },
                  alwaysOutside: true,}
              
              }} width={window.innerWidth > 1650 ? "70%" : "50%"} height={ window.innerWidth > 1650 ? "350px" : "250px"} />
            </div> </> : null}

        {NcrData?.length > 0 ?
          <>
            <h4 className='text-center my-4'>Waterfall Collection Report</h4>
         
               <div  className='d-flex justify-content-center'>
              <div ref={WaterfallRef}>

            <BarChart
                width={1100}
                height={500}
                data={NcrData}
                margin={{
                  top: 10,
                  right: 30,
                  left: 20,
                  bottom: 5
                }}
              >

                <XAxis dataKey="Month"
                 tick={{ fontSize: window.innerWidth > 1650 ? 14 : 12 , fontWeight: '700',fill: 'rgb(0, 0, 0)'}}
                />
                <YAxis tickFormatter={formatDollarValue}
                 tick={{ fontSize: window.innerWidth > 1650 ? 14 : 12 , fontWeight: '700',fill: 'rgb(0, 0, 0)'}}
                />
                <Tooltip />
                <Legend  formatter={(value) => <span style={{ color: 'rgb(0, 0, 0)', fontWeight: 'bold',fontSize: window.innerWidth > 1650 ? 14 : 12 }}>{value}</span>}/>
                <ReferenceLine y={0} stroke="#000" />

                <Bar dataKey="Total_Paid" fill="rgb(30,144,255)" name="Total Paid">
                  <LabelList
                    dataKey="Total_Paid"
                    position="top"
                    formatter={formatDollarValue}
                    style={{ fontSize: window.innerWidth > 1650 ? 14 : 12 ,fontWeight: '700',fill: 'rgb(0, 0, 0)' }}
                  />

                </Bar>

              </BarChart>
              </div>
            </div> </> : null}


        {WaterfallTable?.length > 0 ? (
          <div className='w-50 shadow rounded-lg mx-4  mt-6 mb-6' ref={WaterfallTableRef} style={{ background: "#dbd8e3" }}>

          
            <table id='kt_table_users'
              className='table table-striped align-middle fs-6 p-4  font-family-base no-footer col-12 h-full '>
              <thead className='thead-dark bg-dark'>
                <tr>
                  <th className='text-center  text-white p-2'>Year</th>
                  <th className='text-center text-white p-2'>Total Paid</th>

                </tr>
              </thead>
              <tbody>
                {WaterfallTable.map((res: any, ind: any) => {
                  return (
                    <tr className='text-start  fw-bolder fs-7 gs-0 text-gray-800 py-6 px-6'>
                      <td className='px-3 py-2 text-center'>{res.Month ? res.Month : "None"}</td>
                      <td className='px-3 py-2 text-center'>{res.Total_Paid ? res.Total_Paid.toLocaleString('en-US', options) : "$0"}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>

          </div>
        ) : null}


        {RejectionChart?.length > 0 && RejectionTable?.length > 0 ? (
          <>
            <h4 className='text-center my-4'>Rejection Rate Report</h4>
            {/* <div className='d-flex'> */}

            <div className='w-30' ref={RejectionRef} >
              <BarChart

                width={ window.innerWidth > 1650 ? 800 : 500}
                height={ window.innerWidth > 1650 ? 500 : 400}

                data={RejectionChart}
                margin={{
                  top: 35,
                  right: 30,
                  left: 30,
                  bottom: 5
                }}
              >


                <XAxis dataKey="month"
                  tick={{ fontSize: window.innerWidth > 1650 ? 14 : 10,  fontWeight: '700',fill: 'rgb(0, 0, 0)' }}
                >
                </XAxis>
                <YAxis  tick={{ fontSize: window.innerWidth > 1650 ? 14 : 10,  fontWeight: '700',fill: 'rgb(0, 0, 0)' }}/>
                <Legend 
                 formatter={(value) => <span style={{ color: 'rgb(0, 0, 0)', fontWeight: 'bold',fontSize: window.innerWidth > 1650 ? 14 : 10,  }}>{value}</span>}
                />
                <Bar dataKey="Rejection_Rate" fill="rgb(30,144,255)" barSize={RejectionChart.length <= 3 ? 40 : 30}
                 name ="Rejection Rate"
                >
                  <LabelList
                    dataKey="Rejection_Rate"
                    position="top"
                    formatter={(value: any) => `${value}%`}
                    style={{ fontSize: window.innerWidth > 1650 ? 14 : 10, fontWeight: '700',fill: 'rgb(0, 0, 0)'}}

                  />
                </Bar>
              </BarChart>


            </div>
            {/* </>
            : null } */}

            {/* {RejectionTable.length > 0 ? ( */}
            <div className='w-50 shadow rounded-lg mx-4 d-flex align-items-center' ref={RejectionTableRef}  >

              <table id='kt_table_users'
                className='table table-striped align-middle fs-6 p-4  font-family-base no-footer col-12 h-full ' style={{ background: "#008c91" }}>
                <thead className='thead-dark bg-dark'>
                  <tr className='text-white'>
                    <th className='text-center p-2'>Month</th>
                    <th className='text-center p-2'>Total Rejects</th>
                    <th className='text-center p-2'>Total Charges</th>
                    <th className='text-center p-2'>Reject %</th>
                  </tr>
                </thead>
                <tbody>
                  {RejectionTable.map((res: any, ind: any) => {
                    return (
                      <tr className='text-start  fw-bolder fs-7  gs-0 text-gray-800 py-6 px-6'>
                        <td className='px-3 py-2 text-center'>{res.Month_Wise ? res.Month_Wise : "None"}</td>
                        <td className='px-3 py-2 text-center'>{res.Total_Rejects ? res.Total_Rejects.toLocaleString('en-US', options) : "None"}</td>
                        <td className='px-3 py-2 text-center'>{res.Total_Charges ? res.Total_Charges.toLocaleString('en-US', options) : "None"}</td>
                        <td className='px-3 py-2 text-center'>{res.Reject_Percentage ? res.Reject_Percentage : "-"}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>

            </div>
            {/* </div> */}
          </>
        ) : null}

        {CleanChart?.length > 0 && CleanTable?.length > 0 ? (
          <>
            <h4 className='text-center my-4'>Clean Claim Rate Report</h4>
            {/* <div className='d-flex'> */}
            <div className='w-30' ref={CleanRef}>

              <BarChart

width={ window.innerWidth > 1650 ? 800 : 500}
height={ window.innerWidth > 1650 ? 500 : 400}

                data={CleanChart}
                margin={{
                  top: 35,
                  right: 30,
                  left: 30,
                  bottom: 5
                }}
              >


              <XAxis dataKey="month"
                  tick={{ fontSize: window.innerWidth > 1650 ? 14 : 10,  fontWeight: '700',fill: 'rgb(0, 0, 0)' }}
                >
                </XAxis>
                <YAxis  tick={{ fontSize: window.innerWidth > 1650 ? 14 : 10,  fontWeight: '700',fill: 'rgb(0, 0, 0)' }}/>
                <Legend 
                 formatter={(value) => <span style={{ color: 'rgb(0, 0, 0)', fontWeight: 'bold',fontSize: window.innerWidth > 1650 ? 14 : 10,  }}>{value}</span>}
                />
                <Bar dataKey="Clean_Claim_Rate" fill="rgb(30,144,255)" barSize={RejectionChart.length <= 3 ? 40 : 30}
 name ="Clean Claim Rate"
                >
                  <LabelList
                    dataKey="Clean_Claim_Rate"
                    position="top"
                    formatter={(value: any) => `${value}%`}
                    style={{ fontSize: window.innerWidth > 1650 ? 14 : 10, fontWeight: '700',fill: 'rgb(0, 0, 0)'}}

                  />
                </Bar>
              </BarChart>

            </div>
            {/* : null } */}

            {/* {CleanTable.length > 0 ? ( */}
            <div className='w-50 shadow rounded-lg mx-4 d-flex align-items-center' ref={CleanTableRef}>
              <table id='kt_table_users'
                className='table table-striped align-middle fs-6 p-4  font-family-base no-footer col-12 h-full ' style={{ background: "#84596b" }}>
                <thead className='thead-dark bg-dark'>
                  <tr className='text-white'>
                    <th className='text-center p-2'>Month</th>
                    <th className='text-center p-2'>Total Charges</th>
                    <th className='text-center p-2'>Clean Claim</th>
                    <th className='text-center p-2'>Clean Claim Rate</th>
                  </tr>
                </thead>
                <tbody>
                  {CleanTable.map((res: any, ind: any) => {
                    return (
                      <tr className='text-start  fw-bolder fs-7 gs-0 text-gray-800 py-6 px-6'>
                        <td className='px-3 py-2 text-center'>{res.Month_Wise ? res.Month_Wise : "None"}</td>
                        <td className='px-3 py-2 text-center'>{res.Total_Charges ? res.Total_Charges.toLocaleString('en-US', options) : "None"}</td>
                        <td className='px-3 py-2 text-center'>{res.Clean_Claim ? res.Clean_Claim.toLocaleString('en-US', options) : "None"}</td>
                        <td className='px-3 py-2 text-center'>{res.Clean_Claim_Rate ? res.Clean_Claim_Rate : "-"}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>

            </div>
            {/* </div> */}
          </>
        ) : null}

        {DenialChart?.length > 0 && DenialTable?.length > 0 ? (
          <>
            <h4 className='text-center my-4'>Denial Rate Report</h4>
            {/* <div className='d-flex'> */}
            <div className='w-30' ref={DenialRef}>


              <BarChart

width={ window.innerWidth > 1650 ? 800 : 500}
height={ window.innerWidth > 1650 ? 500 : 400}

                data={DenialChart}
                margin={{
                  top: 35,
                  right: 30,
                  left: 30,
                  bottom: 5
                }}
              >

              <XAxis dataKey="month"
                  tick={{ fontSize: window.innerWidth > 1650 ? 14 : 10,  fontWeight: '700',fill: 'rgb(0, 0, 0)' }}
                >
                </XAxis>
                <YAxis  tick={{ fontSize: window.innerWidth > 1650 ? 14 : 10,  fontWeight: '700',fill: 'rgb(0, 0, 0)' }}/>
                <Legend 
                 formatter={(value) => <span style={{ color: 'rgb(0, 0, 0)', fontWeight: 'bold',fontSize: window.innerWidth > 1650 ? 14 : 10,  }}>{value}</span>}
                />
                <Bar dataKey="Denial_Rate" fill="rgb(30,144,255)" barSize={RejectionChart.length <= 3 ? 40 : 30}
                    name ="Denial Rate" 
                >
                  <LabelList
                    dataKey="Denial_Rate"
                    position="top"
                    formatter={(value: any) => `${value}%`}
                    style={{ fontSize: window.innerWidth > 1650 ? 14 : 10, fontWeight: '700',fill: 'rgb(0, 0, 0)'}}

                  />
                </Bar>
              </BarChart>
            </div>
            {/* : null } */}

            {/* {DenialTable.length > 0 ? ( */}
            <div className='w-50 shadow rounded-lg mx-4 d-flex align-items-center' ref={DenialTableRef}>

              <table id='kt_table_users'
                className='table table-striped align-middle fs-6 p-4  font-family-base no-footer col-12 h-full ' style={{ background: "#ba9d45" }}>
                <thead className='thead-dark bg-dark'>
                  <tr className='text-white'>
                    <th className='text-center p-2'>Month</th>
                    <th className='text-center p-2'>Denial</th>
                    <th className='text-center p-2'>Total Charges</th>
                    <th className='text-center p-2'>Denial Rate</th>
                  </tr>
                </thead>
                <tbody>
                  {DenialTable.map((res: any, ind: any) => {
                    return (
                      <tr className='text-start  fw-bolder fs-7  gs-0 text-gray-800 py-6 px-6'>
                        <td className='px-3 py-2 text-center'>{res.Month_Wise ? res.Month_Wise : "None"}</td>
                        <td className='px-3 py-2 text-center'>{res.Denial_Charges ? res.Denial_Charges.toLocaleString('en-US', options) : "$0"}</td>
                        <td className='px-3 py-2 text-center'>{res.Total_Charges ? res.Total_Charges.toLocaleString('en-US', options) : "$0"}</td>
                        <td className='px-3 py-2 text-center'>{res.Denial_Rate ? res.Denial_Rate : "0"}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>

            </div>
            {/* </div> */}
          </>
        ) : null}


        {BenchMarkChart?.length > 0 && BenchMarkTable?.length > 0 ?
          <>
         
            <h4 className='text-center my-4'>BenchMark Report</h4>
            <div style={{ width: '100%', height: '400px' }} ref={BenchMarkChartRef} >

              <Chart chartType="ColumnChart" data={BenchMarkChart} options={{
                
                // title: 'Benchmarking Against Industry Standards',
                hAxis: {
                  gridlines: {
                    color: 'transparent',
                  },
                  textStyle: {
                    fontSize: window.innerWidth > 1650 ? 16 : 12
                  
                  },
                },
                series: {
                  1:{color:"#4682B4"}
                },
                vAxis: {
                  gridlines: {
                    color: 'transparent',
                  },
                },
                legend: {
                  position: 'bottom',
                  alignment: 'center',
                  textStyle: {
                    fontSize: window.innerWidth > 1650 ? 16 : 12, 
                  },
                },
                annotations: {
                  textStyle: {
                    fontSize: window.innerWidth > 1650 ? 16 : 12,
                    fontStyle:"bolder",
                    color:"black"
                  },
                  stem: {
                    color: 'transparent',
                    length: 2,
                  },
                  alwaysOutside: true,
                },
               
               
               
              }} width={"100%"} height={"400px"} />


           </div>
            <div ref={BenchMarkTableRef} className='w-50  my-4 d-flex justify-content-center' style={{ background: "#dbd8e3" }}>

              <table id='kt_table_users'
                className='table table-striped align-middle fs-6 p-4  font-family-base no-footer col-12 h-full '>
                <thead className='thead-dark bg-dark'>
                  <tr>
                    <th className='text-center p-2 text-white' scope='col'>Category</th>
                    <th className='text-center p-2 text-white'>BenchMark</th>
                    <th className='text-center p-2 text-white'>DM Achieved</th>

                  </tr>
                </thead>
                <tbody>
                  {BenchMarkTable.map((res: any, ind: any) => {
                    return (
                      <>
                        <tr className='text-start  fw-bolder fs-7  gs-0 text-gray-800 py-6 px-6'>
                          <th className='text-center p-2'>AR Days</th>
                          <td className='px-3 py-2 text-center'>35 days</td>
                          <td className='px-3 py-2 text-center'>{res.ArDataDemo ? res.ArDataDemo + " " + "days" : "None"}</td>
                        </tr>
                        <tr className='text-start  fw-bolder fs-7  gs-0 text-gray-800 py-6 px-6'>
                          <th className='text-center p-2'>NCR</th>
                          <td className='px-3 py-2 text-center'>98%</td>
                          <td className='px-3 py-2 text-center'>{res.NcrData ? res.NcrData + " " + "%" : "None"}</td>
                        </tr>
                        <tr className='text-start  fw-bolder fs-7  gs-0 text-gray-800 py-6 px-6'>
                          <th className='text-center p-2'>Clean Claim Rate</th>
                          <td className='px-3 py-2 text-center'>95%</td>
                          <td className='px-3 py-2 text-center'>{res.Clean_Claim_Rate ? res.Clean_Claim_Rate + " " + "%" : "None"}</td>
                        </tr>
                        <tr className='text-start  fw-bolder fs-7  gs-0 text-gray-800 py-6 px-6'>
                          <th className='text-center p-2'>Denial Rate</th>
                          <td className='px-3 py-2 text-center'>5%</td>
                          <td className='px-3 py-2 text-center'>{res.Denial_Rate ? res.Denial_Rate + " " + "%" : "None"}</td>
                        </tr>
                        <tr className='text-start  fw-bolder fs-7  gs-0 text-gray-800 py-6 px-6'>
                          <th className='text-center p-2'>AR 120+</th>
                          <td className='px-3 py-2 text-center'>16%</td>
                          <td className='px-3 py-2 text-center'>{res.Final120Rate ? res.Final120Rate + " " + "%" : "None"}</td>
                        </tr>
                        <tr className='text-start  fw-bolder fs-7  gs-0 text-gray-800 py-6 px-6'>
                          <th className='text-center p-2'>AR 90+</th>
                          <td className='px-3 py-2 text-center'>21%</td>
                          <td className='px-3 py-2 text-center'>{res.Final90Rate ? res.Final90Rate + " " + "%" : "None"}</td>
                        </tr>
                        {/* <tr className='text-start  fw-bolder fs-7  gs-0 text-gray-800 py-6 px-6'>
                          <th className='text-center p-2'>GCR</th>
                          <td className='px-3 py-2 text-center'>47%</td>
                          <td className='px-3 py-2 text-center'>{res.TotalGCR ? res.TotalGCR + " " + "%" : "None"}</td>
                        </tr> */}

                      </>
                    )
                  })}
                </tbody>
              </table>

            </div>


          </> : null}

      </div>
    </>
  )
}
export { ExportPDF }
