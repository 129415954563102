import React, { FC, useState, useEffect } from 'react'
import axios from 'axios'
import Select from 'react-select';
import moment from "moment";
import { toast } from 'react-toastify';
import ToastOption from './Common/ToastOption'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { GridFilterModel, GridSortModel } from '@mui/x-data-grid-pro';
import TextField from '@mui/material/TextField';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth } from '../../modules/auth'
const getPracticeList = `/api/practice/getPracticeListById`
const getPmList = `/api/pmsystem/getPmList`
const MonthendReoprt = `/api/PracticeSummary/MonthendReoprt`




const PowerBI: FC<any> = ({ user, isUserLoading }) => {

  const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
    items: [],
  });
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'updatedAt',
      sort: 'desc',
    },
  ]);
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    pageSize: {
      page: 0,
      pageSize: 20
    }
  })
  const [open, setOpen] = React.useState(false);
  const [WorkingDaysLoading, setWorkingDays] = useState(false)
  const [SelectedPmSystem, setSelectedPmSystem] = useState({ label: "", value: "" })
  const [SelectedPractice, setSelectedPractice] = useState({ label: "", value: "" })
  const [PracticeListData, setPracticeListData] = useState([])
  const { currentUser }: any = useAuth()
  const [DateMonthTo, setDateMonthTo] = useState("")
  const [getPmListData, setdatass] = useState([{ PmName: "", _id: "" }])
  const [DateMonthFrom, setDateMonthFrom] = useState("")
  const [FromDate, setFromDate] = useState("")
  const [ToDate, setToDate] = useState("")
  const [ReportNameList, setReportNameList] = useState([
    {label:"Charges By Posted Date",value:"Charges By Posted Date"},
    {label:"Denial Report",value:"Denial Report"},
    {label:"Payments by Posted Date",value:"Payments by Posted Date"},
    {label:"Adjustments by Posted Date",value:"Adjustments by Posted Date"},
    {label:"All Transaction Report",value:"All Transaction Report"},
    {label:"Patient Aging",value:"Patient Aging"},
    {label:"AR Insurance Aging Report",value:"AR Insurance Aging Report"},
    {label:"Claim Submission",value:"Claim Submission"},
    {label:"Reffering Doctor",value:"Reffering Doctor"},
    {label:"Month-wise Practice Details",value:"Month-wise Practice Details"},
    {label:"Payer Mix",value:"Payer Mix"},
    {label:"Provider Productivity",value:"Provider Productivity"},
    {label:"AR Compilation Report",value:"AR Compilation Report"},
    {label:"Practice Overview",value:"Practice Overview"},
    {label:"Profit & Loss Report",value:"Profit & Loss Report"},
    {label:"Reimbursement Audit",value:"Reimbursement Audit"},
    {label:"MGMA Report",value:"MGMA Report"},
  ])



  const [SelectedReportName, setSelectedReportName] = useState({ label: "", value: "" })

  const [SelectedProviderName, setSelectedProviderName] = useState({ value: "", label: "" })
  const GetPmList = async () => {
    const getPmListData = await axios.get(getPmList)
    if (getPmListData.data) {
      setdatass(getPmListData.data.data)
    }
  }
  const [PracticeList, setUserNameDatas] = useState([{ value: "", label: "" }])
  const getPowerBiPracticeName = `/api/PracticeSummary/getPowerBiPracticeName`
  const getPracticeNameata = async () => {
    const Result = await axios.get(getPowerBiPracticeName)
    console.log("currentUser.EmailId",currentUser.EmailId)
    if(currentUser.EmailId == "dkumar@scioms.com"){
      var Data : any = Result.data.PracticeData.filter((x :any) => x.label == "Mid Florida")
      setUserNameDatas(Data)
    }else {
      setUserNameDatas(Result.data.PracticeData)
    }
  }
  useEffect(() => {
    getPracticeNameata()
  }, [])
  
  const getReportNameColumns = (event: any) => {
    setSelectedReportName(event)

  }
  useEffect(() => {
    GetPmList()
  }, [])

  const handleDateChangeFrom = (date: any) => {
    setDateMonthFrom(date)
    setFromDate(moment(date['$d']).format("MMM-YYYY"))
  };

  const getPMSystem = (event: any) => {
    //setSelectedPractice([])
    setSelectedPmSystem(event)
    if (event.value) {
      axios.post(getPracticeList, { PostData: event.value }).then((res) => {
        setPracticeListData(res.data.data)
      })
    }
  };

  const getPractice = (event: any) => {
    if (Object.keys(event).length > 0) {
      setSelectedPractice(event)
      axios.post(`/api/PracticeSummary/GetSelectedReportNameList`, { 
        PracticeName:  event.value,
      }).then((res) => {
        if(res.data.Data[0].bi_report_names){
          setReportNameList(JSON.parse(res.data.Data[0].bi_report_names))
        }else {
          setReportNameList([])
        }
      })
    } else {
      setSelectedPractice({ label: "", value: "" })
    }
  }
  const handleDateChangeTo = (date: any) => {
    setDateMonthTo(date)
    setToDate(moment(date['$d']).format("MMM-YYYY"))
  };
  const handleExport = async (_e: any) => {
    _e.preventDefault();
    let error = false
    let ErrorArray = []
   
    if (!SelectedReportName.label) {
      error = true
      ErrorArray.push(`Please Select Report`)
    }
    if (!SelectedPractice.label) {
      error = true
      ErrorArray.push(`Please Select PM System`)
    }
    if (error) {
      ErrorArray.map((res, ind) => {
        toast.error(`${res}`, ToastOption);
      })
      return false;
    }
    if(SelectedReportName.label == "Charges By Posted Date"){
      window.open(`https://app.powerbi.com/groups/4953d30e-1e6d-4c2d-a091-688634ee5004/reports/6ffa6d37-88ea-4d81-94c3-463b0e8fbb74/ReportSection?experience=power-bi&filter=PM_Health_x0020_charges_by_posted_date%2Fpractice_id%20eq%20${SelectedPractice.value}`,"_blank")
    }
    if(SelectedReportName.label == "Denial Report"){
      window.open(`https://app.powerbi.com/groups/4953d30e-1e6d-4c2d-a091-688634ee5004/reports/535dfde7-1326-4c51-a9c8-a7ecebcb5f87/ReportSection?experience=power-bi&filter=PM_Health_x0020_denial_report%2Fpractice_id%20eq%20${SelectedPractice.value}`,"_blank")
    }
    if(SelectedReportName.label == "Payments by Posted Date"){
      window.open(`https://app.powerbi.com/groups/4953d30e-1e6d-4c2d-a091-688634ee5004/reports/ee8f112f-a95b-4271-ab42-f9ce75818ec2/ReportSection?experience=power-bi&filter=PM_Health_x0020_payment_adj%2Fpractice_id%20eq%20${SelectedPractice.value}`,"_blank")
    }
    if(SelectedReportName.label == "Adjustments by Posted Date"){
      window.open(`https://app.powerbi.com/groups/4953d30e-1e6d-4c2d-a091-688634ee5004/reports/9a193797-3438-4e89-9094-d73cee1a9fae/ReportSection?experience=power-bi &filter=PM_Health_x0020_payment_adj%2Fpractice_id%20eq%20${SelectedPractice.value}`,"_blank")
    }
    if(SelectedReportName.label == "All Transaction Report"){
      window.open(`https://app.powerbi.com/groups/4953d30e-1e6d-4c2d-a091-688634ee5004/reports/893f1a95-c736-4492-b159-31f44062d357/ReportSection?experience=power-bi&filter=PM_Health_x0020_all_transaction_report%2Fpractice_id%20eq%20${SelectedPractice.value}`,"_blank")
    }
    if(SelectedReportName.label == "Patient Aging Report"){
      window.open(`https://app.powerbi.com/groups/4953d30e-1e6d-4c2d-a091-688634ee5004/reports/05490792-5d14-4169-ab5b-70e88725f54a?experience=power-bi&filter=PM_Health_x0020_patient_aging_report%2Fpractice_id%20eq%20${SelectedPractice.value}`,"_blank")
    }
    if(SelectedReportName.label == "Patient Aging"){
      window.open(`https://app.powerbi.com/groups/4953d30e-1e6d-4c2d-a091-688634ee5004/reports/de1f97eb-39b9-49f6-b246-5b8f84b22a36/ReportSection88110e27dd27d2d009c0?experience=power-bi&filter=PM_Health_x0020_patient_aging_report%2Fpractice_id%20eq%20${SelectedPractice.value}`,"_blank")
    }
    if(SelectedReportName.label == "AR Insurance Aging Report"){
      window.open(`https://app.powerbi.com/groups/4953d30e-1e6d-4c2d-a091-688634ee5004/reports/be55fa63-a32b-491b-9cc9-38db373917dd/ReportSection?experience=power-bi&filter=PM_Health_x0020_insurance_aging_report%2Fpractice_id%20eq%20${SelectedPractice.value}`,"_blank")
    }
    if(SelectedReportName.label == "Claim Submission"){
      window.open(`https://app.powerbi.com/groups/4953d30e-1e6d-4c2d-a091-688634ee5004/reports/de309581-f578-41de-9df4-f4fdf2a8bfd1/ReportSection?experience=power-bi&filter=PM_Health_x0020_claim_submission_report%2Fpractice_id%20eq%20${SelectedPractice.value}`,"_blank")
    }
    if(SelectedReportName.label == "Reffering Doctor"){
      window.open(`https://app.powerbi.com/groups/4953d30e-1e6d-4c2d-a091-688634ee5004/reports/71bc255e-8442-463a-a902-725160c8cbe9/ReportSection?experience=power-bi&filter=PM_Health_x0020_reffering_dr%2Fpractice_id%20eq%20${SelectedPractice.value}`,"_blank")
    }
    if(SelectedReportName.label == "Month-wise Practice Details"){
      window.open(`https://app.powerbi.com/groups/4953d30e-1e6d-4c2d-a091-688634ee5004/reports/0f8b52ab-be1c-490f-ba23-8ae3e5ec6887/ReportSection?experience=power-bi&filter=PM_Health_x0020_monthwise_practice_details_powerbi%2Fpractice_id%20eq%20${SelectedPractice.value}`,"_blank")
    }
    if(SelectedReportName.label == "Payer Mix"){
      window.open(`https://app.powerbi.com/groups/4953d30e-1e6d-4c2d-a091-688634ee5004/reports/8dc2283d-c065-4373-9d60-9623137a1318/ReportSection?experience=power-bi&filter=PM_Health_x0020_payer_mix_report_powerbi%2Fpractice_id%20eq%20${SelectedPractice.value}`,"_blank")
    }
    if(SelectedReportName.label == "Provider Productivity"){
      window.open(`https://app.powerbi.com/groups/4953d30e-1e6d-4c2d-a091-688634ee5004/reports/471f7d2a-d42b-4da8-9f02-0a029fdb759e/ReportSection?experience=power-bi&filter=PM_Health_x0020_provider_productivity_powerbi%2Fpractice_id%20eq%20${SelectedPractice.value}`,"_blank")
      
    }
    if(SelectedReportName.label == "Provider Productivity"){
      window.open(`https://app.powerbi.com/groups/4953d30e-1e6d-4c2d-a091-688634ee5004/reports/b650d83f-3d95-43ef-a7f7-8d2a803395ec/ReportSection?experience=power-bi&filter=PM_Health_x0020_ar_compilation_report_powerbi%2Fpractice_id%20eq%20${SelectedPractice.value}`,"_blank")
    }
    if(SelectedReportName.label == "Practice Overview"){
      window.open(`https://app.powerbi.com/groups/4953d30e-1e6d-4c2d-a091-688634ee5004/reports/23aa340d-18a1-4de7-bc5e-3e35abd6ccd7/ReportSection?experience=power-bi&filter=PM_Health_x0020_practice_overview_powerbi%2Fpractice_id%20eq%20${SelectedPractice.value}`,"_blank")
    }
    if(SelectedReportName.label == "Profit & Loss Report"){
      window.open(`https://app.powerbi.com/groups/4953d30e-1e6d-4c2d-a091-688634ee5004/reports/08c342d7-b7a9-4369-89ee-5621dad48f18/ReportSectionb9043e9a55fd0897a64a?experience=power-bi&filter=PM_Health_x0020_profit_loss_powerbi%2Fpractice_id%20eq%20${SelectedPractice.value}`,"_blank")
    }
    if(SelectedReportName.label == "Reimbursement Audit"){
      window.open(`https://app.powerbi.com/groups/4953d30e-1e6d-4c2d-a091-688634ee5004/reports/e76a8f3e-49e0-4329-8295-c0fa487b2894/ReportSection?experience=power-bi&filter=PM_Health_x0020_reimbursementauditreport_powerbi%2Fpractice_id%20eq%20${SelectedPractice.value}`,"_blank")
    }
    if(SelectedReportName.label == "MGMA Report"){
      window.open(`https://app.powerbi.com/groups/4953d30e-1e6d-4c2d-a091-688634ee5004/reports/bd2bc315-5ad1-4c38-8618-984db8f5084d/ReportSection?experience=power-bi&filter=PM_Health_x0020_benchmark_powerbi%2Fpractice_id%20eq%20${SelectedPractice.value}`,"_blank")
    }

  }

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Power BI</h3>
        </div>
      </div>
      <hr />
      <form id='kt_modal_add_user_form' className='form position-relative h-100 w-100' noValidate>
        <div>
          <div className='row'>
            
            <div className='col-sm-4 fv-row'>
              <label className='col-lg-9 col-form-label fw-bold fs-6'>
                <span className='required'>Practice Name</span>
              </label>
              <Select maxMenuHeight={180} value={SelectedPractice} onChange={getPractice} id="Practice" name={"Practice"} options={PracticeList} />
            </div>
            <div className='col-sm-4 fv-row'>
              <label className='col-lg-12 col-form-label fw-bold fs-6'>
                <span className='required'>Select Report Name</span>
              </label>
              <Select maxMenuHeight={180} value={SelectedReportName} onChange={getReportNameColumns} id="ReportName" name={"ReportName"} options={ReportNameList} />
            </div>
            <div className='col-4 fv-row'>
              <br /> <br/>
              <button type='button' className='btn btn-primary' onClick={handleExport}>
                {WorkingDaysLoading ? "Loading" : "View Reports"}
              </button>
          </div>
          </div>
          
        </div>
        <div></div>
      </form>
    </>
  )
}
export default PowerBI