import React, { useState, useEffect, HTMLAttributes } from 'react'
import { initialUser, User } from './_models'
import { FC, useCallback } from 'react'
import * as Yup from 'yup'
import { Formik, Field, useFormik, ErrorMessage } from 'formik'
import axios from "axios";
import Select from 'react-select';
import swal from "sweetalert"
import moment from "moment";
import { useDropzone, } from 'react-dropzone';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Backdrop from '@mui/material/Backdrop';
import { Typography } from '@mui/material';
import { toast } from 'react-toastify';
import ToastOption from '../Common/ToastOption'
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker/';
import { PageLoading } from '../../../modules/wizards/common/PageLoading'
const GetMonthEndData = `/api/PracticeSummary/GetMonthEndData`
const getPracticeList = `/api/practice/getPracticeListById`
const getPmList = `/api/pmsystem/getPmList`
const getReportNameList = `api/PracticeSummary/getReportNameList`
const UplodClaims = `/api/Claims/UplodClaims`

const UserDetailsSchema = Yup.object().shape({
  PmName: Yup.string().required('PM Name  is required'),
  ReportName: Yup.string().required('Report Name is required'),
  Practice: Yup.string().required('Practice Name is required'),
})

function bytesForHuman(bytes: any) {
  let units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']

  let i = 0

  for (i; bytes > 1024; i++) {
    bytes /= 1024;
  }

  return bytes.toFixed(1) + ' ' + units[i]
}
function onFileUploaded(file: any) {
  console.log("file", file)
  throw new Error('Function not implemented.');
}
interface ChargesAmount {
  no_of_days?: number,
  pending_charges: number,
  pending_charges_number: number,
  pending_denials_number: number,
  pending_denials: number,
  refund_amount: number,
  pharmacy_collections: number,

}
const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});
const ViewUploadedData: React.FC = () => {
  const [setClaimDataFieldsList, setClaimDataFields] = useState([])
  const [ClaimColumns, SelectedFieldsNamesList] = useState([{}])
  const [childData, setChildData] = useState("");
  const [SelectedPmSystem, setSelectedPmSystem] = useState({})
  const [SelectedPractice, setSelectedPractice] = useState({ label: "", value: "" })
  const [isLoading, setLoading] = useState(false)
  const [SelectedReportName, setSelectedReportName] = useState({ label: "", value: "" })
  // const [SelectedLocationId, setSelectedLocationId] = useState({ label: "", value: "" })
  const [ErrorDataMismatchTemplate, setErrorDataMismatchTemplate] = useState([]);
  const [SuccessFileNames, setSuccessFileNames] = useState([]);
  const [DataNoFound, setDataNoFound] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [PracticeListData, setPracticeListData] = useState([])
  const [ReportNameList, setReportNameList] = useState([])
  // const [ProviderList , setProviderList] = useState([])
  const [getPmListData, setdatass] = useState([{ PmName: "", _id: "" }])
  const [DateMonthFrom, setDateMonthFrom] = useState("")
const [FromDate, setFromDate] = useState("")
const [ToDate, setToDate] = useState("")
  const [FileList, setFileList] = useState([])
  const [CPTFileList, setCPTFileList] = useState([])
  const [formData, setFormData] = useState<ChargesAmount>({
    no_of_days: 0.00,
    pending_charges: 0.00,
    pending_charges_number: 0,
    pending_denials_number: 0,
    pending_denials: 0.00,
    refund_amount: 0.00,
    pharmacy_collections: 0.00,
  })

  const FileDownlaod = `api/Claims/FileDownlaod`
  const getUploadASPfeeschedule = `api/Claims/getUploadASPfeeschedule`
  const GetPmList = async () => {
    const getPmListData = await axios.get(getPmList)
    if (getPmListData.data) {
      setdatass(getPmListData.data.data)
    }
    const GetPracticeListData = await axios.get(getReportNameList)
    if (getPmListData.data) {
      setReportNameList(GetPracticeListData.data.TableColumns)
    }

  }

  useEffect(() => {
    GetPmList()

  }, [])
  const Downlaod = async (fileName: any) => {

    axios.get(FileDownlaod,{params : {fileName}}).then((response) => {
      const url = window.location.origin + "/" + response.data;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', '');
      document.body.appendChild(link);
      link.click();
    }).catch((error) => console.log(error));
  }
  const ViewReportList  = async () => {

    var Month = FromDate.split("-")
    let URL = `${getUploadASPfeeschedule}`
    setOpen(true)
    const GetData = await axios.post(URL, {
      Prefix: `PmHealth/${SelectedPractice.label}/${Month[1]}/${Month[0]}/`
    })
    if (GetData.data.FileList.length > 0) {
      setCPTFileList(GetData.data.CPTClass)
      setFileList(GetData.data.FileList)
    } else {
      toast.info(`No File List found`,ToastOption);
      setFileList([])
      setCPTFileList([])
    }
    const res = await axios.get(`${GetMonthEndData}?practice_name=${SelectedPractice.label}&month_year=${FromDate}`)
    if (res.data.MonthEndData.length > 0) {

      var MonthEndData: any = res.data.MonthEndData
      setFormData({
        no_of_days: 0.00,
        pending_charges: MonthEndData[0].pending_charges,
        pending_charges_number: MonthEndData[0].pending_charges_number,
        pending_denials_number: MonthEndData[0].pending_denials_number,
        pending_denials: MonthEndData[0].pending_denials,
        refund_amount: MonthEndData[0].refund_amount,
        pharmacy_collections: MonthEndData[0].pharmacy_collections
      })
    } else {
      toast.info(`No Charges Found`,ToastOption);
      setFormData({
        no_of_days: 0.00,
        pending_charges: 0.00,
        pending_charges_number: 0,
        pending_denials_number: 0,
        pending_denials: 0.00,
        refund_amount: 0.00,
        pharmacy_collections: 0.00
      })
    }
      setOpen(false)
  }
  const getPractice = async (event: any) => {
    if (Object.keys(event).length > 0) {

      setSelectedReportName({ label: "", value: "" })
      setSelectedPractice(event)
      
    } else {
      setSelectedReportName({ label: "", value: "" })
      setSelectedPractice({ label: "", value: "" })
    }

  }

  const getPMSystem = (event: any) => {
    //setSelectedPractice([])
    setSelectedPmSystem(event)

    if (event.value) {
      axios.post(getPracticeList, { PostData: event.value }).then((res) => {
        setPracticeListData(res.data.data)
      })
    }
  };
  var PmListData = [{ label: "Select", value: "" }]
  getPmListData.map((name) => (
    PmListData.push({
      label: name.PmName,
      value: name._id
    })
  ))

  const handleDateChangeFrom = (date: any) => {
    setDateMonthFrom(date)
setFromDate(moment(date['$d']).format("MMM-YYYY"))
  };

  var ResultCPTClass: any;
  if (CPTFileList.length > 0) {
    ResultCPTClass = CPTFileList.map((res: any, _inde: any) => {
      var FileName = res.Key.split("/")
      return (
        <tr>
          <td >{FileName.slice(-1)}</td>
          <td >{moment(res.LastModified).format("MM-DD-YYYY h:mm")}</td>
          <td ><a className='btn btn-primary' onClick={() => Downlaod(res.Key)}>Download </a> </td>
        </tr>
      )
    })
  }
  var Result: any;
  if (FileList.length > 0) {
    Result = FileList.map((res: any, _inde: any) => {
      var FileName = res.FileName.split("/")
      return (
        <tr>
          <td >{FileName.slice(-1)}</td>
          <td >{moment(res.LastModified).format("MM-DD-YYYY h:mm")}</td>
          <td ><a className='btn btn-primary' onClick={() => Downlaod(res.FileName)}>Download </a> </td>
        </tr>
      )
    })
  }
  return (
    <>
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
        <Typography variant="h6" component="div" color="inherit" mt={2} ml={4}>
          Loading...
        </Typography>
      </Backdrop>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>View Uploaded Data (Past 1 year files)</h3>
        </div>
      </div>
      <hr />
      <form id='kt_modal_add_user_form' className='form h-100' noValidate>
       
          <div className='h-full'>
          <Formik initialValues={{ myField: '', }} onSubmit={values => { console.log(values); }}>
            <div className='row h-100'>
              <div className='col-sm-3 fv-row'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>
                  <span className='required'>From</span>
                </label><br />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={DateMonthFrom}
                    minDate={moment("2015")}
                    renderInput={(params:any) => <TextField {...params}  />}
                    maxDate={moment()}
                    views={['year', 'month']} disableFuture
                    onChange={handleDateChangeFrom}
                  />
                </LocalizationProvider>
              </div>

              <div className='col-3 fv-row mb-7'>
                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                  <span className='required'>PM System</span>
                </label>
                <Select maxMenuHeight={180} value={SelectedPmSystem} onChange={getPMSystem} id="PmId" name={"PmId"} options={PmListData} />


              </div>

              <div className='col-sm-3 fv-row'>
                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                  <span className='required'>Practice Name</span>
                </label>

                <Select maxMenuHeight={180} value={SelectedPractice} onChange={getPractice} id="Practice" name={"Practice"} options={PracticeListData} />


              </div>
              <div className='col-3 pt-13'>
            <button type="button" onClick={(e) => { ViewReportList() }} className='btn btn-primary'  >View Reports</button>
          </div>

            </div>
          </Formik>
          </div>
          {Result ? <>

            <table id='kt_table_users' className='table-row-gray-700 table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
              <thead>
                <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                  <th>File Name</th>
                  <th>Upload Date</th>
                  <th>Download</th>
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-bold' >
                {ResultCPTClass}
                {Result}
              </tbody>
            </table>

            <table id='kt_table_users' className='table-row-gray-700 table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
              <thead>
                <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                  <th>Pending Charges</th>
                  <th>Pending Charges Number</th>
                  <th>Pending Denials</th>
                  <th>Pending Denials Number</th>
                  <th>Refund Amount</th>
                  <th>Pharmacy Collections </th>
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-bold' >
                <tr className='text-end'>
                  <td className=''>{currencyFormatter.format(formData.pending_charges)}</td>
                  <td className=''>{formData.pending_charges_number}</td>
                  <td className=''>{currencyFormatter.format(formData.pending_denials)}</td>
                  <td className=''>{currencyFormatter.format(formData.pending_denials_number)}</td>
                  <td className=''>{currencyFormatter.format(formData.refund_amount)}</td>
                  <td className=''>{currencyFormatter.format(formData.pharmacy_collections)}</td>
                </tr>

              </tbody>
            </table>
          </>

            : null}
       

        <div>


        </div>

      </form>
      {isLoading && <PageLoading />}
    </>
  )
}
export { ViewUploadedData }