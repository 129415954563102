import React, {FC, useState, useEffect, useRef} from 'react'
import * as Yup from 'yup'
import {useFormik, useField} from 'formik'
import clsx from 'clsx'
import Select from 'react-select'
import axios from 'axios'
import swal from 'sweetalert'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
import ToastOption from './Common/ToastOption'
import Location from './components/Location'
import e from 'express'
import {KTSVG} from '../../../_metronic/helpers'
import Provider from './components/Provider'
import {States} from '../../../utils/data'

import {Counties} from '../../../utils/data'
import {Localities} from '../../../utils/data'
import { useAuth } from '../../../app/modules/auth'


const getPracticeListPM = `/api/practice/getPracticeListPM`
const getPmListPM = `/api/practice/getPmListPM`
const AddPractice = `/api/DenialReports/CreatePractice`

const BackGround: React.CSSProperties = {
  // background: `linear-gradient(
  //       rgba(255, 255, 255, 0.7),
  //       rgba(255, 255, 255, 0.3)
  //     ),
  //     url("https://images.pexels.com/photos/114979/pexels-photo-114979.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500")`,
  // backgroundSize: 'cover',
  // backgroundPosition: 'center',
  backgroundImage: `linear-gradient(135deg, #79F1A4 10%, #0E5CAD 100%)`,
}



const CreatePractice: FC<any> = ({user, isUserLoading}) => {
  const { currentUser }: any = useAuth()
  const [show, setShow] = useState(true)
  const [Speciality,setSpeciality]=useState<any>([])
  const [SelectedPracticeName, setSelectedPracticeName] = useState({})
  const [getPracticeListData, setdatass] = useState([{PracticeName: '', _id: ''}])
  const [PmSystem, setPmSystem] = useState<any>({PmName: '', _id: ''})
  const [County, setCounty] = useState<any>()
  const [Locality, setLocality] = useState<any>()
  const [AddLocation, setAddLocation] = useState<boolean>(false)
  const [AddProvider, setAddProvider] = useState<boolean>(false)
  const [EditLocation, setEditLocation] = useState<boolean>(false)
  const [EditProvider, setEditProvider] = useState<boolean>(false)
  const [FinalArray, setFinalArray] = useState<any>([])
  const [showModal, setshowModal] = useState<string>('')
  const [LocationEditData, setLocationEditData] = useState<any>([])
  const [ProviderEditData, setProviderEditData] = useState<any>([])
  const [StateValue, setStateValue] = useState<any>()
  const [SpecialityValue, setSpecialityValue] = useState<any>()
  const [FinalProviderArray, setFinalProviderArray] = useState<any>([])
  const [open, setOpen] = React.useState(false);
  const [RadioChecked, setRadioChecked] = useState<any>({
    StatusActive: false,
    StatusInactive: false,
  })

  // const [createdBy,setCreatedBy]=useState<any>(currentUser._id)
  const [formData, setFormData] = useState<any>({
    PracticeName: '',
    PmSystem: '',
    TaxID: '',
    NPI: '',
    ClearingHouse: '',
    EMR: '',
    Drug: '',
    PhyLine1: '',
    PhyLine2: '',
    PhyLine3: '',
    BillLine1: '',
    BillLine2: '',
    BillLine3: '',
    County: '',
    Locality:"",
    State: '',
    Speciality: '',
    PracticeStatus: '',
    CreatedBy:currentUser._id
  })
  const GetSpeciality = async()=>{
    let Response = await axios.get(`/api/Claims/getSpeciality`) 
    setSpeciality(Response.data.data)
  }
  const GetPracticeList = async () => {
    const res = await axios.get(getPracticeListPM)
    if (res.data) {
      setdatass(res.data.data)
    }
  }
  useEffect(() => {
    GetSpeciality()
    GetPracticeList()
  }, [])

  const handleInputChange = (e: any) => {
    const {name, value} = e.target
    setFormData((prev: any) => {
      return {
        ...prev,
        [name]: value,
      }
    })
  }
const handleLocalityChange=(e:any)=>{
  setLocality(e)
  setFormData((prevState: any) => {
    return {...prevState, Locality: e.label}
  })
}
  const handleStateChange = (e: any) => {
    setStateValue(e)
    setFormData((prevState: any) => {
      return {...prevState, State: e.label}
    })
  }

  const handleSpecialityChange = (e: any) => {
    setSpecialityValue(e)
    setFormData((prevState: any) => {
      return {...prevState, Speciality: e.label}
    })
  }

  const handleCountyChange = (e: any) => {

    setCounty(e)
    setFormData((prevState: any) => {
      return {...prevState, County: e.label.trim()}
    })
  }

  const handlePracticeStatus = (e: any) => {

    if (e.target.value === 'Active') {
      setRadioChecked({StatusActive: true})
    } 
    else if (e.target.value === 'Inactive') {
      setRadioChecked({StatusInactive: true})
    }
    setFormData((prevState: any) => {
      return {...prevState, PracticeStatus: e.target.value}
    })
  }

  const handlePracticeList = (event: any) => {
    setSelectedPracticeName(event)
    setFinalArray([])
    setFinalProviderArray([])
    setCounty([])
    setLocality([])
    setStateValue([])
    setSpecialityValue([])
   setRadioChecked({
      StatusActive: false,
      StatusInactive: false,
    })
    setFormData({TaxID: '',
    PmSystem:"",
    NPI: '',
    ClearingHouse: '',
    EMR: '',
    Drug: '',
    PhyLine1: '',
    PhyLine2: '',
    PhyLine3: '',
    BillLine1: '',
    BillLine2: '',
    BillLine3: '',
    County: '',
    Locality:"",
    State: '',
    Speciality: '',
    PracticeStatus: '',
    CreatedBy:currentUser._id,
  })

  setFormData((prevState: any) => {
    return {...prevState, PracticeName: event.label}
  })
    setOpen(true)
    axios.post(getPmListPM, {PostData: event.label}).then((res) => {
      setOpen(false)
      setPmSystem({PmName: res.data.data.PmName, _id: res.data.data._id})
      setFinalArray(res.data.LocationMasterData)
      setFinalProviderArray(res.data.ProviderData)
      // setFormData(res.data.PracticeData)
      setFormData((prevState: any) => {
        return {...prevState, PmSystem: res.data.data.PmName}
      })

      if(res.data.PracticeData.TaxID){
        setFormData((prevState: any) => {
          return {...prevState, TaxID: res.data.PracticeData.TaxID}
        })
      }
      if(res.data.PracticeData.NPI){
        setFormData((prevState: any) => {
          return {...prevState, NPI: res.data.PracticeData.NPI}
        })
      }
      if(res.data.PracticeData.ClearingHouse){
        setFormData((prevState: any) => {
          return {...prevState, ClearingHouse: res.data.PracticeData.ClearingHouse}
        })
      }
      if(res.data.PracticeData.EMR){
        setFormData((prevState: any) => {
          return {...prevState, EMR: res.data.PracticeData.EMR}
        })
      }
      if(res.data.PracticeData.Drug){
        setFormData((prevState: any) => {
          return {...prevState, Drug: res.data.PracticeData.Drug}
        })
      }
      if(res.data.PracticeData.PhyLine1){
        setFormData((prevState: any) => {
          return {...prevState, PhyLine1: res.data.PracticeData.PhyLine1}
        })
      }
      if(res.data.PracticeData.PhyLine2){
        setFormData((prevState: any) => {
          return {...prevState, PhyLine2: res.data.PracticeData.PhyLine2}
        })
      }
      if(res.data.PracticeData.PhyLine3){
        setFormData((prevState: any) => {
          return {...prevState, PhyLine3: res.data.PracticeData.PhyLine3}
        })
      }
      if(res.data.PracticeData.BillLine1){
        setFormData((prevState: any) => {
          return {...prevState, BillLine1: res.data.PracticeData.BillLine1}
        })
      }
      if(res.data.PracticeData.BillLine2){
        setFormData((prevState: any) => {
          return {...prevState, BillLine2: res.data.PracticeData.BillLine2}
        })
      }
      if(res.data.PracticeData.BillLine3){
        setFormData((prevState: any) => {
          return {...prevState, BillLine3: res.data.PracticeData.BillLine3}
        })
      }
      if(res.data.PracticeData.County){
        setCounty({label:res.data.PracticeData.County,value:res.data.PracticeData.County})
        setFormData((prevState: any) => {
          return {...prevState, County: res.data.PracticeData.County}
        })
      }
      if(res.data.PracticeData.Speciality){
        setSpecialityValue({label:res.data.PracticeData.Speciality,value:res.data.PracticeData.Speciality})
        setFormData((prevState: any) => {
          return {...prevState, Speciality: res.data.PracticeData.Speciality}
        })
      }
      if(res.data.PracticeData.State){
        setStateValue({label:res.data.PracticeData.State,value:res.data.PracticeData.State})
        setFormData((prevState: any) => {
          return {...prevState, State: res.data.PracticeData.State}
        })
      }
      if(res.data.PracticeData.Locality){
        setLocality({label:res.data.PracticeData.Locality,value:res.data.PracticeData.Locality})
        setFormData((prevState: any) => {
          return {...prevState, Locality: res.data.PracticeData.Locality}
        })
      }
      if(res.data.PracticeData.PracticeStatus === "A"){
      setRadioChecked({StatusActive:true})
           }
      else{
        setRadioChecked({StatusInactive:true})
      }
      setFormData((prevState: any) => {
        return {...prevState, PracticeStatus: res.data.PracticeData.PracticeStatus}
      })

      
    })
  }




  var PracticeListData = [{label: 'Select', value: ''}]
  getPracticeListData.map((name) =>
    PracticeListData.push({
      label: name.PracticeName,
      value: name._id,
    })
  )
  const handleLocation = (e: any) => {
    e.preventDefault()
    setAddLocation(true)
    setshowModal('show d-block')
  }

  const handleProvider = (e: any) => {
    e.preventDefault()
    setAddProvider(true)
    setshowModal('show d-block')
  }

  function changeState() {
    setShow(!show)
  }
  const handleClick = () => {
    alert('button click catched')
  }

  // function ChildData(data: any) {
  // setFinalArray((prev:any)=>[...prev,data])

  // }

  function ChildData(data: any) {
    if (data.Edited === true) {
      for (let i = 0; i < FinalArray.length; i++) {
        if (FinalArray[i].globalID === data.globalID) {
          FinalArray[i] = data
        }
      }
      setFinalArray(FinalArray)
    } else {
      setFinalArray((prev: any) => [...prev, data])
    }
  }

  function ProviderData(data: any) {
    if (data.Edited === true) {
      for (let i = 0; i < FinalProviderArray.length; i++) {
        if (FinalProviderArray[i].globalID === data.globalID) {
          FinalProviderArray[i] = data
        }
      }
      setFinalProviderArray(FinalProviderArray)
    } else {
      setFinalProviderArray((prev: any) => [...prev, data])
    }
  }

  if (FinalArray.length > 0) {
    for (let i = 0; i < FinalArray.length; i++) {
      FinalArray[i].globalID = i + 1
    }
  }

  if (FinalProviderArray.length > 0) {
    for (let i = 0; i < FinalProviderArray.length; i++) {
      FinalProviderArray[i].globalID = i + 1
    }
  }

  const DeleteItem = (itemID: any) => {
    const res = FinalArray.filter((item: any) => item.globalID !== itemID)
    setFinalArray(res)
    toast.success('Location has been Inactivated ...!',ToastOption);
  }

  const DeleteProviderItem = (itemID: any) => {
    const res = FinalProviderArray.filter((item: any) => item.globalID !== itemID)
    setFinalProviderArray(res)
    toast.success('Provider has been Inactivated ...!',ToastOption);
  }

  

  const handleEditItem = (itemID: any) => {
    const res = FinalArray.filter((item: any) => item.globalID === itemID)
    setLocationEditData(res)
    setEditLocation(true)
    setAddLocation(true)
    setshowModal('show d-block')

  }
  const handleEditProviderItem = (itemID: any) => {
    const res = FinalProviderArray.filter((item: any) => item.globalID === itemID)

    setProviderEditData(res)
    setAddProvider(true)
    setEditProvider(true)
    setshowModal('show d-block')

  }

  const handleSubmit = async () => {
    let error = false
    let ErrorArray = []
    if(!formData.PracticeName){
      error = true
      ErrorArray.push(`Please Select Practice Name`)
    }
    if(!formData.PmSystem){
      error = true
      ErrorArray.push(`Please Select PmSystem`)
    }
    if(!formData.TaxID){
      error = true
      ErrorArray.push(`Please Type  TaxID`)
    }
    if(!formData.NPI){
      error = true
      ErrorArray.push(`Please Type  NPI`)
    }
    if(!formData.ClearingHouse){
      error = true
      ErrorArray.push(`Please Type  Clearing House`)
    }
    if(!formData.EMR){
      error = true
      ErrorArray.push(`Please Type  EMR EMR Name`)
    }
    if(!formData.Drug){
      error = true
      ErrorArray.push(`Please Type  Drug Inventory`)
    }
    if(!formData.PhyLine1){
      error = true
      ErrorArray.push(`Please Type  Physical Address 1`)
    }
    if(!formData.PhyLine2){
      error = true
      ErrorArray.push(`Please Type  Physical Address 2`)
    }
    if(!formData.PhyLine3){
      error = true
      ErrorArray.push(`Please Type  Physical Address 3`)
    }
    if(!formData.BillLine1){
      error = true
      ErrorArray.push(`Please Type  Billing Address 1`)
    }
    if(!formData.BillLine2){
      error = true
      ErrorArray.push(`Please Type  Billing Address 2`)
    }
    if(!formData.BillLine3){
      error = true
      ErrorArray.push(`Please Type  Billing Address 3`)
    }
    if(!formData.County){
      error = true
      ErrorArray.push(`Please Select  County`)
    }
    if(!formData.Locality){
      error = true
      ErrorArray.push(`Please Select  Locality`)
    }
    if(!formData.State){
      error = true
      ErrorArray.push(`Please Select  State`)
    }
    if(!formData.Speciality){
      error = true
      ErrorArray.push(`Please Select  Speciality`)
    }
    if(!formData.PracticeStatus){
      error = true
      ErrorArray.push(`Please Choose  PracticeStatus`)
    }
    if(error){
      ErrorArray.map((res,ind)=>{
        toast.error(`${res}`,ToastOption);
      })
      return false;
    }
    if (FinalArray.length<=0) {
      toast.error('Please add any one Location',ToastOption);
      return false
    }
    if (FinalProviderArray.length<=0) {
      toast.error('Please add any one Provider',ToastOption);
      return false
    }
    setOpen(true)

    const res = await axios.post(AddPractice, {
      formData: formData,
      FinalArray: FinalArray.length>0 ? FinalArray : [],
      FinalProviderArray: FinalProviderArray.length>0 ? FinalProviderArray : [],
    })
    setOpen(false)
    if (res.data.code === 'Yellow') {
      swal({
        title: 'Practice',
        icon: 'success',
        text: res.data.data,
      }).then((_res: any) => {
        setFinalArray([])
        setFinalProviderArray([])
        setSelectedPracticeName([])
        setPmSystem({PmName: '', _id: ''})
        setSpecialityValue([])
        setStateValue([])
        setStateValue([])
        setCounty([])
        setLocality([])
        setRadioChecked({StatusActive:false,StatusInactive:false})
        setFormData({
        PracticeName:"",
        PmSystem:"",
        TaxID: '',
        NPI: '',
        ClearingHouse: '',
        EMR: '',
        Drug: '',
        PhyLine1: '',
        PhyLine2: '',
        PhyLine3: '',
        BillLine1: '',
        BillLine2: '',
        BillLine3: '',
        County: '',
        State: '',
        Speciality: '',
        PracticeStatus: '',
        CreatedBy:currentUser._id,
      })
      })
      return false
    }
    if (res.data.code === 'Green') {
      swal({
        title: 'Practice',
        icon: 'success',
        text: `${res.data.data} :(`,
      }).then((_res: any) => {
        setFinalArray([])
        setFinalProviderArray([])
        setSelectedPracticeName([])
        setPmSystem({PmName: '', _id: ''})
        setSpecialityValue([])
        setStateValue([])
        setStateValue([])
        setCounty([])
        setLocality([])
        setRadioChecked({StatusActive:false,StatusInactive:false})
        setFormData({
          PracticeName:"",
          PmSystem:"",
          TaxID: '',
        NPI: '',
        ClearingHouse: '',
        EMR: '',
        Drug: '',
        PhyLine1: '',
        PhyLine2: '',
        PhyLine3: '',
        BillLine1: '',
        BillLine2: '',
        BillLine3: '',
        County: '',
        State: '',
        Speciality: '',
        PracticeStatus: '',
        CreatedBy:currentUser._id,
      })
      })
      return false
    }
  }

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>
      <p className='text-center' style={{fontFamily: 'Poppins', fontSize: '20px'}}>
        Create/Edit Practice
      </p>
      <form id='kt_modal_add_user_form' className='form position-relative h-100 w-100' noValidate>
        <div>
          <div className='d-flex w-100 h-100 p-6 align-content-center' style={{background:"#dbd8e3"}}>
            {/* 1st column */}
            <div className='d-flex flex-column w-50 pt-2 px-2 mx-4'>
              <div className='fv-row mb-4'>
                <label className=' col-form-label fw-bold fs-6 w-30'>
                  <span className='required'>Practice Name</span>
                </label>
                <Select
                  maxMenuHeight={150}
                  value={SelectedPracticeName}
                  onChange={handlePracticeList}
                  id='PmId'
                  name={'PmId'}
                  options={PracticeListData}
                  className='w-70'
                />
              </div>

              <div className='col-12 fv-row mb-4 d-flex flex-column'>
                <label className='col-form-label fw-bold fs-6  '>
                  <span className='required '>Tax ID</span>
                </label>
                <input
                  className='p-2 w-70'
                  name='TaxID'
                  value={formData.TaxID}
                  onChange={handleInputChange}
                />
              </div>

              <div className='col-12 fv-row mb-4 d-flex flex-column'>
                <label className='col-form-label fw-bold fs-6 '>
                  <span className='required'>NPI</span>
                </label>
                <input
                  className='p-2 w-70'
                  name='NPI'
                  value={formData.NPI}
                  onChange={handleInputChange}
                />
              </div>

              <div className='col-12 fv-row mb-4 d-flex flex-column'>
                <label className='col-form-label fw-bold fs-6'>
                  <span className='required'>PM System</span>
                </label>
                <input className='p-2 w-70' value={PmSystem.PmName} disabled />
              </div>

              <div className='col-12 fv-row mb-4 d-flex flex-column'>
                <label className='col-form-label fw-bold fs-6 '>
                  <span className='required'>Clearing House</span>
                </label>
                <input
                  className='p-2 w-70'
                  name='ClearingHouse'
                  value={formData.ClearingHouse}
                  onChange={handleInputChange}
                />
              </div>

              <div className='col-12 fv-row mb-4 d-flex flex-column'>
                <label className='col-form-label fw-bold fs-6'>
                  <span className='required'>EMR Name</span>
                </label>
                <input
                  className='p-2 w-70'
                  name='EMR'
                  value={formData.EMR}
                  onChange={handleInputChange}
                />
              </div>

              <div className='col-12 fv-row mb-4 d-flex flex-column'>
                <label className='col-form-label fw-bold fs-6'>
                  <span className='required'>Drug Inventory</span>
                </label>
                <input
                  className='p-2 w-70'
                  name='Drug'
                  value={formData.Drug}
                  onChange={handleInputChange}
                />
              </div>
              <div className='fv-row mb-4 '>
                <label className='col-form-label fw-bold fs-6'>
                  <span className='required'>State</span>
                </label>
                <Select
                  maxMenuHeight={150}
                  className='w-70 '
                  options={States}
                  onChange={handleStateChange}
                  value={StateValue}
                />
              </div>
              
            </div>
            {/* 2nd column */}
            <div className='d-flex flex-column w-50 pt-2 mx-4'>
            <div className='fv-row mb-4 d-flex flex-column'>
                <label className='col-form-label fw-bold fs-6'>
                  <span className='required'>Physical Address</span>
                </label>
                {/* <textarea className='p-2 w-70' rows={3}  /> */}
                <input
                  type='text'
                  className='mb-2 p-2'
                  placeholder='Address Line 1...'
                  name='PhyLine1'
                  value={formData.PhyLine1}
                  onChange={handleInputChange}
                />
                <input
                  type='text'
                  className='mb-2 p-2'
                  placeholder='Address Line 2...'
                  name='PhyLine2'
                  value={formData.PhyLine2}
                  onChange={handleInputChange}
                />
                <input
                  type='text'
                  className='mb-2 p-2'
                  placeholder='Address Line 3...'
                  name='PhyLine3'
                  value={formData.PhyLine3}
                  onChange={handleInputChange}
                />
              </div>

              <div className='fv-row mb-4 d-flex flex-column'>
                <label className='col-form-label fw-bold fs-6'>
                  <span className='required'>Billing Address</span>
                </label>
                {/* <textarea className='p-2 w-70' rows={3}  /> */}
                <input
                  type='text'
                  className='mb-2 p-2'
                  placeholder='Address Line 1...'
                  name='BillLine1'
                  value={formData.BillLine1}
                  onChange={handleInputChange}
                />
                <input
                  type='text'
                  className='mb-2 p-2'
                  placeholder='Address Line 2...'
                  name='BillLine2'
                  value={formData.BillLine2}
                  onChange={handleInputChange}
                />
                <input
                  type='text'
                  className='mb-2 p-2'
                  placeholder='Address Line 3...'
                  name='BillLine3'
                  value={formData.BillLine3}
                  onChange={handleInputChange}
                />
              </div>

              <div className='fv-row mb-4 '>
                <label className='col-form-label fw-bold fs-6'>
                  <span className='required'>County</span>
                </label>
                <Select
                  maxMenuHeight={150}
                  className='w-70'
                  options={Counties}
                  value={County}
                  onChange={handleCountyChange}
                />
              </div>
              
              <div className='fv-row mb-4 '>
                <label className='col-form-label fw-bold fs-6'>
                  <span className='required'>Locality</span>
                </label>
                <Select
                  maxMenuHeight={150}
                  className='w-70'
                  options={Localities}
                  value={Locality}
                  onChange={handleLocalityChange}
                />
              </div>

              

              <div className='fv-row mb-4'>
                <label className='col-form-label fw-bold fs-6'>
                  <span className='required'>Speciality</span>
                </label>
                <Select
                  maxMenuHeight={150}
                  className='w-70'
                  placeholder='Search...'
                  value={SpecialityValue}
                  onChange={handleSpecialityChange}
                  options={Speciality}
                />
              </div>

              <div className='fv-row mb-4 d-flex flex-column'>
                <label className='col-form-label fw-bold fs-6'>
                  <span className='required'>Practice Status</span>
                </label>
                <div className='d-flex'>
                  <div className='form-check form-check-inline mt-4 '>
                    <input
                    checked={RadioChecked.StatusActive}
                      className='form-check-input'
                      type='radio'
                      name='Practice'
                      id='Practice'
                      value='Active'
                      onChange={handlePracticeStatus}
                    />
                    <label className='form-check-label' htmlFor='flexRadioDefault2'>
                      Active
                    </label>
                  </div>
                  <div className='form-check form-check-inline mt-4'>
                    <input
                       checked={RadioChecked.StatusInactive}
                      className='form-check-input'
                      type='radio'
                      name='Practice'
                      id='Practice'
                      value='Inactive'
                      onChange={handlePracticeStatus}
                    />
                    <label className='form-check-label' htmlFor='flexRadioDefault2'>
                      Inactive
                    </label>
                  </div>
                </div>
              </div>

              <div className='d-flex'>
                <div className='text-center pt-15 me-4'>
                  <button type='button' className='btn btn-primary' onClick={handleLocation}>
                    {' '}
                    Add Location
                  </button>
                </div>
                <div className='text-center pt-15'>
                  <button type='button' className='btn btn-primary' onClick={handleProvider}>
                    {' '}
                    Add Provider
                  </button>
                </div>
                {/* {FinalProviderArray.length > 0 ? ( */}
                  <div className='text-center pt-15 mx-4'>
                    <button type='button' className='btn btn-primary' onClick={handleSubmit}>
                      Submit
                    </button>
                  </div>
                {/* ) : null} */}
              </div>
            </div>
          </div>
        </div>
        <div></div>
        {AddLocation && (
          <Location
            setAddLocation={setAddLocation}
            showModal={showModal}
            onData={(data: any) => ChildData(data)}
          />
        )}

        {EditLocation && (
          <Location
            setEditLocation={setEditLocation}
            setAddLocation={setAddLocation}
            showModal={showModal}
            LocationEditData={LocationEditData ? LocationEditData : []}
            onData={(data: any) => ChildData(data)}
          />
        )}

        {AddProvider && (
          <Provider
            setAddProvider={setAddProvider}
            showModal={showModal}
            onData={(data: any) => ProviderData(data)}
          />
        )}

        {EditProvider && (
          <Provider
            setEditProvider={setEditProvider}
            setAddProvider={setAddProvider}
            showModal={showModal}
            ProviderEditData={ProviderEditData ? ProviderEditData : []}
            onData={(data: any) => ProviderData(data)}
          />
        )}
      </form>
      {FinalArray.length > 0 ? (
        <>
           <h4 className='mt-4 text-center text-uppercase'>List of Locations</h4>
        <table
          id='kt_table_users'
          className=' table table-striped align-middle fs-6 p-4 mt-8 font-family-base no-footer w-full '
        >
          <thead className='thead-dark bg-dark'>
            <tr className='text-start  fw-bolder fs-7 text-uppercase gs-0 text-gray-800 py-6 px-6'>
              <th className='px-3 py-2 text-light' scope='col'>
                S.No
              </th>
              <th className='text-center p-2 text-light'>Locations</th>
              <th className='text-center p-2 text-light'>Tax ID</th>
              <th className='text-center p-2 text-light'>NPI</th>
              <th className='text-center p-2 text-light'>Speciality</th>

              <th className='text-center p-2 text-light'>State</th>
              <th className='text-center p-2 text-light'>Location Status</th>
              <th className='text-center p-2 text-light'>Address</th>
              <th className='text-center p-2 text-light'>%</th>
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-medium p-8'>
            {FinalArray.map((res: any, _inde: any) => {
              return (
                <>
                  <tr className='py-10 px-4'>
                    <td className='px-3 py-2 text-center'>{Number(_inde) + 1}</td>
                    <td className='px-3 py-2 text-center'>{res.Location ? res.Location : '-'}</td>
                    <td className='px-3 py-2 text-center'>{res.TaxIdLoc ? res.TaxIdLoc : '-'}</td>

                    <td className='px-3 py-2 text-center'>{res.NPILoc ? res.NPILoc : '-'}</td>

                    <td className='px-3 py-2 text-center'>
                      {res.SpecialityLoc ? res.SpecialityLoc : '-'}
                    </td>

                    <td className='px-3 py-2 text-center'>{res.StateLoc ? res.StateLoc : '-'}</td>
                    <td className='px-3 py-2 text-center'>
                      {res.LocationStatus ? res.LocationStatus : '-'}
                    </td>
                    <td className='px-3 py-2 text-center'>
                      {res.PhyLineLoc1 ? res.PhyLineLoc1 : null + ',' + res.PhyLineLoc2? res.PhyLineLoc2:null + ',' + res.PhyLineLoc3? res.PhyLineLoc3:null}
                    </td>
                    {/* <td> <button className='px-3 py-2' onClick={()=>DeleteItem(res.globalID)}>
                                                       Delete
                                                    </button></td> */}
                    <td className='d-flex justify-content-around'>
                      <a href='#' onClick={() => handleEditItem(res.globalID)}>
                        <KTSVG
                          path='/media/icons/duotune/art/art005.svg'
                          className='svg-icon-2 svg-icon-gray-500'
                        />
                      </a>

                      <a href='#' onClick={() => DeleteItem(res.globalID)}>
                        <KTSVG
                          path='/media/icons/duotune/art/art011.svg'
                          className='svg-icon-2 svg-icon-gray-500 zoom'
                        />
                      </a>
                    </td>
                  </tr>
                </>
              )
            })}
          </tbody>
        </table>
        </>
      ) : null}

      {FinalProviderArray.length > 0 ? (
        <>
        <h4 className='mt-6 text-center text-uppercase'>List of Providers</h4>
        <table
          id='kt_table_users'
          className=' table table-striped align-middle fs-6 p-4 mt-8 font-family-base no-footer w-full '
        >
          <thead className='thead-dark bg-dark'>
            <tr className='text-start  fw-bolder fs-7 text-uppercase gs-0 text-gray-800 py-6 px-6'>
              <th className='px-3 py-2 text-light' scope='col'>
                S.No
              </th>
              <th className='text-center p-2 text-light'>Providers</th>
              <th className='text-center p-2 text-light'>Provider NPI</th>
              <th className='text-center p-2 text-light'>From Date</th>
              <th className='text-center p-2 text-light'>To Date</th>

              <th className='text-center p-2 text-light'>Speciality</th>
              <th className='text-center p-2 text-light'>Provider Type</th>
              <th className='text-center p-2 text-light'>Provider Status</th>
              <th className='text-center p-2 text-light'>%</th>
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-medium p-8'>
            {FinalProviderArray.map((res: any, _inde: any) => {
              return (
                <>
                  <tr className='py-10 px-4'>
                    <td className='px-3 py-2 text-center'>{Number(_inde) + 1}</td>
                    <td className='px-3 py-2 text-center'>
                      {res.FirstName + ' ' + res.MiddleName + ' ' + res.LastName}
                    </td>

                    <td className='px-3 py-2 text-center'>
                      {res.ProviderNPI ? res.ProviderNPI : '-'}
                    </td>

                    <td className='px-3 py-2 text-center'>{res.FromDate ? res.FromDate : '-'}</td>
                    <td className='px-3 py-2 text-center'>{res.ToDate ? res.ToDate : '-'}</td>
                    <td className='px-3 py-2 text-center'>
                      {res.SpecialityPro ? res.SpecialityPro : '-'}
                    </td>
                    <td className='px-3 py-2 text-center'>
                      {res.ProviderType ? res.ProviderType : '-'}
                    </td>
                    <td className='px-3 py-2 text-center'>
                      {res.ProviderStatus ? res.ProviderStatus : '-'}
                    </td>

                    <td className='d-flex justify-content-around'>
                      <a href='#' onClick={() => handleEditProviderItem(res.globalID)}>
                        <KTSVG
                          path='/media/icons/duotune/art/art005.svg'
                          className='svg-icon-2 svg-icon-gray-500'
                        />
                      </a>

                      <a href='#' onClick={() => DeleteProviderItem(res.globalID)}>
                        <KTSVG
                          path='/media/icons/duotune/art/art011.svg'
                          className='svg-icon-2 svg-icon-gray-500 zoom'
                        />
                      </a>
                    </td>
                  </tr>
                </>
              )
            })}
          </tbody>
        </table>
        </>
      ) : null}
    </>
  )
}

export {CreatePractice}
