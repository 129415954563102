import React, { FC, useState, useEffect, useRef} from 'react'
import * as Yup from 'yup'
import { useFormik, useField } from 'formik'
import clsx from 'clsx'
import Select from 'react-select';
import axios from 'axios'
import { toast } from 'react-toastify';
import ToastOption from '../Common/ToastOption'
import { AnyObject } from 'yup/lib/types';
import {KTSVG} from '../../../../_metronic/helpers'


const Provider=({setAddProvider,onData,showModal,ProviderEditData,setEditProvider}:any)=>{
    
const [ProviderTypeValue,setProviderTypeValue]=useState<any>()
const [SpecialityValue,setSpecialityValue]=useState<any>()
const [Speciality,setSpeciality]=useState<any>([])
  const [formData, setFormData] = useState<any>({
  FirstName: '',
  MiddleName: '',
  LastName: '',
  ProviderNPI:"",
  FromDate:"",
  ToDate:'',
  ProviderType:'',
  SpecialityPro:'',
  ProviderStatus:"",
  globalID:0,
  provider_id:'',
  Edited:false
});

const [RadioChecked,setRadioChecked]=useState<any>(
  {
      StatusActive:false,
      StatusInactive:false,
     
  })


const ProviderTypeOptions:any=[
  { value: 'Doctor', label: 'Doctor' },
  { value: 'ARNP', label: 'ARNP' },
]

  const GetSpeciality = async()=>{
    let Response = await axios.get(`/api/Claims/getSpeciality`) 
    setSpeciality(Response.data.data)
  }
  const handleLocationStatus=(e:any)=>{
    
    if(e.target.value==="Active"){
      setRadioChecked({StatusActive:true})
      setFormData((prevState:any)=>{
        return {...prevState,ToDate:''}
      })
    }
    else if(e.target.value==="Inactive"){
      setRadioChecked({StatusInactive:true})
      setFormData((prevState:any) => {
        return {  ...prevState,
          ["ToDate"]: new Date().toISOString().split("T")[0] }
        });

    }
       setFormData((prevState:any) => {
      return {...prevState,ProviderStatus:e.target.value}
      });
  }


const handleSpecialityChange=(e:any)=>{
  setSpecialityValue(e)
  setFormData((prevState:any) => {
    return {...prevState,SpecialityPro:e.label}
    });
}

  const handleInputChange = (event:any) => {
    const { name, value } = event.target;

    setFormData((prevState:any) => {
    return {  ...prevState,
      [name]: value }
    });
  };

  const handleProviderChange=(e:any)=>{
    setProviderTypeValue(e)
    setFormData((prevState:any) => {
      return {...prevState,ProviderType:e.label}
      });
  }

  const handleSave=(e:any)=>{
    e.preventDefault()
    if (!formData.FirstName) {
      toast.error('Please Enter your FirstName',ToastOption);
      return false;
    }
    // if (!formData.MiddleName) {
    //   swal({
    //     title: 'MiddleName',
    //     icon: "info",
    //     text: `Please Enter your MiddleName`,
    //   }).then((_res: any) => {

    //   })
    //   return false;
    // }
    if (!formData.LastName) {
      toast.error('Please Enter your LastName',ToastOption);
      return false;
    }
     
    if (!formData.ProviderNPI) {
      toast.error('Please Enter your Provider NPI',ToastOption);
      return false;
    }
    if (!formData.FromDate) {
      toast.error('Please Choose any Start Date',ToastOption);
      return false;
    }
    
    if (!formData.SpecialityPro) {
      toast.error('Please Select any Speciality',ToastOption);
      return false;
    }
    if (!formData.ProviderType) {
      toast.error('Please Select any ProviderType',ToastOption);
      return false;
    }
    if (!formData.ProviderStatus) {
      toast.error('Please Select any Provider Status',ToastOption);
      return false;
    }
    toast.success(`Provider ${formData.Edited ? "Updated" : "Addded"} Successfully...!`,ToastOption);
    onData(formData)
    setAddProvider(false)
    setEditProvider(false)
  }

  useEffect(()=>{
    GetSpeciality()
          if(ProviderEditData && setEditProvider){
        ProviderEditData.map((res:any,ind:any)=>{
        setFormData({
  FirstName: res.FirstName,
  MiddleName: res.MiddleName,
  LastName: res.LastName,
  ProviderNPI:res.ProviderNPI,
  FromDate:res.FromDate,
  ToDate:res.ToDate,
  ProviderType:res.ProviderType,
  SpecialityPro:res.SpecialityPro,
  ProviderStatus:res.ProviderStatus,
  globalID:res.globalID,
  provider_id:res.provider_id,
  Edited:true
        })
        setSpecialityValue({label:res.SpecialityPro,value:res.SpecialityPro})
        setProviderTypeValue({label:res.ProviderType,value:res.ProviderType})


        if(res.ProviderStatus==="Active" ||res.ProviderStatus==="A"){
          setRadioChecked({StatusActive:true})
        }
        else if(res.ProviderStatus==="Inactive" || res.ProviderStatus==="I"){
          setRadioChecked({StatusInactive:true})
        }
       
      })  
    }
    else{
      setFormData({
        FirstName: '',
  MiddleName: '',
  LastName: '',
  ProviderNPI:"",
  FromDate:"",
  ToDate:'',
  ProviderType:'',
  SpecialityPro:'',
  ProviderStatus:"",
  globalID:0,
  Edited:false
      })
      setSpecialityValue([])
      setProviderTypeValue([])
      setRadioChecked({StatusActive:false,StatusInactive:false})
    }
    
  },[])

  const handleClose=()=>{
    setAddProvider(false)
    setEditProvider(false)
  }
    return (
      <div className={`modal fade ${showModal}`}  id='kt_modal_add_user' role='dialog' tabIndex={-1} aria-modal='false' >
      <div className='modal-dialog modal-dialog-centered modal-xl'>
          <div className='modal-content'>
              <div className='modal-header'>
              <h2 className='fw-bolder'>Add Provider</h2>

              <div
                                onClick={handleClose}
                                className='btn btn-icon btn-sm btn-active-icon-primary'
                                data-kt-users-modal-action='close'
                                style={{ cursor: 'pointer' }}
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                            </div>
    </div>



              <div className='d-flex w-full p-6 align-content-center' >
                    {/* 1st column */}
                <div className='row'>
    
                <div className='col-4 fv-row mb-4 d-flex flex-column'>
                  <label className=' col-form-label fw-bold fs-6 w-30'>
                    <span className='required'>First Name</span>
                  </label>
                  <input className='p-2 w-70' name="FirstName" value={formData.FirstName} onChange={handleInputChange} />
               </div>
    
               <div className='col-4 fv-row mb-4 d-flex flex-column'>
                  <label className='col-form-label fw-bold fs-6  '>
                    <span >Middle Name</span>
                  </label>
                  <input className='p-2 w-70' name="MiddleName" value={formData.MiddleName} onChange={handleInputChange} />
               </div>
    
               
               <div className='col-4 fv-row mb-4 d-flex flex-column'>
                  <label className='col-form-label fw-bold fs-6 '>
                    <span className='required'>Last Name</span>
                  </label>
                  <input className='p-2 w-70' name="LastName" value={formData.LastName} onChange={handleInputChange}  />
    
               </div>
    
              
               <div className='col-4 fv-row mb-4 d-flex flex-column'>
                  <label className='col-form-label fw-bold fs-6'>
                    <span className='required'>Provider NPI</span>
                  </label>
                  <input className='p-2 w-70' name="ProviderNPI" value={formData.ProviderNPI} onChange={handleInputChange}  />
               </div>
        
           <div className='col-4 fv-row mb-4 d-flex flex-column '>
            <label className='col-form-label fw-bold fs-6'>
              <span>From Date</span>
            </label>
            <input max={new Date().toISOString().split('T')[0]} onChange={handleInputChange} type="date" value={formData.FromDate} name="FromDate" id="FromDate" className='form-control' />
          </div>
 
          <div className='col-4 fv-row mb-4 d-flex flex-column '>
            <label className='col-form-label fw-bold fs-6'>
              <span>To Date</span>
            </label>
            <input min={new Date().toISOString().split('T')[0]} onChange={handleInputChange} type="date" value={formData.ToDate} name="ToDate" id="FromDate" className='form-control' />
          </div>

          <div className='col-4 fv-row mb-4 d-flex flex-column'>
                  <label className='col-form-label fw-bold fs-6'>
                    <span className='required'>Speciality</span>
                  </label>
                  <Select maxMenuHeight={150} className='w-70' name="SpecialityLoc" value={SpecialityValue} options={Speciality} onChange={handleSpecialityChange}/>
               </div>

               <div className='col-4 fv-row mb-4 d-flex flex-column'>
                  <label className='col-form-label fw-bold fs-6'>
                    <span className='required'>Provider Type</span>
                  </label>
                  <Select maxMenuHeight={150} className='w-70' name="SpecialityLoc" value={ProviderTypeValue} options={ProviderTypeOptions} onChange={handleProviderChange}/>
               </div>

              
               <div className='col-4 fv-row mb-4 d-flex flex-column'>
                  <label className='col-form-label fw-bold fs-6'>
                    <span className='required'>Provider Status</span>
                  </label>
                  <div className='d-flex'>
                  <div className='form-check form-check-inline mt-4 '>
                            <input
                                className='form-check-input'
                               checked={RadioChecked.StatusActive}
                                type='radio'
                                name='Practice'
                                id='Practice'
                                value='Active' 
                                onChange={handleLocationStatus}
                            />
                            <label className='form-check-label' htmlFor='flexRadioDefault2'>
                                Active
                            </label>
                        </div>
                        <div className='form-check form-check-inline mt-4'>
                            <input
                                className='form-check-input'
                                checked={RadioChecked.StatusInactive}
                                type='radio'
                                name='Practice'
                                id='Practice'
                                value='Inactive' 
                                onChange={handleLocationStatus}
                            />
                            <label className='form-check-label' htmlFor='flexRadioDefault2'>
                                Inactive
                            </label>
                        </div>
                        </div>
               </div>
    
            <div className='d-flex'>
               <div className='text-center pt-6 me-4'>
               <button type='button' className='btn btn-danger rounded ' onClick={handleSave}> Save</button>
              </div>
              </div>
    </div>
    
              </div>

              
            </div>
            <div>
    
    
             
    
            </div>
            </div>          
         
        </div>
      )


}


export default Provider

