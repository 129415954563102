import React, { useState, useEffect, HTMLAttributes } from 'react'
import { initialUser, User } from './_models'
import { FC, useCallback } from 'react'
import * as Yup from 'yup'
import { Formik, Field, useFormik, ErrorMessage } from 'formik'
import axios from "axios";
import Select from 'react-select';
import swal from "sweetalert"
import moment from "moment";
import { useDropzone, } from 'react-dropzone';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { toast } from 'react-toastify';
import ToastOption from '../Common/ToastOption'
import TextField from '@mui/material/TextField';
import { PageLoading } from '../../../modules/wizards/common/PageLoading'
import { Backdrop, CircularProgress } from '@mui/material'
import { useAuth } from '../../../modules/auth'
const getPracticeList = `/api/practice/getPracticeListById`
const getPmList = `/api/pmsystem/getPmList`
const getReportNameList = `api/PracticeSummary/getReportNameList`
const DataUploadPowerBI = `/api/Claims/DataUploadPowerBI`
const UserDetailsSchema = Yup.object().shape({
  PmName: Yup.string().required('PM Name  is required'),
  //ReportName: Yup.string().required('Report Name is required'),
  Practice: Yup.string().required('Practice Name is required'),
})

function bytesForHuman(bytes: any) {
  let units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']

  let i = 0

  for (i; bytes > 1024; i++) {
    bytes /= 1024;
  }

  return bytes.toFixed(1) + ' ' + units[i]
}
function onFileUploaded(file: any) {
  console.log("file", file)
  throw new Error('Function not implemented.');
}


const CollectedPaymentsReview: React.FC = () => {
  const [setClaimDataFieldsList, setClaimDataFields] = useState([])
  const [ClaimColumns, SelectedFieldsNamesList] = useState([{}])
  const [childData, setChildData] = useState("");
  const [SelectedPmSystem, setSelectedPmSystem] = useState({ label: "", value: "" })
  const [SelectedPractice, setSelectedPractice] = useState({ label: "", value: "" })
  const [isLoading, setLoading] = useState(false)
  const [SelectedReportName, setSelectedReportName] = useState({ label: "AR Collection By User", value: "payment_adj_collection" })
  const { currentUser }: any = useAuth()
  const [ErrorDataMismatchTemplate, setErrorDataMismatchTemplate] = useState([]);
  const [SuccessFileNames, setSuccessFileNames] = useState([]);
  const [DataNoFound, setDataNoFound] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [PracticeListData, setPracticeListData] = useState([])
  const [ReportNameList, setReportNameList] = useState([])
  const [getPmListData, setdatass] = useState([{ PmName: "", _id: "" }])
  const [DateMonthFrom, setDateMonthFrom] = useState("")
  const [FromDate, setFromDate] = useState("")
  const [DateMonthTo, setDateMonthTo] = useState("")
  const [ToDate, setToDate] = useState("")
  const GetPmList = async () => {
    const getPmListData = await axios.get(getPmList)
    if (getPmListData.data) {
      setdatass(getPmListData.data.data)
    }
    const GetPracticeListData = await axios.get(`${getReportNameList}?pageType=PowerBI`)
    if (getPmListData.data) {
      setReportNameList(GetPracticeListData.data.TableColumns)
    }

  }
  const formik = useFormik({
    initialValues: initialUser,
    validationSchema: UserDetailsSchema,
    onSubmit: async (values, { setSubmitting }) => {
      console.log("values", values)
      setSubmitting(true)

      let error = false
      let ErrorArray: any = []
      if (!SelectedPmSystem.value) {
        ErrorArray.push('Please Select PM System');
        error = true
      }
      if (!SelectedPractice.value) {
        ErrorArray.push('Please Select Practice');
        error = true
      }
      if (!SelectedReportName.value) {
        ErrorArray.push('Please Select Report Name');
        error = true
      }
      if (!DateMonthFrom) {
        ErrorArray.push('Please Select From Month');
        error = true
      }
      // if (!DateMonthTo) {
      //   ErrorArray.push('Please Select To Month');
      //   error = true
      // }
      if (error) {
        ErrorArray.map((res: any, ind: any) => {
          toast.error(`${res}`, ToastOption);
        })
        return false;
      }
      var GetValues: any = values
      var tempValues = {
        UserId: currentUser._id,
        Path: window.location.pathname,
        FromDate: FromDate,
        ReportName: JSON.stringify(SelectedReportName),
        PMSystem: JSON.stringify(SelectedPmSystem),
        PracticeName: JSON.stringify(SelectedPractice)
      }
      setOpen(true)
      setLoading(true)
      var PostData = {
        ReportName: GetValues.ReportName,
        PraciceId: SelectedPractice.label,
        FromDate: FromDate,
        ToDate: FromDate,
        tempValues: JSON.stringify(tempValues)
      }
      try {
        const response = await axios.post(DataUploadPowerBI, PostData)
        console.log("response.data", response.data)
        setOpen(false)
        swal({
          title: '',
          text: `Power BI Data Updated`,
          icon: "info",
        })
      } catch (e) {
        console.log("PowerBI Error",e)
        setOpen(false)
        swal({
          title: '',
          text: `Power BI Data Updated`,
          icon: "info",
        })
      }
    },
  })

  const ParameterStorage = async () => {
    setOpen(true)
    const getPmListData = await axios.get(`/api/PracticeSummary/GetParameterStorage?Path=${window.location.pathname}`)
    setOpen(false)
    if (getPmListData.data != null) {
      let GetData: any = getPmListData.data
      setDateMonthFrom(GetData.FromDate)
      setFromDate(GetData.FromDate)
      setToDate(GetData.ToDate)
      setSelectedPmSystem(JSON.parse(GetData.PMSystem))
      setSelectedPractice(JSON.parse(GetData.PracticeName))
      setSelectedReportName(JSON.parse(GetData.ReportName))

      formik.setFieldValue("PmName", JSON.parse(GetData.PMSystem).label)
      formik.setFieldValue("ReportName", JSON.parse(GetData.ReportName).label)
      formik.setFieldValue("Practice", JSON.parse(GetData.PracticeName).label)
      formik.setFieldValue("DateMonthFrom", GetData.FromDate)
      let PMValue = JSON.parse(GetData.PMSystem).value
      axios.post(getPracticeList, { PostData: PMValue }).then((res) => {
        setPracticeListData(res.data.data)
      })
    }
  }
  useEffect(() => {
    GetPmList()
    ParameterStorage()
  }, [])
  const getPractice = (event: any) => {
    if (Object.keys(event).length > 0) {

      // var PostData = {
      //   PracticeName: event.label,
      // }
      // axios.post(`/api/PracticeSummary/GetProviderList`, PostData).then((res) => {

      //   if (res.data.ProviderList.length > 0) {
      //     setProviderList(res.data.ProviderList)
      //   }else {
      //     setProviderList([])
      //   }
      // })

      setSelectedPractice(event)
      formik.setFieldValue("Practice", event.label)
    } else {

      setSelectedPractice({ label: "", value: "" })
    }

  }
  // const getLocationId = (event: any) => {
  //   setSelectedLocationId(event)

  // }
  const getReportNameColumns = (event: any) => {
    setSelectedReportName(event)
    if (event.value) {
      formik.setFieldValue("ClaimColumns", [])
      setClaimDataFields([])
      SelectedFieldsNamesList([])
      formik.setFieldValue("ReportName", event.label)
      var PostData = {
        tableName: event.value,
        ReportName: event.label,
        Practice: formik.values.Practice,
      }
      axios.post(`/api/PracticeSummary/getColumnNames`, PostData).then((res) => {
        if (res.data.Data.length > 0) {
          setClaimDataFields(res.data.Data)
          if (res.data.AlreadyDefineColumns.length > 0) {
            var ClaimColumnsDataFil: any = []
            res.data.AlreadyDefineColumns.map((res: any) => {
              formik.setFieldValue(res.value, res.label)
              ClaimColumnsDataFil.push({
                label: res.value,
                value: res.value,
              })
            })

            SelectedFieldsNamesList(res.data.AlreadyDefineColumns)
            formik.setFieldValue("ClaimColumns", ClaimColumnsDataFil)
          } else {

          }
        } else {
          swal({
            title: '',
            text: `${res.data.Message}`,
            icon: "info",
          }).then((res: any) => {
          })

          setClaimDataFields([])
          SelectedFieldsNamesList([])
        }

      })

    } else {
      setClaimDataFields([])
      SelectedFieldsNamesList([])
    }

  }

  const getPMSystem = (event: any) => {
    //setSelectedPractice([])
    setSelectedPmSystem(event)
    if (!event.value) {
      formik.setFieldError("PmName", "PM System is required")
    } else {
      formik.setFieldError("PmName", "")
    }

    if (event.value) {
      formik.setFieldValue("PmName", event.value)
      axios.post(getPracticeList, { PostData: event.value }).then((res) => {
        setPracticeListData(res.data.data)
      })
    }
  };
  var PmListData = [{ label: "Select", value: "" }]
  getPmListData.map((name) => (
    PmListData.push({
      label: name.PmName,
      value: name._id
    })
  ))
  const handleChangeMultiColumnMulti = (event: { target: { value: any, name: any } }) => {
    var name: any
    name = event.target.name
    var value = event.target.value
    var Data = { [name]: value }

    console.log("name", name, event.target.value)
    formik.setFieldValue(name, event.target.value)
  }
  const handleDateChangeTo = (date: any) => {
    setDateMonthTo(date)
    setToDate(moment(date['$d']).format("MMM-YYYY"))
  };
  const handleDateChangeFrom = (date: any) => {
    setDateMonthFrom(date)
    formik.setFieldValue("DateMonthFrom", moment(date['$d']).format("MMM-YYYY"))
    setFromDate(moment(date['$d']).format("MMM-YYYY"))
  };
  const handleChangeMultiColumn = (e: any) => {
    SelectedFieldsNamesList(e)
    formik.setFieldValue("ClaimColumns", e)
  }
  const ARCollectionByUser = async (e: any) => {
    e.preventDefault();
    let error = false
    let ErrorArray = []
    if (!SelectedPmSystem.value) {
      ErrorArray.push('Please Select PM System');
      error = true
    }
    if (!SelectedPractice.value) {
      ErrorArray.push('Please Select Practice');
      error = true
    }
    if (!DateMonthFrom) {
      ErrorArray.push('Please Select From Month');
      error = true
    }
    if (error) {
      ErrorArray.map((res, ind) => {
        toast.error(`${res}`, ToastOption);
      })
      return false;
    }
    setOpen(true)
    await axios.get(e.target.href).then((response) => {
      if (response.status == 200) {
        setOpen(false)
        const url = window.location.origin+"/"+response.data;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download',"");
        document.body.appendChild(link);
        link.click();
      } else {
        toast.info(`No Data Found`, ToastOption);
      }
    }).catch((error) => console.log(error));
    return false;
  }
  const onDrop = useCallback((acceptedFiles: any[]) => {

    var filestotal: any = acceptedFiles.map((file: any) => Object.assign(file, {
      preview: URL.createObjectURL(file)
    }))
    setChildData(filestotal)

  }, [onFileUploaded]);

  const {
    acceptedFiles,
    getRootProps,
    getInputProps
  } = useDropzone({

    onDrop,
    accept: {
      'application/vnd.ms-excel': ['.xlsx']
    },
  });
  const acceptedFileItems: any = acceptedFiles.map((file: any) => {

    return (<li key={file.path}>
      {file.path} - {bytesForHuman(file.size)}
    </li>)
  });
  const shouldDisableDate = (date: any) => {
    return date > new Date(); // Disable dates greater than the current date (future dates)
  };
  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Collected Payment Review</h3>
        </div>
      </div>
      <hr />
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div
          className='d-flex flex-column  me-n7 pe-7'
          id='kt_modal_add_user_scroll'
        //data-kt-scroll='true'
        // data-kt-scroll-activate='{default: false, lg: true}'
        // data-kt-scroll-max-height='auto'
        // data-kt-scroll-dependencies='#kt_modal_add_user_header'
        // data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
        // data-kt-scroll-offset='300px'
        >
          <Formik initialValues={{ myField: '', }} onSubmit={values => { console.log(values); }}>
            <div className='row'>
              <div className='col-3 fv-row mb-7'>
                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                  <span className='required'>PM System</span>
                </label>
                <Select maxMenuHeight={180} value={SelectedPmSystem} onChange={getPMSystem} id="PmId" name={"PmId"} options={PmListData} />

                {formik.touched.PmName && formik.errors.PmName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.PmName}</div>
                  </div>
                )}
              </div>

              <div className='col-sm-3 fv-row'>
                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                  <span className='required'>Practice Name</span>
                </label>

                <Select maxMenuHeight={180} value={SelectedPractice} onChange={getPractice} id="Practice" name={"Practice"} options={PracticeListData} />

                {formik.touched.Practice && formik.errors.Practice && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.Practice}</div>
                  </div>
                )}
              </div>

              <div className='col-sm-3 fv-row'>
                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                  <span className='required'>Select From</span>
                </label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker

                    value={DateMonthFrom}
                    minDate={moment("2015")}
                    renderInput={(params: any) => <TextField  {...params} />}
                    maxDate={moment()}
                    views={['year', 'month']} disableFuture
                    onChange={handleDateChangeFrom}
                  />
                </LocalizationProvider>
              </div>
              <div className='col-sm-3 fv-row pt-11'>
                
                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-users-modal-action='submit'
                  disabled={formik.isSubmitting}
                >
                  <span className='indicator-label'>Update</span>
                  {(formik.isSubmitting) && (
                    <span className='indicator-progress'>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>

                <a rel="noreferrer" onClick={(e) => { ARCollectionByUser(e) }} href={`/api/PracticeSummary/ARCollectionByUser?practice_id=${SelectedPractice.label}&ReportName=${SelectedReportName.value}&month_year=${FromDate}`} className=' m-1 btn btn-lg btn-primary me-3'>Download</a>

              </div>
            </div>
          </Formik>
        </div>

        <div>
        </div>
      </form>
      {/* {isLoading && <PageLoading />} */}
    </>
  )
}
export { CollectedPaymentsReview }