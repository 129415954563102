import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { ChargesCollectionsByLocation } from "../pages/dashboard/ChargesCollectionsByLocation"
import { PaymentsBreakDownbyProvider } from "../pages/dashboard/PaymentsBreakDownbyProvider"
import { PaymentsBreakDownbyPayer } from "../pages/dashboard/PaymentsBreakDownbyPayer"
import { ARDetails } from "../pages/dashboard/ARDetails"
import { DaysInAR } from "../pages/dashboard/DaysInAR"
import { NetCollectionRatio } from "../pages/dashboard/NetCollectionRatio"
import { WaterFallCollectionReport } from "../pages/dashboard/WaterFallCollectionReport"
import { RejectionRate } from "../pages/dashboard/RejectionRate"
import { DenialRate } from "../pages/dashboard/DenialRate"
import { ClaimUpload } from "../pages/dashboard/ClaimUpload/ClaimUpload"
import { FindyourDrugsPL } from "../pages/dashboard/ClaimUpload/FindyourDrugsPL"
import { CPTReport } from "../pages/dashboard/ClaimUpload/CPTReport"
import { ViewUploadedData } from "../pages/dashboard/ClaimUpload/ViewUploadedData"
import ReimbursementAuditReport from "../pages/dashboard/ReimbursementAuditReport"
import ProfitLoss from "../pages/dashboard/ProfitLoss"
import { CleanClaimRatio } from "../pages/dashboard/CleanClaimRatio"
import { BenchMark } from "../pages/dashboard/BenchMark"
import { CreatePractice } from "../pages/dashboard/CreatePractice"
import { MonthEndData } from "../pages/dashboard/MonthEndData";
import { CreatePracticeDefineFields } from "../pages/dashboard/PracticeFields/CreatePracticeDefineFields"
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import OpenClaimsAR from '../pages/dashboard/OpenClaimsAR'
import { UploadFeeSchedule } from '../pages/dashboard/UploadFeeSchedule/UploadFeeSchedule'
import { ExportPDF } from '../pages/dashboard/ExportPDF'
import RestrictReports from "../pages/dashboard/RestrictReports"
import { ChargesByCPTClass } from '../pages/dashboard/two_dashboard/ChargesByCPTClass'
import { PaymentsByCPTClass } from '../pages/dashboard/two_dashboard/PaymentsByCPTClass'
import { ProviderProductivity } from '../pages/dashboard/two_dashboard/ProviderProductivity'
import { PayerMixReport } from '../pages/dashboard/two_dashboard/PayerMixReport'
import { ProfitLossDash } from '../pages/dashboard/two_dashboard/ProfitLossDash'
import { ClaimUploadARData } from "../pages/dashboard/ClaimUpload/ClaimUploadARData"
import { GuarantorAging } from '../pages/dashboard/two_dashboard/GuarantorAging'
import { DrugNonDrug } from '../pages/dashboard/two_dashboard/DrugNonDrug'
import { ExportPDFCom } from '../pages/dashboard/two_dashboard/ExportPDFCom'
import PracticeSummaryDownload from "../pages/dashboard/PracticeSummaryDownload"
import {DailyStatusUpload} from "../pages/dashboard/ClaimUpload/DailyStatusUpload"
import PowerBI from "../pages/dashboard/PowerBI"
import { SampleTemplateReport } from '../pages/dashboard/ClaimUpload/SampleTemplateReport'
import { UploadFee } from '../pages/dashboard/UploadFeeSchedule/UploadFee'
import { UploadPowerBIReports } from "../pages/dashboard/ClaimUpload/UploadPowerBIReports"
import { CollectedPaymentsReview } from "../pages/dashboard/ClaimUpload/CollectedPaymentsReview"
const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const TemplatePage = lazy(() => import('../modules/apps/sample-template/TemplatePage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const RolePage = lazy(() => import('../modules/apps/role-manegement/RolePage'))
  const CreateClaimTemplate = lazy(() => import('../modules/ClaimTemplate/CreateClaimTemplate'))
  // const PracticeSummaryDownload = lazy(() => import('../pages/dashboard/PracticeSummaryDownload'))
  // const OpenClaimsARLazy=lazy(()=>import ('../pages/dashboard/OpenClaimsAR'))


  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='ChargesCollectionsByLocation' element={<ChargesCollectionsByLocation />} />
        <Route path='PaymentsBreakDownbyProvider' element={<PaymentsBreakDownbyProvider />} />
        <Route path='PaymentsBreakDownbyPayer' element={<PaymentsBreakDownbyPayer />} />
        <Route path='OpenClaimsAR' element={<OpenClaimsAR />} />

        {/* <Suspense fallback="Loading...">
  <OpenClaimsARLazy />
</Suspense> */}
        <Route path='ProfitLoss' element={<ProfitLoss />} />
        <Route path='ReimbursementAuditReport' element={<ReimbursementAuditReport />} />
        <Route path='CreatePractice' element={<CreatePractice />} />
        <Route path='RejectionRate' element={<RejectionRate />} />
        <Route path='DenialRate' element={<DenialRate />} />
        <Route path='CleanClaimRatio' element={<CleanClaimRatio />} />
        <Route path='BenchMark' element={<BenchMark />} />
        <Route path="MonthEndData" element={<MonthEndData />} />
        <Route path='ClaimUpload' element={<ClaimUpload />} />
        <Route path='CPTReport' element={<CPTReport />} />
        <Route path='UploadPowerBIReports' element={<UploadPowerBIReports />} />

        <Route path='SampleTemplateReport' element={<SampleTemplateReport />} />
        <Route path='SampleTemplateFeeSchedule' element={<UploadFee />} />
        <Route path='DefineFields' element={<CreatePracticeDefineFields />} />
        <Route path='ARDetails' element={<ARDetails />} />
        <Route path='DaysInAR' element={<DaysInAR />} />
        <Route path='OpenClaimsAR' element={<OpenClaimsAR />} />
        <Route path='ViewUploadedData' element={<ViewUploadedData />} />
        <Route path='UploadFeeSchedule' element={<UploadFeeSchedule />} />
        <Route path='WaterFallCollectionReport' element={<WaterFallCollectionReport />} />
        <Route path='ExportPDF' element={<ExportPDF />} />
        <Route path='NetCollectionRatio' element={<NetCollectionRatio />} />
        <Route path='CreateClaimTemplate/*' element={<CreateClaimTemplate />} />
        <Route path='PracticeSummaryDownload' element={<PracticeSummaryDownload />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        {/* Comparative Dashboard */}
        <Route path="ChargesByCPTClass" element={<ChargesByCPTClass />} />
        <Route path="PaymentsByCPTClass" element={<PaymentsByCPTClass />} />
        <Route path="ProviderProductivity" element={<ProviderProductivity />} />
        <Route path="PayerMixReport" element={<PayerMixReport />} />
        <Route path="ProfitLossDash" element={<ProfitLossDash />} />
        <Route path="ARClaimUpload" element={<ClaimUploadARData />} />
        <Route path="GuarantorAging" element={<GuarantorAging />} />
        <Route path="DrugNonDrug" element={<DrugNonDrug />} />
        <Route path="ComExportPDF" element={<ExportPDFCom />} />
        <Route path="PowerBI" element={<PowerBI />} />
        <Route path="RestrictPowerBI" element={<RestrictReports />} />
        <Route path="FindyourDrugsPL" element={<FindyourDrugsPL />} />
        <Route path="CollectedPaymentsReview" element={<CollectedPaymentsReview />} />
        <Route path="DailyStatusUpload" element={<DailyStatusUpload />} />
        

        <Route path='menu-test' element={<MenuTestPage />} />
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/sample-template/*'
          element={
            <SuspensedView>
              <TemplatePage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/role-management/*'
          element={
            <SuspensedView>
              <RolePage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }