import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import axios from "axios";
import { useAuth } from '../../modules/auth'
import Backdrop from '@mui/material/Backdrop';
import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import $ from "jquery"
import Select from 'react-select';
import swal from "sweetalert"
import moment from "moment";
import TextField from '@mui/material/TextField';
const getPracticeName = `/api/PracticeSummary/getPracticeName`
const getDaysinAr = `/api/PracticeSummary/getDaysinAr`
const GetARReport = `/api/DenialReports/GetARReport`
const getArAmount = `/api/DenialReports/getArAmount`

const GetClaimOutstandingCopy = `/api/DenialReports/GetClaimOutstandingCopy`
const postUpdateReason = `/api/DenialReports/UpdateReason`


const options = { style: 'currency', currency: 'USD' };

type InsuranceType={
  label:string,
  value:string
}
const OpenClaimsAR: React.FC = () => {
  const intl = useIntl()
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails: any = location.state;
  const { currentUser }: any = useAuth()
  const [UserNameDatas, setUserNameDatas] = useState([{ value: "", label: "" }])
  const [DateMonthFrom, setDateMonthFrom] = useState("")
const [FromDate, setFromDate] = useState("")
const [ToDate, setToDate] = useState("")
  const [DateMonthTo, setDateMonthTo] = useState("")
  const [SelectedPracticeList, setSelectedPracticeList] = useState([])
  const [open, setOpen] = React.useState(false);
  const [SelectedInsurance,setSelectedInsurance]=useState<any>([])
const [TableData,setTableData]=useState<any>([])
const [ArAmount,setArAmount]=useState<any>({})
const [Amount,setAmount]=useState<number>(0)
const [formData,setFormData]=useState<any>({
  aging:"",
  Reason:"",
  Amount:"",
  })
 const [GetReportDis,setGetReportDis]=useState<boolean>(true)

  const FilterDataList = async () => {

    setOpen(true)

    const res1=await axios.get(`${GetClaimOutstandingCopy}?ChooseDate=${FromDate}&PracticeName=${Object.keys(SelectedPracticeList).length > 0 ? JSON.stringify(SelectedPracticeList) : ""}`);


    const res=await axios.get(`${GetARReport}?ChooseDate=${FromDate}&PracticeName=${Object.keys(SelectedPracticeList).length > 0 ? JSON.stringify(SelectedPracticeList) : ""}`);
    setOpen(false)
    if (res.data.data) {
      swal({
        title: 'AR Report',
        icon: 'success',
        text: `${res.data.data} :(`,
      }).then((res: any) => {})
      return false
    }
    
  }

  const mergedDataFn =  (mergedData:any)=>{
    const mergedDataOne=mergedData.reduce((acc:any, obj:any) => {
        const key = obj.aging;
        if (!acc[key]) {
          acc[key] = {};
        }
        Object.assign(acc[key], obj);
        return acc;
      }, {});
   
      return Object.values(mergedDataOne);
    
}

const handleInsuranceChange=async(e:any)=>{
  
  if (!DateMonthFrom) {
    swal({
      title: 'Month',
      icon: "info",
      text: `Please choose Month `,
    }).then((_res: any) => {

    })
    return false;
  }
  if (SelectedPracticeList.length ===0) {
    swal({
      title: 'Parctice',
      icon: "info",
      text: `Please choose any Practice`,
    }).then((_res: any) => {

    })
    return false;
  }


 setTableData([])
 setAmount(0)
  setSelectedInsurance(e)
  setFormData((prev:any)=>{
    return {
      ...prev,
      aging:e.label
    }
  })

//fetch ar amount
const res=await axios.get(`${getArAmount}?insurance=${e.label}&DateMonthFrom=${FromDate}&SelectedPracticeList=${JSON.stringify(SelectedPracticeList)}`)
// setArAmount((prev:any)=>[...prev,...res.data.FetchedData])
setArAmount(res.data.FetchedData)
}


const handleInputChange=(e:any)=>{
  const {name,value}=e.target;

  setFormData((prev:any)=>{
    return {
      ...prev,
      [name]:value
    }
  })
}



  const getSelectedPractice = (event: any) => {
    setSelectedPracticeList(event)
    setGetReportDis(false)
  }
  
  
  const handleDateChangeFrom = (date: any) => {
    setDateMonthFrom(date)
setFromDate(moment(date['$d']).format("MMM-YYYY"))
  };



  const GetReport = async (ids: any) => {

    if (!DateMonthFrom) {
      swal({
        title: 'Month',
        icon: "info",
        text: `Please choose Month `,
      }).then((_res: any) => {

      })
      return false;
    }
    if (SelectedPracticeList.length ===0) {
      swal({
        title: 'Parctice',
        icon: "info",
        text: `Please choose any Practice`,
      }).then((_res: any) => {

      })
      return false;
    }
  
       
    FilterDataList()
  }

  const handleAdd=()=>{
    if (SelectedPracticeList.length===0) {
      swal({
        title: 'Parctice',
        icon: "info",
        text: `Please choose any Practice`,
      }).then((_res: any) => {

      })
      return false;
    }
    if (!DateMonthFrom) {
      swal({
        title: 'Month',
        icon: "info",
        text: `Please choose Month `,
      }).then((_res: any) => {

      })
      return false;
    }

    if (formData.Reason==="") {
      swal({
        title: 'Reasons',
        icon: "info",
        text: `Please Update your Reasons`,
      }).then((_res: any) => {

      })
      return false;
    }
    if (formData.Amount ==="") {
      swal({
        title: 'Amount',
        icon: "info",
        text: `Please Update your Amount`,
      }).then((_res: any) => {

      })
      return false;
    }
      setTableData((prev:any)=>[...prev,formData])
      setAmount((prev:any)=>prev+Number(formData.Amount))
      setFormData({aging:SelectedInsurance.label,
      Reason:"",
      Amount:""})
      
  }

const UpdateReason=async()=>{
   
  const obj={
  Practice:SelectedPracticeList,
  Month:FromDate,
  ExpectedAr:Number($("#ExpectedValue").val())

  }

  const res=await axios.post(postUpdateReason,{TableData,obj})

  if (res.data.data) {
    swal({
      title: 'Reasons',
      icon: 'success',
      text: `${res.data.data} :(`,
    }).then((_res: any) => {})
    return false
  }

}

  const getPracticeNameata = async () => {
    const Result = await axios.get(getPracticeName)
    setUserNameDatas(Result.data.PracticeData)
  }
  useEffect(() => {
    getPracticeNameata()
  }, [])


  var UsersListData: any = []
  UserNameDatas.map((practiceList: any, index) => {
    UsersListData.push({
      label: practiceList.practice_name,
      value: practiceList.practice_name
    })
  })

  let InsuranceOptions:InsuranceType[]=[
    {label:"0-30",value:'0-30'},
    {label:"30-60",value:'30-60'},
    {label:"60-90",value:'60-90'},
    {label:"90-120",value:'90-120'},
    {label:"Above 120",value:'Above 120'},
  ]

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
        <Typography variant="h6" component="div" color="inherit" mt={2} ml={4}>
          Loading...
        </Typography>
      </Backdrop>


      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <div >
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Open Claims AR</h3>
          </div>
        </div>
        <hr />

        <div className='row'>
          
            <>
              <div className='col-sm-4 fv-row'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>
                  <span className='required'>Select Month/Year</span>
                </label><br />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                  value={DateMonthFrom}
                  minDate={moment("2015")}
                  renderInput={(params:any) => <TextField {...params}  />}
                  maxDate={moment()}
                  views={['year', 'month']} disableFuture
                    onChange={handleDateChangeFrom}
                    
                  />
                </LocalizationProvider>
              </div>
            
              <div className='col-sm-4 fv-row'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>
                  <span className=''>Select Practice</span>
                </label>

                <Select maxMenuHeight={180} value={SelectedPracticeList}  onChange={getSelectedPractice} id="Practice" name={"Practice"} options={UsersListData} />

              </div>
              </>
              { FromDate == moment().format("MMM-YYYY") || FromDate ==  moment().subtract(1,'months').format("MMM-YYYY") ? 
              <div className='col-3 pt-13'>
              <button onClick={(e) => { GetReport(e) }} className='btn btn-primary' disabled={GetReportDis} >Get Report</button>
            </div> : null }
              
          

          <div className="row">
          <div className='col-sm-3 fv-row'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>
                  <span className=''>Insurance AR </span>
                </label>

                <Select maxMenuHeight={180} value={SelectedInsurance}  onChange={handleInsuranceChange} id="Practice" name="Insurance" options={InsuranceOptions} />

              </div>
              <div className='col-sm-3 fv-row'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>
                  <span className=''>AR Balance</span>
                </label>
                <input 
                className='p-2 w-100'
                name='Reason'
                value={ArAmount.total_claim_bal ? ArAmount.total_claim_bal.toLocaleString('en-US', options): ArAmount.total_claim_bal}
                onChange={handleInputChange}
                disabled
                />

              </div>

              <div className='col-sm-4 fv-row'>
                <label className='col-lg-6 col-form-label fw-bold fs-6 '>
                  <span className='required'>Reason</span>
                </label><br />
                <input 
                className='p-2 w-100'
                name='Reason'
                value={formData.Reason}
                onChange={handleInputChange}
                />
              </div>

              {/* <div className='col-sm-3 fv-row'>
                <label className='col-lg-6 col-form-label fw-bold fs-6 '>
                  <span className='required'>Amount</span>
                </label><br />
                <input 
                className='p-2 w-100'
                name='Amount'
                value={formData.Amount}
                onChange={handleInputChange}
                />
              </div> */}

<div className='col-sm-3 fv-row'>
                <label className='col-form-label fw-bold fs-6 '>
                  <span className='required'>Amount</span>
                </label>
                <div className="input-group mb-3">
  <span className="input-group-text">$</span>
  <input type="number" className="form-control" 
  placeholder='Amount'
  name='Amount'
                value={formData.Amount}
                onChange={handleInputChange}
  />
</div>

            
              </div>



              
              <div className='col-2 pt-13'>
            <button onClick={handleAdd} className='btn btn-primary'  >Add</button>
          </div>


              </div>

              {TableData.length>0 ? (
              <div className='p-4 mt-4'>
              <table  id='kt_table_users'
              className='table table-striped align-middle fs-6 p-4  font-family-base no-footer col-12 h-full '>
               <thead className='thead-dark bg-dark'>
                  <tr>
                  <th className='text-center p-2 text-white' >S.No</th>
                     <th className='text-center p-2 text-white'>Reason</th>
                    <th className='text-center p-2 text-white'>Amount</th>
                    <th className='text-center p-2 text-white'>AR Amount</th>
                  
                  
                  </tr>
                </thead>
                <tbody>
                 <tr className='py-10 px-4'>
                    <td className='px-3 py-2 text-center'></td>
                  <td className='px-3 py-2 text-center' style={{fontSize:"16px",fontWeight:600}}>{`Total AR in ${ArAmount.aging} days`}</td>
                  <td className='px-3 py-2 text-center'></td>
                  <td className='px-3 py-2 text-center' style={{fontSize:"16px",fontWeight:600}}>{ArAmount.total_claim_bal.toLocaleString('en-US', options)}</td>

                </tr>
                {TableData.map((res: any, _inde: any) => {
              return (
                <>
                                <tr className='py-10 px-4'>
                    <td className='px-3 py-2 text-center col-1'>{Number(_inde) + 1}</td>
                    <td className='px-3 py-2 text-center col-6 text-wrap'>{res.Reason ? res.Reason : '-'}</td>
                    <td className='px-3 py-2 text-center'>${res.Amount ? res.Amount.toLocaleString('en-US', options) : '-'}</td>
                    
                  </tr>
                </>
              )
            })}
                </tbody>
                <thead>
                  <tr>
                  <th className='px-3 py-2 text-center'></th>
                  <th className='px-3 py-2 text-center' style={{fontSize:"16px",fontWeight:600}}>Expected AR</th>     
                  <th className='px-3 py-2 text-center'></th>   
                  <th className='px-3 py-2 text-center' style={{fontSize:"16px",fontWeight:600}}>{(Number(ArAmount.total_claim_bal)- Number(Amount)).toLocaleString('en-US', options)}</th>   
                  </tr>
  </thead>
              </table>
              </div>
             ) : null} 

{TableData.length>0 ? (
  <div className='d-flex ustify-content-end'>
  <div className='col-3 pt-13 j'>
 <button onClick={UpdateReason } className='btn btn-primary'  >Update Reason</button>
 </div>
</div>
) : null}

<input id="ExpectedValue" value={Number(ArAmount.total_claim_bal)- Number(Amount)} hidden/>

        </div>
        <hr />

      </div>
    </>
  )
}
export default  OpenClaimsAR;