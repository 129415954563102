import React, { useState, useEffect ,FunctionComponent} from 'react'
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { PageTitle } from '../../../../_metronic/layout/core'
import axios from "axios";
import { toast } from 'react-toastify';
import ToastOption from '../Common/ToastOption'
import { useAuth } from '../../../modules/auth'
import { Chart } from "react-google-charts";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Select from 'react-select';
import swal from "sweetalert"
import moment from "moment";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Legend } from "recharts";


const getPracticeName = `/api/PracticeSummary/getPracticeName`
const getProfitLoss=`/api/ChargesWise/getProfitLoss`

interface PracticeListType{
    label:string,
    value:string
}


const options : any = { style: 'currency', currency: 'USD' };
const formatDollarValue = (value: { toLocaleString: () => any; }) => `$${value.toLocaleString()}`;

const colors = [
  "#1f77b4", // blue
  "#ff7f0e", // orange
  "#2ca02c", // green
  "#d62728", // red
  "#9467bd", // purple
  "#8c564b", // brown
  "#e377c2", // pink
  "#7f7f7f", // gray
  "#bcbd22", // lime
  "#17becf"  // cyan
];

const ProfitLossDash: React.FC = () => {
  const intl = useIntl()
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails: any = location.state;
  const { currentUser }: any = useAuth()
  const [UserNameDatas, setUserNameDatas] = useState([{ value: "", label: "" }])
  const [DateMonthFrom, setDateMonthFrom] = useState("")
const [FromDate, setFromDate] = useState("")
const [ToDate, setToDate] = useState("")
  const [DateMonthTo, setDateMonthTo] = useState("")
  const [SelectedPracticeList, setSelectedPracticeList] = useState<PracticeListType[]>([])
  const [open, setOpen] = React.useState(false)
  const [ProfitChart,setProfitChart]=useState<any>([])
  const [LossChart,setLossChart]=useState<any>([])
  const [NcrData, setNcrData] = useState<any>([])
   const [SumData,setSumData]=useState<number>(0)
   const [NoData,setNoData]=useState<boolean>(false);
  const FilterDataList = async () => {
    setNoData(false)
    setProfitChart([])
    setLossChart([])
    setNcrData([])
    setOpen(true)
    var tempValues = {
      UserId :currentUser._id,
      Path:window.location.pathname,
      FromDate:FromDate,
      ToDate:ToDate,
      PracticeName : JSON.stringify(SelectedPracticeList)
    }
    axios.get(`${getProfitLoss}?tempValues=${JSON.stringify(tempValues)}&FromDate=${FromDate}&ToDate=${ToDate}&PracticeName=${JSON.stringify(SelectedPracticeList)}`).then(_res => {
      if(_res.data.message){
        setNoData(true)
        setOpen(false)

      }
      else{

        setProfitChart(_res.data.ProfitResult)
        setLossChart(_res.data.LossResult)
        setNcrData(_res.data.FinalTable)
        setSumData(_res.data.SumData)
        setOpen(false)
      }


    })
  }

  const ParameterStorage = async () => {
    setOpen(true)
    const getPmListData = await axios.get(`/api/PracticeSummary/GetParameterStorage?Path=${window.location.pathname}`)
    setOpen(false)
    if (getPmListData.data != null) {
      let GetData :any= getPmListData.data
      setDateMonthFrom(GetData.FromDate)
      setDateMonthTo(GetData.ToDate)
      setFromDate(GetData.FromDate)
      setToDate(GetData.ToDate)
      setSelectedPracticeList(JSON.parse(GetData.PracticeName))
    }
  }
  const getPath = (x: number, y: number, width: number, height: number) => {
    return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${
      y + height / 3
    } 
    ${x + width / 2}, ${y}
    C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${
      x + width
    }, ${y + height}
    Z`;
  };
  
  const TriangleBar: FunctionComponent<any> = (props: any) => {
    let { fill, x, y, width, height } = props;
  
    return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
  };
  

  const getSelectedPractice = (event: any) => {
    setProfitChart([])
    setLossChart([])
    setNcrData([])
    setSumData(0)
    setSelectedPracticeList(event)
  }
  
  const handleDateChangeFrom = (date: any) => {
    setDateMonthFrom(date)
setFromDate(moment(date['$d']).format("MMM-YYYY"))
  };

  const handleDateChangeTo = (date: any) => {
    setDateMonthTo(date)
setToDate(moment(date['$d']).format("MMM-YYYY"))
  };


  const Filter = async (ids: any) => {

    let error = false
    let ErrorArray = []
    if (!DateMonthFrom) {
      error = true
      ErrorArray.push(`Please Choose From Month / Year`)
    }
    if (!DateMonthTo) {
      error = true
      ErrorArray.push(`Please Choose To Month / Year`)
    }
    if(Object.keys(SelectedPracticeList).length == 0){
      error = true
      ErrorArray.push(`Please Select Practice`)
    }
    if(error){
      ErrorArray.map((res,ind)=>{
        toast.error(`${res}`,ToastOption);
      })
      return false;
    }
    
    
    FilterDataList()
  }
  const ClearFilter = async (ids: any) => {

    setDateMonthFrom("")
    setSelectedPracticeList([])

  }
  const getPracticeNameata = async () => {
    const Result = await axios.get(getPracticeName)
    setUserNameDatas(Result.data.PracticeData)
  }
  useEffect(() => {
    getPracticeNameata()
    ParameterStorage()
  }, [])


  var UsersListData: any = []
  UserNameDatas.map((practiceList: any, index) => {
    UsersListData.push({
      label: practiceList.practice_name,
      value: practiceList.practice_name
    })
  })
 
  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
        
      </Backdrop>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle> */}
      <div >
        {/* <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        > */}
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Profit Loss Dashboard</h3>
          </div>
        {/* </div> */}
        <hr />
        <div className='row'>
          
            <>
              <div className='col-sm-3 fv-row'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>
                  <span className='required'>From</span>
                </label><br />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                  value={DateMonthFrom}
                  minDate={moment("2015")}
                  renderInput={(params:any) => <TextField {...params}  />}
                  maxDate={moment()}
                  views={['year', 'month']} disableFuture
                    onChange={handleDateChangeFrom}
                  />
                </LocalizationProvider>
              </div>

              <div className='col-sm-3 fv-row'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>
                  <span className='required'>To</span>
                </label><br />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                  value={DateMonthTo}
                  minDate={moment("2015")}
                  renderInput={(params:any) => <TextField {...params}  />}
                  maxDate={moment()}
                  views={['year', 'month']} disableFuture
                    onChange={handleDateChangeTo}
                  />
                </LocalizationProvider>
              </div>

              <div className='col-sm-3 fv-row'>
                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                  <span className='required'>Select Practice</span>
                </label>

                <Select maxMenuHeight={180} value={SelectedPracticeList}  onChange={getSelectedPractice} id="Practice" name={"Practice"} options={UsersListData} />

              </div>          
              
              </>
          <div className='col-1 pt-13'>
            <button onClick={(e) => { Filter(e) }} className='btn btn-primary'  >Filter</button>
          </div>
          <div className='col-1 pt-13'>
            <button onClick={(e) => { ClearFilter(e) }} className='btn btn-primary'  >Clear</button>
          </div>
       
        </div><hr />


{ProfitChart.length > 0 || LossChart.length > 0 || NcrData.length > 0 ? 
         <div>

          {ProfitChart.length > 0 ? (
            <>
          <h4 className='fs-3 uppercase text-center'>Top 10 Profitable Drugs (Based on Drug Pricing)</h4>
 <div className='col-12 my-10' style={{background:"#dbd8e3"}}>
<BarChart
      width={900}
      height={400}
      data={ProfitChart}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="cpt_codes" />
      <YAxis />
      <Legend />
      <Bar
        dataKey="Payment"
        fill="#8884d8"
        shape={<TriangleBar />}
        label={{ position: "top" ,formatter: formatDollarValue }}
      >
        {ProfitChart.map((entry:any, index:number) => (
          <Cell key={`cell-${index}`} fill={colors[index]} />
        ))}
      </Bar>
    </BarChart>
 </div>
 </>
          ) : null}


{LossChart.length > 0 ? (
   <>
   <h4 className='fs-3 uppercase text-center'>Top 10 Loss making drugs(Based on Drug Pricing)</h4>
 <div className='col-12 my-10' style={{background:"#dbd8e3"}}>
<BarChart
      width={900}
      height={400}
      data={LossChart}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="cpt_codes" />
      <YAxis />
      <Legend />
      <Bar
        dataKey="Payment"
        fill="#8884d8"
        shape={<TriangleBar />}
        label={{ position: "top",formatter: formatDollarValue  }}
      >
        {ProfitChart.map((entry:any, index:number) => (
          <Cell key={`cell-${index}`} fill={colors[index]} />
        ))}
      </Bar>
    </BarChart>
 </div>

      </>    ) : null}


          {NcrData.length > 0 ? (
            <table
                id='kt_table_users'
                className=' table-bordered align-middle fs-6 p-4  font-family-base no-footer col-12 h-full '
              >
                <thead style={{background:"#a6b1e1"}}>
                  <tr>
                    <th className='text-center p-2'>CPT Codes</th>
                    <th className='text-center p-2' >CPT Description</th>
                    <th className='text-center p-2' >Amount</th>
                    <th className='text-center p-2' >Profit/Loss</th>

                  </tr>
                  
                </thead>
                <tbody style={{background:"#e7eaf6"}}>
                  {NcrData.map((res: any, ind: any) => {
                    return (
                      <tr key={ind}>
                        <td className='px-3 py-2 text-center'>{res.cpt_codes ? res.cpt_codes : 'None'}</td>
                        <td className='px-3 py-2 text-center'>
                          {res.cpt_description ? res.cpt_description : 'None'}
                        </td>
                        <td className='px-3 py-2 text-center'>
                          {res.Payment ? res.Payment.toLocaleString('en-US', options) : '0'}
                        </td>
                        <td className={`px-3 py-2 text-center text-uppercase ${res.pl_type === "Profit" ? "bg-success" : "bg-danger"}`}>
                          {res.pl_type ? res.pl_type : '0'}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
          ): null}
         
       


         {NcrData.length > 0 && (

<div className='col-6 shadow rounded my-6 p-4'>
  <h5>Note:</h5>
  <p className='py-2 '>The total amount yet to receive for:<span className='text-dark fw-bold'>{SumData ? SumData.toLocaleString('en-US', options): "$0"}</span> </p>
</div>
)}

</div>    
: 
(NcrData.length===0 && LossChart.length===0 && ProfitChart.length ===0 && NoData)?
<div>
  <h5 className="text-center fw-bold">NO Data Found</h5>
</div>
                    : null
}  
       
      </div>
    </>
  )
}
export { ProfitLossDash }