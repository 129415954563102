import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import axios from "axios";
import { toast } from 'react-toastify';
import ToastOption from './Common/ToastOption'
import { useAuth } from '../../../app/modules/auth'
import { Chart } from "react-google-charts";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Select from 'react-select';
import swal from "sweetalert"
import moment from "moment";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  LabelList
} from "recharts";


const getPracticeName = `/api/PracticeSummary/getPracticeName`
const getPracticeDashboard = `/api/PracticeSummary/getPracticeDashboard`

const options = { style: 'currency', currency: 'USD' };
const formatDollarValue = (value: {toLocaleString: () => any}) => `$${value.toLocaleString()}`



const DashboardWrapper: React.FC = () => {
  const intl = useIntl()
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails: any = location.state;
  const { currentUser }: any = useAuth()
  const [UserNameDatas, setUserNameDatas] = useState([{ value: "", label: "" }])
  const [DateMonthFrom, setDateMonthFrom] = useState("")
const [FromDate, setFromDate] = useState("")
const [ToDate, setToDate] = useState("")
  const [DateMonthTo, setDateMonthTo] = useState("")

  const [SelectedPracticeList, setSelectedPracticeList] = useState([])
  const [PieChartData, setPieChartData] = useState([])
  const [open, setOpen] = React.useState(false);
  const [NoData,setNoData]=useState<boolean>(false);


  const FilterDataList = async () => {
    setPieChartData([])
    setOpen(true)
    setNoData(false)

    const tempValues = {UserId :currentUser._id,Path:window.location.pathname,FromDate:FromDate,ToDate:ToDate,PracticeName : JSON.stringify(SelectedPracticeList)}

    axios.get(`${getPracticeDashboard}?FromDate=${FromDate}&ToDate=${ToDate}&PracticeName=${SelectedPracticeList.length > 0 ? JSON.stringify(SelectedPracticeList) : ""}&tempValues=${JSON.stringify(tempValues)}`).then(_res => {
      if(_res.data.data.length ===0 ){
        setNoData(true)
        setOpen(false)
      }
      else{
        setOpen(false)
        setPieChartData(_res.data.data)
      }

    })

  }

  const getSelectedPractice = (event: any) => {
    setSelectedPracticeList(event)
  }
  
  const handleDateChangeFrom = (date: any) => {
    setDateMonthFrom(date)
setFromDate(moment(date['$d']).format("MMM-YYYY"))
  };
  const handleDateChangeTo = (date: any) => {
    setDateMonthTo(date)

setToDate(moment(date['$d']).format("MMM-YYYY"))
  };

  const Filter = async (ids: any) => {

    let error = false
    let ErrorArray = []
    if(!FromDate){
      error = true
      ErrorArray.push(`Please Choose From Month / Year`)
    }
    if(!ToDate){
      error = true
      ErrorArray.push(`Please Choose To Month / Year`)
    }
    if(SelectedPracticeList.length == 0){
      error = true
      ErrorArray.push(`Please Select Practice`)
    }
    if(error){
      ErrorArray.map((res,ind)=>{
        toast.error(`${res}`,ToastOption);
      })
      return false;
    }

    FilterDataList()
  }
  const ClearFilter = async (ids: any) => {

    setDateMonthFrom("")
    setDateMonthTo("")
    setSelectedPracticeList([])
    setPieChartData([])

  }
  const getPracticeNameata = async () => {
    const Result = await axios.get(getPracticeName)
    setUserNameDatas(Result.data.PracticeData)
  }
const ParameterStorage=async()=>{
      // setOpen(true)
      const ParameterValues = await axios.get(`/api/PracticeSummary/GetParameterStorage?Path=${window.location.pathname}`)
  // setOpen(false)
      if(ParameterValues.data != null){
        setFromDate(ParameterValues.data.FromDate)
        setToDate(ParameterValues.data.ToDate)
        setDateMonthFrom(ParameterValues.data.FromDate)
        setDateMonthTo(ParameterValues.data.ToDate)
        setSelectedPracticeList(JSON.parse(ParameterValues.data.PracticeName));


    }

}

  useEffect(() => {
    getPracticeNameata()
    ParameterStorage()
  }, [])

  
  var UsersListData: any = []
  UserNameDatas.map((practiceList: any, index) => {
    UsersListData.push({
      label: practiceList.practice_name,
      value: practiceList.practice_name
    })
  })

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <div >
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Practice Wise Charges & Collections</h3>
          </div>
        </div>
        <hr />
        <div className='row'>
          
            <>
              <div className='col-sm-3 fv-row'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>
                  <span className='required'>From</span>
                </label><br />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                  value={DateMonthFrom}
                  minDate={moment("2015")}
                  renderInput={(params:any) => <TextField {...params}  />}
                  maxDate={moment()}
                  views={['year', 'month']} disableFuture
                    onChange={handleDateChangeFrom}
                  />
                </LocalizationProvider>
              </div>
              <div className='col-sm-3 fv-row'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>
                  <span className='required'>To</span>
                </label><br />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                  value={DateMonthTo}
                  minDate={moment("2015")}
                  renderInput={(params:any) => <TextField {...params}  />}
                  maxDate={moment()}
                  views={['year', 'month']} disableFuture
                    onChange={handleDateChangeTo}
                  />
                </LocalizationProvider>
              </div>
              <div className='col-sm-3 fv-row'>
                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                  <span className='required'>Select Practice</span>
                </label>

                <Select maxMenuHeight={180} value={SelectedPracticeList} isMulti onChange={getSelectedPractice} id="Practice" name={"Practice"} options={UsersListData} />

              </div></>
          <div className='col-1 pt-13'>
            <button onClick={(e) => { Filter(e) }} className='btn btn-primary'  >Filter</button>
          </div>
          <div className='col-1 pt-13'>
            <button onClick={(e) => { ClearFilter(e) }} className='btn btn-primary'  >Clear</button>
          </div>
          {/* <div className='col-1 pt-13'>
            <a onClick={(e) => { DownloadFile(e) }} href={`/users/DownloadDashboard?ManagerID=${Object.keys(SelectedManager).length > 0 ? SelectedManager.value : ""}&UserId=${JSON.stringify(SelectedPracticeList)}&FromDate=${formData.FromDate}&ToDate=${formData.ToDate}`} className='btn btn-primary'>Download</a>
          </div> */}

        </div><hr />

        {/* <div className='row'> */}
          <div className='col-6'>

  {PieChartData.length > 0 ? 
          <BarChart
width={PieChartData.length <=3 ? 500 :900 }
height={400}

data={PieChartData}
margin={{
  top: 5,
  right: 30,
  left: 20,
  bottom: 5
}}
>
<CartesianGrid strokeDasharray="3 3" />
<XAxis dataKey="month_year" 
>
</XAxis>
<YAxis tickFormatter={formatDollarValue} />
                <Tooltip formatter={formatDollarValue} />
<Legend fontWeight="bold"/>
<Bar dataKey="Charges" fill="#8884d8"  barSize={PieChartData.length <=3 ? 40 : 30} 
>
</Bar>
<Bar dataKey="Payments" fill="#82ca9d" barSize={PieChartData.length <=3 ? 40 : 30} >
  </Bar>

</BarChart>
: null }

            </div>

            <div className='col-5 mx-2 my-8'>
{PieChartData.length > 0 ?
<table id='kt_table_users' className='table-row-gray-700 table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                  <thead>
                    <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                      <th className='text-dark text-center p-2'>Month</th>
                      <th className='text-dark text-center p-2'>Charges</th>
                      <th className='text-dark text-center p-2'>Payments</th>
                    </tr>
                  </thead>
                  <tbody >
                  {PieChartData.map((res:any,ind:number)=>{
                    return (
                    <tr key={ind}>
                      <td className='px-3 py-2 text-center'>{res.month_year ? res.month_year : "None"}</td>
                      <td className='px-3 py-2 text-center'>{res.Charges ? res.Charges.toLocaleString('en-US', options) : "$0"}</td>
                      <td className='px-3 py-2 text-center'>{res.Payments ? res.Payments.toLocaleString('en-US', options) : "$0"}</td>

                    </tr>
                    )
                  })}
                  </tbody>
                </table> :
 NoData ?
          <h5 className="fw-bold">No Data Found</h5>
        : null
}
</div>

         


        {/* </div> */}
           
          
          
      </div>
    </>
  )
}
export { DashboardWrapper }