import React, { useState, useEffect, HTMLAttributes } from 'react'
import { initialUser, User } from './_models'
import { FC, useCallback } from 'react'
import * as Yup from 'yup'
import { Formik, Field, useFormik, ErrorMessage } from 'formik'
import axios from "axios";
import { toast } from 'react-toastify';
import ToastOption from '../Common/ToastOption'
import Select from 'react-select';
import moment from 'moment'
import Backdrop from '@mui/material/Backdrop';
import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useDropzone, } from 'react-dropzone';
import { States } from "../../../../utils/data";
import { PageLoading } from '../../../modules/wizards/common/PageLoading'
import { event } from 'jquery'
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, } from '@mui/x-date-pickers/DatePicker';
const GetMonthEndData = `/api/PracticeSummary/GetMonthEndData`
const FileDownlaod = `api/Claims/FileDownlaod`
const getUploadASPfeeschedule = `api/Claims/getUploadASPfeeschedule`
const getSampleDownlaodfeeschedule = `api/PracticeSummary/getSampleDownlaodfeeschedule`
const getSpecialityList = `api/PracticeSummary/getSpecialityList`
const UploadASPfeeschedule = `/api/Claims/UploadASPfeeschedule`
const PhysicianMedicareFeeSchedule = `/api/Claims/PhysicianMedicareFeeSchedule`
const UploadMGMAStandard = `/api/Claims/UploadMGMAStandard`
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
const UserDetailsSchema = Yup.object().shape({
})

function bytesForHuman(bytes: any) {
    let units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']

    let i = 0

    for (i; bytes > 1024; i++) {
        bytes /= 1024;
    }

    return bytes.toFixed(1) + ' ' + units[i]
}
function onFileUploaded(file: any) {
    console.log("file", file)
    throw new Error('Function not implemented.');
}

type Props = {
    PageType?: any,
}

const Upload: FC<Props> = (PageType) => {
    const [childData, setChildData] = useState("");
    const [isLoading, setLoading] = useState(false)
    const [DateMonthFrom, setDateMonthFrom] = useState("")
    const [FromDate, setFromDate] = useState("")
    const [SelectedState, setSelectedState] = useState({ label: "", value: "" })
    const [SelectedQuarter, setSelectedQuarter] = useState({ label: "", value: "" })
    const [open, setOpen] = React.useState(false);
    const [SelectedSpeciality, setSelectedSpeciality] = useState({ label: "", value: "" })
    const [ErrorDataMismatchTemplate, setErrorDataMismatchTemplate] = useState([]);
    const [SuccessFileNames, setSuccessFileNames] = useState([]);
    const [DataNoFound, setDataNoFound] = useState([]);
    const [SpecialityList, setSpecialityList] = useState([])
    const [FileList, setFileList] = useState([])
    const GetPmList = async () => {
        const GetPracticeListData = await axios.get(getSpecialityList)
        if (GetPracticeListData.data.SpecialityList) {
            setSpecialityList(GetPracticeListData.data.SpecialityList)
        }

    }
    const GetFileList = async () => {
        let URL = `${getUploadASPfeeschedule}?Prefix=Upload ASP fee schedule`
        if (PageType.PageType == 0) {
            URL = `${getUploadASPfeeschedule}?Prefix=Upload ASP fee schedule`
        } else if (PageType.PageType == 1) {
            URL = `${getUploadASPfeeschedule}?Prefix=Physician Medicare Fee Schedule`
        } else if (PageType.PageType == 2) {
            URL = `${getUploadASPfeeschedule}?Prefix=Upload MGMA Standard`
        }
        const GetData = await axios.get(URL)
        if (GetData.data.FileList) {
            setFileList(GetData.data.FileList)
        } else {
            setFileList([])
        }
    }
    useEffect(() => {
        GetFileList()
        if (PageType.PageType == 2) {
            GetPmList()
        }
    }, [])
    const Downlaod = async (fileName: any) => {
        
        axios.get(FileDownlaod,{params : {fileName}}).then((response) => {
            const url = window.location.origin + "/" + response.data;
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', '');
            document.body.appendChild(link);
            link.click();
        }).catch((error) => console.log(error));
    }
    const getSateNames = (event: any) => {
        setSelectedState(event)
    }
    const getQuarter = (event: any) => {
        setSelectedQuarter(event)
    }
    const handleDateChangeFrom = (date: any) => {
        console.log("date", date)
        setDateMonthFrom(date)
        setFromDate(moment(date['$d']).format("MMM-YYYY"))
      };
    const getSpeciality = (event: any) => {
        setSelectedSpeciality(event)
    }
    const DownloadFile = async (e:any)=>{
        e.preventDefault();
        let URL = `${getSampleDownlaodfeeschedule}?Prefix=Upload ASP fee schedule`
        let FileName = ""
        if (PageType.PageType == 0) {
            FileName = "Upload ASP fee schedule"
            URL = `${getSampleDownlaodfeeschedule}?Prefix=${FileName}`
        } else if (PageType.PageType == 1) {
            FileName = "Physician Medicare Fee Schedule"
            URL = `${getSampleDownlaodfeeschedule}?Prefix=${FileName}`
        } else if (PageType.PageType == 2) {
            FileName = "Upload MGMA Standard"
            URL = `${getSampleDownlaodfeeschedule}?Prefix=${FileName}`
        }
        await axios.get(URL,{responseType: 'arraybuffer',}).then((response) => {
            if(response.status == 200){
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `SampleTemplate_${FileName}.xlsx`);
              document.body.appendChild(link);
              link.click();
            }else {
              toast.info(`No Data Found`,ToastOption);
            }
        }).catch((error) => console.log(error));
        return false;
      }
      
    const onDrop = useCallback((acceptedFiles: any[]) => {

        var filestotal: any = acceptedFiles.map((file: any) => Object.assign(file, {
            preview: URL.createObjectURL(file)
        }))
        setChildData(filestotal)

    }, [onFileUploaded]);

    const {
        acceptedFiles,
        getRootProps,
        getInputProps
    } = useDropzone({

        onDrop,
        accept: {
            'application/vnd.ms-excel': ['.xlsx']
        },
    });
    const acceptedFileItems: any = acceptedFiles.map((file: any) => {

        return (<li key={file.path}>
            {file.path} - {bytesForHuman(file.size)}
        </li>)
    });
    const formik = useFormik({
        initialValues: initialUser,
        validationSchema: UserDetailsSchema,
        onSubmit: async (values, { setSubmitting }) => {
            console.log("values", values)
            setSubmitting(true)
            try {
                setErrorDataMismatchTemplate([])
                setSuccessFileNames([])
                setDataNoFound([])
                var formData = new FormData();
                let URL: string = UploadASPfeeschedule
                if (PageType.PageType == 0) {
                    let error = false
                    let ErrorArray = []
                    if(!FromDate){
                      error = true
                      ErrorArray.push(`Please Choose From Month / Year`)
                    }
                    if(!SelectedQuarter.value){
                      error = true
                      ErrorArray.push(`Please Select Quarter`)
                    }
                    if(!childData){
                        error = true
                        ErrorArray.push(`Please Select Files`)
                    }
                    if(error){
                      ErrorArray.map((res,ind)=>{
                        toast.error(`${res}`,ToastOption);
                      })
                      return false;
                    }
                    formData.append("FromDate",FromDate);
                    formData.append("Quarter", SelectedQuarter.value);
                    Object.entries(childData).forEach(([key, value]) => {
                        formData.append("ClaimFiles", value);
                    });
                    URL = UploadASPfeeschedule
                } else if (PageType.PageType == 1) {

                    let error = false
                    let ErrorArray = []
                    if(!FromDate){
                      error = true
                      ErrorArray.push(`Please Choose From Month / Year`)
                    }
                    if(error){
                      ErrorArray.map((res,ind)=>{
                        toast.error(`${res}`,ToastOption);
                      })
                      return false;
                    }
                    formData.append("FromDate",FromDate);
                    Object.entries(childData).forEach(([key, value]) => {
                        formData.append("ClaimFiles", value);
                    });
                    URL = PhysicianMedicareFeeSchedule
                } else if (PageType.PageType == 2) {
                    let error = false
                    let ErrorArray = []
                    if(!SelectedSpeciality.value){
                      error = true
                      ErrorArray.push(`Please Select Speciality`)
                    }
                    if(error){
                      ErrorArray.map((res,ind)=>{
                        toast.error(`${res}`,ToastOption);
                      })
                      return false;
                    }
                    formData.append("Speciality", SelectedSpeciality.value);
                    Object.entries(childData).forEach(([key, value]) => {
                        formData.append("ClaimFiles", value);
                    });
                    URL = UploadMGMAStandard
                }
                setLoading(true)
                const response = await axios.put(URL, formData)

                var GetResponseData = response.data
                var MismatchTemplates = GetResponseData.Errors.MismatchTemplates
                var DataNoFound = GetResponseData.Errors.DataNoFound

                var SuccessFileNames = GetResponseData.SuccessFileNames

                if (MismatchTemplates.length > 0) {
                    setErrorDataMismatchTemplate(MismatchTemplates)
                } else {
                    setErrorDataMismatchTemplate([])
                }
                if (SuccessFileNames.length > 0) {
                    setSuccessFileNames(SuccessFileNames)
                } else {
                    setSuccessFileNames([])
                }
                if (DataNoFound.length > 0) {
                    setDataNoFound(DataNoFound)
                } else {
                    setDataNoFound([])
                }
                setChildData("")
                setLoading(false)
            } catch (ex) {
                setLoading(false)
                console.error(ex)
            }
        },
    })
    var Result: any;
    if (FileList.length > 0) {
        Result = FileList.map((res: any, _inde: any) => {
            var FileName = res.FileName.split("/")
            return (
                <tr>
                    <td >{FileName.slice(-1)}</td>
                    <td >{moment(res.LastModified).format("MM-DD-YYYY")}</td>
                    <td ><a className='btn btn-primary' onClick={() => Downlaod(res.FileName)}>Download </a> </td>
                </tr>
            )
        })
    }
    return (
        <>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
        // onClick={handleClose}
        >
            <CircularProgress color="inherit" />
            <Typography variant="h6" component="div" color="inherit" mt={2} ml={4}>
            Loading...
            </Typography>
        </Backdrop>
            <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
                <div className='d-flex flex-column scroll-y me-n7 pe-7' id='kt_modal_add_user_scroll' data-kt-scroll='true' data-kt-scroll-activate='{default: false, lg: true}' data-kt-scroll-max-height='auto' data-kt-scroll-dependencies='#kt_modal_add_user_header' data-kt-scroll-wrappers='#kt_modal_add_user_scroll' data-kt-scroll-offset='300px'>
                    <Formik initialValues={{ myField: '', }} onSubmit={values => { console.log(values); }}>
                        <div className='row'>
                            {PageType.PageType == 0 ?
                                <>
                                <div className='col-sm-3 fv-row'>
                                    <label className='col-lg-6 col-form-label fw-bold fs-6'>
                                        <span className='required'>Select Year</span>
                                    </label>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                        value={DateMonthFrom}
                                        minDate={moment("2015")}
                                        renderInput={(params:any) => <TextField {...params}  />}
                                        maxDate={moment()}
                                        views={['year']} disableFuture
                                        onChange={handleDateChangeFrom}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div className='col-sm-4 fv-row'>
                                    <label className='col-lg-6 col-form-label fw-bold fs-6'>
                                        <span className='required'>Select Quarter</span>
                                    </label>
                                    <Select maxMenuHeight={180} value={SelectedQuarter} onChange={getQuarter} id="Quarter" name={"Quarter"} options={[
                                        {label : "Select",value:""},
                                        {label : "Quarter 1 (Jan-Feb-Mar)",value:"Q1"},
                                        {label : "Quarter 2 (Apr-May-Jun)",value:"Q2"},
                                        {label : "Quarter 3 (Jul-Aug-Sept)",value:"Q3"},
                                        {label : "Quarter 4 (Oct-Nov-Dec)",value:"Q4"}
                                    ]} />
                                </div> 
                                </>
                                 : null
                            }
                            {PageType.PageType == 1 ?
                                <div className='col-sm-3 fv-row'>
                                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                                    <span className='required'>Select Year / Month</span>
                                </label>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                    value={DateMonthFrom}
                                    minDate={moment("2015")}
                                    renderInput={(params:any) => <TextField {...params}  />}
                                    maxDate={moment()}
                                    views={['year']} disableFuture
                                    onChange={handleDateChangeFrom}
                                    />
                                </LocalizationProvider>
                            </div> : null
                            }
                            {PageType.PageType == 2 ?
                                <div className='col-sm-4 fv-row'>
                                    <label className='col-lg-6 col-form-label fw-bold fs-6'>
                                        <span className='required'>Select Speciality</span>
                                    </label>
                                    <Select maxMenuHeight={180} value={SelectedSpeciality} onChange={getSpeciality} id="Speciality" name={"Speciality"} options={SpecialityList} />
                                </div> : null
                            }
                            <div className='w-100'>
                                <hr/>
                                <div className='fv-row mb-10'>
                                    <label className='form-label required'>Select Files</label>
                                    <div {...getRootProps({ className: 'dropzone' })}>
                                        <Field name="ClaimFiles" id="ClaimFiles" type="file" multiple {...getInputProps()} />

                                        <p>Drag 'n' drop some files here, or click to select files</p>

                                    </div>
                                    <br />
                                    <aside>
                                        <ul>{acceptedFileItems}</ul>
                                    </aside>

                                    <div className='text-danger mt-2'>
                                        <ErrorMessage name='ClaimFiles' />
                                    </div>

                                    {
                                        SuccessFileNames.length > 0 ?
                                            <>
                                                <h4>Success Report : </h4>
                                                {SuccessFileNames.map((res: any) => {
                                                    return (<li dangerouslySetInnerHTML={{ __html: res }}></li>)
                                                })}
                                                <br />
                                            </>
                                            : null
                                    }

                                    {
                                        DataNoFound.length > 0 ?
                                            <>
                                                <h4>No Data Found Report : </h4>
                                                {DataNoFound.map((res: any) => {
                                                    return (<li dangerouslySetInnerHTML={{ __html: res }}></li>)
                                                })}
                                                <br />
                                            </>
                                            : null
                                    }

                                    {
                                        ErrorDataMismatchTemplate.length > 0 ?
                                            <>
                                                <h4>Template Mismtach Report : </h4>
                                                {ErrorDataMismatchTemplate.map((res: any) => {
                                                    return (<li dangerouslySetInnerHTML={{ __html: res }}></li>)
                                                })}
                                            </>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                    </Formik>
                </div>
                <div className='text-center pt-15'>
                <a rel="noreferrer"  onClick={(e) => { DownloadFile(e) }} href={`api/PracticeSummary/getSampleDownlaodfeeschedule`} className='btn btn-lg btn-primary me-3'>Sample Template Downlaod</a>
                    <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-users-modal-action='submit'
                        disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                    >
                        <span className='indicator-label'>Submit</span>
                        {(formik.isSubmitting) && (
                            <span className='indicator-progress'>
                                Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div>
                <div>
                </div>
                {Result ? <>
                    <table id='kt_table_users' className='table-row-gray-700 table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                        <thead>
                            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                <th>File Name</th>
                                <th>Upload Date</th>
                                <th>Download</th>
                            </tr>
                        </thead>
                        <tbody className='text-gray-600 fw-bold' >
                            {Result}
                        </tbody>
                    </table>
                </> : ""}
            </form>
            {/* {isLoading && <PageLoading />} */}
        </>
    )
}
export default Upload