import React, { FC, useState, useEffect } from 'react'
import axios from 'axios'
import Select from 'react-select';
import moment from "moment";
import { toast } from 'react-toastify';
import ToastOption from './Common/ToastOption'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import swal from "sweetalert"
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { GridFilterModel, GridSortModel } from '@mui/x-data-grid-pro';
import TextField from '@mui/material/TextField';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth } from '../../modules/auth'
const getPracticeList = `/api/practice/getPracticeListById`
const getPmList = `/api/pmsystem/getPmList`
const MonthendReoprt = `/api/PracticeSummary/MonthendReoprt`




const RestrictReports: FC<any> = ({ user, isUserLoading }) => {

  const [open, setOpen] = React.useState(false);
  const [WorkingDaysLoading, setWorkingDays] = useState(false)
  const [SelectedPractice, setSelectedPractice] = useState({ label: "", value: "" })
  const { currentUser }: any = useAuth()
  const [getPmListData, setdatass] = useState([{ PmName: "", _id: "" }])
  
  const [ReportNameList, setReportNameList] = useState<any>([
    {label:"Charges By Posted Date",value:"Charges By Posted Date"},
    {label:"Denial Report",value:"Denial Report"},
    {label:"Payments by Posted Date",value:"Payments by Posted Date"},
    {label:"Adjustments by Posted Date",value:"Adjustments by Posted Date"},
    {label:"All Transaction Report",value:"All Transaction Report"},
    {label:"Patient Aging",value:"Patient Aging"},
    {label:"AR Insurance Aging Report",value:"AR Insurance Aging Report"},
    {label:"Claim Submission",value:"Claim Submission"},
    {label:"Reffering Doctor",value:"Reffering Doctor"},
    {label:"Month-wise Practice Details",value:"Month-wise Practice Details"},
    {label:"Payer Mix",value:"Payer Mix"},
    {label:"Provider Productivity",value:"Provider Productivity"},
    {label:"AR Compilation Report",value:"AR Compilation Report"},
    {label:"Practice Overview",value:"Practice Overview"},
    {label:"Profit & Loss Report",value:"Profit & Loss Report"},
    {label:"Reimbursement Audit",value:"Reimbursement Audit"},
    {label:"MGMA Report",value:"MGMA Report"},
  ])

  const [SelectedReportName, setSelectedReportName] = useState([])

  const GetPmList = async () => {
    const getPmListData = await axios.get(getPmList)
    if (getPmListData.data) {
      setdatass(getPmListData.data.data)
    }
  }
  const [PracticeList, setUserNameDatas] = useState([{ value: "", label: "" }])
  const getPowerBiPracticeName = `/api/PracticeSummary/getAllPracticeName`
  const getPracticeNameata = async () => {
    const Result = await axios.get(getPowerBiPracticeName)
    if(currentUser.EmailId == "dkumar@scioms.com"){
      var Data : any = Result.data.PracticeData.filter((x :any) => x.label == "Mid Florida")
      setUserNameDatas(Data)
    }else {
      setUserNameDatas(Result.data.PracticeData)
    }
  }
  useEffect(() => {
    getPracticeNameata()
  }, [])
  
  const getReportNameColumns = (event: any) => {
    setSelectedReportName(event)

  }
  useEffect(() => {
    GetPmList()
  }, [])

  const getPractice = (event: any) => {
    if (Object.keys(event).length > 0) {
      setSelectedPractice(event)
      axios.post(`/api/PracticeSummary/GetSelectedReportNameList`, { 
        PracticeName:  event.value,
      }).then((res) => {
        if(res.data.Data){
          setSelectedReportName(JSON.parse(res.data.Data[0].bi_report_names))
        }
      })
    } else {
      setSelectedPractice({ label: "", value: "" })
    }
  }

  const handleSubmit = async (_e: any) => {
    _e.preventDefault();
    let error = false
    let ErrorArray = []
   
    if (SelectedReportName.length  == 0) {
      error = true
      ErrorArray.push(`Please Select Report`)
    }
    if (!SelectedPractice.label) {
      error = true
      ErrorArray.push(`Please Select PM System`)
    }
    if (error) {
      ErrorArray.map((res, ind) => {
        toast.error(`${res}`, ToastOption);
      })
      return false;
    }
    axios.post(`/api/PracticeSummary/UpdatebiReportNames`, { 
      PracticeName:  SelectedPractice.value,
      SelectedReportName : JSON.stringify(SelectedReportName)

    }).then((res) => {
      swal({
        title: '',
        icon: "success",
        text: `Report Names Updated ${SelectedPractice.value}`,
      }).then((_res: any) => {

      })
    })
  }

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Power BI</h3>
        </div>
      </div>
      <hr />
      <form id='kt_modal_add_user_form' className='form position-relative h-100 w-100' noValidate>
        <div>
          <div className='row'>
            
            <div className='col-sm-4 fv-row'>
              <label className='col-lg-9 col-form-label fw-bold fs-6'>
                <span className='required'>Practice Name</span>
              </label>
              <Select maxMenuHeight={180} value={SelectedPractice} onChange={getPractice} id="Practice" name={"Practice"} options={PracticeList} />
            </div>
            <div className='col-sm-4 fv-row'>
              <label className='col-lg-12 col-form-label fw-bold fs-6'>
                <span className='required'>Select Report Name</span>
              </label>

              <Select isMulti maxMenuHeight={180} value={SelectedReportName} onChange={getReportNameColumns} id="ReportName" name={"ReportName"} options={ReportNameList} />
            </div>
            <div className='col-4 fv-row'>
              <br /> <br/>
              <button type='button' className='btn btn-primary' onClick={handleSubmit}>
                {WorkingDaysLoading ? "Loading" : "Submit"}
              </button>
          </div>
          </div>
          
        </div>
        <div></div>
      </form>
    </>
  )
}
export default RestrictReports