import {ToastOptions} from "react-toastify"

const ToastOption : ToastOptions= {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: "",
    theme: "light",
}
export default ToastOption