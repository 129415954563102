import React, { FC, useState, useEffect } from 'react'
import axios from 'axios'
import Select from 'react-select';
import moment from "moment";
import { toast } from 'react-toastify';
import ToastOption from './Common/ToastOption'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { GridFilterModel, GridSortModel } from '@mui/x-data-grid-pro';
import TextField from '@mui/material/TextField';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth } from '../../../app/modules/auth'
const getPracticeList = `/api/practice/getPracticeListById`
const getPmList = `/api/pmsystem/getPmList`
const ReimbursementAuditReportDownload = `/api/PracticeSummary/ReimbursementAuditReportDownload`
const ReimbursementAuditReport: FC<any> = ({ user, isUserLoading }) => {

  const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
    items: [],
  });
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'updatedAt',
      sort: 'desc',
    },
  ]);
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    pageSize: {
      page: 0,
      pageSize: 20
    }
  })
  const [open, setOpen] = React.useState(false);
  const [WorkingDaysLoading, setWorkingDays] = useState(false)
  const [SelectedPmSystem, setSelectedPmSystem] = useState({ label: "", value: "" })
  const [SelectedPractice, setSelectedPractice] = useState({ label: "", value: "" })
  const [PracticeListData, setPracticeListData] = useState([])
  const { currentUser }: any = useAuth()
  const [DateMonthTo, setDateMonthTo] = useState("")
  const [getPmListData, setdatass] = useState([{ PmName: "", _id: "" }])
  const [DateMonthFrom, setDateMonthFrom] = useState("")
  const [FromDate, setFromDate] = useState("")
  const [ToDate, setToDate] = useState("")
  const [SelectedProviderName, setSelectedProviderName] = useState({ value: "", label: "" })
  const GetPmList = async () => {
    const getPmListData = await axios.get(getPmList)
    if (getPmListData.data) {
      setdatass(getPmListData.data.data)
    }
  }
  const ParameterStorage = async () => {
    setOpen(true)
    const getPmListData = await axios.get(`/api/PracticeSummary/GetParameterStorage?Path=${window.location.pathname}`)
    setOpen(false)
    if (getPmListData.data != null) {
      let GetData :any= getPmListData.data
      setDateMonthFrom(GetData.FromDate)
      setDateMonthTo(GetData.ToDate)
      setFromDate(GetData.FromDate)
      //setToDate(GetData.ToDate)
      setSelectedPmSystem(JSON.parse(GetData.PMSystem))
      setSelectedPractice(JSON.parse(GetData.PracticeName))
      let PMValue  = JSON.parse(GetData.PMSystem).value
      axios.post(getPracticeList, { PostData:  PMValue}).then((res) => {
        setPracticeListData(res.data.data)
      })
    }
  }
  useEffect(() => {
    GetPmList()
    ParameterStorage()
  }, [])
  
  const handleDateChangeFrom = (date: any) => {
    console.log("date", date)
    setDateMonthFrom(date)
    setFromDate(moment(date['$d']).format("MMM-YYYY"))
  };
  
  const getPMSystem = (event: any) => {
    //setSelectedPractice([])
    setSelectedPmSystem(event)
    if (event.value) {
      axios.post(getPracticeList, { PostData: event.value }).then((res) => {
        setPracticeListData(res.data.data)
      })
    }
  };
  
  const getPractice = (event: any) => {
    if (Object.keys(event).length > 0) {
      setSelectedPractice(event)
    } else {
      setSelectedPractice({ label: "", value: "" })
    }
  }
  const handleDateChangeTo = (date: any) => {
    setDateMonthTo(date)
    setToDate(moment(date['$d']).format("MMM-YYYY"))
  };
  const handleExport = async (_e: any) => {
    _e.preventDefault();
    let error = false
    let ErrorArray = []
    if(!FromDate){
      error = true
      ErrorArray.push(`Please Choose From Month / Year`)
    }
    if(!SelectedPractice.label){
      error = true
      ErrorArray.push(`Please Select Practice`)
    }
    if(!SelectedPmSystem.label){
      error = true
      ErrorArray.push(`Please Select PM System`)
    }
    if(error){
      ErrorArray.map((res,ind)=>{
        toast.error(`${res}`,ToastOption);
      })
      return false;
    }
    setOpen(true)
    var tempValues = {UserId :currentUser._id,Path:window.location.pathname,FromDate:FromDate,PMSystem:JSON.stringify(SelectedPmSystem),PracticeName : JSON.stringify(SelectedPractice)}
    await axios.get(`${ReimbursementAuditReportDownload}?tempValues=${JSON.stringify(tempValues)}&FromDate=${FromDate}&ToDate=${ToDate}&PracticeName=${SelectedPractice.label}`,{responseType: 'arraybuffer',}).then((response) => {
        setOpen(false)
        if(response.status == 200){
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Reimbursement Audit Report-${SelectedPractice.label}.xlsx`);
          document.body.appendChild(link);
          link.click();
        }else {
          toast.info(`No Data Found for this Practice`,ToastOption);
        }
    }).catch((error) => console.log(error));
  }
 
  var PmListData = [{ label: "Select", value: "" }]
  getPmListData.map((name) => (
    PmListData.push({
      label: name.PmName,
      value: name._id
    })
  ))
  return (
    <>
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Reimbursement Audit Report</h3>
          </div>
        </div>
        <hr />
      
      <form id='kt_modal_add_user_form' className='form position-relative h-100 w-100' noValidate>
        <div>
          <div className='row'>
            <div className='col-sm-3 fv-row'>
              <label className='col-lg-12 col-form-label fw-bold fs-6'>
                <span className='required'>Select Month Year</span>
              </label><br />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={DateMonthFrom}
                  minDate={moment("2015")}
                  renderInput={(params: any) => <TextField {...params} />}
                  maxDate={moment()}
                  views={['year', 'month']} disableFuture
                  onChange={handleDateChangeFrom}
                />
              </LocalizationProvider>
            </div>
            {/* <div className='col-sm-3 fv-row'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>
                  <span className='required'>To</span>
                </label><br />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                  value={DateMonthTo}
                  minDate={moment("2015")}
                  renderInput={(params:any) => <TextField {...params}  />}
                  maxDate={moment()}
                  views={['year', 'month']} disableFuture
                  onChange={handleDateChangeTo}

                  />
                </LocalizationProvider>
              </div> */}
            <div className='col-3 fv-row mb-7'>
              <label className='col-lg-8 col-form-label fw-bold fs-6'>
                <span className='required'>PM System</span>
              </label>
              <Select maxMenuHeight={180} value={SelectedPmSystem} onChange={getPMSystem} id="PmId" name={"PmId"} options={PmListData} />


            </div>

            <div className='col-sm-3 fv-row'>
              <label className='col-lg-6 col-form-label fw-bold fs-6'>
                <span className='required'>Practice Name</span>
              </label>

              <Select maxMenuHeight={180} value={SelectedPractice} onChange={getPractice} id="Practice" name={"Practice"} options={PracticeListData} />


            </div>

          </div>
          <div className='col-4'>
            <button type='button' className='btn btn-primary' onClick={handleExport}>
              {WorkingDaysLoading ? "Loading" : "Download"}
            </button>
          </div>
        </div>
        <div></div>
      </form>
    </>
  )
}
export default ReimbursementAuditReport