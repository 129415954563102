import React, { FC, useState, useEffect } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify';
import ToastOption from './Common/ToastOption'
import swal from 'sweetalert'
import Select from 'react-select';
import moment from "moment";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DataGridPro, GridColDef, GridFilterModel, DataGridProProps, GridSortModel, GridRowParams, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridValueGetterParams, GridRowModel } from '@mui/x-data-grid-pro';
import TextField from '@mui/material/TextField';

import Button, { ButtonProps } from '@mui/material/Button';
import { useAuth } from '../../modules/auth'
import { TableStyles } from '../../modules/wizards/common/TableStyle';
import { HideFooterButtons } from '../../modules/wizards/common/HideFooterButtons';
import { buttonBaseProps } from '../../modules/wizards/common/buttonBaseProp';
import { event } from 'jquery';

const getProviderMaster = `/api/ChargesByProvider/getProviderMaster`
const GetMonthEndData = `/api/PracticeSummary/GetMonthEndData`
const MonthEndDataPost = `/api/PracticeSummary/MonthEndDataPost`
const ProviderDays = `/api/PracticeSummary/ProviderDays`
const GetProviderDays = `/api/PracticeSummary/GetProviderDays`
const BackGround: React.CSSProperties = {
  background: `linear-gradient(
        rgba(255, 255, 255, 0.7),
        rgba(255, 255, 255, 0.3)
      ),
      url("https://images.pexels.com/photos/114979/pexels-photo-114979.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500")`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}
const getPracticeList = `/api/practice/getPracticeListById`
const getPmList = `/api/pmsystem/getPmList`
const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});
interface RowData {
  id: number;
  name: string;
  age: number;
  // Add other properties as needed
}

const MonthEndData: FC<any> = ({ user, isUserLoading }) => {
  const { currentUser }: any = useAuth()

  const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
    items: [],
  });
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'updatedAt',
      sort: 'desc',
    },
  ]);
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    pageSize: {
      page: 0,
      pageSize: 20
    }
  })
  //const [editedRow, setEditedRow] = useState(null);
  
  const [ChargesLoading, setChargesLoading] = useState(false)
  const [WorkingDaysLoading, setWorkingDays] = useState(false)
  const [ListProvider, setListProvider] = useState([])
  const [SelectedPmSystem, setSelectedPmSystem] = useState({ label: "", value: "" })
  const [SelectedPractice, setSelectedPractice] = useState({ label: "", value: "" })
  const [PracticeListData, setPracticeListData] = useState([])
  const [formData, setFormData] = useState({
    no_of_days: "",
    pending_charges: '',
    pending_charges_number: "",
    pending_denials_number: "",
    pending_denials: '',
    refund_amount: '',
    pharmacy_collections: '',
  })
  const [showOneDecimalError, setshowOneDecimalError] = useState<boolean>(false);
  const [showTwoDecimalError, setshowTwoDecimalError] = useState<boolean>(false);


  const columns: GridColDef[] = [
    { type: "text", align: 'center', headerAlign: "center", field: 'name', headerName: 'First Name', width: 200, },
    // { type: "text",align: 'center', headerAlign: "center", field: 'middlename', headerName: 'Middle Name', width: 150, },
    { align: 'center', headerAlign: "center", field: 'id', headerName: 'id', width: 200, },
    { type: "text", align: 'center', headerAlign: "center", field: 'month_year', headerName: 'month_year', width: 150, },
    { type: "text", align: 'center', headerAlign: "center", field: 'practice_id', headerName: 'practice_id', width: 200, },
    { type: "text", align: 'center', headerAlign: "center", field: 'worked_days', headerName: 'Worked Days', width: 150, },
  ]
  const [DefaultColumns, setDefaultColumns] = useState({ firstname: true, middlename: true, lastname: true, speciality: true, provider_type: true, worked_days: true })

  const [getPmListData, setdatass] = useState([{ PmName: "", _id: "" }])
  const [DateMonthFrom, setDateMonthFrom] = useState("")
const [FromDate, setFromDate] = useState("")
const [ToDate, setToDate] = useState("")
  const [SelectedProviderName, setSelectedProviderName] = useState({ value: "", label: "" })
  const GetPmList = async () => {
    const getPmListData = await axios.get(getPmList)
    if (getPmListData.data) {
      setdatass(getPmListData.data.data)
    }
  }
  const GetPracticeList = async (event: any) => {
    setChargesLoading(true)
    const res = await axios.get(`${GetMonthEndData}?practice_name=${event.label}&month_year=${FromDate}`)
    if (res.data.MonthEndData.length > 0) {
      setChargesLoading(false)
      var MonthEndData: any = res.data.MonthEndData
      setFormData({
        no_of_days: "",
        pending_charges: MonthEndData[0].pending_charges,
        pending_charges_number: MonthEndData[0].pending_charges_number,
        pending_denials_number: MonthEndData[0].pending_denials_number,
        pending_denials: MonthEndData[0].pending_denials,
        refund_amount: MonthEndData[0].refund_amount,
        pharmacy_collections: MonthEndData[0].pharmacy_collections
      })
    } else {
      setChargesLoading(false)
      setFormData({
        no_of_days: "",
        pending_charges: "",
        pending_charges_number: "",
        pending_denials_number: "",
        pending_denials: "",
        refund_amount: "",
        pharmacy_collections: ""
      })
    }
    var Result = await axios.get(`${getProviderMaster}?practice_name=${event.label}&month_year=${FromDate}&page=${pageState.pageSize.page}&limit=${pageState.pageSize.pageSize}&filter=${JSON.stringify(filterModel.items)}&type=view&sort=${JSON.stringify(sortModel)}`)
    setPageState(old => ({ ...old, isLoading: false, data: Result.data.data, total: Result.data.total }))
  }
  useEffect(() => {
    GetPmList()
  }, [])
  const handleCellEditCommit = (params: any) => {
    const { id, field, value } = params;
    // const updatedRows = rows.map((row: RowData) => {
    //   if (row.id === id) {
    //     return { ...row, [field]: value };
    //   }
    //   return row;
    // });
    // setEditedRow(updatedRows.find((row: RowData) => row.id === id) || null);
  };

  
  
  const handleDateChangeFrom = (date: any) => {
    
    setDateMonthFrom(date)
setFromDate(moment(date['$d']).format("MMM-YYYY"))
  };


  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    if(name ==="pending_charges_number"){
      if (value === '' || /^\d+$/.test(value)) {
        setshowOneDecimalError(false);
      } 
      else {
        setshowOneDecimalError(true);
      }
    }
    if( name ==="pending_denials"){
      if (value === '' || /^\d+$/.test(value)) {
        setshowTwoDecimalError(false);
      } 
      else {
        setshowTwoDecimalError(true);
      }
    }

    setFormData((prev: any) => {
      return {
        ...prev,
        [name]: value,
      }
    })
  }

  const getPMSystem = (event: any) => {
    //setSelectedPractice([])
    setSelectedPmSystem(event)
    if (event.value) {
      axios.post(getPracticeList, { PostData: event.value }).then((res) => {
        setPracticeListData(res.data.data)
      })
    }
  };
  const getNoofDays = async (event: any) => {

    setSelectedProviderName(event)
    setWorkingDays(true)
    const res = await axios.post(GetProviderDays, {
      month_year: FromDate,
      practice_name: SelectedPractice.label,
      provider_id: event.value,
      no_of_days: formData.no_of_days
    })
    if (res.data.Result) {
      setWorkingDays(false)

      var Days: any = res.data.worked_days
      setFormData((prev: any) => {
        return {
          ...prev,
          "no_of_days": Days,
        }
      })
    }
  }
  const getPractice = (event: any) => {
    if (Object.keys(event).length > 0) {
      GetPracticeList(event)
      setSelectedPractice(event)
    } else {
      setSelectedPractice({ label: "", value: "" })
    }
  }
  const SaveProviderDays = async () => {

    let error = false
    let ErrorArray = []
    if(!FromDate){
      error = true
      ErrorArray.push(`Please Choose From Month / Year`)
    }
    if(!SelectedPmSystem.label){
      error = true
      ErrorArray.push(`Please Select PM System`)
    }
    if(!SelectedPractice.label){
      error = true
      ErrorArray.push(`Please Select Practice`)
    }
    if(!SelectedProviderName.value){
      error = true
      ErrorArray.push(`Please Select Provider Name`)
    }
    if(!formData.no_of_days){
      error = true
      ErrorArray.push(`Please Type No Of Days`)
    }
    if(error){
      ErrorArray.map((res,ind)=>{
        toast.error(`${res}`,ToastOption);
      })
      return false;
    }
    setWorkingDays(true)
    const res = await axios.post(ProviderDays, {
      month_year: FromDate,
      practice_name: SelectedPractice.label,
      provider_id: SelectedProviderName.value,
      no_of_days: formData.no_of_days
    })

    if (res.data.Result) {
      setWorkingDays(false)
      swal({
        title: '',
        icon: 'success',
        text: `${res.data.Message}`,
      }).then((_res: any) => {

      })
      return false
    } else {
      setWorkingDays(false)
      swal({
        title: '',
        icon: 'success',
        text: `Something Went to Wrong ...!`,
      }).then((_res: any) => {

      })
      return false
    }
  }
  const handleSubmit = async () => {

    let error = false
    let ErrorArray = []
    if(!FromDate){
      error = true
      ErrorArray.push(`Please Choose From Month / Year`)
    }
    if(!SelectedPmSystem.label){
      error = true
      ErrorArray.push(`Please Select PM System`)
    }
    if(!SelectedPractice.label){
      error = true
      ErrorArray.push(`Please Select Practice`)
    }
    if(!formData.pending_charges){
      error = true
      ErrorArray.push(`Please Type  Pending Charges`)
    }
    if(!formData.pending_denials){
      error = true
      ErrorArray.push(`Please Type  Pending Denials Number`)
    }
    if(!formData.pending_denials_number){
      error = true
      ErrorArray.push(`Please Type  Pending Denials Number`)
    }
    if(!formData.pharmacy_collections){
      error = true
      ErrorArray.push(`Please Type  Pharmacy Collections`)
    }
    if(!formData.refund_amount){
      error = true
      ErrorArray.push(`Please Type  Refund Amount`)
    }
    if(error){
      ErrorArray.map((res,ind)=>{
        toast.error(`${res}`,ToastOption);
      })
      return false;
    }

    setChargesLoading(true)
    const res = await axios.post(MonthEndDataPost, {
      month_year: FromDate,
      practice_name: SelectedPractice.label,
      pending_charges: formData.pending_charges,
      pending_charges_number: formData.pending_charges_number,
      pending_denials: formData.pending_denials,
      pending_denials_number: formData.pending_denials_number,
      pharmacy_collections: formData.pharmacy_collections,
      refund_amount: formData.refund_amount,
    })

    if (res.data.Result) {
      setChargesLoading(false)
      swal({
        title: '',
        icon: 'success',
        text: `Data Updated Successfully ...!`,
      }).then((_res: any) => {

      })
      return false
    } else {
      setChargesLoading(false)
      swal({
        title: '',
        icon: 'success',
        text: `Something Went to Wrong ...!`,
      }).then((_res: any) => {

      })
      return false
    }
  }
  const handleExport = async (_e: any) => {

  }
  console.log("pageState.data", pageState.data)
  const CustomToolBar = () => {

    return (<GridToolbarContainer>

      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <Button
        {...buttonBaseProps}
        onClick={(e) => handleExport(e)}
      >Export</Button>
    </GridToolbarContainer>
    );
  }
  //   const onRowsSelectionHandler = (ids: any) => {
  //     console.log("ids", ids)
  //     const selectedRowsData = ids.map((id: any) => pageState.data.find((row: any) => row.id === id));
  //     setSelectedRows(selectedRowsData)

  // };
  var PmListData = [{ label: "Select", value: "" }]
  getPmListData.map((name) => (
    PmListData.push({
      label: name.PmName,
      value: name._id
    })
  ))

  console.log('formData',formData)
  return (
    <>
      <p className='text-center' style={{ fontFamily: 'Poppins', fontSize: '20px' }}>
        Input Month End Data
      </p>
      <form id='kt_modal_add_user_form' className='form position-relative h-100 w-100' noValidate>
        <div>
          <div className='row bg-secondary shadow-sm p-3 mb-8 bg-white rounded '>
            <div className='col-sm-4 fv-row'>
              <label className='col-lg-6 col-form-label fw-bold fs-6'>
                <span className='required'>Select Month Year</span>
              </label><br />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={DateMonthFrom}
                  minDate={moment("2015")}
                 renderInput={(params:any) => <TextField {...params}  />}
                 maxDate={moment()}
                 views={['year', 'month']} disableFuture
                  onChange={handleDateChangeFrom}
                />
              </LocalizationProvider>
            </div>
            <div className='col-4 fv-row mb-7'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>PM System</span>
              </label>
              <Select maxMenuHeight={180} value={SelectedPmSystem} onChange={getPMSystem} id="PmId" name={"PmId"} options={PmListData} />


            </div>

            <div className='col-sm-4 fv-row'>
              <label className='col-lg-6 col-form-label fw-bold fs-6'>
                <span className='required'>Practice Name</span>
              </label>

              <Select maxMenuHeight={180} value={SelectedPractice} onChange={getPractice} id="Practice" name={"Practice"} options={PracticeListData} />


            </div>

            <div className='col-4'>
              <label className='col-lg-6 col-form-label fw-bold fs-6'>
                <span className='required'>Provider Name</span>
              </label>

              <Select maxMenuHeight={180} value={SelectedProviderName} onChange={getNoofDays} id="ProviderName" name={"ProviderName"} options={pageState.data} />
              <br />


            </div>
            <div className='col-4'>
              <label className='col-lg-6 col-form-label fw-bold fs-6'>
                <span className='required'>Working Days</span>
              </label>
              <input className='p-2 w-70' name='no_of_days' value={formData.no_of_days} onChange={handleInputChange} />
            </div>
            <div className='col-4 pt-11'>
              <button type='button' className='btn btn-primary' onClick={SaveProviderDays}>
                {WorkingDaysLoading ? "Loading" : "Update"}
              </button>
            </div>

          </div>
          <div className='d-flex w-100 h-100 p-6 align-content-center mt-8 bg-secondary shadow-sm p-3 mb-5 bg-white rounded' 
          // style={BackGround}
          >
            {/* 1st column */}

            <div className='d-flex flex-column w-50 pt-2 px-2 mx-4'>


              <div className='col-12 fv-row mb-4 d-flex flex-column'>
                <label className='col-form-label fw-bold fs-6  '>
                  <span className='required '>Pending Charges</span>
                </label>

                <div className="input-group mb-3">
  <span className="input-group-text">123</span>
  <input type="number" className="form-control" 
  placeholder='Pending Charges Number'
   onChange={handleInputChange}
   value={formData.pending_charges_number}
   name='pending_charges_number'
  />
</div>

                {showOneDecimalError && <span style={{ color: 'red' }}>Decimal places are not allowed.</span>}
                <br />

<div className="input-group mb-3">
  <span className="input-group-text">$</span>
  <input type="number" className="form-control" 
  placeholder='Charges'
   onChange={handleInputChange}
   value={formData.pending_charges}
   name='pending_charges'
  />
</div>


              </div>

              <div className='col-12 fv-row mb-4 d-flex flex-column'>
                <label className='col-form-label fw-bold fs-6 '>
                  <span className='required'>Pending Denials</span>
                </label>

                <div className="input-group mb-3">
  <span className="input-group-text">123</span>
  <input type="number" className="form-control" 
  placeholder='Pending Denials'
   onChange={handleInputChange}
   value={formData.pending_denials}
   name='pending_denials'
  />
</div>

                {showTwoDecimalError && <span style={{ color: 'red' }}>Decimal places are not allowed.</span>}
                <br />

                <div className="input-group mb-3">
  <span className="input-group-text">$</span>
  <input type="number" className="form-control" 
  placeholder='Charges'
  name='pending_denials_number' 
   onChange={handleInputChange}
   value={formData.pending_denials_number}
  />
</div>

              </div>

            </div>
            <div className='d-flex flex-column w-50 pt-2 px-2 mx-4'>
              <div className='col-12 fv-row mb-4 d-flex flex-column'>
                <label className='col-form-label fw-bold fs-6 '>
                  <span className='required'>Refund Amount</span>
                </label>
                <div className="input-group mb-3">
  <span className="input-group-text">$</span>
  <input type="number" className="form-control" 
  placeholder='Refund Amount'
   onChange={handleInputChange}
   value={formData.refund_amount}
   name='refund_amount'
  />
</div>
 
              </div>

              <div className='col-12 fv-row mb-4 d-flex flex-column'>
                <label className='col-form-label fw-bold fs-6'>
                  <span className='required'>Pharmacy Collections</span>
                </label>

                <div className="input-group mb-3">
  <span className="input-group-text">$</span>
  <input type="number" className="form-control" 
  placeholder='Pharmacy Collections'
   onChange={handleInputChange}
   value={formData.pharmacy_collections}
   name='pharmacy_collections'
  />
</div>
              </div>
              <div className='text-center pt-15 mx-4'>
                <button type='button' className='btn btn-primary' onClick={handleSubmit}>
                  {ChargesLoading ? "Loading" : "Submit"}
                </button>
              </div>
            </div>

          </div>
          <div className='row'>

          

          </div>
        </div>
        <div></div>
      </form>
    </>
  )
}

export { MonthEndData }
