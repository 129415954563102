import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import axios from 'axios'
import { toast } from 'react-toastify';
import ToastOption from './Common/ToastOption'
import {useAuth} from '../../modules/auth'
import {Chart} from 'react-google-charts'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {DatePicker, DatePickerProps} from '@mui/x-date-pickers/DatePicker'
import TextField from '@mui/material/TextField'
import Select from 'react-select'
import swal from 'sweetalert'
import moment from 'moment'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine
} from "recharts";



const getPracticeName = `/api/PracticeSummary/getPracticeName`
const getNcr = `/api/PracticeSummary/getNcr`
const getWaterfallReport = `/api/DenialReports/WaterfallReport`

const options = {style: 'currency', currency: 'USD'}

const formatDollarValue = (value: { toLocaleString: () => any }) => `$${value.toLocaleString()}`
const WaterFallCollectionReport: React.FC = () => {
  const intl = useIntl()
  const location = useLocation()
  const {currentUser}: any = useAuth()
  const [UserNameDatas, setUserNameDatas] = useState([{value: '', label: ''}])
  const [DateMonthFrom, setDateMonthFrom] = useState('')
  const [FromDate, setFromDate] = useState('')
  const [ToDate, setToDate] = useState('')
  const [DateMonthTo, setDateMonthTo] = useState('')
  const [SelectedPracticeList, setSelectedPracticeList] = useState([])
  const [PieChartData, setPieChartData] = useState([])

  const [NcrData, setNcrData] = useState([])
  const [WaterfallTable, setWaterfallTable] = useState<any>([])
  const [open, setOpen] = React.useState(false)
  const [NoData,setNoData]=useState<boolean>(false);
 

  const FilterDataList = async () => {
    setOpen(true)
    setNoData(false);
    setNcrData([])
    setWaterfallTable([])
    var tempValues = {
      UserId :currentUser._id,
      Path:window.location.pathname,
      FromDate:FromDate,
      PracticeName : JSON.stringify(SelectedPracticeList)
    }
    axios
      .get(
        `${getWaterfallReport}?tempValues=${JSON.stringify(tempValues)}&ChooseDate=${FromDate}&PracticeName=${
          Object.keys(SelectedPracticeList).length > 0 ? JSON.stringify(SelectedPracticeList) : ''
        }`
      )
      .then((_res) => {
        if(_res.data.WaterfallData.length ===0 && _res.data.WaterfallTable.length===0){
          setNoData(true);
          setOpen(false)
        }
        setOpen(false)
        setNcrData(_res.data.WaterfallData)
        setWaterfallTable(_res.data.WaterfallTable)
      })
  }



  const getSelectedPractice = (event: any) => {
    setNcrData([])
    setWaterfallTable([])
    setSelectedPracticeList(event)
  }

  const handleDateChangeFrom = (date: any) => {
    setDateMonthFrom(date)
    setFromDate(moment(date['$d']).format('MMM-YYYY'))
  }
  const handleDateChangeTo = (date: any) => {
    setDateMonthTo(date)
    setToDate(moment(date['$d']).format('MMM-YYYY'))
  }

  const Filter = async (ids: any) => {
    
    let error = false
    let ErrorArray = []
    if(!DateMonthFrom){
      error = true
      ErrorArray.push(`Please Choose From Month / Year`)
    }
    if(SelectedPracticeList.length == 0){
      error = true
      ErrorArray.push(`Please Select Practice`)
    }
    if(error){
      ErrorArray.map((res,ind)=>{
        toast.error(`${res}`,ToastOption);
      })
      return false;
    }
    // if (!DateMonthTo) {
    //   swal({
    //     title: 'Date',
    //     icon: "info",
    //     text: `Please choose To Date`,
    //   }).then((_res: any) => {

    //   })
    //   return false;
    // }

    FilterDataList()
  }
  const ClearFilter = async (ids: any) => {
    setDateMonthFrom('')
    setDateMonthTo('')
    setSelectedPracticeList([])
    setPieChartData([])
  }
  const ParameterStorage = async () => {
    setOpen(true)
    const getPmListData = await axios.get(`/api/PracticeSummary/GetParameterStorage?Path=${window.location.pathname}`)
    setOpen(false)
    if (getPmListData.data != null) {
      let GetData :any= getPmListData.data
      setDateMonthFrom(GetData.FromDate)
      setDateMonthTo(GetData.ToDate)
      setFromDate(GetData.FromDate)
      setToDate(GetData.ToDate)
      setSelectedPracticeList(JSON.parse(GetData.PracticeName))
    }
  }
  const getPracticeNameata = async () => {
    const Result = await axios.get(getPracticeName)
    setUserNameDatas(Result.data.PracticeData)
  }
  useEffect(() => {
    getPracticeNameata()
    ParameterStorage()
  }, [])
  var UsersListData: any = []
  UserNameDatas.map((practiceList: any, index) => {
    UsersListData.push({
      label: practiceList.practice_name,
      value: practiceList.practice_name,
    })
  })
  // var GeneeratePieChartData : any = []
  // var TotalListChart = []
  // var LocationList :any = ["","NCR"]
  // TotalListChart.push(LocationList)

  // if(NcrData.length > 0){
  //   NcrData.map((res: any, _inde: any) => {
  //     var TotalCharges = Number(Number(res.total_paid) + Number(res.adjustments))
  //     var FinalCharges = res.total_paid / (TotalCharges) * 100
  //     GeneeratePieChartData = [res.month_year,FinalCharges]
  //     TotalListChart.push(GeneeratePieChartData)

  //   })
  // }
  // const TotalListChart = [
  //   ['Category', 'Value'],
  //   ['Start', 100],
  //   ['Step 1', -20],
  //   ['Step 2', -10],
  //   ['Step 3', -30],
  //   ['End', 40],
  // ];
  return (
    <>
      <Backdrop
        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
        open={open}
        // onClick={handleClose}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <div>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>KPI’S – Water Fall Collection Report</h3>
          </div>
        </div>
        <hr />
        <div className='row'>
          <>
            <div className='col-sm-4 fv-row'>
              <label className='col-lg-6 col-form-label fw-bold fs-6'>
                <span className='required'>Select Month/Year</span>
              </label>
              <br />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={DateMonthFrom}
                  minDate={moment('2015')}
                  renderInput={(params: any) => <TextField {...params} />}
                  maxDate={moment()}
                  views={['year', 'month']}
                  disableFuture
                  onChange={handleDateChangeFrom}
                />
              </LocalizationProvider>
            </div>
            {/* <div className='col-sm-4 fv-row'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>
                  <span className='required'>To</span>
                </label><br />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                  value={DateMonthTo}
                  minDate={moment("2015")}
                  renderInput={(params:any) => <TextField {...params}  />}
                  maxDate={moment()}
                  views={['year', 'month']} disableFuture
                  onChange={handleDateChangeTo}

                  />
                </LocalizationProvider>
              </div> */}
            <div className='col-sm-4 fv-row'>
              <label className='col-lg-6 col-form-label fw-bold fs-6'>
                <span className=''>Select Practice</span>
              </label>

              <Select
                maxMenuHeight={180}
                value={SelectedPracticeList}
                onChange={getSelectedPractice}
                id='Practice'
                name={'Practice'}
                options={UsersListData}
              />
            </div>
          </>
          <div className='col-1 pt-13'>
            <button
              onClick={(e) => {
                Filter(e)
              }}
              className='btn btn-primary'
            >
              Filter
            </button>
          </div>
          <div className='col-1 pt-13'>
            <button
              onClick={(e) => {
                ClearFilter(e)
              }}
              className='btn btn-primary'
            >
              Clear
            </button>
          </div>
        </div>
        <hr />

        <div className='row'>
          <div className='col-12'>
            {NcrData.length > 0 ? (
              <div>
                {/* <Chart
                  chartType='ColumnChart'
                  width='100%'
                  height='600px'
                  data={NcrData}
                  options={Options}
                /> */}

<BarChart
      width={700}
      height={350}
      data={NcrData}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="Month" 
      angle={-30}
      textAnchor="end"
      interval={0}
      height={60}
      tick={{ fontSize: 10 }}
      />
      <YAxis tickFormatter={formatDollarValue} />
  <Tooltip formatter={(value) => formatDollarValue(value)} />
      <Legend />
      <ReferenceLine y={0} stroke="#000" />
     
      <Bar dataKey="Total_Paid" fill="url(#2ndGradient)" />
      <defs>
            <linearGradient id="2ndGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={1} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0.2} />
            </linearGradient>
          </defs>
    </BarChart>
              </div>
            ) : null}
          </div>
        </div>

        <div className='d-flex justify-content-center'>
          <div className='w-50 shadow rounded-lg mx-4  mt-6 mb-6'>
            {WaterfallTable.length > 0 ? (
              <table
                id='kt_table_users'
                className=' table-bordered align-middle fs-6 p-4  font-family-base no-footer col-12 h-full '
              >
                <thead>
                  <tr>
                    <th className='text-center p-2'>Year</th>
                    <th className='text-center p-2'>Total Paid</th>
                  </tr>
                </thead>
                <tbody>
                  {WaterfallTable.map((res: any, ind: any) => {
                    return (
                      <tr>
                        <td className='px-3 py-2 text-center'>{res.Month ? res.Month : 'None'}</td>
                        <td className='px-3 py-2 text-center'>
                          {res.Total_Paid ? res.Total_Paid.toLocaleString('en-US', options) : '$0'}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            ) : NoData ?
                <h5 className="fw-bold">No Data Found</h5>
                : null}
          </div>
        </div>

      </div>
    </>
  )
}
export {WaterFallCollectionReport}
