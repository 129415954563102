import React, { useEffect, useState, ChangeEvent } from 'react'
import { initialUser } from './_models'
import { KTSVG } from '../../../../_metronic/helpers' 
import { Formik, useFormik } from 'formik'
import axios from "axios";
import Select from 'react-select';
import swal from "sweetalert"
import { Backdrop, CircularProgress } from '@mui/material'
import Addnewvalue from '../components/Addnewvalue'
const CreateNewCPTCode='../api/PracticeSummary/CreateNewCPTCode'
const getPracticeList = `/api/practice/getPracticeListById`
const getPmList = `/api/pmsystem/getPmList`
const getCPTList = `api/PracticeSummary/getCPTList`
const CPTReport: React.FC = () => {
  const [SelectedPmSystem, setSelectedPmSystem] = useState({ label: "", value: "" })
  const [SelectedPractice, setSelectedPractice] = useState({ label: "", value: "" })
  const [SelectedCPTCode, setSelectedCPTCode] = useState({ label: "", value: "" })
  const [CPTList, setCPTList] = useState([])
  const [SelectedReportName, setSelectedReportName] = useState({ label: "", value: "" })
  const [values, setValues] = useState<FormData[]>([]);
  const [open, setOpen] = React.useState(false);
  const [PracticeListData, setPracticeListData] = useState([])
  const [getPmListData, setdatass] = useState([{ PmName: "", _id: "" }])
  const [RadioChecked, setRadioChecked] = useState<any>({
    StatusUpdate: false,
    StatusNew: false,
  });
  const [inputFields, setInputFields] = useState({
    CPT_Code: "",
    CPT_class1: '',
    CPT_class2: '',
    CPT_Description: '',
  })
  const [CPT_class1Content, setCPT_class1Content] = useState('');
  const [CPT_class2Content, setCPT_class2Content] = useState('');
  const [CPT_description, setCPT_Description] = useState('');
  const [showTextBoxes, setShowTextBoxes] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showButton, setShowButton] = useState(false)
  const [showButton1, setShowButton1] = useState(false)
  const [showButton2, setShowButton2] = useState(false)
  const [showtable,setshowtable]=useState(false)
  const [showModal, setshowModal] = useState("");
  const [FormData, setFormData] = useState({
    _class1: '',
    CPT_class2: '',
    CPT_description: '',
    cpt_code: '',
    id:"",
  });
  interface FormData {
    CPT_class1: string;
    CPT_class2: string;
    CPT_description: string;
    CPT_code: string;
   
  }   
  const handleFormDataChange = (data: FormData) => {
    setValues([...values, data]);
};
  const handleOpenModal = (e: any) => {
    setshowModal('show d-block');
    setShowButton1(true)
    setshowtable(true)
    
  }

    const handleCloseModal = () => setshowModal('');

  const GetPmList = async () => {
    const getPmListData = await axios.get(getPmList)
    if (getPmListData.data) {
      setdatass(getPmListData.data.data)
    }

  }
  const formik = useFormik({
    initialValues: initialUser,

    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
    },
  })
  useEffect(() => {
    GetPmList()
  }, [])
  const GetSelectedCPTCode = (event: any) => {
    setShowTextBoxes(true)
    setShowButton(true)

    setInputFields((prev: any) => {
      return {
        ...prev,
        CPT_Code: event.label,
        CPT_class1: event.cpt_class1,
        CPT_class2: event.cpt_class2,
        CPT_Description: event.cpt_description,
      }
    })
    setSelectedCPTCode(event)
    
  }
  const getPractice = (event: any) => {
    if (Object.keys(event).length > 0) {
      setValues([])
      setRadioChecked({
        StatusUpdate: false,
        StatusNew: false,
      })
      setShowDropdown(false)
      setShowTextBoxes(false)
      setShowButton(false)
      setShowButton2(false)
      setshowtable(false)
      setShowButton1(false)
      setSelectedReportName({ label: "", value: "" })
      setSelectedPractice(event)
      formik.setFieldValue("Practice", event.label)
    } else {
      setValues([])
      setSelectedReportName({ label: "", value: "" })
      setSelectedPractice({ label: "", value: "" })
    }

  }
  const getPMSystem = (event: any) => {
   
    setSelectedPmSystem(event)
    if (!event.value) {
      formik.setFieldError("PmName", "PM System is required")
    } else {
      formik.setFieldError("PmName", "")
    }

    if (event.value) {
      formik.setFieldValue("PmName", event.value)
      axios.post(getPracticeList, { PostData: event.value }).then((res) => {
        setPracticeListData(res.data.data)
      })
    }
  };
  var PmListData = [{ label: "Select", value: "" }]
  getPmListData.map((name) => (
    PmListData.push({
      label: name.PmName,
      value: name._id
    })
  ))
  const handleAddData = async (Type:String) => {
    var PostData = {
      Type : Type,
      CPTList : JSON.stringify(values),
      PracticeName: SelectedPractice.label
    }
    if(Type == "Update"){
      Object.assign(PostData,{
        cpt_class1 : inputFields.CPT_class1,
        cpt_class2 : inputFields.CPT_class2,
        cpt_description: inputFields.CPT_Description,
        cpt_code  : inputFields.CPT_Code
      })
    }
    await axios.post(CreateNewCPTCode,PostData).then((geRes)=>{
      swal({
        title: '',
        text: `${geRes.data.Message}`,
        icon: "info",
      }).then((res: any) => {
      })
    }).catch((e)=>{
      console.log("e",e.message)
    })
  }
  const handleDelete = (cptCode: string) => {
    setValues(values.filter(value => value.CPT_code !== cptCode));
    
};

  const handlebuttonclick = async (e: any) => {

    if (e.target.value === 'Update') {
      setOpen(true)
      const FetchData = await axios.get(`${getCPTList}?practice_id=${SelectedPractice.label}`)
      setOpen(false)
      if (FetchData.data.CPTList.length > 0) {
        setCPTList(FetchData.data.CPTList)

      }
      setRadioChecked({ StatusUpdate: true })
      setShowDropdown(true);
      setSelectedOption(false);
      setShowButton1(false);
      setShowButton2(false);
      setshowtable(false)
      setNewEntryValue('');
    } else if (e.target.value === 'New') {
      setRadioChecked({
        StatusUpdate: false,
        StatusNew: false,
      }) 
      setInputFields((prev: any) => {
        return {
          ...prev,
          CPT_class1: "",
          CPT_class2: "",
          CPT_Description: "", 
        }
      })
      setShowTextBoxes(false);
      setCPTList([])
      setRadioChecked({ StatusNew: true })
      setShowDropdown(false);
      setSelectedOption(true);
      setShowButton(false);
      setShowButton2(true);      
      setNewEntryValue('');
      setCPT_class1Content('');
      setCPT_class2Content('');
      setCPT_Description('');
    }
  };
  const [selectedOption, setSelectedOption] = useState(false);
  const [newEntryValue, setNewEntryValue] = useState<string>('');
  
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
   
    setInputFields((prev: any) => {
      return {
        ...prev,
        [name]: value,
      }
    })
    
  };
  
  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>CPT Report</h3>
        </div>
      </div>
      <hr />
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div
          className='d-flex flex-column  me-n7 pe-7'
          id='kt_modal_add_user_scroll'
        >
          <Formik initialValues={{ myField: '', }} onSubmit={values => { console.log(values); }}>
            <div className='row'>
              <div className='col-3 fv-row mb-7'>
                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                  <span className='required'>PM System</span>
                </label>
                <Select maxMenuHeight={180} value={SelectedPmSystem} onChange={getPMSystem} id="PmId" name={"PmId"} options={PmListData} />
                {formik.touched.PmName && formik.errors.PmName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.PmName}</div>
                  </div>
                )}
              </div>
              <div className='col-sm-3 fv-row'>
                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                  <span className='required'>Practice Name</span>
                </label>
                <Select maxMenuHeight={180} value={SelectedPractice} onChange={getPractice} id="Practice" name={"Practice"} options={PracticeListData} />
                {formik.touched.Practice && formik.errors.Practice && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.Practice}</div>
                  </div>
                )}
              </div>
              <div className='col-sm-2 fv-row'>
                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                  <span className='required'>CPT Report</span>
                </label>
                <div className='d-flex'>
                  <div className='form-check form-check-inline mt-4 '>
                    <input
                      checked={RadioChecked.StatusUpdate}
                      className='form-check-input'
                      type='radio'
                      name='CPTReport'
                      id='CPTReportNew'
                      value='Update'
                      onClick={handlebuttonclick}
                    />
                    <label className='form-check-label' htmlFor='flexRadioDefault2'>
                      Update
                    </label>
                  </div>
                  <div className='form-check form-check-inline mt-4'>
                    <input
                      checked={RadioChecked.StatusNew}
                      className='form-check-input'
                      type='radio'
                      name='CPTReport'
                      id='CPTReportNew'
                      value='New'
                      onClick={handlebuttonclick}
                    />
                    <label className='form-check-label' htmlFor='flexRadioDefault2'>
                      New
                    </label>
                  </div>
                </div>
              </div>
              {showDropdown && (
                <div className='col-sm-4 fv-row'>
                  <label className='col-lg-12 col-form-label fw-bold fs-6'>
                    <span>Dropdown</span>
                  </label>
                  <Select maxMenuHeight={180} value={SelectedCPTCode} onChange={GetSelectedCPTCode} id="SelectedCPTCode" name={"SelectedCPTCode"} options={CPTList} />
                  {formik.touched.Practice && formik.errors.Practice && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.Practice}</div>
                    </div>
                  )}
                </div>
              )}
              {
                showButton2 && (
                  <div className='col-sm-4 fv-row'>
                    <div className='form-check form-check-inline mt-12'>
                      <button type='button' className='btn btn-primary' onClick={handleOpenModal} >
                        {' '}
                        Add New Value
                      </button>
                      <Addnewvalue
                        showModal={showModal}
                        handleClose={handleCloseModal}
                        onFormDataChange={handleFormDataChange}
                      />
                    </div>
                  </div>
                )
              }
              {showTextBoxes && (
                <div className='row'>
                  <div className='col-4 fv-row mb-4 d-flex flex-column'>
                    <label className=' col-form-label fw-bold fs-6 w-30'>
                      CPT Code:
                    </label>
                    <input
                      className='p-2 w-70'
                      type="text"
                      name="CPT_Code"
                      value={inputFields.CPT_Code}
                     disabled={selectedOption  ? false:true }
                      onChange={handleInputChange}
                    />
                  </div>                 
                  <div className='col-4 fv-row mb-4 d-flex flex-column'>
                    <label className=' col-form-label fw-bold fs-6 w-30'>
                      <span >CPT Class 1:</span>
                    </label>
                    <input
                      name="CPT_class1"
                      type='text'
                      value={inputFields.CPT_class1}
                      onChange={handleInputChange}
                      className='p-2 w-70'
                    />
                  </div>
                  <div className='col-4 fv-row mb-4 d-flex flex-column'>
                    <label className='col-form-label fw-bold fs-6 w-30'>
                      <span >CPT Class 2:</span>
                    </label>
                    <input
                      name="CPT_class2"
                      type='text'
                      value={inputFields.CPT_class2}
                      onChange={handleInputChange}
                      className='p-2 w-70'
                    />
                  </div>
                  <div className='col-9 fv-row mb-4 d-flex flex-column'>
                    <label className='col-form-label fw-bold fs-6 w-30'>
                      <span >CPT Description:</span>
                    </label>
                    <input
                      name="CPT_Description"
                      type='text'
                      value={inputFields.CPT_Description}
                      onChange={handleInputChange}
                      className='p-2 w-70'
                    />
                  </div>
                </div>
              )}
            </div>
          </Formik>
        </div>
        {
          showButton && (
            <button
              type='submit'
              className='btn btn-primary'
              onClick={()=>handleAddData("Update")}
              data-kt-users-modal-action='submit'
              disabled={formik.isSubmitting}
            >
              <label className='col-lg-12'>
                <span className='indicator-label'>Updated</span>
              </label>
            </button>
          )
        }
        {
          showtable && (
            <div>
              <hr>
              </hr>
              <table className='table'>
                <thead>
                  <tr>
                    <th>CPT Code</th>
                    <th>CPT Class 1</th>
                    <th>CPT Class 2</th>
                    <th>CPT Description</th>
                  </tr>
                </thead>
                <tbody>
                  {values.map((value) => (
                    <tr key={value.CPT_code}>
                      <td>{value.CPT_code}</td>
                      <td>{value.CPT_class1}</td>
                      <td>{value.CPT_class2}</td>
                      <td>{value.CPT_description}</td>
                      <td>
                        <a href='#' onClick={() => handleDelete(value.CPT_code)}>
                          <KTSVG
                            path='/media/icons/duotune/art/art011.svg'
                            className='svg-icon-2 svg-icon-gray-500'
                          />
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )
        }
        {
          showButton1 && (
            <div className='col-form-label fw-bold fs-6 w-30'>
              <div className='col-sm-4 fv-row'>
                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-users-modal-action='submit'
                  onClick={() => handleAddData("Create")}
                  disabled={formik.isSubmitting}
                >
                  <span >Create</span>

                </button>
              </div>
            </div>
          )

        }
      </form>
    </>
  )
}

export default CPTReport;
export { CPTReport }