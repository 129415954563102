import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import axios from "axios";
import { toast } from 'react-toastify';
import ToastOption from './Common/ToastOption'
import { useAuth } from '../../modules/auth'
import { Chart } from "react-google-charts";
import Backdrop from '@mui/material/Backdrop';
import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Select from 'react-select';
import swal from "sweetalert"
import moment from "moment";
const getPracticeName = `/api/PracticeSummary/getPracticeName`
const getDaysinAr = `/api/PracticeSummary/getDaysinAr`
const getBenchMark = `/api/DenialReports/getBenchMark`
const options = { style: 'currency', currency: 'USD' };


const BenchMark: React.FC = () => {
  const intl = useIntl()
  const location = useLocation();
  const { currentUser }: any = useAuth()
  const [UserNameDatas, setUserNameDatas] = useState([{ value: "", label: "" }])
  const [DateMonthFrom, setDateMonthFrom] = useState("")
const [FromDate, setFromDate] = useState("")
const [ToDate, setToDate] = useState("")
  const [DateMonthTo, setDateMonthTo] = useState("")
  const [SelectedPracticeList, setSelectedPracticeList] = useState([])
  const [PieChartData, setPieChartData] = useState([])
const [BenchMarkTable,setBenchMarkTable]=useState<any>([])
const [BenchMarkChart,setBenchMarkChart]=useState<any>([])
  const [open, setOpen] = React.useState(false);
  const [NoData,setNoData]=useState<boolean>(false);


  const FilterDataList = async () => {
    setBenchMarkTable([])
    setBenchMarkChart([])
    setNoData(false);
    setOpen(true)
    var tempValues = {UserId :currentUser._id,Path:window.location.pathname,FromDate:FromDate,PracticeName : JSON.stringify(SelectedPracticeList)}
    axios.get(`${getBenchMark}?ChooseDate=${FromDate}&tempValues=${JSON.stringify(tempValues)}&PracticeName=${Object.keys(SelectedPracticeList).length > 0 ? JSON.stringify(SelectedPracticeList) : ""}`).then(_res => {

      if(_res.data.BenchMarkTable.length ===0 && _res.data.BenchMarkChart.length ===0){
        setNoData(true);
        setOpen(false)
      }
      else{
        setOpen(false)
        setBenchMarkTable(_res.data.BenchMarkTable)
        setBenchMarkChart(_res.data.BenchMarkChart)
      }
    })
  }

  const getSelectedPractice = (event: any) => {
    setBenchMarkTable([])
    setBenchMarkChart([])
    setSelectedPracticeList(event)
  }
  
  const handleDateChangeFrom = (date: any) => {
    setDateMonthFrom(date)
setFromDate(moment(date['$d']).format("MMM-YYYY"))
  };
  const handleDateChangeTo = (date: any) => {
    setDateMonthTo(date)
setToDate(moment(date['$d']).format("MMM-YYYY"))
  };

  const Filter = async (ids: any) => {

    let error = false
    let ErrorArray = []
    if(!DateMonthFrom){
      error = true
      ErrorArray.push(`Please Choose From Month / Year`)
    }
    if(SelectedPracticeList.length == 0){
      error = true
      ErrorArray.push(`Please Select Practice`)
    }
    if(error){
      ErrorArray.map((res,ind)=>{
        toast.error(`${res}`,ToastOption);
      })
      return false;
    }
    if (SelectedPracticeList.length ===0) {
      swal({
        title: 'Practice',
        icon: "info",
        text: `Please choose any Practice`,
      }).then((_res: any) => {

      })
      return false;
    }
    
    FilterDataList()
  }
  const ClearFilter = async (ids: any) => {

    setDateMonthFrom("")
    setDateMonthTo("")
    setSelectedPracticeList([])
    setPieChartData([])

  }
  const ParameterStorage = async () => {
    setOpen(true)
    const getPmListData = await axios.get(`/api/PracticeSummary/GetParameterStorage?Path=${window.location.pathname}`)
    setOpen(false)
    if (getPmListData.data != null) {
      let GetData :any= getPmListData.data
      setDateMonthFrom(GetData.FromDate)
      setDateMonthTo(GetData.ToDate)
      setFromDate(GetData.FromDate)
      setToDate(GetData.ToDate)
      setSelectedPracticeList(JSON.parse(GetData.PracticeName))
    }
  }
  const getPracticeNameata = async () => {
    const Result = await axios.get(getPracticeName)
    setUserNameDatas(Result.data.PracticeData)
  }
  useEffect(() => {
    getPracticeNameata()
    ParameterStorage()
  }, [])
  var UsersListData: any = []
  UserNameDatas.map((practiceList: any, index) => {
    UsersListData.push({
      label: practiceList.practice_name,
      value: practiceList.practice_name
    })
  })


  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
        <Typography variant="h6" component="div" color="inherit" mt={2} ml={4}>
          Loading...
        </Typography>
      </Backdrop>


      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <div >
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Industry Standard-Benchmark</h3>
          </div>
        </div>
        <hr />
        <div className='row'>
          
            <>
              <div className='col-sm-4 fv-row'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>
                  <span className='required'>Select Month/Year</span>
                </label><br />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={DateMonthFrom}
                    minDate={moment("2015")}
                    renderInput={(params:any) => <TextField {...params}  />}
                    maxDate={moment()}
                    views={['year', 'month']} disableFuture
                    onChange={handleDateChangeFrom}
                  />
                </LocalizationProvider>
              </div>
            
              <div className='col-sm-4 fv-row'>
                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                  <span className='required'>Select Practice</span>
                </label>

                <Select maxMenuHeight={180} value={SelectedPracticeList}  onChange={getSelectedPractice} id="Practice" name={"Practice"} options={UsersListData} />

              </div></>
          <div className='col-1 pt-13'>
            <button onClick={(e) => { Filter(e) }} className='btn btn-primary'  >Filter</button>
          </div>
          <div className='col-1 pt-13'>
            <button onClick={(e) => { ClearFilter(e) }} className='btn btn-primary'  >Clear</button>
          </div>
          {/* <div className='col-1 pt-13'>
            <a onClick={(e) => { DownloadFile(e) }} href={`/users/DownloadDashboard?ManagerID=${Object.keys(SelectedManager).length > 0 ? SelectedManager.value : ""}&UserId=${JSON.stringify(SelectedPracticeList)}&FromDate=${formData.FromDate}&ToDate=${formData.ToDate}`} className='btn btn-primary'>Download</a>
          </div> */}

        </div><hr />
<div className='d-flex flex-column'>
<div className='col-12 shadow rounded-lg mx-4'>
          {BenchMarkChart.length > 0 ? 
          <>
           <Chart chartType="ColumnChart" data={BenchMarkChart} options={{
              animation: {
                duration: 1500,
                startup: true,
                legend: { position: 'top', alignment: 'end' },

              },
              is3D: true,
              title: 'Benchmarking Against Industry Standards',
            }} width={"100%"} height={"400px"}
            />
           
          </>
           
            
            
            : null}
         
            </div>


    <div className='d-flex items-center justify-content-center  '>  
<div className='col-8 bg-primary mt-10'>
            <div className='col-12 shadow'>
            {BenchMarkTable.length > 0 ? (
              <table  id='kt_table_users'
              className='table table-striped align-middle fs-6 p-4  font-family-base no-footer col-12 h-full '>
               <thead className='thead-dark bg-dark'>
                  <tr>
                  <th className='text-center p-2 text-white' scope='col'>Category</th>
                    <th className='text-center p-2 text-white'>BenchMark</th>
                    <th className='text-center p-2 text-white'>DM Achieved</th>
                   
                    </tr>
                </thead>
                <tbody>
                  {BenchMarkTable.map((res:any,ind:any)=>{
                    return (
                        <>
                      <tr>
                       <th className='text-center p-2'>AR Days</th>
                       <td className='px-3 py-2 text-center'>{res.GetArDays} days</td>
                       <td className='px-3 py-2 text-center'>{res.ArDataDemo ? res.ArDataDemo +" " + "days" : "None"}</td> 
                   </tr>
                    <tr>
                    <th className='text-center p-2'>NCR</th>
                    <td className='px-3 py-2 text-center'>{res.GetNetCollectionRatio}%</td>
                    <td className='px-3 py-2 text-center'>{res.NcrData ? res.NcrData+" "+"%" : "None"}</td>  
                </tr>
                <tr>
                    <th className='text-center p-2'>Clean Claim Rate</th>
                    <td className='px-3 py-2 text-center'>{res.GetClean_Claim_Ratio}%</td>
                    <td className='px-3 py-2 text-center'>{res.Clean_Claim_Rate ? res.Clean_Claim_Rate+" "+"%" : "None"}</td>  
                </tr>
                <tr>
                    <th className='text-center p-2'>Denial Rate</th>
                    <td className='px-3 py-2 text-center'>{res.GetDenialRate}%</td>
                    <td className='px-3 py-2 text-center'>{res.Denial_Rate ? res.Denial_Rate+" "+"%" : "None"}</td>  
                </tr>
                <tr>
                    <th className='text-center p-2'>AR 120+</th>
                    <td className='px-3 py-2 text-center'>{res.GetAgingAbove120}%</td>
                    <td className='px-3 py-2 text-center'>{res.Final120Rate ? res.Final120Rate+" "+"%" : "None"}</td>  
                </tr>
                <tr>
                    <th className='text-center p-2'>AR 90+</th>
                    <td className='px-3 py-2 text-center'>{res.GetAgingAbove90}%</td>
                    <td className='px-3 py-2 text-center'>{res.Final90Rate ? res.Final90Rate+" "+"%" : "None"}</td>  
                </tr>
                {/* <tr>
                    <th className='text-center p-2'>GCR</th>
                    <td className='px-3 py-2 text-center'>{res.GetGrossCollectionRate}%</td>
                    <td className='px-3 py-2 text-center'>{res.TotalGCR ? res.TotalGCR+" "+"%" : "None"}</td>  
                    </tr> */}
                <tr>
                   
                </tr>
                </>
                    )
                  })}
                </tbody>
              </table>
            ) :  NoData ?
                <h5 className="fw-bold">No Data Found</h5>
                : null}
          </div>
          </div>
          </div>
          </div>
      </div>
    </>
  )
}
export { BenchMark }