import { initialUser } from './_models'
import { FC } from 'react'
import * as Yup from 'yup'
import { Formik, useFormik } from 'formik'
import axios from "axios";
import { toast } from 'react-toastify';
import ToastOption from '../Common/ToastOption'
import Backdrop from '@mui/material/Backdrop';
import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import  { useState } from 'react'


const getSampleDownlaodfeeschedule = `api/PracticeSummary/getSampleDownlaodfeeschedule`

const UserDetailsSchema = Yup.object().shape({
})

type Props = {
    PageType?: any,
}

const SampleTemplateFeeSchedule: FC<Props> = (PageType) => {
    const [open,setOpen]=useState<boolean>(false);

    const DownloadFile = async (e: any) => {
        setOpen(true);
        e.preventDefault();
        let URL = `${getSampleDownlaodfeeschedule}?Prefix=Upload ASP fee schedule`
        let FileName = ""
        if (PageType.PageType == 0) {
            FileName = "Upload ASP fee schedule"
            URL = `${getSampleDownlaodfeeschedule}?Prefix=${FileName}`
        } else if (PageType.PageType == 1) {
            FileName = "Physician Medicare Fee Schedule"
            URL = `${getSampleDownlaodfeeschedule}?Prefix=${FileName}`
        } else if (PageType.PageType == 2) {
            FileName = "Upload MGMA Standard"
            URL = `${getSampleDownlaodfeeschedule}?Prefix=${FileName}`
        }
        await axios.get(URL, { responseType: 'arraybuffer', }).then((response) => {
            if (response.status == 200) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `SampleTemplate_${FileName}.xlsx`);
                document.body.appendChild(link);
                link.click();
                setOpen(false)

            } else {
                setOpen(false)
                toast.info(`No Data Found`, ToastOption);
            }
        }).catch((error) => console.log(error));
        return false;
    }

    const formik = useFormik({
        initialValues: initialUser,
        validationSchema: UserDetailsSchema,
        onSubmit: async (values, { setSubmitting }) => {
        },
    })
    return (
        <>
       <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
        // onClick={handleClose}
        >
            <CircularProgress color="inherit" />
            <Typography variant="h6" component="div" color="inherit" mt={2} ml={4}>
            Loading...
            </Typography>
        </Backdrop>

            <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
                <div className='d-flex flex-column scroll-y me-n7 pe-7' id='kt_modal_add_user_scroll' data-kt-scroll='true' data-kt-scroll-activate='{default: false, lg: true}' data-kt-scroll-max-height='auto' data-kt-scroll-dependencies='#kt_modal_add_user_header' data-kt-scroll-wrappers='#kt_modal_add_user_scroll' data-kt-scroll-offset='300px'>
                    <Formik initialValues={{ myField: '', }} onSubmit={values => { console.log(values); }}>
                        <div className='row'>
                            {PageType.PageType == 0 ?
                                <>
                                    <div className='col-6'>
                                        <a rel="noreferrer" onClick={(e) => { DownloadFile(e) }} href={`api/PracticeSummary/getSampleDownlaodfeeschedule`} className='btn btn-lg btn-primary me-3'>Sample Template Downlaod ASP Fee</a>
                                    </div>

                                </>
                                : null
                            }
                            {PageType.PageType == 1 ?
                                <>
                                    <div className='col-6'>
                                        <a rel="noreferrer" onClick={(e) => { DownloadFile(e) }} href={`api/PracticeSummary/getSampleDownlaodfeeschedule`} className='btn btn-lg btn-primary me-3'>Physician Medicare</a>
                                    </div>

                                </> : null
                            }
                            {PageType.PageType == 2 ?
                                <>
                                    <div className='col-6'>
                                        <a rel="noreferrer" onClick={(e) => { DownloadFile(e) }} href={`api/PracticeSummary/getSampleDownlaodfeeschedule`} className='btn btn-lg btn-primary me-3'>MGMA Standard</a>
                                    </div>

                                </>
                                : null
                            }
                        </div>
                    </Formik>
                </div>

                <div>
                </div>
            </form>
        </>
    )
}
export default SampleTemplateFeeSchedule